import React from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import "./styles/home_carousel.css";
import { detectMob } from '../../Lib/utils/helperFunctions';
import { carouselImgFive, carouselImgFour, carouselImgOne, carouselImgSix, carouselImgThree, carouselImgTwo } from '../../Assests/assets';



export default function HomeCarousel(){


  const imageCarouselArray = [

    !detectMob() && <SplideSlide key="img6" style={{borderRadius: 0}}>
        <video style={{height: "100%", width: "100%", objectFit: "cover", objectPosition: "bottom 0 right 0", pointerEvents: "none"}} autoplay="autoplay" loop muted playsinline>
            <source src={carouselImgOne} type="video/mp4" />
        </video>
    </SplideSlide>,


    <SplideSlide key="img1" style={{borderRadius: 0}}>
        <img style={{height: "100%", width: "100%", objectFit: "cover", objectPosition: "left top"}} src={carouselImgTwo} alt="img1" />
    </SplideSlide>,

    <SplideSlide key="img2" style={{borderRadius: 0}}>
        <img style={{height: "100%", width: "100%", objectFit: "cover"}} src={carouselImgThree} alt="img2" />
    </SplideSlide>,

    <SplideSlide key="img3" style={{borderRadius: 0}}>
        <img style={{height: "100%", width: "100%", objectFit: "cover", objectPosition: "bottom 0 right 0"}} src={carouselImgFour} alt="img3" />
    </SplideSlide>,

    <SplideSlide key="img4" style={{borderRadius: 0}}>
        <img style={{height: "100%", width: "100%", objectFit: "cover", objectPosition: "center top 500px"}} src={carouselImgFive} alt="img3" />
    </SplideSlide>,

    <SplideSlide key="img5" style={{borderRadius: 0}}>
        <img style={{height: "100%", width: "100%", objectFit: "cover"}} src={carouselImgSix} alt="img4" />
    </SplideSlide>,

  ]

  const options = {
    type         : 'loop',
    gap          : '1rem',
    autoplay     : true,
    pauseOnHover : false,
    resetProgress: false,
    fixedHeight       : '75vh',
    arrows        : false,
    breakpoints: {
      600: {
        fixedHeight       : '350px',
      },
    }
  };


  return (
    <div className="wrapper homescreen-carousel">
      <Splide
        options={ options }
        aria-labelledby="autoplay-example-heading"
        hasTrack={ false }
      >
        <div style={ { position: 'relative' } }>
          <SplideTrack>
            { imageCarouselArray.map((slide) => (
              slide
            ))}
          </SplideTrack>
        </div>
      </Splide>
    </div>
  );
};