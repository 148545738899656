import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { BlueButton, InputField, WhiteCard } from '../../../Lib/MuiThemes/MuiComponents';
import { IconButton, MenuItem, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, DesktopDatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { Close } from '@mui/icons-material';
import useSnackBar from '../../../Lib/CustomHooks/useSnackBar';
import dayjs from 'dayjs';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import * as locales from '@mui/x-date-pickers/locales';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 340, md: 700 },
    maxHeight: 650,
    overflowY: "scroll",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 3,
    p: 2,
    display: "flex",
    gap: "10px",
    flexDirection: "column",
  
};

function AddTravellerInBookingPage(props) {
    
      const { t, i18n } = useTranslation();
      const { showSnackBar } = useSnackBar();
      const [isDataValidated, setDataValidated] = useState({
        firstName: false, 
        lastName: false,
        email: false, 
        gender: false, 
        dob: false
      });
      const [passengerDetails, setPassengerDetails] = useState({
        firstName: "",
        lastName: "",
        email: "",
        dob: "",
        gender: "",
        nationality: "",
        passportNumber: "",
        expiryDate: "",
        issuingCountry: "",
        callingCode: "",
        number: ""
      });
      
      /****************************Handling changes**************************** */
    
      function handleChanges(event){
        const {name, value} = event.target;
    
        setPassengerDetails(prevState => ({...prevState, [name]: value}));
        setDataValidated(prevState => ({...prevState, [name]: false}));
      }
    
      /****************************Form validation functions*******************************/
    
      const handleDataValidation = () => {
    
        let isValidated = true;
    
        if(passengerDetails.firstName.length > 50 || passengerDetails.firstName.length < 1 || passengerDetails.firstName.length === 0){
            setDataValidated(prev => ({...prev, firstName: true}))
            isValidated = false;
        }

        if(passengerDetails.lastName.length > 50 || passengerDetails.lastName.length < 1 || passengerDetails.lastName.length === 0){
            setDataValidated(prev => ({...prev, lastName: true}))
            isValidated = false;
        }

        // if(!/^\S+$/.test(passengerDetails.lastName)){
        //     setDataValidated(prev => ({...prev, lastName: true}))
        //     isValidated = false;
        // }
    
        if(passengerDetails.email.length === 0 || !/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(passengerDetails.email)){
            setDataValidated(prev => ({...prev, email: true}))
            isValidated = false;
        }
    
        if(passengerDetails.gender === ""){
            setDataValidated(prev => ({...prev, gender: true}))
            isValidated = false;
        }
        
        if(passengerDetails.dob.length === 0){
            setDataValidated(prev => ({...prev, dob: true}))
            isValidated = false;
        }

        if(passengerDetails.number.length === 0){
            setDataValidated(prev => ({...prev, number: true}))
            isValidated = false;
        }
 
        return isValidated;
      }
    
      /**************************API call : Add master passenger *******************************/
    
      async function addTraveller(){
        if(!handleDataValidation()){
            return showSnackBar("error", t("Please fill the mandatory fields"))
        }

        let passengerObj = {
            name: passengerDetails.firstName.trim(),
            lastName: passengerDetails.lastName.trim(),
            email: passengerDetails.email.trim(),
            dob: passengerDetails.dob.trim(),
            gender: passengerDetails.gender,
            nationality: passengerDetails.nationality.trim(),
            passportNumber: passengerDetails.passportNumber.trim(),
            expiryDate: passengerDetails.expiryDate.trim(),
            issuingCountry: passengerDetails.issuingCountry.trim(),
            callingCode: passengerDetails.callingCode.trim(),
            number: passengerDetails.number.trim(),
            isMasterPassenger: false
        }
        
        props.setTravellersList(prevState => [...prevState, passengerObj]);
        props.addTravelerToTheBooking(passengerObj)
        setPassengerDetails({
            firstName: "",
            lastName: "",
            email: "",
            dob: "",
            gender: "",
            nationality: "",
            passportNumber: "",
            expiryDate: "",
            issuingCountry: "",
            callingCode: "",
            number: ""
        });
        props.setOpen(false);
      }
    
    
      return (
        <Box>
          <Modal
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
                <Box sx={style}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant='h5' fontWeight="bold">{t("addTraveller")}</Typography>
                        <IconButton onClick={() => props.setOpen(false)}>
                            <Close></Close>
                        </IconButton>
                    </Stack>
                    <Typography variant='subtitle1' color="red">
                        {t("pleaseNote")}: {t("theSlightestSpellingErrorOrFirstname")}
                    </Typography>
                    <Typography variant='h5'>{t("mandatory")}</Typography>
                    <Stack direction={{ sm: "column", md: "row" }} spacing={{ xs: 2 , md: 4 }}>
                        <InputField
                            fullWidth
                            name="firstName"
                            type="text"
                            variant='outlined'
                            label={t("firstname")}
                            size='small'
                            value={passengerDetails.firstName}
                            onChange={handleChanges}
                            error={isDataValidated.firstName}
                            helperText={isDataValidated.firstName && t("Passenger firstname is required and must be below 50 characters")}
                        />

                        <InputField
                            fullWidth
                            name="lastName"
                            type="text"
                            variant='outlined'
                            label={t("lastname")}
                            size='small'
                            value={passengerDetails.lastName}
                            onChange={handleChanges}
                            error={isDataValidated.lastName}
                            helperText={isDataValidated.lastName && t("Passenger lastname is required and must be below 50 characters")}
                        />
                    </Stack>
                    <Stack direction={{ sm: "column", md: "row" }} spacing={{xs:2,md:4}}>
                        <InputField
                            fullWidth
                            size='small'
                            name='gender'
                            select
                            label={t("gender")}
                            value={passengerDetails.gender}
                            onChange={handleChanges}
                            error={isDataValidated.gender}
                            helperText={isDataValidated.gender && t("Please select gender")}
                        >
                            
                            <MenuItem value="">{t("selectGender")}</MenuItem>
                            <MenuItem value="MALE">{t("male")}</MenuItem>
                            <MenuItem value="FEMALE">{t("female")}</MenuItem>
                        </InputField>
    
                        <LocalizationProvider 
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n?.language}
                        >
                            <MobileDatePicker
                                disableFuture
                                label={t("dateOfBirth")}
                                format='DD-MM-YYYY'
                                value={passengerDetails.dob ? dayjs(passengerDetails.dob) : null}
                                onChange={(newValue) => newValue !== null && setPassengerDetails(prev => ({...prev, dob: dayjs(newValue["$d"]).format("YYYY-MM-DD")}))}
                                slots={{
                                    textField: (params) => <InputField
                                        // required
                                        size="small" fullWidth {...params}
                                        error={isDataValidated.dob}
                                        helperText={isDataValidated.dob && t("Please enter a valid date format")}
                                    />
                                }}
                            />
                        </LocalizationProvider>
                    </Stack>
                    <Stack direction={{ sm: "column", md: "row" }} spacing={{xs:2,md:4}}>
                        <InputField
                            fullWidth
                            name="email"
                            type="email" 
                            variant='outlined'
                            label={t("email")}
                            size='small'
                            value={passengerDetails.email}
                            onChange={handleChanges}
                            error={isDataValidated.email}
                            helperText={isDataValidated.email && t("Please enter a valid email format")}
                        />

                        <Stack width="100%">
                            <PhoneInput
                                className="secure-payment-phone-field"
                                specialLabel=''
                                country={'fr'}
                                inputStyle={{
                                    border: isDataValidated.number ? "1px solid red" : "1px solid black",
                                    backgroundColor: "white",
                                    height: 36,
                                    fontFamily: "Montserrat",
                                    fontSize: 14,
                                    fontWeight: 500,
                                }}
                                fullWidth
                                placeholder={t("phoneNumber")}
                                type='text'
                                name="phone"
                                value={passengerDetails.number.slice(passengerDetails.callingCode)}
                                onChange={(num, countryDetails) => {
                                    setDataValidated(prev => ({...prev, number: false}))
                                    setPassengerDetails(prev => ({...prev, number: num.slice(countryDetails.dialCode.length), callingCode: countryDetails.dialCode}))
                                }}
                            />
                            <Typography ml={2} height={20} variant='subtitle1' color="error">{isDataValidated.number && t("Phone number must be in valid format")}</Typography>
                        </Stack>
                    </Stack>
                    <Typography variant='h5'>{t("optionalFields")}</Typography>
                    <Stack direction={{ sm: "column", md: "row" }} spacing={{xs:2,md:4}}>
                        <InputField
                            fullWidth
                            name="nationality"
                            type="text" 
                            variant='outlined'
                            label={t("nationality")}
                            size='small'
                            value={passengerDetails.nationality}
                            onChange={handleChanges}
                        />
                        <InputField
                            fullWidth
                            name="passportNumber"
                            type="text" 
                            variant='outlined'
                            label={t("passportNumber")}
                            size='small'
                            value={passengerDetails.passportNumber}
                            onChange={handleChanges}
                        />
                    </Stack>
                    <Stack direction={{ sm: "column", md: "row" }} spacing={{xs:2,md:4}}>   
                        <LocalizationProvider 
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n?.language}
                        >
                            <MobileDatePicker
                                disablePast
                                label={t("expiryDate")}
                                format='DD-MM-YYYY'
                                value={passengerDetails.expiryDate ? dayjs(passengerDetails.expiryDate) : null}
                                onChange={(newValue) => newValue !== null && setPassengerDetails(prev => ({...prev, expiryDate: dayjs(newValue["$d"]).format("YYYY-MM-DD")}))}
                                slots={{
                                    textField: (params) => <InputField
                                        // required
                                        size="small" 
                                        fullWidth 
                                        {...params}
                                    />
                                }}
                            />
                        </LocalizationProvider>
                        <InputField
                            fullWidth
                            name="issuingCountry"
                            type="text" 
                            variant='outlined'
                            label={t("passportIssuingCountry")}
                            size='small'
                            value={passengerDetails.issuingCountry}
                            onChange={handleChanges}
                        />
                    </Stack>
                    <Stack py={2}>
                        <BlueButton onClick={addTraveller} size='large'>
                            {t("addPassenger")}
                        </BlueButton>
                    </Stack>
                </Box>
          </Modal>
        </Box>
      );
    
}

export default AddTravellerInBookingPage