import { Box, Grid, Modal, Skeleton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom';
import HomeBanner from '../ReusableComponents/HomeBanner';
import TopDestinationTourCard from './TopDestinationTourCard';
import axios from 'axios';
import { Axios } from '../../Lib/Axios/AxiosConfig';
import ConnectWithUs from './ConnectWithUs';
import { beautifulTajMahalImg } from '../../Assests/assets';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    boxShadow: 24
};

function TourPackagesList() {

    // const { state } = useLocation();                    // Receive selected country from menu
    const [ searchParams ] = useSearchParams();
    const [ openConnectWithUsModal, setOpenConnectWithUsModal ] = useState(false);
    const [ listOfTourPackage, setListOfTourPackage ] = useState({
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: [],
        error: null
    });

    console.log(searchParams.get("package"));
    console.log(searchParams.get("country"));

    useEffect(() => {
        const controller = axios.CancelToken.source();
        (async () => {
            setListOfTourPackage(prev => ({...prev, isLoading: true}));
            try{
                const response = await Axios({
                    url: searchParams.get("country") !== null && searchParams.get("country") !== undefined ? `fetchPackagesByCountry?packageName=${searchParams.get("package")}&country=${searchParams.get("country")}` : `fetchPackages?packageName=${searchParams.get("package")}`,
                    method: "get",
                    cancelToken: controller.token,
                });

                if(response.status === 200){
                    setListOfTourPackage(prev => ({...prev, isLoading: false, isSuccess: true, data: response.data}));  
                }

                if(response.status  >= 500){
                    setListOfTourPackage(prev => ({...prev, isLoading: false, isError: true, error: "Bad request"}));  
                }
            }catch(error){
                setListOfTourPackage(prev => ({...prev, isLoading: false, isError: true, error: error.message}));  
            }
        })()

        return () => controller.cancel();
    }, [ searchParams ]);


    let jsx;

    if(listOfTourPackage.isLoading){
        jsx = [1, 2, 3, 4, 5, 6, 7, 8].map((skeleton) => (
            <Grid key={skeleton}  item xs={12} sm={6} md={3}>
                <Skeleton animation="wave" variant="rounded" width="auto" height={500} />
            </Grid>
        ))
    }

    if(listOfTourPackage.isError){
        jsx = (
            <Box>
                <Typography>No results found</Typography>
            </Box>
        )
    }

    if(listOfTourPackage.isSuccess){
        jsx = listOfTourPackage?.data.map((tour, index) => (
            <Grid key={index}  item xs={12} sm={6} md={3}>
                <TopDestinationTourCard
                    tourName={tour.name} 
                    destinations={tour.destination}
                    duration={tour.duration}
                    imageUrl={tour.images[0]}
                    packageName={searchParams.get("package")}
                    // packageId={packageId}
                    tourObj={tour}
                    setOpenConnectWithUsModal={setOpenConnectWithUsModal}
                />
            </Grid>
        ))
    }

  return (
    <>
    <Box
      sx={{ backgroundColor: "card.background", overflow: "hidden" }}
    >
     <HomeBanner bannerImage={beautifulTajMahalImg}>
        <Box 
            sx={{
                position: "absolute",
                width: "100%",
                textAlign: "center",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)", 
            }}
        >
          <Typography sx={{fontWeight: "bold", fontSize: {xs: "2rem", md: "3rem"}}} variant="h3" color="text.white">
            {searchParams.get("package").toUpperCase()}
          </Typography>
        </Box>
     </HomeBanner>
     <Box sx={{ backgroundColor: "card.background", px: {xs: 1, md: 15}, py: 7, minHeight: "100vh"}}>
        <Stack py={2}>
            {!!listOfTourPackage?.data?.length && <Typography variant='h5' fontWeight="bold">{listOfTourPackage?.data?.length} tours found</Typography>}
            <Stack 
                py={3}
            >
                <Grid container spacing={5}>
                    {jsx}
                </Grid>
            </Stack>
        </Stack>
     </Box>
   </Box>

   <div>
      <Modal
        open={openConnectWithUsModal}
        onClose={() => setOpenConnectWithUsModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <ConnectWithUs openConnectWithUsModal={openConnectWithUsModal} setOpenConnectWithUsModal={setOpenConnectWithUsModal}/>
        </Box>
      </Modal>
    </div>
   </>
  )
}

export default TourPackagesList