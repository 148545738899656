import React, { useEffect, useState } from 'react'
import { StyledRadioControl, WhiteCard } from '../../../Lib/MuiThemes/MuiComponents'
import { Box, Card, Checkbox, Divider, FormControl, List, ListItem, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { Done } from '@mui/icons-material'
import SeeConditionsTravelInsuranceModal from '../Modals/SeeConditionsTravelInsuranceModal'
import { useTranslation } from 'react-i18next'

const style = {
    width: "auto",
    borderRadius: 10,
    color: "error.main",
    backgroundColor: "white",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "error.main",
    "&:hover": {
        backgroundColor: "transparent",
    },
    "&.Mui-checked": {
        backgroundColor: "error.main",
        color: "white"
    },
    "&.Mui-disabled": {
        backgroundColor: "veryLightBlue.main"
    }
}

function TravelInsuranceOptions({ data, travelInsurance, setTravelInsurance }) {

  const { insurance, totalPassengers, currency } = data;
  const cancellationFare = parseFloat(insurance.cancellationFee.toFixed(2));
  const multiRiskCancellationFare = parseFloat(insurance.fee.toFixed(2));
  const { t } = useTranslation();

  const [ openTravelInsuranceList, setOpenTravelInsuranceList ] = useState(false);

  const handleTravelInsuranceList = (event) => {
    if(event.target.value === "true"){
        setOpenTravelInsuranceList(true)

    }else{
        setOpenTravelInsuranceList(false)
    }
  }

  useEffect(() => {
    if(openTravelInsuranceList){
        setTravelInsurance({fee: cancellationFare, type: 0})
    }else{
        setTravelInsurance({fee: 0, type: null})
    }
  }, [ cancellationFare, openTravelInsuranceList, setTravelInsurance ])

  return (
    <WhiteCard>
        <Stack py={1}>
            <Typography variant='h5' fontWeight="bold" color="primary">{t("travelInsurance")} <Typography component="span" variant='subtitle1'>({t("serviceCharge")})</Typography></Typography>
            <Typography variant='subtitle1'>{t("preferredByMillionsOfTravellers")}</Typography>
        </Stack>
        <FormControl fullWidth>
            <RadioGroup
                sx={{gap: 2}}
                value={openTravelInsuranceList}
                onChange={handleTravelInsuranceList}
            >
                <StyledRadioControl
                    value={true} 
                    control={<Radio />} 
                    label={
                        <>
                        <Typography variant='h6' color="text.main">{t("yesSecureMyTravel")}</Typography>
                        {/* <Typography variant='subtitle1' color="text.main">Travel Insurance Cancellation fee: &nbsp; 
                        <FormattedNumber value={insuranceData.cancellationFee} currency={currency} style="currency"/></Typography> */}
                        </>
                    } 
                />
                {openTravelInsuranceList && 
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            gap: 5,
                            justifyContent: "center"
                        }}
                    >
                        <Card 
                            elevation={3}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                pt: 5,
                                pb: 2,
                                borderRadius: 5
                            }}
                        >
                                <Stack justifyContent="center" alignItems="center" spacing={2}>
                                    <Typography variant='h5' color="error">{t("cancellation")}</Typography>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant='h4'>+ {cancellationFare} {currency}</Typography>
                                        <Typography component="span" variant='subtitle1'> {t("perPerson")}.</Typography>
                                    </Stack>
                                    <Stack>
                                        <List>
                                            <ListItem sx={{ gap: 2, alignItems: "center" }}>
                                                <Done color='success'/>
                                                <Typography variant='h6'>{t("cancellationForAllCauses")}</Typography>
                                            </ListItem>
                                            <ListItem sx={{ gap: 2, alignItems: "center" }}>
                                                <Done color="success"/>
                                                <Typography variant='h6' fontWeight="bold">{t("allPandemicsAndApidemics")}</Typography>
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </Stack>
                                <Stack spacing={2} justifyContent="center" alignItems="center">
                                    <Stack spacing={1} textAlign="center">
                                        <SeeConditionsTravelInsuranceModal/>
                                        <Typography variant='h6'>{t("total")} &nbsp;<Typography variant='h3' component="span">
                                            {cancellationFare * totalPassengers} 
                                        <Typography variant='h6' component="span">{currency}</Typography></Typography></Typography>
                                        <Typography variant='subtitle2'>{t("forAllTravellers")}</Typography>
                                    </Stack>
                                    <Stack>
                                        <Checkbox
                                            sx={style}
                                            icon={<Typography>{t("select")}</Typography>} 
                                            checkedIcon={<Typography>{t("selected")}</Typography>}
                                            value={cancellationFare}
                                            checked={travelInsurance.fee === cancellationFare}
                                            onChange={(e) => {
                                                if(travelInsurance.fee !== cancellationFare){
                                                    setTravelInsurance({ fee: cancellationFare, type: 0 })
                                                }
                                            }}
                                        />
                                    </Stack>
                               </Stack>
                        </Card>
                        <Card 
                            elevation={3}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                pt: 5,
                                pb: 2,
                                borderRadius: 5

                            }}
                        >
                                <Stack justifyContent="center" alignItems="center" spacing={2}>
                                    <Typography variant='h5' color="error">{t("multiRiskCancellation")}</Typography>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant='h4'>+ {multiRiskCancellationFare} {currency}</Typography>
                                        <Typography component="span" variant='subtitle1'> {t("perPerson")}.</Typography>
                                    </Stack>
                                    <Stack>
                                        <List>
                                            <ListItem sx={{ gap: 2, alignItems: "center" }}>
                                                <Done color='success'/>
                                                <Typography variant='h6'>{t("cancellationForAllCauses")}</Typography>
                                            </ListItem>
                                            <ListItem sx={{ gap: 2, alignItems: "center" }}>
                                                <Done color='success'/>
                                                <Typography variant='h6'>{t("missedDepartureCompensation")}</Typography>
                                            </ListItem>
                                            <ListItem sx={{ gap: 2, alignItems: "center" }}>
                                                <Done color='success'/>
                                                <Typography variant='h6'>{t("repatriationAssistance")}</Typography>
                                            </ListItem>
                                            <ListItem sx={{ gap: 2, alignItems: "center" }}>
                                                <Done color="success"/>
                                                <Typography variant='h6' fontWeight="bold">{t("allPandemicsAndApidemics")}</Typography>
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </Stack>
                               <Stack spacing={2} justifyContent="center" alignItems="center">
                                    <Stack spacing={1} textAlign="center">
                                        <SeeConditionsTravelInsuranceModal/>
                                        <Typography variant='h6'>{t("total")} &nbsp;<Typography variant='h3' component="span">
                                            {multiRiskCancellationFare * totalPassengers}
                                        <Typography variant='h6' component="span">{currency}</Typography></Typography></Typography>
                                        <Typography variant='subtitle2'>{t("forAllTravellers")}</Typography>
                                    </Stack>
                                    <Stack>
                                        <Checkbox
                                            sx={style}
                                            icon={<Typography>{t("select")}</Typography>} 
                                            checkedIcon={<Typography>{t("selected")}</Typography>}
                                            value={multiRiskCancellationFare}
                                            checked={travelInsurance.fee === multiRiskCancellationFare}
                                            onChange={(e) => {
                                                if(travelInsurance.fee !== multiRiskCancellationFare){
                                                    setTravelInsurance({ fee: multiRiskCancellationFare, type: 1 })
                                                }
                                            }}
                                        />
                                    </Stack>
                               </Stack>
                        </Card>
                    </Box>
                }
                <Divider></Divider>
                <StyledRadioControl 
                    value={false} 
                    control={<Radio />} 
                    label={
                        <>
                        <Typography variant='h6' color="text.main">{t("noIDontWantToOptForTravelInsuranceForThisTrip")}</Typography>
                        </>
                    }
                />
            </RadioGroup>
        </FormControl>
    </WhiteCard>
  )
}

export default TravelInsuranceOptions