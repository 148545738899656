import React, { useState } from 'react';
import { 
    Typography, 
    Badge,
    Avatar,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Divider,
    Stack,
} from '@mui/material';
import { AccountCircleOutlined, Logout,  SupportAgent } from '@mui/icons-material';
import { logoutUser } from '../../Lib/Redux/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useMenu from '../../Lib/CustomHooks/useMenu';
import { ReuseMenu } from '../../Lib/MuiThemes/MuiComponents';
import { clearUserDetails } from '../../Lib/Redux/AccountSlice';
import { useTranslation } from 'react-i18next';
import SelectCountryDialog from './SelectCountryDialog';

function UserNavbar({auth, profile}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {menu, openMenu, closeMenu} = useMenu();
    const [openCountryDialog, setOpenCountryDialog] = useState(false);
    const { t } = useTranslation();
    

    const logout = () => {
        dispatch(logoutUser());
        dispatch(clearUserDetails());
        // localStorage.clear();
        sessionStorage.clear();
        return navigate("/home", {replace: true})
    }

    return (
    <>
        {/* <Typography variant='h6' color="text.main">{country && `${country} -` } {language.toUpperCase()}</Typography> */}
        <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            // badgeContent={<BsCheckCircleFill style={{color: "black", fontSize: 18}}/>}
        >
            <Avatar onClick={openMenu} sx={{height: "40px", width: "40px", border: "3px solid rgb(184, 218, 255)", cursor: "pointer"}} src={profile.profilePicture }/>
        </Badge>
        <ReuseMenu menu={menu} closeMenu={closeMenu}>

            <ListItemButton sx={{"&:hover": { backgroundColor: "transparent" }}} disableRipple onClick={(e) => {
                e.stopPropagation();
            }}>
                <ListItemIcon>
                    <Avatar sx={{ border: "3px solid rgb(184, 218, 255)", cursor: "pointer"}} src={profile.profilePicture }/>
                </ListItemIcon>
                <Stack direction="column">
                    <Typography variant='h6'>{profile.name}</Typography>
                    {auth.role !== "user" && <Typography variant='subtitle1'>{auth.role}</Typography>}
                </Stack>
            </ListItemButton>
            <Divider></Divider>
            <ListItemButton onClick={() => {
                navigate("/profile/account")
                closeMenu()
            }}>
                <ListItemIcon>
                    <AccountCircleOutlined/>
                </ListItemIcon>
                <ListItemText primary={<Typography variant='subtitle1'>{t("myAccount")}</Typography>} />
            </ListItemButton>
            {/* <Divider></Divider>
            <ListItemButton onClick={(e) => {
                e.stopPropagation();
            }}>
                <ListItemIcon>
                    <LockResetOutlined />
                </ListItemIcon>
                <ListItemText primary={<Typography variant='subtitle1'>{t("changePassword")}</Typography>} />
            </ListItemButton> */}
    
            <Divider />

            <ListItemButton>
                <ListItemIcon>
                    <SupportAgent />
                </ListItemIcon>
                <ListItemText primary={<Typography variant='subtitle1'>{t("support")}</Typography>} />
            </ListItemButton>
            <Divider></Divider>
            <ListItemButton onClick={() => {
                logout() 
                closeMenu()
            }}>
                <ListItemIcon>
                    <Logout />
                </ListItemIcon>
                <ListItemText primary={<Typography variant='subtitle1'>{t("logout")}</Typography>} />
            </ListItemButton>
        </ReuseMenu>
        <SelectCountryDialog open={openCountryDialog} setOpen={setOpenCountryDialog}></SelectCountryDialog>
    </>
    )
}

export default UserNavbar