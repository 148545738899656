import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { Axios } from "../Axios/AxiosConfig";
import useSnackBar from "../CustomHooks/useSnackBar";
import { useTranslation } from "react-i18next";

const PromotionsContext = createContext();

export const PromotionContextProvider = (props) => {

    const { t } = useTranslation()
    const [ promotionsList, setPromotionsList ] = useState([]);
    const { showSnackBar } = useSnackBar();

    useEffect(() => {
        const controller = axios.CancelToken.source();
        (async () => {

            try{
                const response = await Axios({
                    method: "get",
                    url: "promotions",
                    cancelToken: controller.token,
                });
                
                if(response.status === 200){
                    setPromotionsList(response.data)
                }
                
            }catch(error){
                console.log(error);
                showSnackBar("error", t("Error happened while fetching promotions"))
            }
        })()
          
        return () => {
          controller.cancel();
        }

    },[]);

    return(
        <PromotionsContext.Provider value={[ promotionsList ]}>
            {props.children}
        </PromotionsContext.Provider>
    )

}

export const usePromotionContextConsumer = () => {
    return useContext(PromotionsContext);
}