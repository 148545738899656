import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import "./css/packages.css"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Axios } from '../../Lib/Axios/AxiosConfig';
import useSnackBar from '../../Lib/CustomHooks/useSnackBar';
import { useTranslation } from 'react-i18next';

function PackageCard({data}){

    const navigate = useNavigate();

    return(
        <Grid item xs={12} md={4}>
            <div
                onClick={() => navigate(`/packages/tour?package=${data.name}`)} 
                className="card"
                style={{
                    background: `url(${data.image})`,
                    backdropFilter: "brightness(60%)",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                    
                }}
            >
                <div className="card-details">
                    <Typography sx={{ fontWeight: "bold" }} variant='h4' color="text.white">{data.name.toUpperCase()}</Typography>
                    <Typography
                        sx={{
                            backgroundColor: "#2E3191",
                            width: "fit-content",
                            px: 2,
                            py: 1,
                            color: "text.white",
                            borderRadius: 1,
                            mx: "auto",
                        }}
                    >
                        {data.count} Tours
                    </Typography>
                </div>
            </div>
        </Grid>
    )
}

function OurPackages() {

  const [ tourPackagesList, setTourPackagesList ] = useState({
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: [],
    error: null
  });
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();
//   const { state } = useLocation();              // state comes from view more packages navbar
//   console.log(state);

//   console.log(tourPackagesList);

  useEffect(() => {
    const controller = axios.CancelToken.source();
    (async () => {
        setTourPackagesList(prev => ({...prev, isLoading: true}));
        try{
            const response = await Axios({
                url: "fetchPackagesInfo",
                method: "get",
                cancelToken: controller.token,
                validateStatus: function (status) {
                    if(status >= 400){
                        showSnackBar("error", t("Error happened while fetching packages, Please try again later"));   
                        setTourPackagesList(prev => ({...prev, isLoading: false, isError: true, error: t("Error happened on our side, Please try again later")}));
                        throw new Error("Error");
                    }
                    return status;
                }
            });
            console.log(response);
            if(response.status === 200){
    
                setTourPackagesList(prev => ({...prev, isLoading: false, isSuccess: true, data: response.data}));  
            }

            if(response.status  >= 500){
                setTourPackagesList(prev => ({...prev, isLoading: false, isError: true, error: t("Error happened on our side, Please try again later")}));  
            }
        }catch(error){
            setTourPackagesList(prev => ({...prev, isLoading: false, isError: true, error: error.message}));  
        }
    })()

    return () => controller.cancel();
  },[]);


  /* Render elements */
  let jsx;

  if(tourPackagesList.isLoading){
    jsx = [1, 2, 3, 4, 5, 6].map(skeleton => (
        <Grid key={skeleton} item xs={12} md={4}>
            <Skeleton animation="wave" variant="rounded" width="auto" height={300} />
        </Grid>
    ))
  }

  if(tourPackagesList.isError){
    jsx = (<Box sx={{m: "0 auto", pt: 10}}>
        <Typography variant='h5' color="error">{tourPackagesList.error}</Typography>
    </Box>)
  }

  if(tourPackagesList.isSuccess && tourPackagesList.data.length > 0){
    jsx = tourPackagesList.data.map((tourPackage, i) => (
        <PackageCard key={i} data={tourPackage}/>
    ))
  }
  return (
    <Stack>
        <Stack textAlign="center">
            <Typography 
                component={motion.h1} 
                initial={{
                    opacity: 0,
                    y: 50
                }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                    delay: 0.1,
                    damping: 2
                    }
                }}
                sx={{py: 2, fontWeight: "bold"}} 
                variant='h3' 
                color="primary.main"
            >
                {t("ourPackages")}
            </Typography>
            <Typography
                component={motion.h2} 
                initial={{
                    opacity: 0,
                    y: 50
                }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                    delay: 0.1,
                    damping: 2
                    }
                }}
                sx={{fontWeight: "bold"}} 
                variant='body2'
            >
                {t("pondicherryTravelAgency")}
            </Typography>
            <Box sx={{ flexGrow: 1, px: {xs: 1, md: 5}, py: {xs: 3, md: 5} }}>
                <Grid container spacing={5}>
                    {jsx}
                </Grid>
            </Box>
        </Stack>
    </Stack>
  )
}

export default OurPackages