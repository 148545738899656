import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    stops: "",
    price: "",
    duration: "",
    airlines: "",
    departureTime: "",
    arrivalTime: "",
    corporateFares: false
};

export const filterSlice = createSlice({
    name: "flightResultFilter",
    initialState,
    reducers: {
        setStops: (state, action) => {
            return {...state, stops: action.payload}
        },
        removeStops: (state) => {
            return {...state, stops: null}
        },
        setPrice: (state, action) => {
            return {...state, price: action.payload}
        },
        removePrice: (state) => {
            return {...state, price: null}
        },
        setDuration: (state, action) => {
            return {...state, duration: action.payload}
        },
        removeDuration: (state) => {
            return {...state, duration: null}
        },
        setAirlines: (state, action) => {
            return {...state, airlines: action.payload}
        },
        removeAirlines: (state) => {
            return {...state, airlines: null}
        },
        setDepartureTime: (state, action) => {
            return {...state, departureTime: action.payload}
        },
        removeDepartureTime: (state) => {
            return {...state, departureTime: null}
        },
        setArrivalTime: (state, action) => {
            return {...state, arrivalTime: action.payload}
        },
        removeArrivalTime: (state) => {
            return {...state, arrivalTime: null}
        },
        setCorporateFares: (state, action) => {
            return {...state, corporateFares: action.payload}
        },

        removeCorporateFares: (state) => {
            return {...state, corporateFares: false}
        }

    }
});

export const { setStops, removeStops, setPrice, removePrice, setDuration, removeDuration, setAirlines, removeAirlines, setDepartureTime, removeDepartureTime, setArrivalTime, removeArrivalTime, setCorporateFares, removeCorporateFares } = filterSlice.actions;
export const flightResultFilterReducer = filterSlice.reducer;