
import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageContext = createContext();

function LanguageContextProvider(props){

    const ln = localStorage.getItem("i18nextLng");
    const cn = JSON.parse(localStorage.getItem("country"));
    const [language, setLanguage] = useState(ln);
    const [country, setCountry] = useState({
        country: "France",
        code: "FR",
        currency: "EUR",
        currencySymbol: "€"
    });
    const {i18n} = useTranslation();

    useEffect(() => {
        if(!!cn){
            setCountry(cn)
        }
    }, [])

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n])

    useEffect(() => {
        localStorage.setItem("country", JSON.stringify(country));
    },[country])


    return(
        <LanguageContext.Provider value={{language, setLanguage, country, setCountry}}>
            {props.children}
        </LanguageContext.Provider>
    )
}


export {LanguageContextProvider, LanguageContext}