import airportsList from "./airport.json";

export function capitalize(str){                //Captilize string
    return str && str.slice(0,1).toUpperCase().concat(str.slice(1).toLowerCase());
}

export function detectMob() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    
    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

export function getAirports(iataCode){          // returns airport name, city, country based on iatacode
    return airportsList.reduce((airport, currentValue) => {
        if(currentValue.IATA === iataCode){
            airport = { name: currentValue.name, city: currentValue.city, country: currentValue.country }
        }
        return airport;
    }, {});
}

function agentHas(keyword) {
    return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
}

export function isSafari() {
    return (!!window.ApplePaySetupFeature || !!window.safari) && agentHas("Safari") && !agentHas("Chrome") && !agentHas("CriOS");
}


export function age(dob) {
    const today = new Date();
    const birthdate = new Date(dob);
    const age = today.getFullYear() - birthdate.getFullYear() - 
    (today.getMonth() < birthdate.getMonth() || 
    (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate()));

    return age;
}

export function travellerType(age){
    if(age < 2){
        return "Infants"
    }else if(age <= 11 && age >= 2){
        return "Children"
    }else{
        return "Adult"
    }
}

export function getCurrencySymbol(type){
    switch(type){
        case "INR":
            return "₹";
        case "USD":
            return "$";
        case "EUR":
            return "€";
        default: 
            return "";
    }
}

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]
