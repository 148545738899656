import { Box, Checkbox, FormControlLabel, FormGroup, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useState } from 'react'
import SideCarousel from './SideCarousel';
import { Link, useNavigate } from 'react-router-dom';
import { AnchorText, BlueButton, TitleLogo } from '../../Lib/MuiThemes/MuiComponents';
import { useTranslation } from 'react-i18next';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';

function Policy() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted ] = useState(false);
    const steps = [
        'setupYourAccount',
        'acceptTermsAndCondition',
        // 'verifyCodeViaEmail',
    ];

    function proceedAfterTermsAndCondition(){
        navigate("/login")
    }
      
    return (
        <Stack 
            direction="row" 
            height="99vh" 
            justifyContent="center"
            gap={10}
        >
            <SideCarousel></SideCarousel>   
            <Stack 
                p={5} 
                flexDirection="column"
                gap={2}
    
            >
                <Box sx={{ margin: "0 auto" }}>
                    <Link to="/home">
                        <Box sx={{
                            height: "auto",
                            width: 350
                        }}>
                            <TitleLogo/>
                        </Box>
                    </Link>
                </Box>
                <Stepper activeStep={1} alternativeLabel >
                    {steps.map((label) => (
                        <Step key={label}>
                        <StepLabel><Typography variant='subtitle1'>{t(label)}</Typography></StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <AnchorText onClick={() => navigate(-1)}>
                        <MdOutlineKeyboardArrowLeft/>
                        <Typography variant='subtitle1' component="span">
                            {t("back")}
                        </Typography>
                </AnchorText>
                <Stack spacing={3} direction="column" width={{xs: 350, sm: 550}}>
                    <Stack 
                        justifyContent="space-between" 
                        alignItems="center" 
                        direction="row"
                    >
                        <FormGroup>
                            <FormControlLabel
                                aria-label='terms-and-conditions' 
                                name="isTCchecked" 
                                checked={isTermsAndConditionsAccepted} 
                                onChange={() => setIsTermsAndConditionsAccepted(!isTermsAndConditionsAccepted)} 
                                control={<Checkbox />} 
                                label={<Typography>{t("agreeToTermsAndConditions")}</Typography>}
                            />
                        </FormGroup>
                    </Stack>
                    
                    <BlueButton disabled={!isTermsAndConditionsAccepted} onClick={proceedAfterTermsAndCondition}>{t("continue")}</BlueButton>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Policy