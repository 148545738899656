import { createTheme } from "@mui/material"
import * as locales from "@mui/material/locale";

console.log(locales["frFR"]);

export const LightTheme = createTheme({
    palette: {
      common: {
        background: "#FAFAFA"   //whitesmoke
        //containes common black and white color too
      },
      card: {
        background: "#fff"  //white
      },
      primary: {
        main: "#2E3191",
      },
      error: {
        main: "#FF0000"
      },
      veryLightBlue: {
        main: "#F9F7FE"      //Need this light blue in few place
      },
      text: {
        main: "#000",
        light: "#A5A8B0",
        // secondary color : gray
        white: "#fff", //optional, may need this in some components
        black: "#000"
      }
    },
    typography: {
      fontFamily: "'Montserrat', 'sans-serif'",
      h3: {
        fontWeight: 500,
        fontSize: "30px"
      },
      h4: {
        fontWeight: 500,
        fontSize: "20px"
      },
      h5: {
        fontWeight: 500,
        fontSize: "18px"
      },
      h6: {
        fontWeight: 500,
        fontSize: "16px"
      },
      body1: {
        fontWeight: 500,
        fontSize: "14px"
      },
      body2: {
        fontWeight: 500,
        fontSize: "14px",
        color: "gray"
      },
      subtitle1: {
        fontWeight: 500,
        fontSize: "12px"
      },
      subtitle2: {
        fontWeight: 500,
        fontSize: "12px",
        color: "gray"
      },
      button: {
        textTransform: "capitalize"
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        desktop: 2560,
      },
    },
})