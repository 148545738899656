import { Box } from '@mui/material'
import React from 'react'
import HomeBanner from '../ReusableComponents/HomeBanner'
import SubscribeModal from '../FightSearch/SubscribeModal'
import { archivedPromotions } from '../../Assests/assets'
import ArchivedPromotionsList from './ArchivedPromotionsList'

function ArchivedPromotions() {

  return (
    <Box
      sx={{ backgroundColor: "card.background", overflow: "hidden",}}
    >
     <HomeBanner
       bannerImage={archivedPromotions}
     />
     <Box sx={{ backgroundColor: "card.background"}}>
       <ArchivedPromotionsList/>
       <SubscribeModal></SubscribeModal>
     </Box>
   </Box>
  )
}

export default ArchivedPromotions