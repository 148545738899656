import React from 'react';
import { Button, styled, Card, TextField, Box, Typography, Avatar, FormControlLabel, Menu, ToggleButtonGroup, ToggleButton, LinearProgress, IconButton, Tooltip, tooltipClasses,  } from '@mui/material';
import { appleColorIcon, facebookColorIcon, googleColorIcon, transparentLogo, flightLoader, flightSearchLoader, titleLogoWithoutName, globeFlightLoader, logoWithTitleAndLoveIsInTheAir } from '../../Assests/assets';
import { LoadingButton } from '@mui/lab';
import { Backdrop } from '@mui/material';
import Lottie from 'react-lottie-player';
import { useTranslation } from 'react-i18next';
import officialLogo from "../../Assests/official-logo.svg";


export const BlueButton = styled(LoadingButton)(({theme}) => ({    //Contained Blue button
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    px: 1,
    gap: 5,
    width: "100%",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: theme.palette.primary.dark,
    },
    "&:disabled": {
        backgroundColor: theme.palette.text.secondary,
        // color: theme.palette.text.white
    }
}));

export const BlueButtonDashed = styled(LoadingButton)(({theme}) => ({    //outlined dashed Blue button
    borderColor: theme.palette.primary.main,
    border: "2px dashed",
    px: 1,
    gap: 5,
    width: "100%",
    cursor: "pointer",
    "&:hover": {
        borderColor: theme.palette.primary.main,
        border: "2px dashed",
    },
    "&:disabled": {
        backgroundColor: theme.palette.text.secondary,
        color: theme.palette.text.white,
        border: "2px dashed"
    }
}));

export const BlueBox = styled(Box)(({theme}) => ({                 // Box with blue border

    display: "flex",
    alignItems: "center", 
    height: "auto", 
    width: "auto",
    gap: "10px", 
    border: `1px solid ${theme.palette.primary.main}`, 
    borderRadius: "10px",
    padding: "5px",

}))


export const BlackButton = styled(Button)(({theme}) => ({   //Contained black button
    backgroundColor: theme.palette.common.black,
    color: theme.palette.text.white,
    "&:hover": {
        backgroundColor: theme.palette.common.black
    }
}))

export const BlackButtonOutlined = (props) => {     //Border lined black button
    return <LoadingButton variant='outlined' color='text' {...props}>{props.children}</LoadingButton>
}

export const WhiteCard = (props) => {      //white card component
    return(
        <Card elevation={0} sx={{
            ...props.sx,
            backgroundColor: "card.background",
            borderRadius: "10px",
            p: 2,
            boxShadow: "0px 4px 16px rgba(141, 211, 187, 0.15)",
        }}>
            {props.children}
        </Card>
    )
}

export const GrayCard = styled(Card)(({theme}) => ({
    backgroundColor: "#F7F7FB",
    borderRadius: "10px",
    p: 2,
    // boxShadow: "0px 4px 16px rgba(141, 211, 187, 0.15)",
})) 

export const InputField = styled(TextField)(({theme}) => ({     //Styled input field
    backgroundColor: theme.palette.card.background,
    borderRadius: theme.shape.borderRadius,
    border: theme.palette.text.main,
    input: {
        color: theme.palette.text.main,
    },
    '& label': {
        color: theme.palette.text.main,
    },
    '& label.Mui-focused': {
        color: theme.palette.text.main,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
        color: theme.palette.text.main
    },
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: theme.palette.text.main
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.text.secondary,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.text.secondary,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.text.secondary,
        },
    }
}));

export const TitleLogo = () => (                          // tiltle with logo

    <img style={{height: "100%", width: "100%", objectFit: "cover"}} src={officialLogo} alt="logo" />
    
);

export const TitleLogoWithoutName = () => (                          // tiltle with logo
    <Box 
        display={{xs: "flex", sm: "none"}}
        flexDirection="row"
        justifyContent="center" 
        alignItems="center" 
        gap="5px"
        height={50}
        width={80}
    >
        <img style={{height: "100%", width: "100%", objectFit: "cover"}} src={titleLogoWithoutName} alt="logo" />
    </Box>
);

export const AnchorText = styled(Typography)(() => ({   // Yellow color link text
    display: "flex", 
    alignItems: "center",
    color: "#FF8682",
    cursor: "pointer"
}));

export const SocialButton = styled(IconButton)(({theme}) => ({
    color: theme.palette.primary.main, 
    bgcolor: theme.palette.text.white, 
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    transition: "all 0.2s ease",
    "&:hover": {
        color: theme.palette.text.white,
        // transform: "translateY(-5px)"
    }
}))

export const SpanText = styled(Typography)(() => ({   // Yellow color span text
    color: "#FF8682",
    // cursor: "pointer"
}));

export const GoogleButton = (props) => {                // Google button component
    return(
        <LoadingButton 
            variant="outlined"
            sx={{gap: "10px"}}
            {...props}
        >
            {!props.loading && <img src={googleColorIcon} alt="img" height={21} width={21} />}
            {props.children}
        </LoadingButton>
    )
}

export const AppleButton = (props) => {                //Apple button Component
    return(
        <Button variant="outlined"
            sx={{gap: "10px"}}
        >
            <img src={appleColorIcon} alt="img" height={21} width={21} />
            {props.children}
        </Button>
    )
}

export const FacebookButton = (props) => {                //Facebook button Component
    return(
        <Button 
            variant="outlined"
            sx={{gap: "10px"}}
        >
            <img src={facebookColorIcon} alt="img" height={21} width={21} />
            {props.children}
        </Button>
    )
}

export const AvatarProfile =  styled(Avatar)(() => ({
    width: 160,
    height: 160,
    border: `4px solid #CCD6F1`,
}));

export const FullScreenLazyLoader = () => (
    <>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#87CEEB" }}
        open={true}
        // onClick={handleClose}
      >
        <Lottie 
            style={{
                height: 200,
                width: 400
            }}
            loop={true}
            play={true}
            animationData={flightLoader}
            rendererSettings= {{
                preserveAspectRatio: "xMidYMid slice"
            }}
        />
    </Backdrop>
    </>
)

export const FlightLoaderWithTransparentBackdrop = () => (
    <>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#19191bdf" }}
        open={true}
        // onClick={handleClose}
      >
        <Lottie 
            style={{
                height: 200,
                width: 400
            }}
            loop={true}
            play={true}
            animationData={flightLoader}
            rendererSettings= {{
                preserveAspectRatio: "xMidYMid slice"
            }}
        />
    </Backdrop>
    </>
);

export const GlobeLoaderWithTransparentBackdrop = () => (
    <>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#19191bdf" }}
        open={true}
        // onClick={handleClose}
      >
        <Box
          sx={{
            position: "relative",
            // border: "1px solid red",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 500,
            width: 500,
            marginTop: "30vh",
          }}
        >
            <Box
                sx={{
                position: "absolute"
                }}
            >
                <Lottie 
                style={{
                    height: "auto",
                    width: 400,
                    // margin: "0 auto",
                }}
                loop={true}
                play={true}
                animationData={globeFlightLoader}
                rendererSettings= {{
                    preserveAspectRatio: "xMidYMid slice"
                }}
                />
            </Box>
            <Box
                sx={{
                height: "auto",
                width: 250,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
                mb: 10,
                //   border: "1px solid black",
                overflow: "hidden"
                }}
            >
                <img style={{ height: "100%", width: "100%", objectFit: "cover" }} src={logoWithTitleAndLoveIsInTheAir} alt="logo" />
            </Box>
        </Box>
    </Backdrop>
    </>
)

export const FullScreenLoader = () => {
    const { t } = useTranslation();
    return <Backdrop
        sx={{ 
          zIndex: (theme) => theme.zIndex.drawer + 1, 
          background: "linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url('https://images.pexels.com/photos/3881104/pexels-photo-3881104.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
          backgroundImage: "brightness(40%)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          width: "100vw",
        }}
        open={true}
      >
        <Box
          sx={{
            position: "relative",
            // border: "1px solid red",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 500,
            width: 500,
            marginTop: "30vh",
          }}
        >
          <Box
            sx={{
              position: "absolute"
            }}
          >
            <Lottie 
              style={{
                  height: "auto",
                  width: 400,
                  // margin: "0 auto",
              }}
              loop={true}
              play={true}
              animationData={globeFlightLoader}
              rendererSettings= {{
                preserveAspectRatio: "xMidYMid slice"
              }}
            />
          </Box>
          <Box
            sx={{
              height: "auto",
              width: 250,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "column",
              mb: 10,
            //   border: "1px solid black",
              overflow: "hidden"
            }}
          >
            <img style={{ height: "100%", width: "100%", objectFit: "cover" }} src={logoWithTitleAndLoveIsInTheAir} alt="logo" />
            <Typography fontWeight="bold" textAlign="center" variant='body1' color="text.main">{t("pleaseWaitWeAreLookingForBestAvailableFares")}</Typography>
          </Box>
        </Box>
    </Backdrop>
}

export const FlightSearchLoader = () => (
    <Lottie 
        style={{
            height: 400,
            width: 600,
            backgroundColor: "transparent",
            margin: "0 auto"
        }}
        loop={true}
        play={true}
        animationData={flightSearchLoader}
        rendererSettings= {{
            preserveAspectRatio: "xMidYMid slice"
        }}
    />
    
)

export const SavedCard = styled(Box)(({theme}) => ({
    height: "200px",
    width: "auto",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    borderRadius: "15px",
    padding: "20px",
    display: "flex", 
    flexDirection: "column", 
    justifyContent: "space-between",
    boxSizing: "border-box",
    cursor: "pointer"
}));

export const AddNewCard = styled(Box)(({theme}) => ({
    height: "198px",
    width: "auto",
    color: theme.palette.primary.main,
    border: `3px dashed ${theme.palette.primary.main}`,
    borderRadius: "15px",
    padding: "20px",
    display: "flex",
    flexDirection: "column", 
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    cursor: "pointer"
}))


export const StyledRadioControl = styled(FormControlLabel)(() => ({
    padding: "10px",
    marginRight: 2, 
    borderRadius: "10px",
    display: "flex",
    gap: 10,
    transition: "all 0.2s ease-in",
}));

export const ReuseMenu = (props) => {
    // const [openMenu, setOpenMenu] = React.useState(null);

    return (
        <Menu
            anchorEl={props.menu}
            open={Boolean(props.menu)}
            onClose={props.closeMenu}
            onClick={props.closeMenu}
            PaperProps={{
            elevation: 1,
            sx: {
                overflow: 'visible',
                // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 6,
                // '& .MuiAvatar-root': {
                //     width: 32,
                //     height: 32,
                //     ml: -0.5,
                //     mr: 1,
                // },
                '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
                },
            },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {props.children}
        </Menu>
    )
}

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
    //   margin: theme.spacing(1),
      padding: 0,
      border: 0,
    //   '&.Mui-disabled': {
    //     border: 0,
    //   },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
      },
    },
}));

export const StyledToggleButton = styled(ToggleButton)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    outlineColor: theme.palette.text.main, 
    outlineWidth: '1px', 
    outlineStyle: 'solid', 
    margin: '2px'
}))

export const NonStopConnector = () => (
    <Box sx={{width: 150, backgroundColor: "#1DAD3C", p: "2px", borderRadius: 10}}></Box>
)

export const OneStopConnector = () => (
    <Box sx={{position: "relative", width: 150, backgroundColor: "#FF9A3E", p: "2px", borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Box sx={{position: "absolute", height: 15, width: 15, borderRadius: "50%", backgroundColor: "#DCDEE3", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Box sx={{height: 8, width: 8, borderRadius: "50%", backgroundColor: "#51535E"}}></Box>
        </Box>
    </Box>
)

export const TwoStopConnector = () => (
    <Box sx={{position: "relative", width: 150, backgroundColor: "#CD1031", p: "2px", borderRadius: 10, display: "flex", alignItems: "center"}}>
        <Box sx={{position: "absolute", left: "20%", height: 15, width: 15, borderRadius: "50%", backgroundColor: "#DCDEE3", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Box sx={{height: 8, width: 8, borderRadius: "50%", backgroundColor: "#51535E"}}></Box>
        </Box>
        <Box sx={{position: "absolute", right: "20%", height: 15, width: 15, borderRadius: "50%", backgroundColor: "#DCDEE3", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Box sx={{height: 8, width: 8, borderRadius: "50%", backgroundColor: "#51535E"}}></Box>
        </Box>
    </Box>
)

export const LoadingProgressBar = () => (
    <LinearProgress sx={{ width: '100%', position: "fixed", top: 0, left: 0, zIndex: 10000 }} />
)

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));