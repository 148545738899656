import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { BlackButtonOutlined } from '../../../Lib/MuiThemes/MuiComponents';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function LoginToContinuePopup({open, setOpen}) {

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Dialog
        open={open}
        onClose={setOpen}
    >
    <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack>
                <Typography variant="h5">{t("pleaseLoginToContinueYourBookingWith")}</Typography>
                <Typography variant="h5">Errances Voyages</Typography>
            </Stack>
            <IconButton onClick={() => setOpen(false)}>
                <Close></Close>
            </IconButton>
        </Stack>
    </DialogTitle>
    <DialogContent>
            <Typography variant='body1'>
                {t("hiToContinueYourBookingYouNeedToLoginToYourAccountThisOnlyTakeFewMinutesIfYouDontHaveAnErrancesVoyagesAccountPleaseSignup")}
            </Typography>
    </DialogContent>
    <DialogActions>
        <Button onClick={() => navigate("/login")} autoFocus>Login</Button>
        <BlackButtonOutlined onClick={() => navigate("/signup")}>
            Signup
        </BlackButtonOutlined>
    </DialogActions>
    </Dialog>
  );
}