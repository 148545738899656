import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { motion } from "framer-motion"
import OurResultsCountIncrement from './fragments/OurResultsCountIncrement'
import { useTranslation } from 'react-i18next'

function OurResults() {

//   const ref = useRef(null)
//   const isInView = useInView(ref)
  const { t } = useTranslation();

  const container = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.1
      }
    },
    
  }
    
  const item = {
    hidden: { x: -20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1
    },
    
  }

  return (
    <Box sx={{px: {xs: 0, md: 15}, pt: 5}}>
        <Typography
            component={motion.h3} 
            initial={{
                opacity: 0,
                y: 50
            }}
            whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.1,
                  damping: 2
                }
            }}
            // animate={{
            //     opacity: isInView ? 1 : 0,
            //     y: isInView ? 0 : 50,
            //     transition: {
            //       delay: 0.1,
            //       damping: 2
            //     }
            // }}
            sx={{
                textAlign: "center",
                py: 2, 
                fontWeight: "bold"
            }} 
            variant='h3' 
            color="primary.main"
        >
          {t("ourResultsInNumbers")}
        </Typography>
        <Stack 
            direction="row" 
            justifyContent="space-evenly" 
            alignItems="center" 
            py={3} 
            flexWrap="wrap" 
            gap={3}
            component={motion.div}
            variants={container}
            initial="hidden"
            whileInView="visible"
            viewport={{once: false, amount: 0.1}}
        >
            <OurResultsCountIncrement variants={item} count={20} title={t("yearsOfExperience")} />
            <OurResultsCountIncrement variants={item} count={50} title={t("customTourPackages")} />
            <OurResultsCountIncrement variants={item} count={20} title={t("airlinersToChoose")} />
            <OurResultsCountIncrement variants={item} count={50} title={t("guidedTours")} />
        </Stack>
    </Box>
  )
}

export default OurResults