import React, { memo, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { BlueButton, InputField } from '../../../Lib/MuiThemes/MuiComponents';
import { Button, IconButton, MenuItem, Stack, Tooltip } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { Close } from '@mui/icons-material';
import useSnackBar from '../../../Lib/CustomHooks/useSnackBar';
import dayjs from 'dayjs';
import PhoneInput from 'react-phone-input-2';
import useToggle from '../../../Lib/CustomHooks/useToggle';
import { useTranslation } from 'react-i18next';
import * as locales from '@mui/x-date-pickers/locales';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 340, md: 700 },
    maxHeight: 650,
    overflowY: "scroll",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 3,
    p: 2,
    display: "flex",
    gap: "10px",
    flexDirection: "column",
  
};

const button = {
    width: "auto",
    minWidth: "80px",
    borderRadius: 2,
    boxShadow: "0px 2px 10px rgba(155, 157, 172, 0.3)",
    color: "black",
    backgroundColor: "white",
    "&:hover": {
        backgroundColor: "transparent",
    },
    "&.Mui-checked": {
        backgroundColor: "primary.main",
        color: "white"
    },
    "&.Mui-disabled": {
        backgroundColor: "veryLightBlue.main"
    }
}

function EditTravellerInBookingPage({ traveller, index, setTravellersList, setDummy, removeTravelerFromBooking }) {


      const { t, i18n } = useTranslation();
      const [ editModalOpen, openEditModal, closeEditModal ] = useToggle();
      const { showSnackBar } = useSnackBar();
      const [isDataValidated, setDataValidated] = useState({
        firstName: false, 
        lastName: false,
        email: false, 
        gender: false, 
        dob: false
      });
      const [ passengerDetails, setPassengerDetails ] = useState({
        firstName: "",
        lastName: "",
        email: "",
        dob: "",
        gender: "",
        nationality: "",
        passportNumber: "",
        expiryDate: "",
        issuingCountry: "",
        callingCode: "",
        number: ""
      });

      useEffect(() => {

        setPassengerDetails({ 
            firstName: traveller.name,
            lastName: traveller.lastName,
            email: traveller.email,
            dob: traveller.dob,
            gender: traveller.gender,
            nationality: traveller.nationality,
            passportNumber: traveller.passportNumber,
            expiryDate: traveller.expiryDate,
            issuingCountry: traveller.issuingCountry,
            callingCode: traveller.callingCode,
            number: traveller.number
            
        })
      },[ traveller ]);
      
      /****************************Handling changes**************************** */
    
      function handleChanges(event){
        const {name, value} = event.target;
    
        setPassengerDetails(prevState => ({...prevState, [name]: value}));
        setDataValidated(prevState => ({...prevState, [name]: false}));
      }
    
      /****************************Form validation functions*******************************/
    
      const handleDataValidation = () => {
    
        let isValidated = true;
    
        if(passengerDetails.firstName.length > 50 || passengerDetails.firstName.length < 1 || passengerDetails.firstName.length === 0){
            setDataValidated(prev => ({...prev, firstName: true}))
            isValidated = false;
        }

        if(passengerDetails.lastName.length > 50 || passengerDetails.lastName.length < 1 || passengerDetails.lastName.length === 0){
            setDataValidated(prev => ({...prev, lastName: true}))
            isValidated = false;
        }
    
        if(passengerDetails.email.length === 0 || !/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(passengerDetails.email)){
            setDataValidated(prev => ({...prev, email: true}))
            isValidated = false;
        }
    
        if(passengerDetails.gender === ""){
            setDataValidated(prev => ({...prev, gender: true}))
            isValidated = false;
        }
        
        if(passengerDetails.dob.length === 0){
            setDataValidated(prev => ({...prev, dob: true}))
            isValidated = false;
        }

        if(passengerDetails.number.length === 0){
            setDataValidated(prev => ({...prev, number: true}))
            isValidated = false;
        }
 
        return isValidated;
      }
    
      /**************************API call : Add master passenger *******************************/
    
      async function addEditedTraveller(){
        if(!handleDataValidation()){
            return showSnackBar("error", t("Please fill the mandatory fields"))
        }

        removeTravelerFromBooking(traveller);
        
        let passengerObj = {
            name: passengerDetails.firstName.trim(),
            lastName:  passengerDetails.lastName.trim(),
            email: passengerDetails.email.trim(),
            dob: passengerDetails.dob.trim(),
            gender: passengerDetails.gender,
            nationality: passengerDetails.nationality.trim(),
            passportNumber: passengerDetails.passportNumber.trim(),
            expiryDate: passengerDetails.expiryDate.trim(),
            issuingCountry: passengerDetails.issuingCountry.trim(),
            callingCode: passengerDetails.callingCode.trim(),
            number: passengerDetails.number.trim(),
            isMasterPassenger: false
        }
        
        setTravellersList(prevState => {
            const arr = prevState;
            arr[index] = passengerObj
            return arr;
        });
        setDummy(prev => !prev)
        closeEditModal();
      }
    
      console.log(passengerDetails);
    
      return (
        <Box>
            <Tooltip title="Edit traveller" placement="top">
                <Button
                    sx={button}
                    onClick={openEditModal}
                >
                    {t("edit")}
                </Button>
            </Tooltip>
          <Modal
            open={editModalOpen}
            onClose={closeEditModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
                <Box sx={style}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant='h4'>Edit traveller</Typography>
                        <IconButton onClick={closeEditModal}>
                            <Close></Close>
                        </IconButton>
                    </Stack>
                    <Typography variant='subtitle1' color="red">
                        {t("pleaseNote")}: {t("theSlightestSpellingErrorOrFirstname")}
                    </Typography>
                    <Typography variant='h5'>Mandatory</Typography>
                    <Stack direction={{ sm: "column", md: "row" }} spacing={{xs:2,md:4}}>
                        <InputField
                            fullWidth
                            name="firstName"
                            type="text"
                            variant='outlined'
                            label="Firstname"
                            size='small'
                            value={passengerDetails.firstName}
                            onChange={handleChanges}
                            error={isDataValidated.firstName}
                            helperText={isDataValidated.firstName && t("Passenger firstname is required and must be below 50 characters")}
                        />

                        <InputField
                            fullWidth
                            name="lastName"
                            type="text"
                            variant='outlined'
                            label="Lastname"
                            size='small'
                            value={passengerDetails.lastName}
                            onChange={handleChanges}
                            error={isDataValidated.lastName}
                            helperText={isDataValidated.lastName && t("Passenger lastname is required and must be below 50 characters")}
                        />
                    </Stack>
                    <Stack direction={{ sm: "column", md: "row" }} spacing={{xs:2,md:4}}>
                        <InputField
                            fullWidth
                            size='small'
                            name='gender'
                            select
                            label="Gender"
                            value={passengerDetails.gender}
                            onChange={handleChanges}
                            error={isDataValidated.gender}
                            helperText={isDataValidated.gender && t("Please enter gender")}
                        >
                            
                            <MenuItem value="">Select Gender</MenuItem>
                            <MenuItem value="MALE">Male</MenuItem>
                            <MenuItem value="FEMALE">Female</MenuItem>
                        </InputField>

                        <LocalizationProvider 
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n?.language} 
                        >
                            <MobileDatePicker
                                disableFuture
                                label="Date of birth"
                                format='DD-MM-YYYY'
                                value={passengerDetails.dob ? dayjs(passengerDetails.dob) : null}
                                onChange={(newValue) => newValue !== null && setPassengerDetails(prev => ({...prev, dob: dayjs(newValue["$d"]).format("YYYY-MM-DD")}))}
                                slots={{
                                    textField: (params) => <InputField
                                        // required
                                        size="small" fullWidth {...params}
                                        error={isDataValidated.dob}
                                        helperText={isDataValidated.dob && t("Please enter a valid date format")}
                                    />
                                }}
                            />
                        </LocalizationProvider>
                    </Stack>
                    <Stack direction={{ sm: "column", md: "row" }} spacing={{xs:2,md:4}}>
                        <InputField
                            fullWidth
                            name="email"
                            type="email" 
                            variant='outlined'
                            label="Email"
                            size='small'
                            value={passengerDetails.email}
                            onChange={handleChanges}
                            error={isDataValidated.email}
                            helperText={isDataValidated.email && t("Please enter a valid email format")}
                        />

                        <Stack width="100%">
                            <PhoneInput
                                className="secure-payment-phone-field"
                                specialLabel=''
                                country={'fr'}
                                inputStyle={{
                                    border: isDataValidated.number ? "1px solid red" : "1px solid black",
                                    backgroundColor: "white",
                                    height: 36,
                                    fontFamily: "Montserrat",
                                    fontSize: 14,
                                    fontWeight: 500,
                                }}
                                fullWidth
                                placeholder="Phone number"
                                type='text'
                                name="phone"
                                value={`${passengerDetails.callingCode}${passengerDetails.number}`}
                                onChange={(num, countryDetails) => {
                                    setDataValidated(prev => ({...prev, number: false}))
                                    setPassengerDetails(prev => ({...prev, number: num.slice(countryDetails.dialCode.length), callingCode: countryDetails.dialCode}))
                                }}
                            />
                            <Typography ml={2} height={20} variant='subtitle1' color="error">{isDataValidated.number && t("Phone number must be in valid format")}</Typography>
                        </Stack>
                    </Stack>
                    <Typography variant='h5'>Optional fields</Typography>
                    <Stack direction={{ sm: "column", md: "row" }} spacing={{xs:2,md:4}}>
                        <InputField
                            fullWidth
                            name="nationality"
                            type="text" 
                            variant='outlined'
                            label="Nationality"
                            size='small'
                            value={passengerDetails.nationality}
                            onChange={handleChanges}
                        />
                        <InputField
                            fullWidth
                            name="passportNumber"
                            type="text" 
                            variant='outlined'
                            label="Passport number"
                            size='small'
                            value={passengerDetails.passportNumber}
                            onChange={handleChanges}
                        />
                    </Stack>
                    <Stack direction={{ sm: "column", md: "row" }} spacing={{xs:2,md:4}}>   
                        <LocalizationProvider 
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n?.language}
                        >
                            <MobileDatePicker
                                disablePast
                                label="Expiry Date"
                                format='DD-MM-YYYY'
                                value={passengerDetails.expiryDate ? dayjs(passengerDetails.expiryDate) : null}
                                onChange={(newValue) => newValue !== null && setPassengerDetails(prev => ({...prev, expiryDate: dayjs(newValue["$d"]).format("YYYY-MM-DD")}))}
                                slots={{
                                    textField: (params) => <InputField
                                        // required
                                        size="small" 
                                        fullWidth 
                                        {...params}
                                    />
                                }}
                            />
                        </LocalizationProvider>
                        <InputField
                            fullWidth
                            name="issuingCountry"
                            type="text" 
                            variant='outlined'
                            label="Passport issuing country"
                            size='small'
                            value={passengerDetails.issuingCountry}
                            onChange={handleChanges}
                        />
                    </Stack>
                    <Stack>
                        <BlueButton onClick={addEditedTraveller} size='large'>
                            Save passenger
                        </BlueButton>
                    </Stack>
                </Box>
          </Modal>
        </Box>
      );
    
}

export default memo(EditTravellerInBookingPage)