import React, { useState } from 'react'
import { Close, MenuOutlined } from '@mui/icons-material';
import { Avatar, Badge, Box, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, SwipeableDrawer, Tab, Tabs, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { CiAirportSign1, CiAlignBottom, CiBookmarkCheck, CiMoneyCheck1, CiReceipt, CiSignpostDuo1, CiUser } from 'react-icons/ci';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../Lib/Redux/AuthSlice';
import { clearUserDetails } from '../../Lib/Redux/AccountSlice';
import { BlackButtonOutlined } from '../../Lib/MuiThemes/MuiComponents';

function MobileDrawer({auth, profile}) {

  const [ mobileDrawer, toggleMobileDrawer ] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ navigatorValue, setNavigatorValue ] = useState("explore");
  const dispatch = useDispatch();


  const redirect = (route) => {
    navigate(route);
    toggleMobileDrawer(false)
  }

  const tabRoutes = [
    {
        route: "/home",
        title: "home",
    },
    {
        route: "/visa-enquiry",
        title: "visaenquiry",
    },
    {
        route: "/packages",
        title: "packages",
    },
    {
        route: "/promotions",
        title: "promotions",
    },
    {
        route: "/secure-payment",
        title: "securePayment",
    },
    {
        route: "/contact",
        title: "contactUs",
    },
  ];

  const adminRoutes = [
    {
        route: "/admin/dashboard",
        title: "Dashboard",
        icon: <CiAlignBottom/>
      },
      {
          route: "/admin/requested-booking",
          title: "Requested bookings",
          icon: <CiReceipt/>
      },
      {
          route: "/admin/trip-details",
          title: "Trip details",
          icon: <CiAirportSign1/>
      },
      {
        route: "/admin/user-details",
        title: "User details",
        icon: <CiUser/>
      },
      {
        route: "/admin/approve-travelers",
        title: "Approve travelers",
        icon: <CiBookmarkCheck/>
      },
      {
        route: "/admin/config-packages",
        title: "Add packages",
        icon: <CiSignpostDuo1/>
      },
      {
        route: "/admin/transactions",
        title: "Transaction details",
        icon: <CiMoneyCheck1/>
      },
  ]

    const logout = () => {
        dispatch(logoutUser());
        dispatch(clearUserDetails());
        // localStorage.clear();
        sessionStorage.clear();
        toggleMobileDrawer(false)
        return navigate("/", {replace: true})
    }



  return (
    <Box sx={{display: {xs: "block", md: "none"}}}>
        {auth.auth ? 
            <Badge
                onClick={() => toggleMobileDrawer(!mobileDrawer)}
                overlap="circular"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                // badgeContent={<BsCheckCircleFill style={{color: "black", fontSize: 18}}/>}
            >
                <Avatar sx={{height: "40px", width: "40px", border: "3px solid rgb(184, 218, 255)", cursor: "pointer"}} src={profile.profilePicture}/>
            </Badge>
            :
            <IconButton onClick={() => toggleMobileDrawer(!mobileDrawer)} size='medium'>
                {mobileDrawer ? <Close sx={{fontSize: 35, color: "text.main"}}/> : <MenuOutlined sx={{fontSize: 35, color: "text.main"}}/>}
            </IconButton>}
        
        <SwipeableDrawer
            // sx={{minHeight: "120vh", border: "1px solid red"}}
            anchor="bottom"
            open={mobileDrawer}
            onClose={() => toggleMobileDrawer(false)}
            onOpen={() => toggleMobileDrawer(true)}
        >
            <Stack sx={{
                height: "100vh"
            }}>
                <TabContext value={navigatorValue}>
                    {auth.auth && auth.role === "admin" && <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100vw"  }}>
                        <TabList onChange={(e, newVal) => setNavigatorValue(newVal)}>
                            <Tab label="Explore" value="explore" sx={{width: "50%"}} />
                            <Tab label="Admin" value="admin" sx={{width: "50%"}} />
                        </TabList>
                    </Box>}
                    <TabPanel value="explore">
                        <List 
                            sx={{
                                px: "10px",
                            }}
                        >
                            {tabRoutes.map((text, index) => (
                            <ListItem  
                                onClick={() => redirect(`${text.route}`)} 
                                key={index} 
                                disablePadding
                            >
                                <ListItemButton sx={{p: 4}}>
                                <ListItemText primary={<Typography variant='h5' color="text.main">{t(text.title)}</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            ))}
                        </List>
                        <Divider/>
                        {auth.auth ? 
                            <>
                                <List 
                                    sx={{
                                        px: "10px",
                                    }}
                                >
                                    <ListItem  
                                        onClick={() => redirect(`/profile/account`)} 
                                        disablePadding
                                    >
                                        <ListItemButton sx={{p: 4}}>
                                        <ListItemText primary={<Typography variant='h5' color="text.main">My Account</Typography>} />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem  
                                        onClick={() => logout()}
                                        disablePadding
                                    >
                                        <ListItemButton sx={{p: 4}}>
                                        <ListItemText primary={<Typography variant='h5' color="text.main">Logout</Typography>} />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </> : 
                            <Stack sx={{py: 3}}>
                                <BlackButtonOutlined sx={{ py: 2 }} onClick={() => navigate("login")}>{t("login")} / {t("signup")}</BlackButtonOutlined>
                            </Stack>
                        }   
                    </TabPanel>
                    {auth.auth && auth.role === "admin" && 
                        <TabPanel value="admin">
                            <List 
                                sx={{
                                    px: "10px",
                                }}
                            >
                                {adminRoutes.map((text, index) => (
                                    <ListItem  
                                        onClick={() => redirect(`${text.route}`)} 
                                        key={index} 
                                        disablePadding
                                    >
                                        <ListItemButton sx={{p: 4}}>
                                            <ListItemText primary={<Typography variant='h5' color="text.main">{t(text.title)}</Typography>} />
                                        </ListItemButton>
                                    </ListItem>
                                    ))}
                            </List>
                        </TabPanel>
                    }
                </TabContext>
            </Stack>
        </SwipeableDrawer>
    </Box>
  )
}

export default MobileDrawer