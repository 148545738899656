import { Box, Grid, Modal, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import TopDestinationTourCard from '../Packages/TopDestinationTourCard'
import ConnectWithUs from '../Packages/ConnectWithUs'
import axios from 'axios'
import { Axios } from '../../Lib/Axios/AxiosConfig'
import { usePackageContextConsumer } from '../../Lib/Contexts/PackageListContext'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    boxShadow: 24,
};

function TopDestinations() {

    const { t } = useTranslation();
    const [ openConnectWithUsModal, setOpenConnectWithUsModal ] = useState(false);
    const [ topCity, setTopCity ] = useState([]);
    const [ packagesList ] = usePackageContextConsumer();
    const container = {
        hidden: { opacity: 1 },
        visible: {
          opacity: 1,
          scale: 1,
          transition: {
            delayChildren: 0.1,
            staggerChildren: 0.1
          }
        },
        
    }

    useEffect(() => {
        const controller = axios.CancelToken.source();
        if(packagesList){
          (async () => {
            try{
              const [ topDestination ] = Object.keys(packagesList).filter(key => key.startsWith("Top") || key.startsWith("top"))
              const response = await Axios({
                method: "get",
                url: `fetchPackages?packageName=${topDestination}`,
                cancelToken: controller.token,
                validateStatus: function (status) {
                  if(status >= 400){
                    // showSnackBar("error", `Error: ${status}, Unable to fetch packages, Please try again`);  
                    // setDataFetchError(true); 
                    throw new Error("Error");
                  }
                  return status;
                }
              });
              
              if(response.data && response.status === 200){
                setTopCity(response.data);
              }
              
            }catch(error){
            //   setDataFetchError(true);
            //   showSnackBar("error", "Unable to fetch passenger details");
              console.log(error);
            }
          })()
        }
    
        return () => {
          controller.cancel();
        }
    },[ packagesList ]);

  return (
    <>
    <Box sx={{px: {xs: 2, md: 20}, py: 7}}>
        <Stack textAlign="center">
            <Typography
                component={motion.h3} 
                initial={{
                    opacity: 0,
                    y: 50
                }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      delay: 0.1,
                      damping: 2
                    }
                }}
                sx={{fontWeight: "bold"}} 
                variant='body2'
            >
                {t("topSelling")}
            </Typography>
            <Typography 
                component={motion.h3} 
                initial={{
                    opacity: 0,
                    y: 50
                }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                    delay: 0.1,
                    damping: 2
                    }
                }}
                sx={{py: 2, fontWeight: "bold"}} 
                variant='h3' 
                color="primary.main"
            >
                {t("topDestinations")}
            </Typography>
        </Stack>
        <Box 
            component={motion.div}
            variants={container}
            initial="hidden"
            whileInView="visible"
            viewport={{once: false, amount: 0.1}}
            sx={{
                // border: "1px solid black",
                position: "relative", 
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center", 
                // direction: {xs: "column", sm: "row"}, 
                // justifyContent: "center", 
                // alignItems: "center", 
                py: 3,
            }}
        >
            {/* <Box 
                component={motion.div}
                variants={item} 
                sx={{
                    display: {xs: "none", md: "block"}, 
                    height: "auto", 
                    width: 100,
                    position: "relative", 
                    left: 30,
                    // border: "1px solid red" 
                    zIndex: 0, 
                    // left: {xs: 0, xl: 210}
                    // left: 2
                }}
            >
                <img style={{height: "100%", width: "100%", objectFit: "cover"}} src={springLeft} alt="img" />
            </Box> */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xs: "column", md: "row"},
                    alignItems: "center",
                    justifyContent: "center",
                    height: "auto",
                    position: "relative",
                    zIndex: 1,
                    // border: "1px solid green",
                    width: "auto",
                    // gap: 5,
                }} 
            >
                <Grid container rowSpacing={7} columnSpacing={7}>
                    {topCity.map((tour, index) => (
                        <Grid key={index}  item xs={12} sm={6} md={4}>
                            <TopDestinationTourCard
                                tourName={tour.name} 
                                destinations={tour.destination}
                                duration={tour.duration}
                                packageName={"Top destination tour packages"}
                                packageId={"9"}
                                tourObj={tour}
                                setOpenConnectWithUsModal={setOpenConnectWithUsModal}
                                imageUrl={tour.images[0]}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
            {/* <Box 
                component={motion.div}
                variants={item} 
                sx={{
                    display: {xs: "none", md: "block"}, 
                    height: "auto", 
                    width: 100, 
                    position: "relative", 
                    right: 30,
                    zIndex: 0
                    // border: "1px solid red"  
                }}>
                <img style={{height: "100%", width: "100%", objectFit: "cover"}} src={springRight} alt="img" />
            </Box> */}
        </Box>
    </Box>
    <div>
      <Modal
        open={openConnectWithUsModal}
        onClose={() => setOpenConnectWithUsModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <ConnectWithUs openConnectWithUsModal={openConnectWithUsModal} setOpenConnectWithUsModal={setOpenConnectWithUsModal}/>
        </Box>
      </Modal>
    </div>
    </>
  )
}

export default TopDestinations