import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useState } from 'react';
import { Box, Button, Fab, IconButton } from '@mui/material';
import { Close, FilterAlt } from '@mui/icons-material';
import FiltersSetting from './FiltersSetting';
import { Stack } from '@mui/material';

  

function MobileFilter() {

    const [state, setState] = useState(false);
    
      const toggleDrawer = (open) => (event) => {
        if (
          event &&
          event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')
        ) {
          return;
        }
    
        setState(open);
      };

  return (
    <Box 
        sx={{
            display: {
                xs: "block",
                lg: "none"
            }
        }}
    >
        <Fab 
            sx={{ position: 'fixed', bottom: 80, right: 16 }} 
            color="primary" 
            size='medium'
            onClick={toggleDrawer(true)}
        >
            <FilterAlt sx={{ color: "white" }}/>
        </Fab>
        <SwipeableDrawer
            anchor={"bottom"}
            open={state}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            sx={{
                maxHeight: "80vh",
                p: 2
            }}
        >
            <Stack p={2}>
                <Box>
                    <Button sx={{ float: "right", p: 0, borderRadius: 3 }} variant='contained'  onClick={toggleDrawer(false)}>
                        Apply
                    </Button>
                </Box>
            </Stack>
            
            <FiltersSetting/>
        </SwipeableDrawer>
    </Box>
  );
}


export default MobileFilter;
