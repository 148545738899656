import { Box, Rating, Stack, Typography } from '@mui/material'
import React from 'react'

export const TestimonialCard = (props) => {
  return (
        <Stack sx={{
            width: "350px",
            height: "auto",
            py: 5,
            overflow: "visible",
            position: "relative",
            textAlign: "center",
            // transform: "skew(-8deg)",
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: "primary.main",
            minHeight: "270px",
            zIndex: 1,
            "-webkit-box-shadow": "1px 0px 47px -17px rgba(89,177,230,1)",
            "-moz-box-shadow": "1px 0px 47px -24px rgba(89,177,230,1)",
            boxShadow: "1px 0px 47px -17px rgba(89,177,230,1)" 
        }}>
            <Box sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                // transform: "skew(8deg)",
            }}>
                <Typography fontSize={16} fontWeight="bold" variant='subtitle1' color="text.main">{props.name.toUpperCase()}</Typography>
                <Typography variant='subtitle2'>{props.date}</Typography>
                {props.rating && <Rating sx={{ margin: "0 auto" }} name="read-only" value={props.rating} readOnly />}
                <Typography variant='body2'>
                    {props.review}
                </Typography>
            </Box>

        {/* <Box 
            sx={{
                display: {xs: "none", md: "block"},
                position: "absolute", 
                height: "250px", 
                width: "300px", 
                transform: "rotate(30deg)", 
                backgroundColor: "#2E3191",
                borderTopLeftRadius: "100px",
                borderTopRightRadius: "100px",
                borderBottomLeftRadius: "50%",
                borderBottomRightRadius: "50%",
                zIndex: 0,
                top: 50,
                left: 5
            }}>

        </Box> */}
    </Stack>
  )
}
