import { Box, Grid, Pagination, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import HomeBanner from '../ReusableComponents/HomeBanner'
import { backgroundPatternImg, blogsBanner, flightLoader } from '../../Assests/assets'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import { motion } from 'framer-motion'
import axios from 'axios'
import { BASE_URL } from '../../Lib/Axios/AxiosConfig'
import Lottie from 'react-lottie-player'
import BlogCard from './fragments/BlogCard'
import { useSearchParams } from 'react-router-dom'

function MetaTags(){

  const { t } = useTranslation();
  return(
    <Helmet>
        <meta charSet="utf-8" name='description' content={t("discoverOurStreamlinedProcessAndExpertGuidance")}/>
        <title>{t("franceVisaFromIndiaForSeamlessTravel")}</title>
        <link rel="canonical" href="https://errancesvoyages.com/our-blogs" />
    </Helmet>
  )
}

function ViewBlogs() {

    const { t } = useTranslation();
    const [ blogsData, setBlogsData ] = useState({
        isLoading: false,
        isSuccess: false,
        isError: false,
        data: {},
        error: null
    });
    const [ searchParams, setSearchParams ] = useSearchParams(); 

    console.log(searchParams.get("page"));

    const handleChange = (event, value) => {
        console.log(value);
        searchParams.set("page", value);
        setSearchParams(searchParams);
        document.body.scrollTop = 500; // For Safari
        document.documentElement.scrollTop = 500; // For Chrome, Firefox, IE and Opera
    };


    useEffect(() => {
        const controller = axios.CancelToken.source();
        
        (async () => {
            try{
                setBlogsData(prev => ({ ...prev, isLoading: true, isError: false, isSuccess: false, data: [], error: null }))
                const response = await axios({
                    method: "get",
                    url: `${BASE_URL}/fetchAllBlogs?offset=${searchParams.get("page") ? parseInt(searchParams.get("page")) - 1 : 0}`,
                    cancelToken: controller.token,
                });
            
                if(response.status === 200){
                    return setBlogsData(prev => ({ ...prev, isLoading: false, isSuccess: true, data: response.data }))
                }
    
                return setBlogsData(prev => ({ ...prev, isLoading: false, isError: true, error: response.data }))
            
            }catch(error){
                if(error.code !== "ERR_CANCELED"){
                    console.log(error);
                    return setBlogsData(prev => ({ ...prev, isLoading: false, isError: true, error: error.message }))
                }
            }
        })()
        

        return () => {
            controller.cancel();
        }
    },[ searchParams ]);


let content;

if(blogsData.isLoading){
    content = (
        <Stack
            sx={{
                margin: "0 auto"
            }}
        >
            <Lottie 
                style={{
                    height: 200,
                    width: 400
                }}
                loop={true}
                play={true}
                animationData={flightLoader}
                rendererSettings= {{
                    preserveAspectRatio: "xMidYMid slice"
                }}
            />
        </Stack>
    )
}

if(blogsData.isError){
    content = <Typography variant='h5' color="red" fontWeight="bold" textAlign="center">{blogsData.error}</Typography>
}

if(blogsData.isSuccess && blogsData.data.data.length === 0){
    content = (
        <Typography textAlign="center" variant='body2' mt={2}>No blogs for now</Typography>
    )
}

if(blogsData.isSuccess && blogsData.data.data.length > 0){
    content = (
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {blogsData.data.data.map((blog, index) => (
                <Grid key={`blog${index}`} item xs={12} md={6}>
                    <BlogCard { ...blog }/>
                </Grid>
            ))}
        </Grid>
            
    )
}

  return (
      <Box
        sx={{ 
          background: `none, url('${backgroundPatternImg}')`,
          backgroundRepeat: "repeat",
          backgroundSize: "700px", 
          overflow: "hidden",}}
      >
      <MetaTags/>
      <HomeBanner bannerImage={blogsBanner}/>
      <Box sx={{ px: {xs: 1, md: 15}}}>
        <Stack textAlign="center">
            <Typography 
                component={motion.h1} 
                initial={{
                    opacity: 0,
                    y: 50
                }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                    delay: 0.1,
                    damping: 2
                    }
                }}
                sx={{py: 2, fontWeight: "bold"}} 
                variant='h3' 
                color="primary.main"
            >
                {t("our blogs")}
            </Typography>
            <Typography
                component={motion.h2} 
                initial={{
                    opacity: 0,
                    y: 50
                }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                    delay: 0.1,
                    damping: 2
                    }
                }}
                sx={{fontWeight: "bold"}} 
                variant='body2'
            >
                {t("franceVisaFromIndia")}
            </Typography>
        </Stack>
        <Stack py={5} minHeight="100vh">
            {content}
        </Stack>
        {blogsData?.data?.data?.length > 0 &&
            <Stack alignItems="center" justifyContent="center" py={5}>
                <Pagination 
                    count={Math.ceil(blogsData?.data?.count / 10)} 
                    color="primary"
                    page={searchParams.get("page") ? parseInt(searchParams.get("page")) : 1} 
                    onChange={handleChange} 
                />
            </Stack>
        }
      </Box>
    </Box>
  )
}

export default ViewBlogs