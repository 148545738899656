import React, { useState, Fragment, useEffect, useContext } from 'react'
import { Add } from '@mui/icons-material';
import { Avatar, Box, Button, Checkbox, IconButton, List, ListItem, ListItemText, Stack, Tooltip, Typography } from '@mui/material'
import { useSelector } from 'react-redux';
import { WhiteCard } from '../../../Lib/MuiThemes/MuiComponents'
import AddTravellerInBookingPage from '../Modals/AddTravellerInBookingPage';
import { BookingObjContext } from '../../../Lib/Contexts/BookingObjContext';
import { age, travellerType } from '../../../Lib/utils/helperFunctions';
import EditTravellerInBookingPage from '../Modals/EditTravellerInBookingPage';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const style = {
    width: "auto",
    minWidth: "80px",
    borderRadius: 2,
    boxShadow: "0px 2px 10px rgba(155, 157, 172, 0.3)",
    color: "black",
    backgroundColor: "white",
    "&:hover": {
        backgroundColor: "transparent",
    },
    "&.Mui-checked": {
        backgroundColor: "primary.main",
        color: "white"
    },
    "&.Mui-disabled": {
        backgroundColor: "veryLightBlue.main"
    }
}

function TravellerDetailsCard({bookingDetailsData}) {

  const [ dummy, setDummy ] = useState(false);
  const [ openNewPassengerModal, setOpenNewPassengerModal ] = useState(false);
  const { account } = useSelector(data => data.persistedReducer);
  const [travellersList, setTravellersList] = useState([]);
  const [passengers, setPassengers] = useState(new Set());
  const { setBookingObj } = useContext(BookingObjContext);
  const { t } = useTranslation();

  const expectedTravellers = {
    adult: bookingDetailsData?.bookingObj?.flightOffers[0].travelerPricings.filter(traveler => traveler.travelerType === "ADULT").length,
    children: bookingDetailsData?.bookingObj?.flightOffers[0].travelerPricings.filter(traveler => traveler.travelerType === "CHILD").length,
    infants: bookingDetailsData?.bookingObj?.flightOffers[0].travelerPricings.filter(traveler => traveler.travelerType === "HELD_INFANT").length,
  }

  const [ addedTravellers, setAddedTravellers ] = useState({
    adult: 0,
    children: 0,
    infants: 0
  })



  function addTravelerToTheBooking(obj){
    const newSet = new Set(passengers);
    if(passengers.has(obj)){
        newSet.delete(obj);
        setPassengers(newSet);
        setBookingObj(prev => ({...prev, travelers: [...passengers]}))
    }else{
        newSet.add(obj);
        setPassengers(newSet);
        setBookingObj(prev => ({...prev, travelers: [...passengers]}))
    }
    
  }

  function removeTravelerFromBooking(obj){
    const newSet = new Set(passengers);
    newSet.delete(obj);
    setPassengers(newSet);
    setBookingObj(prev => ({...prev, travelers: [...passengers]}))
  }

  useEffect(() => {
    if(account.masterList){
        const masterListArray = account.masterList.map(obj => ({
            number: obj.number, 
            callingCode: obj.callingCode, 
            gender: obj.gender.toUpperCase(),
            name: obj.name,
            lastName: obj.lastName,
            email: obj.email,
            dob: obj.dob,
            nationality: obj.nationality,
            passportNumber: obj.passportNumber,
            expiryDate: obj.expiryDate,
            issuingCountry: obj.issuingCountry,
            isMasterPassenger: true
        }))
        setTravellersList(masterListArray);
    }
  },[ account.masterList ]);

  useEffect(() => {

    const travellersArray = [...passengers];

    setBookingObj(prev => ({...prev, travelers: travellersArray}));

    setAddedTravellers(prev => ({
        ...prev,
        adult: travellersArray.filter(traveler => travellerType(age(traveler.dob)) === "Adult").length,
        children: travellersArray.filter(traveler => travellerType(age(traveler.dob)) === "Children").length,
        infants: travellersArray.filter(traveler => travellerType(age(traveler.dob)) === "Infants").length
    }));

  }, [passengers, setBookingObj]);


  return (
    <>
    <Stack flex={2}>
        <WhiteCard 
            sx={{
                border: passengers.size > bookingDetailsData?.totalPassengers ? "2px solid red" : "none"
            }}
        >
            <Stack spacing={2} height="100%">
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack>
                        <Typography variant='h5' fontWeight="bold" color="primary">{t("travellerDetails")}</Typography>
                        <Typography variant='subtitle1' fontWeight="bold">Travellers required: 
                        &nbsp;

                        {expectedTravellers.adult !== 0 && <Typography component="span" variant='subtitle1' fontWeight="bold" color={expectedTravellers.adult === addedTravellers.adult ? "success.main" : "error"}>Adult(s) - {expectedTravellers.adult}, </Typography>} 
                        
                        {expectedTravellers.children !== 0 && <Typography component="span" variant='subtitle1' fontWeight="bold" color={expectedTravellers.children === addedTravellers.children ? "success.main" : "error"}>Children - {expectedTravellers.children}, </Typography>} 
                        
                        {expectedTravellers.infants !== 0 && <Typography component="span" variant='subtitle1' fontWeight="bold" color={expectedTravellers.infants === addedTravellers.infants ? "success.main" : "error"}>Infant(s) - {expectedTravellers.infants}</Typography>}
                        
                        </Typography>

                        {/* <Typography variant='subtitle1'>{passengers.size > 0 && `${t("numberOfTravellersAdded")}: ${passengers.size} of ${totalPassengers}`}</Typography> */}
                        <Typography variant='subtitle1'>{passengers.size > bookingDetailsData?.totalPassengers && <span style={{color: "red"}}>{t("Travellers limit exceeded")}</span>}</Typography>
                    </Stack>
                    <Tooltip title="Add new traveller" placement="top">
                        <IconButton sx={{ bgcolor: "primary.main", "&: hover": { bgcolor: "primary.main" } }} onClick={() => setOpenNewPassengerModal(true)} >
                            <Add sx={{color: "white"}}/>
                        </IconButton>
                    </Tooltip>
                </Stack>
                {travellersList.length !== 0 ? 
                    <Stack>
                        <List>
                            {travellersList.map((traveller, index) => (
                                <Fragment key={index}>
                                    <ListItem>
                                        <ListItemText
                                            sx={{ m: 0 }} 
                                            primary={
                                                <Stack direction="column" justifyContent="space-between">
                                                    <Stack direction="row" justifyContent="space-between">
                                                        <Stack direction="row" spacing={2} alignItems="center">
                                                            <Avatar />
                                                            <Typography variant='h6' color="text.main" fontWeight="bold">{traveller.name} {traveller.lastName}<Typography component="span" variant='subtitle1'>({t(travellerType(age(traveller.dob)).toLowerCase())})</Typography></Typography>
                                                        </Stack>
                                                        <Stack display={{ xs: "none", md: "flex" }} direction={!traveller.isMasterPassenger ? "row" : "row-reverse"} alignItems="center" justifyContent="space-between" spacing={3}>
                                                            {!traveller.isMasterPassenger &&
                                                                <Tooltip title="Edit traveller" placement="top">
                                                                    <EditTravellerInBookingPage
                                                                        traveller={traveller}
                                                                        index={index}
                                                                        setTravellersList={setTravellersList}
                                                                        removeTravelerFromBooking={removeTravelerFromBooking}
                                                                        setDummy={setDummy}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                            <Tooltip title={passengers.has(traveller) ? "Traveller added" : "Add this traveller to your journey"} placement="top">
                                                                <Checkbox
                                                                    sx={style}
                                                                    icon={<Typography sx={{fontSize: "12px"}}>{t("add")}</Typography>} 
                                                                    checkedIcon={<Typography sx={{fontSize: "12px"}}>{t("added")} !</Typography>}
                                                                    value={traveller}
                                                                    checked={passengers.has(traveller)}
                                                                    onChange={() => addTravelerToTheBooking(traveller)}
                                                                />
                                                            </Tooltip>
                                                        </Stack>
                                                    </Stack>
                                                    <Stack direction={{ xs: "column", md: "row" }} py={2} spacing={{ xs: 0, md: 3 }}>
                                                        <Stack spacing={1}>
                                                            <Typography variant='h6' component="span">{t("gender")} : {t(traveller.gender.toLowerCase())}</Typography>
                                                            <Typography variant='h6' component="span">{t("dateOfBirth")} : {dayjs(traveller.dob).format("DD-MM-YYYY")}</Typography>
                                                            <Typography variant='h6' component="span">{t("email")} : {traveller.email}</Typography>
                                                            <Typography variant='h6' component="span">{t("phone")} : {traveller.number}</Typography>
                                                        </Stack>
                                                        <Stack spacing={1}>
                                                            {traveller.nationality && <Typography variant='h6' component="span">{t("nationality")} : {traveller.nationality}</Typography>}
                                                            {traveller.passportNumber && <Typography variant='h6' component="span">{t("passportNumber")} : {traveller.passportNumber}</Typography>}
                                                            {traveller.expiryDate && <Typography variant='h6' component="span">{t("expiryDate")} : {traveller.expiryDate}</Typography>}
                                                            {traveller.issuingCountry && <Typography variant='h6' component="span">{t("passportIssuingCountry")} : {traveller.issuingCountry}</Typography>}
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            }
                                            secondary={
                                                <Stack display={{ xs: "flex", md: "none" }} direction={!traveller.isMasterPassenger ? "row" : "row-reverse"} alignItems="center" justifyContent="space-between">
                                                    {!traveller.isMasterPassenger &&
                                                        <Tooltip title="Edit traveller" placement="top">
                                                            <EditTravellerInBookingPage
                                                                traveller={traveller}
                                                                index={index}
                                                                setTravellersList={setTravellersList}
                                                                removeTravelerFromBooking={removeTravelerFromBooking}
                                                                setDummy={setDummy}
                                                            />
                                                        </Tooltip>
                                                    }
                                                    <Tooltip title={passengers.has(traveller) ? "Traveller added" : "Add this traveller to your journey"} placement="top">
                                                        <Checkbox
                                                            sx={style}
                                                            icon={<Typography sx={{fontSize: "12px"}}>{t("add")}</Typography>} 
                                                            checkedIcon={<Typography sx={{fontSize: "12px"}}>{t("added")} !</Typography>}
                                                            value={traveller}
                                                            checked={passengers.has(traveller)}
                                                            onChange={() => addTravelerToTheBooking(traveller)}
                                                        />
                                                    </Tooltip>
                                                </Stack>
                                            }
                                        />
                                    </ListItem>
                                    <hr />
                                </Fragment>
                            ))}
                        </List>
                    </Stack> : 
                    <Box
                        sx={{
                            width: "100%", 
                            height: 300, 
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "10px",
                            cursor: "pointer",
                            bgcolor: "primary.main",
                        }}
                        onClick={() => setOpenNewPassengerModal(true)}
                    >
                        <Button 
                            variant='contained' 
                            sx={{ 
                                bgcolor: "white", 
                                p: 0, 
                                borderRadius: "50%", 
                                "&: hover": { bgcolor: "white" } 
                            }}
                        >
                            <Add sx={{color: "primary.main", fontSize: 50}}/>
                        </Button>
                        <Typography variant='h3' sx={{ color: "white" }}>Please add traveller details here...</Typography>
                    </Box>
                }
            </Stack>
        </WhiteCard>
    </Stack>
    <AddTravellerInBookingPage 
        setTravellersList={setTravellersList}
        addTravelerToTheBooking={addTravelerToTheBooking}
        open={openNewPassengerModal} 
        setOpen={setOpenNewPassengerModal} 
    />
    </>
  )
}

export default TravellerDetailsCard