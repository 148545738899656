import React from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { promos } from '../../Assests/assets'

function ArchivedPromotionsList() {

    const container = {
        hidden: { opacity: 1 },
        visible: {
          opacity: 1,
          scale: 1,
          transition: {
            delayChildren: 0.1,
            staggerChildren: 0.1
          }
        },
        
    }
        
    const item = {
        hidden: { x: -20, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1
        },
    
    }

  return (
    <Box sx={{py: 7, px: {xs: 1, md: 15}}}>
        <Stack textAlign="center">
            {/* <Typography
                component={motion.h3} 
                initial={{
                    opacity: 0,
                    y: 50
                }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      delay: 0.1,
                      damping: 2
                    }
                }}
                sx={{fontWeight: "bold"}} 
                variant='body2'
            >
                THE BEST OFFERS FOR ALL DESTINATIONS
            </Typography> */}
            <Typography 
                component={motion.h3} 
                initial={{
                    opacity: 0,
                    y: 50
                }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                    delay: 0.1,
                    damping: 2
                    }
                }}
                sx={{py: 2, fontWeight: "bold"}} 
                variant='h3' 
                color="primary.main"
            >
                Archived Promotions
            </Typography>
        </Stack>
            <Grid 
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 5
                }} 
                container 
                spacing={{ xs: 2, md: 5 }} 
                columns={{ xs: 4, sm: 8, md: 1 }}
                component={motion.div}
                variants={container}
                initial="hidden"
                animate="visible"
                viewport={{once: false, amount: 0.1}}
            >
            {promos.map((data, index) => (
                <Grid 
                    item 
                    key={index}
                    component={motion.div}
                    variants={item} 
                >
                    <Box 
                        sx={{
                            p: 3,
                            height: "100%",
                            width: "320px",
                            borderRadius: 3, 
                            bgcolor: data.bgColor, 
                            overflow: "hidden",
                            boxShadow: 1
                        }}
                    >
                        <img 
                            style={{
                                height: "100%", 
                                width: "100%", 
                                objectFit: "cover", 
                                borderRadius: 10
                            }} 
                            src={data.promo} 
                            alt="img" 
                        />
                    </Box>
                </Grid>
            ))}
        </Grid>
   </Box>
  )
}

export default ArchivedPromotionsList