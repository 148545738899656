import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { BlueButton, InputField, WhiteCard } from '../../Lib/MuiThemes/MuiComponents'
import { Box, MenuItem, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { FiSend } from 'react-icons/fi'
import dayjs from 'dayjs'
import axios from 'axios'
import useSnackBar from '../../Lib/CustomHooks/useSnackBar'
import { LoaderConsumer } from '../../Lib/Contexts/LoaderContext'

function VisaTrackerForm() {

  const { showSnackBar } = useSnackBar();
  const [ isLoading, startLoading, restLoading ] = LoaderConsumer();
  const { t, i18n } = useTranslation();
//   const captchaRef = useRef(null);
  const [ formData, setFormData ] = useState({
    "devis-form-firstname": "",
    "devis-form-lastname": "",
    "devis-form-email": "",
    "devis-form-phone": "",
    "allerretour": "Aller simple",
    "devis-form-allerretourdate": "",
    "devis-form-allersimpledate": "",
    "devis-form-ville": "",
    "devis-form-adultscount":  1,
    "devis-form-enfantscount": 0,
    "devis-form-babiescount": 0,
    "preferred_airlines": "",
    "devis-form-comments": "",
    // "devis-form-botcheck": "",
    // "g-recaptcha-response": "",
    // "form-submit": ""
  });
  const [ isFormDataError, setIsFormDataError ] = useState({
    "devis-form-firstname": false, 
    "devis-form-lastname": false, 
    "devis-form-email": false,
    "devis-form-phone": false,
    "devis-form-allersimpledate": false,
    "devis-form-ville": false
});

  //Form validation function
  function validateFormData(){

    let error = false;

    if(formData["devis-form-firstname"].length === 0){
        setIsFormDataError(prev => ({...prev, "devis-form-firstname": true}));
        error = true;
    }

    if(formData["devis-form-lastname"].length === 0){
        setIsFormDataError(prev => ({...prev, "devis-form-lastname": true}));
        error = true;
    }

    if(!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData["devis-form-email"])){
        setIsFormDataError(prev => ({...prev, "devis-form-email": true}));
        error = true;
    }

    if(formData["devis-form-phone"].length === 0){
        setIsFormDataError(prev => ({...prev, "devis-form-phone": true}));
        error = true;
    }

    if(formData["devis-form-allersimpledate"].length === 0){
        setIsFormDataError(prev => ({...prev, "devis-form-allersimpledate": true}));
        error = true;
    }

    if(formData["devis-form-ville"].length === 0){
        setIsFormDataError(prev => ({...prev, "devis-form-ville": true}));
        error = true;
    }

    return error;
  }

  // Handling form changes
  function handleChange(event){
    const { name, value } = event.target;
    setFormData(prevState => ({...prevState, [name]: value}))
    setIsFormDataError(prevState => ({...prevState, [name]: false}))
  }

  //Form submission
  async function handleSubmit(event){
    event.preventDefault();
    if(!validateFormData()){
        const controller = axios.CancelToken.source();
        const formdata = new FormData();
        formdata.append("devis-form-lastname", formData["devis-form-lastname"]);
        formdata.append("devis-form-firstname", formData["devis-form-firstname"]);
        formdata.append("devis-form-email", formData["devis-form-email"]);
        formdata.append("devis-form-phone", formData["devis-form-phone"]);
        formdata.append("allerretour", formData["allerretour"]);
        formdata.append("devis-form-allerretourdate", formData["devis-form-allerretourdate"]);
        formdata.append("devis-form-allersimpledate", formData["devis-form-allersimpledate"]);
        formdata.append("devis-form-ville", formData["devis-form-ville"]);
        formdata.append("devis-form-adultscount", formData["devis-form-adultscount"]);
        formdata.append("devis-form-enfantscount", formData["devis-form-enfantscount"]);
        formdata.append("devis-form-babiescount", formData["devis-form-babiescount"]);
        formdata.append("preferred_airlines", formData["preferred_airlines"]);
        formdata.append("devis-form-comments", formData["devis-form-comments"]);

        try{
            startLoading();
            const response = await axios({
                method: "post",
                url: "https://errancesvoyages.com/include/devis.php",
                maxBodyLength: Infinity,
                data: formdata,
                cancelToken: controller.token
            });
            console.log(response);
            restLoading();
            showSnackBar("success", t("We received your form and will respond to you within 24 hours"))
        }catch(error){
            restLoading();
            console.log(error);
            showSnackBar("error", error.message)
        }
    }
  }

  return (
    <Box sx={{px: {xs: 0, md: 25}, py: 3}}>
        <Stack py={5}>
            <WhiteCard>
                <Stack textAlign="center">
                    <Typography 
                        component={motion.h1} 
                        initial={{
                            opacity: 0,
                            y: 50
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                            transition: {
                            delay: 0.1,
                            damping: 2
                            }
                        }}
                        sx={{py: 2, fontWeight: "bold"}} 
                        variant='h3' 
                        color="primary.main"
                    >
                       {t("fillInTheFormBelow")}
                    </Typography>
                    <Typography
                        component={motion.h2} 
                        initial={{
                            opacity: 0,
                            y: 50
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                            transition: {
                            delay: 0.1,
                            damping: 2
                            }
                        }}
                        sx={{fontWeight: "bold"}} 
                        variant='body2'
                    >
                        {t("franceVisaFromIndia")}
                    </Typography>
                </Stack>
                
                    <Stack py={5} px={{xs: 0, md: 3}} spacing={{xs: 3, sm: 3}} direction="column">
                        <Stack>
                            <Typography variant='h5' color="text.main" sx={{fontWeight: "bold"}}>
                                {t("emailUsNow")}
                            </Typography>
                            <Typography
                                sx={{fontWeight: "bold"}} 
                                variant='subtitle2'
                            >
                                {t("andWeWillRespond")}
                            </Typography>
                        </Stack>
                        <Stack spacing={{xs: 2, md: 7}} direction={{xs: "column", md: "row"}} justifyContent="center" alignItems="center">
                            <Stack spacing={1} width="100%">
                                <Typography variant='body1' color="text.main">{t("firstName")}*</Typography>
                                <InputField
                                    // required
                                    name='devis-form-firstname'
                                    value={formData["devis-form-firstname"]}
                                    onChange={handleChange}
                                    fullWidth
                                    placeholder={t("yourFirstName")}
                                    type='text'
                                    error={isFormDataError["devis-form-firstname"]}
                                    helperText={isFormDataError["devis-form-firstname"] && t("Firstname is required")}
                                />
                            </Stack>
                            <Stack spacing={1} width="100%">
                                <Typography variant='body1' color="text.main">{t("lastname")}*</Typography>
                                <InputField
                                    // required
                                    name="devis-form-lastname"
                                    value={formData["devis-form-lastname"]}
                                    onChange={handleChange}
                                    fullWidth
                                    placeholder={t("yourLastName")}
                                    type='text'
                                    error={isFormDataError["devis-form-lastname"]}
                                    helperText={isFormDataError["devis-form-lastname"] && t("Lastname is required")}
                                />
                            </Stack>
                        </Stack>
                        <Stack spacing={{xs: 2, md: 7}} direction={{xs: "column", md: "row"}} justifyContent="center" alignItems="center">
                            <Stack spacing={1} width="100%">
                                <Typography variant='body1' color="text.main">{t("emailAddress")}*</Typography>
                                <InputField
                                    // required
                                    value={formData['devis-form-email']}
                                    onChange={handleChange}
                                    name="devis-form-email"
                                    fullWidth
                                    placeholder={t("yourEmailAddress")}
                                    type='email'
                                    error={isFormDataError["devis-form-email"]}
                                    helperText={isFormDataError["devis-form-email"] && t("Email is required")}
                                    
                                />
                            </Stack>
                            <Stack spacing={1} width="100%">
                                <Typography variant='body1' color="text.main">{t("phoneNumber")}*</Typography>
                                <InputField
                                    // required
                                    value={formData["devis-form-phone"]}
                                    onChange={handleChange}
                                    name="devis-form-phone"
                                    fullWidth
                                    placeholder={t("yourPhoneNumber")}
                                    type='text'
                                    error={isFormDataError["devis-form-phone"]}
                                    helperText={isFormDataError["devis-form-phone"] && t("Phone number is required")}

                                />
                            </Stack>
                        </Stack>
                        <Stack spacing={{xs: 2, md: 7}} direction={{xs: "column", md: "row"}} justifyContent="center" alignItems="center">
                            <Stack spacing={1} width="100%">
                                <Typography variant='body1' color="text.main">{t("trip")}</Typography>
                                <InputField
                                    // required
                                    fullWidth
                                    displayEmpty
                                    renderValue={(value) => (value !== '' ? value : 'Placeholder text')}
                                    type='text'
                                    select
                                    value={formData["allerretour"]}
                                    onChange={handleChange}
                                    name="allerretour"
                                >   
                                    {/* <MenuItem value={null}>Select trip</MenuItem> */}
                                    <MenuItem value="Aller simple">{t("oneWay")}</MenuItem>
                                    <MenuItem value="Aller et retour">{t("roundTrip")}</MenuItem>
                                </InputField>
                            </Stack>
                            <Stack spacing={1} width="100%">
                                <Typography variant='body1' color="text.main">{t("pickAirlines")}</Typography>
                                <InputField
                                    name="preferred_airlines"
                                    value={formData["preferred_airlines"]}
                                    onChange={handleChange}
                                    fullWidth
                                    placeholder='Oman Airlines, Emirates, Air India'
                                    type='text'
                                />
                            </Stack>
                        </Stack>
                        <Stack spacing={{xs: 2, md: formData["allerretour"] === "Aller et retour" ? 2 : 7}} direction={{xs: "column", md: "row"}} justifyContent="center" alignItems="center">
                            <Stack spacing={1} width="100%">
                                <Typography variant='body1' color="text.main">{t("departureDate")}*</Typography>
                                <LocalizationProvider 
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale={i18n?.language}
                                >
                                    <DatePicker
                                        disablePast
                                        value={formData["devis-form-allersimpledate"] ? dayjs(formData["devis-form-allersimpledate"]): null}
                                        onChange={(newValue) => newValue !== null && setFormData(prev => ({...prev, "devis-form-allersimpledate": dayjs(newValue["$d"]).format("MM/DD/YYYY")}))}
                                        slots={{
                                            textField: (params) => <InputField
                                                required
                                                size="medium" fullWidth {...params}
                                                error={isFormDataError["devis-form-allersimpledate"]}
                                                helperText={isFormDataError["devis-form-allersimpledate"] && "Please enter a departure date"}
                                            />
                                        }}
                                    />
                                </LocalizationProvider>
                            </Stack>
                            {formData["allerretour"] === "Aller et retour" && (
                                <Stack spacing={1} width="100%">
                                    <Typography variant='body1' color="text.main">{t("returnDate")}</Typography>
                                    <LocalizationProvider 
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={i18n?.language}
                                    >
                                        <DatePicker
                                            disablePast
                                            value={formData["devis-form-allerretourdate"] ? dayjs(formData["devis-form-allerretourdate"]) : null}
                                            onChange={(newValue) => newValue !== null && setFormData(prev => ({...prev, "devis-form-allerretourdate": dayjs(newValue["$d"]).format("MM/DD/YYYY")}))}
                                            slots={{
                                                textField: (params) => <InputField
                                                    size="medium" fullWidth {...params}
                                                />
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                            )}
                            <Stack spacing={1} width="100%">
                                <Typography variant='body1' color="text.main">{t("destination")}*</Typography>
                                <InputField
                                    required
                                    value={formData["devis-form-ville"]}
                                    onChange={handleChange}
                                    name="devis-form-ville"
                                    fullWidth
                                    placeholder={t("enterDestination")}
                                    type='text'
                                    error={isFormDataError["devis-form-ville"]}
                                    helperText={isFormDataError["devis-form-ville"] && t("Destination is required")}

                                />
                            </Stack>
                        </Stack>
                        <Stack direction="row" spacing={{xs: 2, md: 2}} width="100%">
                            <Stack spacing={1} width="100%">
                                <Typography variant='body1' color="text.main">{t("adult")}</Typography>
                                <InputField
                                    // ref={anchorRef}
                                    fullWidth
                                    size='medium'
                                    placeholder={t("adult")}
                                    type='number'
                                    value={formData["devis-form-adultscount"]}
                                    name='devis-form-adultscount'
                                    onChange={handleChange}
                                    InputProps={{ inputProps: { min: 1, max: 9 } }}
                                    onKeyDown={(e) => e.preventDefault()}
                                ></InputField>
                            </Stack>
                            <Stack spacing={1} width="100%">
                                <Typography variant='body1' color="text.main">{t("children")}</Typography>
                                <InputField
                                    // ref={anchorRef}
                                    fullWidth
                                    size='medium'
                                    placeholder={t("children")}
                                    type='number'
                                    value={formData["devis-form-enfantscount"]}
                                    name='devis-form-enfantscount'
                                    onChange={handleChange}
                                    InputProps={{ inputProps: { min: 0, max: 9 } }}
                                    onKeyDown={(e) => e.preventDefault()}
                                    
                                ></InputField>
                            </Stack>
                            <Stack spacing={1} width="100%">
                                <Typography variant='body1' color="text.main">{t("infants")}</Typography>
                                <InputField
                                    // ref={anchorRef}
                                    fullWidth
                                    size='medium'
                                    placeholder={t("infants")}
                                    type='number'
                                    value={formData["devis-form-babiescount"]}
                                    name='devis-form-babiescount'
                                    onChange={handleChange}
                                    InputProps={{ inputProps: { min: 0, max: 9 } }}
                                    onKeyDown={(e) => e.preventDefault()}
                                    
                                ></InputField>
                            </Stack>
                            
                        </Stack>
                        <Stack spacing={1} width="100%">
                            <Typography variant='body1' color="text.main">{t("comment")}</Typography>
                            <InputField
                                value={formData["devis-form-comments"]}
                                name="devis-form-comments"
                                onChange={handleChange}
                                fullWidth
                                placeholder={t("typeYourMessageHere")}
                                type='text'
                                multiline
                                rows={5}
                            />
                        </Stack>
                        {/* <ReCAPTCHA
                            ref={captchaRef}
                            sitekey="6LcsqEAmAAAAAPq5w-r93qNKEjRHUyG6q-Yq2Zq2"
                        /> */}
                        <Stack direction={{xs: "column", md: "row"}} spacing={3}>

                            <BlueButton
                                loading={isLoading}  
                                size='large'  
                                // disabled={seachLoading} 
                                onClick={handleSubmit}
                            >
                                <FiSend/>
                                {t("sendMessage")}
                            </BlueButton>
                        </Stack>
                    </Stack>
                    

            </WhiteCard>
        </Stack>
   </Box>
  )
}

export default VisaTrackerForm