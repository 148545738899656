import { createContext, useContext, useState } from "react";

const PackageListContext = createContext();

export const PackageListContextProvider = (props) => {

    const [ packagesList, setPackagesList ] = useState({});

    return(
        <PackageListContext.Provider value={[ packagesList, setPackagesList ]}>
            {props.children}
        </PackageListContext.Provider>
    )

}

export const usePackageContextConsumer = () => {
    return useContext(PackageListContext);
}