import { Box } from '@mui/material'
import React from 'react'
import HomeBanner from '../ReusableComponents/HomeBanner'
import VisaTrackerForm from './VisaTrackerForm'
import { backgroundPatternImg, visaTrackerBanner } from '../../Assests/assets'
import SubscribeModal from '../FightSearch/SubscribeModal'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

function MetaTags(){

  const { t } = useTranslation();
  return(
    <Helmet>
        <meta charSet="utf-8" name='description' content={t("discoverOurStreamlinedProcessAndExpertGuidance")}/>
        <title>{t("franceVisaFromIndiaForSeamlessTravel")}</title>
        <link rel="canonical" href="https://errancesvoyages.com/visa-enquiry" />
    </Helmet>
  )
}

function VisaTracker() {

  return (
      <Box
        sx={{ 
          background: `none, url('${backgroundPatternImg}')`,
          backgroundRepeat: "repeat",
          backgroundSize: "700px", 
          overflow: "hidden",}}
      >
      <MetaTags/>
      <HomeBanner bannerImage={visaTrackerBanner}/>
      <Box sx={{ px: {xs: 1, md: 15}}}>
        <VisaTrackerForm/>
      </Box>
      <SubscribeModal/>
    </Box>
  )
}

export default VisaTracker