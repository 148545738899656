import { Box } from '@mui/material'
import React from 'react'
import HomeBanner from '../ReusableComponents/HomeBanner'
import FrenchNationals from './FrenchNationals'
import { formalitiesBanner } from '../../Assests/assets'

function Formalities() {
  return (
    <Box
      sx={{ backgroundColor: "card.background", overflow: "hidden",}}
    >
     <HomeBanner bannerImage={formalitiesBanner}/>
     <Box sx={{ backgroundColor: "card.background", px: {xs: 0, md: 15}}}>
       <FrenchNationals/>
     </Box>
   </Box>
  )
}

export default Formalities