import { Box, Button, Card, Stack, Typography } from '@mui/material'
import { BlueButton } from '../../Lib/MuiThemes/MuiComponents'
import { GoClock } from "react-icons/go"
import { BsMap } from "react-icons/bs"
import { FaHotel } from 'react-icons/fa'
import { MdOutlineCameraEnhance } from 'react-icons/md'
import { GiHotMeal } from 'react-icons/gi'
import { AiFillCar } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function TopDestinationTourCard(props) {

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Card
        elevation={2} 
        sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "card.background",
            height: "100%",
            width: {xs: "auto", sm: "auto"},
            borderRadius: "10px",
            // overflow: "hidden",
            cursor: "pointer",
            // zIndex: 5, 
            position: "relative", 
            zIndex: 1, 
            overflow: "visible",
            p:{xs:0.5 , md:1},
            "-webkit-box-shadow": "1px 0px 47px -17px rgba(89,177,230,1)",
            "-moz-box-shadow": "1px 0px 47px -24px rgba(89,177,230,1)",
            boxShadow: "1px 0px 47px -17px rgba(89,177,230,1)" ,
           
        }}>
        <Box sx={{height: {xs: "300px", sm: "250px"}, width: "100%", overflow: "hidden"}}>
            <img style={{height: "100%", width: "100%", objectFit: "cover", borderRadius: "8px"}} src={props.imageUrl ? props.imageUrl : "https://images.pexels.com/photos/933054/pexels-photo-933054.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"} alt="img" />
        </Box>
        <Stack p={1} spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <GoClock/>
                <Typography variant='subtitle1' >{ props?.duration[0] } / { props?.duration[1] }</Typography>
            </Stack>
            <Typography fontWeight="bold" variant='h5' >{props.tourName}</Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
                <BsMap/>
                <Typography variant='subtitle1' color="text.main" >{props?.destinations?.join(" - ")}</Typography>
            </Stack>
            <Stack 
                color="primary.main" 
                direction="row" 
                justifyContent="space-between" 
                alignItems="center" 
                spacing={1}
                p={1}
            >
                <Stack alignItems="center" color="text.secondary">
                    <FaHotel fontSize={22}/>
                    <Typography variant='subtitle2'>Hotel</Typography>
                </Stack>
                <Stack alignItems="center" color="text.secondary">
                    <MdOutlineCameraEnhance fontSize={22}/>
                    <Typography variant='subtitle2'>Sightseeing</Typography>
                </Stack>
                <Stack alignItems="center" color="text.secondary">
                    <GiHotMeal fontSize={22}/>
                    <Typography variant='subtitle2'>Meals</Typography>
                </Stack>
                <Stack alignItems="center" color="text.secondary">
                    <AiFillCar fontSize={22}/>
                    <Typography variant='subtitle2'>Transport</Typography>
                </Stack>
            </Stack>
        </Stack>
        <Stack gap={3} p={1} direction="row" justifyContent="space-between">
            <BlueButton onClick={() => props.setOpenConnectWithUsModal(true)}>{t("getQuote")}</BlueButton>
            <Button fullWidth variant='outlined' onClick={() => navigate(`/packages/tour/itinerary`, {state: props.tourObj})}>{t("viewDetails")}</Button>
        </Stack>
    </Card>
  )
}

export default TopDestinationTourCard