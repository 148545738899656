import { Box } from '@mui/system';
import React from 'react';
import { backgroundPatternImg } from '../../Assests/assets';
import SearchFlightBox from '../ReusableComponents/SearchFlightBox';
import OurResults from './OurResults';
import TopDestinations from './TopDestinations';
import AirlinesList from './AirlinesList';
import AboutUs from './AboutUs';
import { ValuesVision } from './ValuesVision';
import BookNextTrip from './BookNextTrip';
import YouAreServedWorldWide from './YouAreServedWorldWide';
import SubscribeModal from './SubscribeModal';
import { Testimonials } from './Testimonials';
import { useTranslation } from 'react-i18next';
import HomeCarousel from './HomeCarousel';
import { BlueButton } from '../../Lib/MuiThemes/MuiComponents';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import Search from '../ReusableComponents/Search';

function MetaTags(){

  const { t } = useTranslation();
  return(
    <Helmet>
        <meta charSet="utf-8" name='description' content={t("exploreBudgetFriendlyParisToIndiaFlights")}/>
        <title>{t("parisToIndiaCheapTicketOnline")}</title>
        <link rel="canonical" href="https://errancesvoyages.com/" />
    </Helmet>
  )
}

function FlightSearch() {

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
      <Box
        sx={{ 
          background: `none, url('${backgroundPatternImg}')`,
          // backgroundImage: "brightness(40%)",
          backgroundRepeat: "repeat",
          backgroundSize: "700px", 
          overflow: "hidden",
          position: "relative",
        }}
      >
        <MetaTags/>
        <BlueButton
          onClick={() => navigate("/visa-enquiry")} 
          sx={{
            display: {xs: "block", sm: "none"}, 
            position: "fixed",
            minWidth: 150,
            maxWidth: 200,
            paddingX: 0,
            transform: "rotate(270deg)",
            top: "35vh",
            left: "-20%",
            zIndex: 100 
          }} 
          color="primary" 
        >
          {t("visaenquiry").toUpperCase()}
        </BlueButton>
        <BlueButton 
          onClick={() => navigate("/secure-payment")}
          sx={{ 
            display: {xs: "block", md: "none"}, 
            position: "fixed",
            minWidth: 150,
            maxWidth: 200,
            paddingX: 0,
            transform: "rotate(90deg)",
            top: "35vh",
            right: "-20%",
            zIndex: 100 
          }} 
          color="primary" 
        >
          {t("securePayment").toUpperCase()}
        </BlueButton>
        <Box sx={{minHeight: { xs: "25vh", md: "100vh"}}}>
          <HomeCarousel/>
          {/* <SearchFlightBox
            px={15} 
            top={{xs: -80, md: -300}}
          /> */}
          <Search 
            px={15} 
            top={{xs: -80, md: -300}}
          />
        </Box>
        <Box sx={{py: { xs: 0, md: 20 }}}>
          <AboutUs></AboutUs>
          <OurResults></OurResults>
          <ValuesVision></ValuesVision>
          <TopDestinations></TopDestinations>
          {/* <WeOfferBestService></WeOfferBestService> */}
          <BookNextTrip></BookNextTrip>
          <Testimonials/>
          <YouAreServedWorldWide></YouAreServedWorldWide>
          <AirlinesList></AirlinesList>
          <SubscribeModal></SubscribeModal>
        </Box>
      </Box>
  )
}

export default FlightSearch