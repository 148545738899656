import React from 'react'
import { Box } from '@mui/material'
import { motion } from "framer-motion"

function HomeBanner({bannerImage, children, sx = {}}) {
  return (
    <Box
      component={motion.div}
      initial={{
        opacity: 0,
        y: -500
       }} 
  
      animate={{
        opacity: 1,
        y: 0,
        transition: {
          duration: 1
        }
      }}
      sx={{
        minHeight: {
          xs: 300,
          md: "70vh"
        },
        width: "100vw",
        background: `url(${bannerImage}) center`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        zIndex: 10,
        ...sx
      }}>
        {children}
      </Box>
  )
}

export default HomeBanner