import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import HomeBanner from '../ReusableComponents/HomeBanner'
import { backgroundPatternImg } from '../../Assests/assets'
import ListOfSeasonalFares from './fragments/ListOfSeasonalFares'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import Promotions from './fragments/Promotions'
import { Link } from 'react-router-dom'

function SeasonalFares() {

  const { t } = useTranslation();

  return (
    <Box
      sx={{ 
        // backgroundColor: "card.background", 
        background: `none, url('${backgroundPatternImg}')`,
        backgroundRepeat: "repeat",
        backgroundSize: "700px", 
        overflow: "hidden"
      }}
    >
    <HomeBanner 
        bannerImage={"https://images.pexels.com/photos/3016350/pexels-photo-3016350.jpeg"}

    />
    <Box sx={{ px: {xs: 1, md: 15}, py: 7}}>
      <Stack textAlign="center">
        <Typography 
          component={motion.h3} 
          initial={{
              opacity: 0,
              y: 50
          }}
          whileInView={{
              opacity: 1,
              y: 0,
              transition: {
              delay: 0.1,
              damping: 2
              }
          }}
          sx={{py: 2, fontWeight: "bold"}} 
          variant='h3' 
          color="primary.main"
        >
          {t("seasonalFares")}
        </Typography>
      </Stack>
      <ListOfSeasonalFares/>
      <Stack textAlign="center">
          <Typography 
            component={motion.h3} 
            initial={{
                opacity: 0,
                y: 50
            }}
            whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                delay: 0.1,
                damping: 2
                }
            }}
            sx={{py: 2, fontWeight: "bold"}} 
            variant='h3' 
            color="primary.main"
          >
            {t("PROMOTIONS")}
          </Typography>
          <Box sx={{
            textAlign: "right"
          }}>
            <Link style={{ width: "fit-content" }} to="/archived-promotions">Archived Promotions</Link>
          </Box>
      </Stack>
      <Promotions/>
    </Box>
   </Box>
  )
}

export default SeasonalFares