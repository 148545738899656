import React from 'react';
import { 
    Box,
    AppBar, 
    Toolbar, 
    Typography, 
    Button,
    Stack,
    IconButton,
    Tooltip,
} from '@mui/material';
import { BlackButtonOutlined, FullScreenLoader, SocialButton, TitleLogo, } from '../../Lib/MuiThemes/MuiComponents';
import { Link, useNavigate } from 'react-router-dom';
import UserNavbar from './UserNavbar';
import { LoaderConsumer } from '../../Lib/Contexts/LoaderContext';
import { useTranslation } from 'react-i18next';
import ChooseLanguage from './ChooseLanguage';
import MobileDrawer from './MobileDrawer';
import PackagesMenu from './fragments/PackagesMenu';
import CitiesMenu from './fragments/CitiesMenu';
import AdminMenu from './fragments/AdminMenu';
import { FiPhoneCall } from 'react-icons/fi';
import MarketeerMenu from './fragments/MarketeerMenu';
import DownloadOnButton from './DownloadOnButton';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';



export default function Navbar({auth, profile}){

  const navigate = useNavigate();
  const [isLoading] = LoaderConsumer();
  const { t } = useTranslation();

  let roleMenu = "";

  if(auth.auth && auth.role === "admin"){
    roleMenu = <AdminMenu/>
  }

  if(auth.auth && auth.role === "marketeer"){
    roleMenu = <MarketeerMenu/>
  }

  return (
    <>
    <AppBar position='sticky' sx={{backgroundColor: "card.background"}}>
        <Box>
            {isLoading && <FullScreenLoader/>}
        </Box>
        <Toolbar sx={{display: "flex", justifyContent: "space-between", height: "auto", py: 2}}>
            <Link to="/home">
              <Box sx={{
                height: "auto",
                width: { xs: 200, sm: 350 },
              }}>
                <TitleLogo/>
              </Box>
            </Link>
            <Box display={{xs: "none", md: "flex"}} flexDirection="row" alignItems="center" gap="10px">
              <Stack 
                display={{xs: "none", md: "flex" }} 
                spacing={1}
                direction="row" 
              >
                <Button 
                    disableRipple 
                    onClick={() => navigate("home")} 
                    sx={{
                        color: "primary.main",
                        "&:hover": {
                            color: "red"
                        },
                    }}
                >
                    <Typography variant='body1' fontWeight="bold">
                        {t("home")}
                    </Typography>
                </Button>

                <Button 
                    disableRipple 
                    onClick={() => navigate("our-blogs")} 
                    sx={{
                        color: "primary.main",
                        "&:hover": {
                            color: "red"
                        },
                    }}
                >
                    <Typography variant='body1' fontWeight="bold">
                        {t("blogs")}
                    </Typography>
                </Button>

                <Button 
                    disableRipple 
                    onClick={() => navigate("/visa-enquiry")} 
                    sx={{
                        color: "primary.main",
                        "&:hover": {
                            color: "red"
                        },
                    }}
                >
                    <Typography variant='body1' fontWeight="bold">
                        {t("visaenquiry")}
                    </Typography>
                </Button>

                <PackagesMenu/>

                {/* <CitiesMenu/> */}

                <Button 
                  onClick={() => navigate("/promotions")}
                  disableRipple
                  sx={{
                    color: "primary.main",
                    "&:hover": {
                      color: "red"
                    },
                  }}
                >
                    <Typography variant='body1' fontWeight="bold">
                      {t("promotions")}
                    </Typography>
                </Button>

                <Button 
                  disableRipple
                  onClick={() => navigate("/secure-payment")} 
                  sx={{
                    color: "primary.main",
                    "&:hover": {
                      color: "red"
                    },
                  }}>
                    <Typography sx={{ whiteSpace: "nowrap" }} variant='body1' fontWeight="bold">
                      {t("securePayment")}
                    </Typography>
                </Button>

                {roleMenu}

                <Tooltip title={t("contactUs")} placement="bottom">
                  <IconButton
                    onClick={() => navigate("/contact")}
                    sx={{ color: "primary.main" }}
                  >
                    <FiPhoneCall/>
                  </IconButton>
                </Tooltip>
                
              </Stack>
                <ChooseLanguage />
                {auth.auth && ( auth.role === "admin" || auth.role === "user" || auth.role === "marketeer") ? <UserNavbar auth={auth} profile={profile}/> :

                  <>
                  {/* <Button onClick={() => navigate("login")} variant='text'></Button> */}
                  <BlackButtonOutlined onClick={() => navigate("login")}>{t("login")} / {t("signup")}</BlackButtonOutlined>
                  </>
                }
            </Box>
            
            <MobileDrawer auth={auth} profile={profile}/>
        </Toolbar>
    </AppBar>
    </>
  )
}
