import React from 'react'
import { WhiteCard } from '../../../Lib/MuiThemes/MuiComponents'
import { Chip, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom';

function BlogCard(props) {

  
  return (
    <Link style={{ textDecoration: "none" }} to={`blog/${props.id}`}>
        <WhiteCard
            sx={{
                cursor: "pointer",
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between" pb={1}>
                <Typography variant='subtitle1' fontWeight="bold">{props.author}</Typography>
            </Stack>
            <Stack direction={{ xs: "column-reverse", md: "row" }} spacing={2} alignItems="center" justifyContent="space-between">
                <Stack>
                    <Typography variant='h5' fontWeight="bold">{props.title}</Typography>
                    <Typography variant='body2' py={1}>{props.description.length > 80 ? `${props.description.slice(0, 80)}...` : props.description}</Typography>
                    <Stack direction="column" spacing={1} py={1}>
                        <Typography variant="subtitle2">{dayjs(props.updateOn).format("MMM DD, YYYY")}</Typography>
                        <Stack direction="row" flexWrap="wrap" gap={1}>
                            {props.tags.split("#").slice(0, 6).map((tag, index) => {
                                if(tag.trim() !== ""){
                                    return <Chip key={`tag${index}`} label={`#${tag.trim()}`} variant="outlined" />
                                }
                            })}
                        </Stack>
                        
                    </Stack>
                </Stack>
                <Stack
                    sx={{
                        height: { xs: "100%", md: 150 },
                        minWidth: { xs: "100%", md: 250 },
                        overflow: "hidden",
                        borderRadius: 3
                    }}
                >
                    <img style={{ height: "100%", width: "100%", objectFit: "cover" }} src={props.image} alt="img" />
                </Stack>
            </Stack>
        </WhiteCard>
    </Link>
  )
}

export default BlogCard