import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { sunglassBeachImage } from '../../Assests/assets'
import { FaRegEnvelope } from 'react-icons/fa'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

function SubscribeModal() {

  const { t } = useTranslation();
  return (
    <Box 
        sx={{
            display: "flex", 
            flexDirection: "row",
            justifyContent: "space-evenly",
            px: {xs: 2, md: 15}, 
            py: 5,
            background: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url('${sunglassBeachImage}')`,
            backgroundImage: "brightness(40%)",
            backgroundSize: "cover",
            // backgroundRepeat: "no-repeat",
            backgroundPosition: "-100px 0"
        }}
        component={motion.div} 
        initial={{
            opacity: 0,
            y: 50
        }}
        whileInView={{
            opacity: 1,
            y: 0,
            transition: {
            delay: 0.1,
            damping: 2
            }
        }}
    >
        {/* <Stack sx={{
            backgroundColor: "#F9F7FE",
            height: 320,
            // backgroundImage: `url(${spiralCircleImage})`,
            // backgroundPositionY: "100px",
            // backgroundRepeat: "no-repeat",
            maxWidth: "70%",
            px: {xs: 2, md: 10},
            // py: 7,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            gap: 5,
            borderRadius: 3,
            borderTopLeftRadius: "50px",
        }}>
            <IconButton size='medium' sx={{
                position: "absolute",
                top: -15,
                left: "96%",
                backgroundColor: "primary.main",
                color: "text.white",
                "&:hover": {
                    backgroundColor: "primary.main",
                }

            }}>
                <FiSend/>
            </IconButton>
            <Box sx={{
                height: "auto", 
                width: "280px", 
                position: "absolute",
                left: "25%",
                bottom: -4,
                opacity: 0.1,
                zIndex: 0,
                overflow: "hidden",
            }}>
                <img style={{height: "100%", width: "100%", objectFit: "cover"}} src={spiralCircleImage} alt="img" />
            </Box>
            <Box sx={{
                height: "auto", 
                width: "250px", 
                position: "absolute",
                right: -24,
                top: 20,
                opacity: 0.1,
                zIndex: 0,
                overflow: "hidden",
                transform: "rotate(270deg)",
            }}>"
                <img style={{height: "100%", width: "100%", objectFit: "cover"}} src={spiralCircleImage} alt="img" />
            </Box> */}
            <form>
                <Stack direction="column" spacing={{xs: 10, md: 30}}  position="relative" zIndex={1} alignItems="center">
                    <Typography 
                        variant='h4' 
                        color="text.white" 
                        sx={{
                            fontWeight: "bold", 
                            textAlign: "center", 
                            position: "relative", 
                            zIndex: 1,
                            maxWidth: {xs: "100vw", md: 700},
                            lineHeight: 2
                        }}
                    >
                        {t("subscribeToGetInformation")}
                    </Typography>
                    <Stack direction={{xs: "column", md: "row"}} spacing={3}>
                        <Box sx={{
                            border: "1px solid black",
                            width: 350,
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            px: 1,
                            borderRadius: 1,
                            backgroundColor: "card.background",
                            p: 1
                        }}>
                            <Box sx={{color: "text.light"}}>
                                <FaRegEnvelope />
                            </Box>
                            <TextField
                                variant='standard'
                                sx={{
                                    background: "transparent",
                                }}
                                size='medium'
                                placeholder={t("yourEmail")}
                                InputProps={{
                                    disableUnderline: true, // <== added this to disable border line
                                }}
                            />
                        </Box>
                        <Button
                            size='small'
                            variant='contained'
                            // sx={{MaxWidth: 100}}
                            // sx={{backgroundColor: "primary.main"}}
                        >
                            {t("subscribe")}
                        </Button>
                    </Stack>
                </Stack>
            </form>
            
        {/* </Stack> */}
    </Box>
  )
}

export default SubscribeModal