import { Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { WhiteCard } from '../../../Lib/MuiThemes/MuiComponents'
import PhoneInput from 'react-phone-input-2'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

function SendBookingDetailsTo() {

  const { account } = useSelector(data => data.persistedReducer);
  const { t } = useTranslation();

  return (
    <WhiteCard>
        <Stack spacing={3} height="100%" width="100%">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant='h5' fontWeight="bold" color="primary">{t("bookingDetailsWillBeSentTo")}</Typography>
            </Stack>
            <Stack spacing={2} direction="column" justifyContent="space-between">
                <PhoneInput
                    className="secure-payment-phone-field"
                    country={'fr'}
                    inputStyle={{
                        // border: validateData.phone ? "2px solid red" : "2px solid #00000030",
                        border: "1px solid #000000",
                        backgroundColor: "white",
                        // boxShadow: "4px 4px 36px 0px #0000801A",
                        height: 38,
                        fontFamily: "Montserrat",
                        fontSize: 14,
                        fontWeight: 500,
                    }}
                    // {...props}
                    fullWidth
                    // placeholder={t("yourPhoneNumber")}
                    type='text'
                    name="phone"
                    value={account.number}
                    // onChange={(num) => {
                        
                    // }}
                />
                <TextField
                    fullWidth
                    name="email"
                    size='small'
                    type="email"
                    label={t("email")}
                    // required
                    value={account.email}
                    // onChange={handleChange}
                    // error={isDataValidated.email}
                    // helperText={isDataValidated.email && "Please enter a valid Email"}
                    />

            </Stack>
        </Stack>
    </WhiteCard>
  )
}

export default SendBookingDetailsTo