import { Checkbox, Typography } from '@mui/material';
import React, { useState } from 'react';
import useBookingObj from '../../../../Lib/CustomHooks/useBookingObj';

function Seat(props){

    const style = {
        position: "absolute",
        left: `${props.y*3.5}em`,
        top: `${props.x*3.5}em`,
        height: "40px",
        width: "40px",
        padding: 3,
        borderRadius: 2,
        boxShadow: "0px 2px 10px rgba(155, 157, 172, 0.3)",
        color: "black",
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "transparent",
        },
        "&.Mui-checked": {
            backgroundColor: "primary.main",
            color: "white"
        },
        "&.Mui-disabled": {
            backgroundColor: "veryLightBlue.main"
        }
    }

    return (
        <Checkbox
            sx={style}
            value={props.number}
            checked={props.seatArray.includes(props.number)}
            onChange={(e) => props.handleSeatSelection(e)}
            disabled={props.availability === "BLOCKED"} 
            icon={<Typography>{props.number}</Typography>} 
            checkedIcon={<Typography>{props.number}</Typography>}
        />

    )   
}

export default Seat