import React from 'react';
import { Avatar, Box, Grid, Stack, Typography } from '@mui/material'
import { BlueBox } from '../../../Lib/MuiThemes/MuiComponents';
import { FaCalendar, FaPlaneArrival, FaPlaneDeparture } from 'react-icons/fa';
import { MdOutlineAirlineSeatReclineNormal } from 'react-icons/md';
import dayjs from 'dayjs';

function TicketDetailsCard({ data, fetchedTickedDetails }) {
 
  return (

        <Stack direction="row">
            <Stack width="20%" bgcolor="#EBF6F2" direction="column" justifyContent="center" spacing={3} px={4} py={6}>
                <Box>
                    <Typography variant='body2'>Flight number</Typography>
                    <Typography variant='h5'>{data.flightNumber}</Typography>
                </Box>

                <Box>
                    <Typography variant='body2'>Class</Typography>
                    <Typography variant='h5'>{data.travelClass}</Typography>
                </Box>

                <Box>
                    <Typography variant='body2'>Status</Typography>
                    <Typography variant='h5' textTransform="uppercase">{fetchedTickedDetails?.bookingData?.status}</Typography>
                </Box>
            </Stack>
            <Stack direction="column" width="100%" spacing={3}>
                <Box bgcolor="primary.main" px={4} py={1} sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar 
                            src={data.airlinesLogo} 
                            sx={{
                                height: "50px", 
                                width: "50px", 
                                border: "3px solid rgb(184, 218, 255)", 
                                cursor: "pointer"
                            }}
                        />
                        <Stack>
                            <Typography variant="h5" color="text.white">{data.airlines}</Typography>
                            <Typography variant="subtitle1" color="text.white">{data.flightNumber}</Typography>
                        </Stack>
                    </Stack>
                    <Stack>
                        <Typography variant="h5" color="text.white">Duration: {data.duration}</Typography>
                    </Stack>
                </Box>
                
                {/* <Box px={10} sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}> */}
                <Box px={10} pb={2} sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                    <Grid 
                        container 
                        // border="1px solid black" 
                        rowSpacing={5} 
                        columnSpacing={{ xs: 1, sm: 2, md: 5 }}
                    >
                        <Grid item xs={6}>
                            <Stack 
                                direction="row"  
                                alignItems="center" 
                                spacing={2}
                            >
                                <BlueBox sx={{p: 1}} color="primary.main"><FaPlaneDeparture fontSize={35}/></BlueBox>
                                <Box>
                                    <Typography variant='body2' >Departure</Typography>
                                    <Typography color="text.main" variant='h5'>{data.originAirport} ({data.origin})</Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack 
                                direction="row" 
                                alignItems="center"
                                spacing={2}
                            >
                                <BlueBox sx={{p: 1}} color="primary.main"><FaCalendar fontSize={35}/></BlueBox>
                                <Box>
                                    <Typography variant='body2'>Departure date</Typography>
                                    <Typography color="text.main" variant='h5'>{dayjs(`${data.departureDate} ${data.originTime}`).format("YYYY-MM-DD, HH:mm")}</Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack 
                                direction="row" 
                                alignItems="center"
                                spacing={2}
                            >
                                <BlueBox sx={{p: 1}} color="primary.main"><FaPlaneArrival fontSize={35}/></BlueBox>
                                <Box>
                                    <Typography variant='body2'>Arrival</Typography>
                                    <Typography color="text.main" variant='h5'>{data.destinationAirport} ({data.destination})</Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack 
                                direction="row" 
                                alignItems="center"
                                spacing={2}
                            >
                                <BlueBox sx={{p: 1}} color="primary.main"><FaCalendar fontSize={35}/></BlueBox>
                                <Box>
                                    <Typography variant='body2'>Arrival date</Typography>
                                    <Typography color="text.main" variant='h5'>{dayjs(`${data.destinationDate} ${data.destinationTime}`).format("YYYY-MM-DD, HH:mm")}</Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack 
                                direction="row" 
                                alignItems="center"
                                spacing={2}
                            >
                                <BlueBox sx={{p: 1}} color="primary.main"><MdOutlineAirlineSeatReclineNormal fontSize={35}/></BlueBox>
                                <Box>
                                    <Typography variant='body2'>Departure terminal</Typography>
                                    <Typography color="text.main" variant='h5'>{data.originTerminal ? data.originTerminal : "not available"}</Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack 
                                direction="row" 
                                alignItems="center"
                                spacing={2}
                            >
                                <BlueBox sx={{p: 1}} color="primary.main"><MdOutlineAirlineSeatReclineNormal fontSize={35}/></BlueBox>
                                <Box>
                                    <Typography variant='body2'>Arrival terminal</Typography>
                                    <Typography color="text.main" variant='h5'>{data.destinationTerminal ? data.destinationTerminal : "not available"}</Typography>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </Stack>
  )
}

export default TicketDetailsCard