import React from 'react'
import { Box } from '@mui/material'
import HomeBanner from '../ReusableComponents/HomeBanner';
import { backgroundPatternImg, packagesBanner } from '../../Assests/assets';
import OurPackages from './OurPackages';
import TopSellingDestinations from './TopSellingDestinations';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

function MetaTags(){

  const { t } = useTranslation();
  return(
    <Helmet>
        <meta charSet="utf-8" name='description' content={t("yourGotoAirTicketAgentInPondicherry")}/>
        <title>{t("airTicketAgencyInPondicherry")}</title>
        <link rel="canonical" href="https://errancesvoyages.com/packages" />
    </Helmet>
  )
}

function Packages() {

  return (
      <Box
        sx={{ 
          // backgroundColor: "card.background", 
          background: `none, url('${backgroundPatternImg}')`,
          backgroundRepeat: "repeat",
          backgroundSize: "700px", 
          overflow: "hidden",
          }}
      >
      <MetaTags/>
      <HomeBanner 
        sx={{ 
          backgroundPosition:{xs:0,md:"0 -300px"},
      
        }} 
        bannerImage={packagesBanner}
      />
      <Box sx={{ px: {xs: 1, md: 15}, py: 7}}>
          <OurPackages/>
          <TopSellingDestinations/>
      </Box>
    </Box>

  )
}

export default Packages