import { Box } from '@mui/material'
import React from 'react'
import PayOnlineForm from './PayOnlineForm'
import { backgroundPatternImg } from '../../Assests/assets'
import SubscribeModal from '../FightSearch/SubscribeModal'

function PayOnline() {
  return (
    <Box
      sx={{ 
        background: `none, url('${backgroundPatternImg}')`,
        backgroundRepeat: "repeat",
        backgroundSize: "700px", 
        overflow: "hidden"
      }}
    >
     <Box sx={{ px: {xs: 0, md: 10}}}>
       <PayOnlineForm/>
     </Box>
     <SubscribeModal/>
   </Box>
  )
}

export default PayOnline