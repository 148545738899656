import { Masonry } from "@mui/lab"
import { Card, CardContent, CardMedia, Pagination, Stack, Typography } from "@mui/material"
import { usePromotionContextConsumer } from "../../../Lib/Contexts/PromotionApiContext";
import { useState } from "react";

function Promotions() {

  const [ promotionsList ] = usePromotionContextConsumer();
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    console.log(value)
    setPage(value);
  };


  return (
    <Stack sx={{ py: 5 }}>
        <Masonry columns={{xs: 1, md: 3}} spacing={{xs: 0, md: 3}}>
            {promotionsList.map((item, index) => (
                <Card 
                    key={index} 
                    sx={{p: 1, mt: {xs: 5, md: 3}, cursor: "pointer"}}
                    onClick={() => window.open("https://www.facebook.com/mithra.vijay.94")} 
                >
                    <CardMedia
                        component="img"
                        // height="100%"
                        // width="100%"
                        image={item.attachment.src}
                        alt="promotion img"
                    />
                    {item.message && 
                    <CardContent sx={{px: 0}}>
                        <Typography variant="body1" color="text.main">
                            {item.message}
                        </Typography>
                    </CardContent>}
                </Card>
            ))}
        </Masonry>
        <Stack mt={5} alignItems="center" justifyContent="center">
            <Pagination 
                count={8} 
                color="primary"
                page={page} 
                onChange={handleChange} 
            />
        </Stack>
    </Stack>
  )
}

export default Promotions