import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    searchData: {
        origin: { "name": "Charles de Gaulle Intl ", "city": "Paris", "country": "France", "IATA": "CDG" },
        destination: "",
        trip: "twoway",
        isCombinable: false,
        isMultiCity: false,
        departureDate: null,
        returnDate: null,
        class: "ECONOMY",
        corporateFare: false,
        adultCount: 1,
        childrenCount: 0,
        infantsCount: 0
    },

    multicitySearchData: [
        {
            origin: "",
            destination: "",
            departureDate: null,
            minDepartureDate: null
        }
    ]
}

export const searchSlice = createSlice({
    name: "searchKeys",
    initialState,
    reducers: {
        setSearchState: (state, action) => {
            const { type, value } = action.payload;

            const searchObj = { ...state.searchData, [type]: value }
            
            return { ...state, searchData: searchObj }
        },

        increasePassengerCount: (state, action) => {

            if(state.searchData[action.payload] < 9){

                state.searchData[action.payload]++

            }

            return state
        },

        decreasePassengerCount: (state, action) => {

            if(state.searchData[action.payload] > 0){

                state.searchData[action.payload]--

            }

            return state
        },

        setMulticitySearchState: (state, action) => {
            const { type, value, index } = action.payload;

            state.multicitySearchData[index] = { ...state.multicitySearchData[index], [type]: value }
            
            return state
        },

        insertNewMulticityObject: (state) => {
            const newArray = [ 
                ...state.multicitySearchData,  
                {
                    origin: "",
                    destination: "",
                    departureDate: null,
                    minDepartureDate: state.multicitySearchData[state.multicitySearchData.length - 1].departureDate
                }
            ];

            // console.log(newArray);

            return { ...state, multicitySearchData: newArray };
        },

        removeMultiCityObject: (state) => {

            console.log(state.multicitySearchData);

            state.multicitySearchData.pop();


            // return { ...state, multicitySearchData: newArray };
        },

        clearState: () => {
            return initialState
        }
    }
});

export const { setSearchState, increasePassengerCount, decreasePassengerCount, setMulticitySearchState, insertNewMulticityObject, removeMultiCityObject, clearState } = searchSlice.actions;
export const searchKeysReducer = searchSlice.reducer;