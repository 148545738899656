import React from 'react'
import { ExpandMore } from '@mui/icons-material'
import { Box, Button, List, ListItem, ListItemButton, Typography } from '@mui/material'
import { ReuseMenu } from '../../../Lib/MuiThemes/MuiComponents'
import useMenu from '../../../Lib/CustomHooks/useMenu';
import { useTranslation } from 'react-i18next';
import { CiAirportSign1, CiAlignBottom, CiBookmarkCheck, CiMoneyCheck1, CiPlane, CiReceipt, CiSignpostDuo1, CiUser, CiViewTimeline } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';

function AdminMenu() {

    const navigate = useNavigate();
    const { menu, openMenu, closeMenu} = useMenu(); 
    const { t } = useTranslation();
    const adminRoutes = [
        {
          route: "/admin/dashboard",
          title: "Dashboard",
          icon: <CiAlignBottom/>
        },
        // {
        //     route: "/admin/requested-booking",
        //     title: "Requested bookings",
        //     icon: <CiReceipt/>
        // },
        {
            route: "/admin/trip-details",
            title: "Trip details",
            icon: <CiAirportSign1/>
        },
        {
          route: "/admin/user-details",
          title: "User details",
          icon: <CiUser/>
        },
        {
          route: "/admin/approve-travelers",
          title: "Approve travelers",
          icon: <CiBookmarkCheck/>
        },
        {
          route: "/admin/config-packages",
          title: "Add packages",
          icon: <CiSignpostDuo1/>
        },
        {
          route: "/admin/blogs",
          title: "Blogs",
          icon: <CiViewTimeline/>
        },
        {
          route: "/admin/fares",
          title: "Seasonal Fares",
          icon: <CiPlane />
        },
        {
          route: "/admin/transactions",
          title: "Transaction details",
          icon: <CiMoneyCheck1/>
        },
      ]
    


  return (
    <>
        <Button 
            disableRipple 
            onClick={openMenu} 
            sx={{
                color: "primary.main",
                "&:hover": {
                    color: "red"
                },
            }}
        >
            <Typography variant='body1' fontWeight="bold">
                {t("C Panel")}
            </Typography>
            <ExpandMore/>
        </Button>
        <ReuseMenu
            menu={menu}
            closeMenu={closeMenu}
        >
            <List sx={{ p: 1 }}>
                {adminRoutes.map((text, index) => (
                    <ListItem
                        key={index}
                        onClick={() => navigate(`${text.route}`)} 
                        disablePadding
                    >
                        <ListItemButton 
                        sx={{
                            py: 1,
                            px: 3, 
                            mt: 2,
                            borderRadius: 2,
                            "&:hover": {
                                backgroundColor: "primary.main",
                                color: "text.white"
                            }
                        }}>
                            <Box sx={{fontSize: 25}}>
                                {text.icon}
                            </Box>
                            <Typography sx={{ml: 5}} variant='h6'>{t(text.title)}</Typography>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </ReuseMenu>
    </>
  )
}

export default AdminMenu