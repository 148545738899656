import { Autocomplete, Stack, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { capitalize } from '../../Lib/utils/helperFunctions';
import dayjs from 'dayjs';
import airports from "../../Lib/utils/airport.json";
import * as locales from '@mui/x-date-pickers/locales';
import { useDispatch, useSelector } from 'react-redux';
import { setMulticitySearchState } from '../../Lib/Redux/SearchSlice';


const inputStyle = {
    input: {
        color: 'black',
    },
    '& label': {
        color: "black",
    },
    '& label.Mui-focused': {
        color: "black",
    },
    '& .MuiFormLabel-root.Mui-disabled': {
        color: "black"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'black',
        },
        '&:hover fieldset': {
          borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'black',
        },
    },
}

function MultiCityInputs({ index }) {

  const { t, i18n } = useTranslation();
  const [originKey, setOriginKey] = useState({searchKey: "", options: []})
  const [destinationKey, setDestinationKey] = useState({searchKey: "", options: []})

  const dispatch = useDispatch();
  const { multicitySearchData } = useSelector(data => data.persistedReducer.searchKeys) 



  /* multi city origin flight search */
//   useEffect(() => {   
//     const controller = axios.CancelToken.source();
//     if(originKey.searchKey !== ""){
//         (async () => {
//             try{
//                 // startLoading();
//                 // const verifyId = await auth.currentUser.getIdToken();
//                 const fetch = await Axios({
//                     url: `airports?keyword=${originKey.searchKey}`,
//                     method: "get",
//                     cancelToken: controller.token
//                 });

//                 if(fetch.status === 200){
//                     setOriginKey(prev => ({...prev, options: fetch.data}))
//                 }
//                 // console.log(fetch);
//             }catch(err){
//                 console.error(err)
//             }
//         })()
//     }

//     return () => controller.cancel();
//   },[originKey.searchKey]);

//   /*multi city destination flight search*/
//   useEffect(() => {   
//     const controller = axios.CancelToken.source();
//     if(destinationKey.searchKey !== ""){
//         (async () => {
//             try{
//                 // startLoading();
//                 // const verifyId = await auth.currentUser.getIdToken();
//                 const fetch = await Axios({
//                     url: `airports?keyword=${destinationKey.searchKey}`,
//                     method: "get",
//                     cancelToken: controller.token
//                 });

//                 if(fetch.status === 200){
//                     setDestinationKey(prev => ({...prev, options: fetch.data}))
//                 }
//                 // console.log(fetch);
//             }catch(err){
//                 console.error(err)
//             }
//         })()
//     }

//     return () => controller.cancel();
//   },[destinationKey.searchKey]);


  return (
    <Stack width="59%" direction={{xs: "column", md: "row"}} spacing={3}>
        <Autocomplete
            fullWidth
            onChange={(event, newVal) => {
                if(newVal){
                    dispatch(setMulticitySearchState({ type: "origin", value: newVal, index: index}))
                }
            }}

            value={multicitySearchData[index].origin ? multicitySearchData[index].origin : ""}
            size="medium"
            options={airports}
            getOptionLabel={(option) => option && `${capitalize(option.name)}(${option.IATA}), ${option.city}`}
            // isOptionEqualToValue={(option, value) =>  console.log(option.name, " ---- ", value.name, option.name === value.name)}
            isOptionEqualToValue={(option, value) => true}
            noOptionsText="No flights available"
            blurOnSelect
            renderInput={(params) => (
                <TextField
                    // error={!searchData.origin}
                    // helperText={!searchData.origin && "Please choose your origin"}
                    {...params}
                    sx={inputStyle}
                    label={t("from")}
                    value={originKey.searchKey}
                    onChange={(e) => setOriginKey(prev => ({...prev, searchKey: e.target.value}))}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                    }}
                />
            )}
        />
        
        <Autocomplete
            fullWidth
            onChange={(event, newVal) => {
                if(newVal){
                    dispatch(setMulticitySearchState({ type: "destination", value: newVal, index: index}))
                }
            }}
            value={multicitySearchData[index].destination ? multicitySearchData[index].destination : null}
            size="medium"
            options={airports}
            getOptionLabel={(option) => option && `${capitalize(option.name)}(${option.IATA}), ${option.city}`}
            // isOptionEqualToValue={(option, value) =>  console.log(option.name, " ---- ", value.name, option.name === value.name)}
            isOptionEqualToValue={(option, value) => true}
            noOptionsText="No flights available"
            blurOnSelect
            renderInput={(params) => (
                <TextField
                    // error={!searchData.destination}
                    // helperText={!searchData.destination && "Please choose destination"}
                    autoComplete='off'
                    {...params}
                    sx={inputStyle}
                    value={destinationKey.searchKey}
                    onChange={(e) => setDestinationKey(prev => ({...prev, searchKey: e.target.value}))}
                    label={t("to")}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                    }}
                />
            )}
        />

        <LocalizationProvider 
            dateAdapter={AdapterDayjs}
            adapterLocale={i18n?.language}
        >
            <MobileDatePicker
                disablePast
                format='DD-MM-YYYY'
                label={t("departureDate")}
                minDate={dayjs(multicitySearchData[index].minDepartureDate)}
                value={!!multicitySearchData[index].departureDate ? dayjs(multicitySearchData[index].departureDate) : null}
                onChange={(newValue) => {
                    if(newValue !== null){
                        dispatch(setMulticitySearchState({ type: "departureDate", value: dayjs(newValue["$d"]).format("YYYY-MM-DD"), index: index}))
                        
                        if(!!multicitySearchData[index + 1]){
                            dispatch(setMulticitySearchState({ type: "minDepartureDate", value: dayjs(newValue["$d"]).format("YYYY-MM-DD"), index: index + 1}))
                        }
                    }
                }}
                slots={{
                    textField: (params) => <TextField
                        error={false}
                        size="medium" fullWidth {...params}
                        sx={inputStyle} 
                    />
                }}
            />
        </LocalizationProvider>
    </Stack>
  )
}

export default MultiCityInputs