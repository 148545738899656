import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { WhiteCard } from '../../Lib/MuiThemes/MuiComponents'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { valueAndVisionImage } from '../../Assests/assets'

export const ValuesVision = () => {
  
  const { t } = useTranslation();

  return (
    <Box 
        component={motion.div}
        initial={{
            opacity: 0,
            y: 100
        }}
        whileInView={{
            y: 0,
            opacity: 1,
            transition: {
                delay: 0.2,
                damping: 2
            }
        }}
        viewport={{once: false}}
        sx={{
            display: "flex", 
            flexDirection: {xs: "column", md: "row"}, 
            gap: {xs: 5, md: 7}, 
            px: {xs: 1, md: 15}, 
            py: 5,
            mt: {xs: -5, md: 0},
           
        }}
    >
        <Stack width={{xs: "100%", md: "50%"}}>
            <Typography textAlign={{xs: "center", md: "left"}} sx={{fontWeight: "bold", pb: 2}} variant='h3' color="primary.main">{t("valuesAndVision")}</Typography>
            <Typography sx={{lineHeight: "30px"}} variant='h5' color="text.main">
                {t("ourPrimaryAmbition")}
            </Typography>
        </Stack>
        <Box 
            sx={{
                height: {xs: 250, sm: 300}, 
                width: {xs: "100%", md: "50%"}, 
                overflow: "hidden",
                borderRadius: 4,
                boxShadow: "4px 4px 30px rgba(0, 0, 0, .2)"
            }}
        >
            <img style={{height: "100%", width: "100%", objectFit: "cover"}} src={valueAndVisionImage} alt="img" />
        </Box>
    </Box>
  )
}
