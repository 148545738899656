import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

function FlagWithCountryName(props) {

    const { t } = useTranslation();

    const item = {
        hidden: { x: -20, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1
        },

    }
  return (
    <Stack 
        direction="row" 
        alignItems="center" 
        spacing={3}
        component={motion.div}
        variants={item}
    >
        <Box sx={{
            height: 25, 
            width: 30, 
            borderRadius: 1,
            overflow: "hidden"
        }}>
            {/* <GoLocation style={{ color: "white", fontSize: 25}}/> */}
            <img
                loading="lazy"
                width="100%"
                height="100%"
                style={{objectFit: "cover"}}
                src={`https://flagcdn.com/w40/${props.alphaCode}.png`}
                alt="flag"
            />
        </Box>
        <Box>
            <Typography sx={{fontWeight: "bold"}} variant='body2'>{t(props.name)}</Typography>
        </Box>
    </Stack>
  )
}

export default FlagWithCountryName