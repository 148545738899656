import { Accordion, AccordionDetails, AccordionSummary, IconButton, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import React from 'react'
import { AnchorText, GrayCard, WhiteCard } from '../../Lib/MuiThemes/MuiComponents'
import { ArrowRightAlt, CheckCircle, ExpandMore, PanToolAlt } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function FrenchNationals() {

  const { t } = useTranslation();

  return (
    <Stack py={7} sx={{mt: -30, position: "relative", zIndex: 10}}>
        <WhiteCard>
            <Stack spacing={3}>
                <Stack textAlign="center">
                    <Typography 
                        component={motion.h3} 
                        initial={{
                            opacity: 0,
                            y: 50
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                            transition: {
                            delay: 0.1,
                            damping: 2
                            }
                        }}
                        sx={{py: 2, fontWeight: "bold"}} 
                        variant='h3' 
                        color="primary.main"
                    >
                        {t("accessTheEssentialInformation")}
                    </Typography>
                    <Typography
                        component={motion.h3} 
                        initial={{
                            opacity: 0,
                            y: 50
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                            transition: {
                            delay: 0.1,
                            damping: 2
                            }
                        }}
                        sx={{fontWeight: "bold", px: {xs: 1, md: 15}}} 
                        variant='body2'
                    >
                        {t("itIsTravelersResponsibility")}
                    </Typography>
                </Stack>
                <Stack>
                    <GrayCard elevation={0} >
                        <Stack sx={{px: {xs: 1, md: 15}, py: 5}} spacing={3}>
                            <Typography 
                                sx={{fontWeight: "bold"}} 
                                variant='h4' 
                                color="text.main"
                            >
                                {t("formalitiesForFrenchNationals")}
                            </Typography>
                            <Stack>
                                <Accordion elevation={0} sx={{boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", p: {xs: 0, md: 2}}}>
                                    <AccordionSummary
                                        expandIcon={
                                        <IconButton sx={{backgroundColor: "text.white", boxShadow: "0px 6px 16px rgba(8, 15, 52, 0.08)"}}>
                                            <ExpandMore />
                                        </IconButton>}
                                    >
                                    <Typography variant='body1' sx={{fontWeight: "bold"}}>{t("outsideTheEuAndTheSchengenArea")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{wordBreak: "break-word"}}>
                                        <Typography lineHeight={1.4} variant='h5' color="text.main">
                                            {t("mostStatesRequirePassport")} <Link style={{textDecoration: "none"}} to="https://www.diplomatie.gouv.fr/fr/conseils-aux-voyageurs/informations-pratiques/"><AnchorText variant='h5'>https://www.diplomatie.gouv.fr/fr/conseils-aux-voyageurs/informations-pratiques/</AnchorText></Link> {t("youCanFindContactDetails")}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>
                            <Stack>
                                <Accordion elevation={0} sx={{boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", p: {xs: 0, md: 2}}}>
                                    <AccordionSummary
                                        expandIcon={
                                        <IconButton sx={{backgroundColor: "text.white", boxShadow: "0px 6px 16px rgba(8, 15, 52, 0.08)"}}>
                                            <ExpandMore />
                                        </IconButton>}
                                    >
                                    <Typography variant='body1' sx={{fontWeight: "bold"}}>{t("withinTheEuropeanUnion")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{wordBreak: "break-word"}}>
                                        <Typography lineHeight={1.4} variant='h5' color="text.main">
                                        {t("theValidNationalIdentityCard")} <Link
                                        style={{textDecoration: "none"}} to="https://www.diplomatie.gouv.fr/fr/services-aux-francais/documents-officiels-a-l-etranger/"><AnchorText variant='h5'>https://www.diplomatie.gouv.fr/fr/services-aux-citoyens/documents-officiels-al-foreign</AnchorText></Link><br /> 
                                        {t("dependingOnTheCountry")}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>
                        </Stack>
                    </GrayCard>
                </Stack>
                <Stack>
                    <GrayCard elevation={0} >
                        <Stack sx={{px: {xs: 1, md: 15}, py: 5}} spacing={3}>
                            <Typography 
                                sx={{fontWeight: "bold"}} 
                                variant='h4' 
                                color="text.main"
                            >
                                {t("formalitiesForOtherCountries")}
                            </Typography>
                            <Stack>
                                <Accordion elevation={0} sx={{boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", p: {xs: 0, md: 2}}}>
                                    <AccordionSummary
                                        expandIcon={
                                        <IconButton sx={{backgroundColor: "text.white", boxShadow: "0px 6px 16px rgba(8, 15, 52, 0.08)"}}>
                                            <ExpandMore />
                                        </IconButton>}
                                    >
                                    <Typography variant='body1' sx={{fontWeight: "bold"}}>{t("formalitiesForNationalsOfOtherCountries")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{wordBreak: "break-word"}}>
                                        <Typography lineHeight={1.4} variant='h5' color="text.main">
                                            {t("checkTheTravelDocumentsRequired")} <Link style={{textDecoration: "none"}} to="https://www.diplomatie.gouv.fr/fr/le-ministere-et-son-reseau/representations-etrangeres-en-france/"><AnchorText variant='h5'>{t("contactDetailsOfForeignEmbassies")}</AnchorText></Link> {t("regardingThePassport")}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>
                        </Stack>
                    </GrayCard>
                </Stack>
                <Stack>
                    <GrayCard elevation={0} >
                        <Stack sx={{px: {xs: 1, md: 15}, py: 5}} spacing={3}>
                            <Typography 
                                sx={{fontWeight: "bold"}} 
                                variant='h4' 
                                color="text.main"
                            >
                                {t("formalitiesForMinorChildren")}
                            </Typography>
                            <Typography 
                                sx={{fontWeight: "bold"}} 
                                variant='body1' 
                                color="text.main"
                            >
                                {t("aMinorChildWhoIsFrenchNational")}
                            </Typography>
                            <Stack>
                                <Accordion elevation={0} sx={{boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", p: {xs: 0, md: 2}}}>
                                    <AccordionSummary
                                        expandIcon={
                                        <IconButton sx={{backgroundColor: "text.white", boxShadow: "0px 6px 16px rgba(8, 15, 52, 0.08)"}}>
                                            <ExpandMore />
                                        </IconButton>}
                                    >
                                    <Typography variant='body1' sx={{fontWeight: "bold"}}>{t("withinTheEuropeanUnion")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{wordBreak: "break-word"}}>
                                        <Typography lineHeight={1.4} variant='h5' color="text.main">
                                            {t("hisHerValidNationalIdentityCard")}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>
                            <Stack>
                                <Accordion elevation={0} sx={{boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", p: {xs: 0, md: 2}}}>
                                    <AccordionSummary
                                        expandIcon={
                                        <IconButton sx={{backgroundColor: "text.white", boxShadow: "0px 6px 16px rgba(8, 15, 52, 0.08)"}}>
                                            <ExpandMore />
                                        </IconButton>}
                                    >
                                    <Typography variant='body1' sx={{fontWeight: "bold"}}>{t("outsideTheEuAndTheSchengenArea")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{wordBreak: "break-word"}}>
                                        <Stack spacing={2}>
                                            <Stack direction="row" spacing={1}>
                                                <CheckCircle sx={{color: "primary.main"}} />
                                                <Typography lineHeight={1.4} variant='h5' color="text.main">
                                                    {t("eitherAValidIndividualPassport")}
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <CheckCircle sx={{color: "primary.main"}} />
                                                <Typography lineHeight={1.4} variant='h5' color="text.main">
                                                    {t("eitherAValidIndividualPassportAndAVisa")}
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <CheckCircle sx={{color: "primary.main"}} />
                                                <Typography lineHeight={1.4} variant='h5' color="text.main">
                                                   {t("aMinorChildWhoLivesInFrance")}
                                                </Typography>
                                            </Stack>
                                            <Stack pl={{xs: 0, md: 5}} direction="row" spacing={1}>
                                                <ArrowRightAlt sx={{color: "primary.main"}}/>
                                                <Typography lineHeight={1.4} variant='h5' color="text.main">
                                                    <Typography sx={{fontWeight: "bold"}} color="primary.main" component="span" variant='h5'>{t("frenchChild")}</Typography>
                                                    {t("validIdentityDocumentOfTheMinor")}   
                                                </Typography>
                                            </Stack>
                                            <Stack pl={{xs: 0, md: 5}} direction="row" spacing={1}>
                                                <ArrowRightAlt sx={{color: "primary.main"}}/>
                                                <Typography lineHeight={1.4} variant='h5' color="text.main">
                                                    <Typography sx={{fontWeight: "bold"}} color="primary.main" component="span" variant='h5'>{t("foriegnChild")} </Typography>
                                                    {t("validIdentityDocumentOfTheMinor")}   
                                                </Typography>
                                            </Stack>
                                            <Stack pl={{xs: 0, md: 5}} direction="row" spacing={1}>
                                                <ArrowRightAlt sx={{color: "primary.main"}}/>
                                                <Typography lineHeight={1.4} variant='h5' color="text.main">
                                                    <Typography sx={{fontWeight: "bold"}} color="primary.main" component="span" variant='h5'>{t("authorizationToLeaveTerritory")} </Typography>
                                                    {t("originalForm")}    
                                                </Typography>
                                            </Stack>
                                            <Stack pl={{xs: 0, md: 5}} direction="row" spacing={1}>
                                                <ArrowRightAlt sx={{color: "primary.main"}}/>
                                                <Typography lineHeight={1.4} variant='h5' color="text.main">
                                                    <Typography sx={{fontWeight: "bold"}} color="primary.main" component="span" variant='h5'>{t("frenchParentEuropeanForeignParent")} </Typography>
                                                    {t("photocopyOfTheIdentityCard")} 
                                                </Typography>
                                            </Stack>
                                            <Stack pl={{xs: 0, md: 5}} direction="row" spacing={1}>
                                                <ArrowRightAlt sx={{color: "primary.main"}}/>
                                                <Typography lineHeight={1.4} variant='h5' color="text.main">
                                                    <Typography sx={{fontWeight: "bold"}} color="primary.main" component="span" variant='h5'>{t("foreignRelative")} </Typography>
                                                    {t("photocopyOfTheValidIdentityDocument")}  
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <CheckCircle sx={{color: "primary.main"}} />
                                                <Typography lineHeight={1.4} variant='h5' color="text.main">
                                                    <Typography sx={{fontWeight: "bold"}} color="primary.main" component="span" variant='h5'>{t("toKnow: ")} </Typography>
                                                    {t("noTextObliges")}   
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <CheckCircle sx={{color: "primary.main"}} />
                                                <Typography lineHeight={1.4} variant='h5' color="text.main">
                                                    {t("ifTheChildLeavesMainland")}
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <CheckCircle sx={{color: "primary.main"}} />
                                                <Typography lineHeight={1.4} variant='h5' color="text.main">
                                                    {t("ifTheChildIsTravelingWithOnlyOneParent")}
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <CheckCircle sx={{color: "primary.main"}} />
                                                <Typography lineHeight={1.4} variant='h5' color="text.main">
                                                    {t("inAllCasesItIsRecommendedToCheckTheDocuments")} <Link style={{textDecoration: "none"}} to="https://www.diplomatie.gouv.fr/fr/conseils-aux-voyageurs/informations-pratiques/"><AnchorText variant='h5'>https://www.diplomatie.gouv.fr/fr/conseils-aux-travellers/practical-information</AnchorText></Link> 
                                                </Typography>
                                            </Stack>
                                            <Stack direction="column" spacing={1} sx={{bgcolor: "veryLightBlue.main", p: 2, borderRadius: 3}}>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <PanToolAlt sx={{color: "primary.main"}} />
                                                    <Typography sx={{fontWeight: "bold"}} color="primary.main" component="span" variant='h5'>{t("note")} </Typography>
                                                </Stack>
                                                    
                                                <Typography pl={2} lineHeight={1.4} variant='h5' color="text.main">
                                                    {t("doNotTravelWithAPassportThatHasBeenDeclaredLostOrStolen")}
                                                </Typography> 
                                                <Typography pl={2} lineHeight={1.4} variant='h5' color="text.main">
                                                    {t("doNotTravelWithDamagedPassport")}
                                                </Typography>
                                                <Typography pl={2} lineHeight={1.4} variant='h5' color="text.main">
                                                    {t("ifYourIdentityDocumentIsLostOrStolen")}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>
                        </Stack>
                    </GrayCard>
                </Stack>
                <Stack>
                    <GrayCard elevation={0} >
                        <Stack sx={{px: {xs: 1, md: 15}, py: 5}} spacing={3}>
                            <Typography 
                                sx={{fontWeight: "bold"}} 
                                variant='h4' 
                                color="text.main"
                            >
                                {t("generalVISAInformation")}
                            </Typography>
                            <Stack>
                                <Accordion elevation={0} sx={{boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", p: {xs: 0, md: 2}}}>
                                    <AccordionSummary
                                        expandIcon={
                                        <IconButton sx={{backgroundColor: "text.white", boxShadow: "0px 6px 16px rgba(8, 15, 52, 0.08)"}}>
                                            <ExpandMore />
                                        </IconButton>}
                                    >
                                    <Typography variant='body1' sx={{fontWeight: "bold"}}>{t("toFrenchNationals")} </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{wordBreak: "break-word"}}>
                                        <Typography lineHeight={1.4} variant='h5' color="text.main">
                                            {t("forATripToACountryOfTheEuropean")} <br />
                                            {t("forMostOtherForeignCountries")} <Link style={{textDecoration: "none"}} to="https://www.diplomatie.gouv.fr/fr/conseils-aux-voyageurs/conseils-par-pays-destination/"><AnchorText variant='h5'>Travel advice section of the Ministry of Foreign Affairs website</AnchorText></Link> 
                                            {t("byConsultingTheSheetForTheCountry")}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>
                            <Stack>
                                <Accordion elevation={0} sx={{boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", p: {xs: 0, md: 2}}}>
                                    <AccordionSummary
                                        expandIcon={
                                        <IconButton sx={{backgroundColor: "text.white", boxShadow: "0px 6px 16px rgba(8, 15, 52, 0.08)"}}>
                                            <ExpandMore />
                                        </IconButton>}
                                    >
                                        <Typography variant='body1' sx={{fontWeight: "bold"}}>
                                            {t("toNationalsOfOtherCountries")}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{wordBreak: "break-word"}}>
                                        <Typography lineHeight={1.4} variant='h5' color="text.main">
                                            {t("toFindOutIfYourCountryOfDestination")}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>
                            <Stack>
                                <Accordion elevation={0} sx={{boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", p: {xs: 0, md: 2}}}>
                                    <AccordionSummary
                                        expandIcon={
                                        <IconButton sx={{backgroundColor: "text.white", boxShadow: "0px 6px 16px rgba(8, 15, 52, 0.08)"}}>
                                            <ExpandMore />
                                        </IconButton>}
                                    >
                                    <Typography variant='body1' sx={{fontWeight: "bold"}}>{t("toKnowThePoliceAndCustomsFormalities")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{wordBreak: "break-word"}}>
                                        <Typography lineHeight={1.4} variant='h5' color="text.main">
                                            {t("ofTheCountryOfDestination")} <Link style={{textDecoration: "none"}} to="https://www.douane.gouv.fr/dossier/voyagez-en-toute-tranquillite"><AnchorText variant='h5'>http://douane.gouv.fr/articles/c666-formalites-generales</AnchorText></Link>
                                            {t("asWellAsOnTheTravelAdvice")}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>
                            <Stack>
                                <Accordion elevation={0} sx={{boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", p: {xs: 0, md: 2}}}>
                                    <AccordionSummary
                                        expandIcon={
                                        <IconButton sx={{backgroundColor: "text.white", boxShadow: "0px 6px 16px rgba(8, 15, 52, 0.08)"}}>
                                            <ExpandMore />
                                        </IconButton>}
                                    >
                                    <Typography variant='body1' sx={{fontWeight: "bold"}}>{t("toKnowTheHealthFormalities")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{wordBreak: "break-word"}}>
                                        <Typography lineHeight={1.4} variant='h5' color="text.main">
                                            {t("vaccinationsHealthRisksRecommended")} <Link style={{textDecoration: "none"}} to="https://www.diplomatie.gouv.fr/fr/conseils-aux-voyageurs/conseils-par-pays-destination/"><AnchorText variant='h5'>Travel advice section of the Ministry of Foreign Affairs website</AnchorText></Link> {t("byConsultingTheSheetForTheCountryPasteurWebsite")}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>
                            <Stack>
                                <Accordion elevation={0} sx={{boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", p: {xs: 0, md: 2}}}>
                                    <AccordionSummary
                                        expandIcon={
                                        <IconButton sx={{backgroundColor: "text.white", boxShadow: "0px 6px 16px rgba(8, 15, 52, 0.08)"}}>
                                            <ExpandMore />
                                        </IconButton>}
                                    >
                                    <Typography variant='body1' sx={{fontWeight: "bold"}}>{t("toKnowTheSecurityRisks")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{wordBreak: "break-word"}}>
                                        <Typography lineHeight={1.4} variant='h5' color="text.main">
                                           {t("of the country of destination, find out about the Travel advice section of the Ministry of Foreign Affairs website by consulting the sheet for the country concerned")}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>
                            <Stack>
                                <Accordion elevation={0} sx={{boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", p: {xs: 0, md: 2}}}>
                                    <AccordionSummary
                                        expandIcon={
                                        <IconButton sx={{backgroundColor: "text.white", boxShadow: "0px 6px 16px rgba(8, 15, 52, 0.08)"}}>
                                            <ExpandMore />
                                        </IconButton>}
                                    >
                                    <Typography variant='body1' sx={{fontWeight: "bold"}}>{t("travelAdvice")} </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{wordBreak: "break-word"}}>
                                        <Typography lineHeight={1.4} variant='h5' color="text.main">
                                            {t("TheMinistryOfForeignAndEuropeanAffairs")}
                                            <br />
                                            {t("beforeLeavingWeAdviseYouToMakeTheNecessaryInstitutionalContacts")}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>
                        </Stack>
                    </GrayCard>
                </Stack>
            </Stack>
        </WhiteCard>
    </Stack>
  )
}

export default FrenchNationals