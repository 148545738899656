import React from 'react'
import { Box, Card, CardContent, CardMedia, Stack, Typography } from '@mui/material'
import { GoLocation } from "react-icons/go"
import { AiOutlineEuro } from "react-icons/ai"
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { CiPlane } from 'react-icons/ci'
import { usePromotionContextConsumer } from '../../Lib/Contexts/PromotionApiContext'



function BookNextTrip() {

    const [ promotionsList ] = usePromotionContextConsumer();
    const { t } = useTranslation();

    const container = {
        hidden: { opacity: 1 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.5,
                staggerChildren: 0.1
            }
        },
            
    }
        
    const item = {
        hidden: { x: -20, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1
        },
    
    }
  return (
    <Box sx={{
        display: "flex", 
        flexDirection: {xs: "column", sm: "row"},
        justifyContent: "space-evenly",
        alignItems: "center",
        gap: 5,
        px: {xs: 2, md: 15}, 
        py: 5, 
        mt: {xs: -5, md: 0}
    }}>
        <Stack spacing={4} maxWidth={{xs: "100%", sm: "50%"}}>
            <Stack>
                <Typography
                    component={motion.h3} 
                    initial={{
                        opacity: 0,
                        y: 50
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: {
                        delay: 0.1,
                        damping: 2
                        }
                    }}
                 sx={{fontWeight: "bold"}} 
                 variant='body2'
                >{t("easyAndFast")}</Typography>
                <Typography
                    component={motion.h3} 
                    initial={{
                        opacity: 0,
                        y: 50
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: {
                        delay: 0.1,
                        damping: 2
                        }
                    }} 
                    sx={{fontWeight: "bold", py: 2}} 
                    variant='h3' 
                    color="primary.main"
                >{t("bookYourNextTrip")}</Typography>
            </Stack>
            <Stack
                spacing={4}
                component={motion.div}
                variants={container}
                initial="hidden"
                whileInView="visible"
            >
                <Stack 
                    direction="row" 
                    alignItems="center" 
                    spacing={3}
                    component={motion.div}
                    variants={item}
                >
                    <Box sx={{
                        bgcolor: "green",
                        height: 50, 
                        width: 50, 
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        borderRadius: 3
                    }}>
                        <GoLocation style={{ color: "white", fontSize: 25}}/>
                    </Box>
                    <Box>
                        <Typography sx={{fontWeight: "bold"}} variant='body2'>{t("chooseDestination")}</Typography>
                        <Typography sx={{lineHeight: "25px"}} variant='body2'>
                            {t("exploreDecideAndEmbark")}
                        </Typography>
                    </Box>
                </Stack>
                <Stack 
                    direction="row" 
                    alignItems="center" 
                    spacing={3}
                    component={motion.div}
                    variants={item}
                >
                    <Box sx={{
                        bgcolor: "red",
                        height: 50, 
                        width: 50, 
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        borderRadius: 3
                    }}>
                        <AiOutlineEuro style={{ color: "white", fontSize: 25}}/>
                    </Box>
                    <Box>
                        <Typography sx={{fontWeight: "bold"}} variant='body2'>{t("makePayment")}</Typography>
                        <Typography sx={{lineHeight: "25px"}} variant='body2'>
                            {t("secureTransactions")}
                        </Typography>
                    </Box>
                </Stack>
                <Stack 
                    direction="row" 
                    alignItems="center" 
                    spacing={3}
                    component={motion.div}
                    variants={item}
                >
                    <Box sx={{
                        bgcolor: "primary.main",
                        height: 50, 
                        width: 50, 
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        borderRadius: 3
                    }}>
                        <CiPlane style={{ color: "white", fontSize: 25}}/>
                    </Box>
                    <Box>
                        <Typography sx={{fontWeight: "bold"}} variant='body2'>{t("reachAirportOnSelectedDate")}</Typography>
                        <Typography sx={{lineHeight: "25px"}} variant='body2'>
                            {t("smoothDepartures")}
                        </Typography>
                    </Box>
                </Stack>
            </Stack>
        </Stack>
        <Box position="relative">
                <Card 
                    sx={{p: 1, mt: {xs: 5, md: 3}, cursor: "pointer", width: "400px" }}
                    onClick={() => window.open("https://www.facebook.com/mithra.vijay.94")} 
                >
                    <CardMedia
                        component="img"
                        image={promotionsList[0]?.attachment?.src}
                        alt="promotion img"
                        sx={{
                            width: "100%", height: "100%",
                        }}
                    />
                </Card>
            {/* <BookYourNextTripCard></BookYourNextTripCard> */}
            {/* <BookYourNextTripCard></BookYourNextTripCard>
            <BookYourNextTripCard></BookYourNextTripCard>
            <BookYourNextTripCard></BookYourNextTripCard>
            <BookYourNextTripCard></BookYourNextTripCard> */}
        </Box>
    </Box>
  )
}

export default BookNextTrip