import React, { Suspense, useEffect } from "react";
import { IntlContextProvider } from "./Lib/Contexts/IntlContextProvider";
import { LanguageContextProvider } from "./Lib/Contexts/LanguageContext";
import { LoaderContextProvider } from "./Lib/Contexts/LoaderContext";
import { SnackbarContextProvider } from "./Lib/Contexts/SnackbarContext";
import AppRoutes from "./Routes/AppRoutes";
import { BookingObjContextProvider } from "./Lib/Contexts/BookingObjContext";
import { useLocation } from "react-router-dom";
import { PackageListContextProvider } from "./Lib/Contexts/PackageListContext";
import { PromotionContextProvider } from "./Lib/Contexts/PromotionApiContext";



function App() {

  function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }

  return (
    <Suspense fallback={null}>
      <LanguageContextProvider>
          <IntlContextProvider>
            <LoaderContextProvider>
              <SnackbarContextProvider>
                <PackageListContextProvider>
                  <BookingObjContextProvider>
                    <PromotionContextProvider>
                      <ScrollToTop/>
                      <AppRoutes/>
                    </PromotionContextProvider>
                  </BookingObjContextProvider>
                </PackageListContextProvider>
              </SnackbarContextProvider>
            </LoaderContextProvider>
          </IntlContextProvider>
      </LanguageContextProvider>
    </Suspense>
  );
}

export default App;
