// functional and config imports
import React, { useCallback, useEffect, useState } from 'react';
import { capitalize } from '../../Lib/utils/helperFunctions';
import useLoader from '../../Lib/CustomHooks/useLoader';
import { motion } from "framer-motion";

// ui imports
import { Autocomplete, Box, Checkbox, ClickAwayListener, FormControlLabel, FormGroup, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Stack, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { BlueButton, BlueButtonDashed } from '../../Lib/MuiThemes/MuiComponents';
import {FiSend} from "react-icons/fi";
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSnackBar from '../../Lib/CustomHooks/useSnackBar';
import { AddCircleOutline, Delete, RemoveCircleOutline, Replay } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import MultiCityInputs from './MultiCityInputs';
import useLanguageConsumer from '../../Lib/CustomHooks/useLanguageConsumer';
import airports from "../../Lib/utils/airport.json";
import "../FightSearch/styles/search_box.css";
import { specialFaresAnimation } from '../../Assests/assets';
import Lottie from 'react-lottie-player';
import EthnicFaresModal from './EthnicFaresModal';
import { nanoid } from '@reduxjs/toolkit';

import * as locales from '@mui/x-date-pickers/locales';
import { useDispatch, useSelector } from 'react-redux';
import { clearState, setSearchState, setMulticitySearchState, removeMultiCityObject, insertNewMulticityObject, increasePassengerCount, decreasePassengerCount } from '../../Lib/Redux/SearchSlice';

const checkboxStyle = {
    color: "primary.main",
    "&:hover": {
        backgroundColor: "transparent",
    },
    "&.Mui-checked": {
        color: "primary.main",
    },
    "&.Mui-disabled": {
        backgroundColor: "veryLightBlue.main"
    }
};

const toggleStyle = {
    color: "primary.main",
    "&:hover": {
        backgroundColor: "transparent",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: "primary.main"
    },
    "&.Mui-disabled": {
        backgroundColor: "veryLightBlue.main"
    }
}

const inputStyle = {
    input: {
        color: 'black',
    },
    '& label': {
        color: "black",
    },
    '& label.Mui-focused': {
        color: "black",
    },
    '& .MuiFormLabel-root.Mui-disabled': {
        color: "black"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'black',
        },
        '&:hover fieldset': {
          borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'black',
        },
    },
}

function Search(props) {

    const dispatch = useDispatch();
    const { searchData: data, multicitySearchData } = useSelector(data => data.persistedReducer.searchKeys) 

    

  /************************** States and Variables ******************************/  
  
  const navigate = useNavigate()
  const [originKey, setOriginKey] = useState({searchKey: "", options: []})
  const [destinationKey, setDestinationKey] = useState({searchKey: "", options: []})

  const [ isLoading, startLoading, restLoading ] = useLoader();
  const { t, i18n } = useTranslation();
  const { showSnackBar } = useSnackBar();
  const { country } = useLanguageConsumer();
  
  const [ searchParams ] = useSearchParams();
  const [ openEthnicFaresModal, setOpenEthnicFaresModal ] = useState(false);
  const [ triggerFlightSearchThroughEthnicFaresModal, setTriggerFlightSearchThroughEthnicFaresModal ] = useState(false);
  

  // popper
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  /**************API call : Flight suggestion for origin and destination****************/

//   useEffect(() => {   
//     if(suggestRef.current && originKey.searchKey !== ""){
//         (async () => {
//             try{
//                 startLoading();
//                 // const verifyId = await auth.currentUser.getIdToken();
//                 const fetch = await Axios({
//                     url: `airports?keyword=${originKey.searchKey}`,
//                     method: "get",
//                     // cancelToken: controller.token
//                 });

//                 if(fetch.status === 200){
//                     setOriginKey(prev => ({...prev, options: fetch.data}))
//                 }
//                 // console.log(fetch);
//             }catch(err){
//                 console.error(err)
//             }finally{
//                 restLoading();
//             }
//         })()
//         suggestRef.current = false;
        
//     }

//     return () => {
//         suggestRef.current = true;
//     }
//   },[originKey.searchKey]);

//   useEffect(() => {
//     if(suggestRef.current && destinationKey.searchKey !== ""){
//         (async () => {
//             try{
//                 startLoading();
//                 // const verifyId = await auth.currentUser.getIdToken();
//                 const fetch = await Axios({
//                     url: `/airports?keyword=${destinationKey.searchKey}`,
//                     method: "get",
//                     // cancelToken: controller.token
//                 });
//                 if(fetch.status === 200){
//                     setDestinationKey(prev => ({...prev, options: fetch.data}))
//                 }
//             }catch(err){
//                 console.error(err)
//             }finally{
//                 restLoading();
//             }
//         })()
//         suggestRef.current = false;
//     }

//     return () => {
//         suggestRef.current = true;
//     }
//   },[destinationKey.searchKey]);

/***************************API call : Search Flight ******************************/

  const validations = () => {

    let isDataValidated = true;

    if(data.origin.IATA === ""){
        isDataValidated = false;
        showSnackBar("error", t("Please enter your place of origin in From field"))
    }

    if(data.destination.IATA === ""){
        isDataValidated = false;
        showSnackBar("error", t("Please enter your place of destination in To field"))
    }

    if(!data.departureDate){
        isDataValidated = false;
        showSnackBar("error", t("Please enter departure date"))
    }

    if(data.trip === "twoway" && !data.returnDate){
        isDataValidated = false;
        showSnackBar("error", t("Please enter return date"))
    }

    return  isDataValidated;
  }

  const triggerSearch = useCallback(async () => {

    if(data.isMultiCity){
        let multiCityReducedData = multicitySearchData.reduce((acc, curr) => {
            if(!!curr.origin.IATA){
                acc.origin = [...acc.origin, curr.origin.IATA]
            }

            if(!!curr.origin.name){
                acc.originName = [...acc.originName, curr.origin.name]
            }

            if(!!curr.destination.IATA){
                acc.destination = [...acc.destination, curr.destination.IATA]
            }

            if(!!curr.destination.name){
                acc.destinationName = [...acc.destinationName, curr.destination.name]
            }

            if(!!curr.departureDate){
                acc.departureDate = [...acc.departureDate, curr.departureDate]
            }
            return acc;
        }, {
            origin: [data.origin.IATA],
            originName: [data.origin.name],
            destination: [data.destination.IATA],
            destinationName: [data.destination.name],
            departureDate: [data.departureDate]
        });

        // console.log(multiCityReducedData);
        searchParams.set("tripType", data.trip);
        searchParams.set("isCombinable", Number(data.isCombinable));
        searchParams.set("isMultiCity", Number(data.isMultiCity));
        searchParams.set("origin", multiCityReducedData.origin);
        searchParams.set("originName", multiCityReducedData.originName);
        searchParams.set("destination", multiCityReducedData.destination);
        searchParams.set("destinationName", multiCityReducedData.destinationName);
        searchParams.set("departureDate", multiCityReducedData.departureDate);
        searchParams.set("returnDate", data.returnDate);
        searchParams.set("adults", data.adultCount);
        searchParams.set("children", data.childrenCount);
        searchParams.set("infants", data.infantsCount);
        searchParams.set("travelClass", data.class);
        searchParams.set("currencyCode", country.currency);
        searchParams.set("corporateCode", data.corporateFare);
        searchParams.set("searchId", nanoid());
    }else{
        searchParams.set("tripType", data.trip);
        searchParams.set("isCombinable", Number(data.isCombinable));
        searchParams.set("isMultiCity", Number(data.isMultiCity));
        searchParams.set("origin", data.origin.IATA);
        searchParams.set("originName", data.origin.name);
        searchParams.set("destination", data.destination.IATA);
        searchParams.set("destinationName", data.destination.name);
        searchParams.set("departureDate", data.departureDate);
        searchParams.set("returnDate", data.returnDate);
        searchParams.set("adults", data.adultCount);
        searchParams.set("children", data.childrenCount);
        searchParams.set("infants", data.infantsCount);
        searchParams.set("travelClass", data.class);
        searchParams.set("currencyCode", country.currency);
        searchParams.set("corporateCode", data.corporateFare);
        searchParams.set("searchId", nanoid());
    }
    
    return navigate(`/flights?${searchParams}`)
  }, [ data.adultCount, data.childrenCount, data.infantsCount, country.currency, multicitySearchData, navigate, data.class, data.corporateFare, data.departureDate, data.destination, data.destinationName, data.isCombinable, data.isMultiCity, data.origin, data.originName, data.returnDate, data.trip, searchParams ])

  const initiateSearch = () => {
    if(validations()){
        if(data.corporateFare){
            triggerSearch()
        }else{
            setOpenEthnicFaresModal(true)
        }
    }
  }

  useEffect(() => {
    if(triggerFlightSearchThroughEthnicFaresModal){
        triggerSearch();
        setTriggerFlightSearchThroughEthnicFaresModal(false)
    }
  }, [ triggerFlightSearchThroughEthnicFaresModal ])

  return (
    <>
    <Box sx={{
        height: "auto",
        width: "auto",
        // backgroundColor: "card.background",
        px: {
            xs: 1,
            sm: props.px
        },
        // py: 5
        py: 1,
        position: "relative", 
        top: props.top, 
        zIndex: 20,
    }}
        component={motion.div}
        initial={{
            opacity: 0
        }}
        animate={{
            opacity: 1,
            transition: {
                delay: 1
            }
        }}
    >
        <Box sx={{
            backgroundColor: "veryLightBlue.main",
            p: 2,
            borderRadius: 2,
        }}>
            <Stack spacing={{xs: 3, md: 0}} direction={{xs: "column", md: "row"}} justifyContent="space-between" alignItems="center">
                <Stack direction="row" alignItems="center" gap={2}>
                    <ToggleButtonGroup
                        size='small'
                        value={data.trip}
                        exclusive
                        onChange={(event, value) => {

                            if(value !== null){
                                dispatch(setSearchState({ type: "trip", value: value }))
                                dispatch(setSearchState({ type: "isCombinable", value: false }))
                            }

                            if(value === "multi"){
                                dispatch(setSearchState({ type: "isMultiCity", value: true }))
                            }else{
                                dispatch(setSearchState({ type: "isMultiCity", value: false }))
                            }
                        }}
                    >
                        <ToggleButton sx={toggleStyle} value="twoway">{t("roundTrip")}</ToggleButton>
                        <ToggleButton sx={toggleStyle} value="oneway">{t("oneWay")}</ToggleButton>
                        <ToggleButton sx={toggleStyle} value="multi">{t("multicity")}</ToggleButton>
                    </ToggleButtonGroup>
                    <IconButton
                        sx={{
                            color: "primary.main",
                            backgroundColor: "transparent",
                            "&:hover": {
                                backgroundColor: "white",
                            },
                        }}
                        onClick={() => dispatch(clearState())}
                    >
                        <Replay/>
                    </IconButton>
                </Stack>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <label className="corporate-fare-switch">
                        <input 
                            type="checkbox"
                            name="corporateFares"
                            checked={data.corporateFare}
                            onChange={() => dispatch(setSearchState({ type: "corporateFare", value: !data.corporateFare }))}
                        />
                        <span className="corporate-fare-slider"></span>
                    </label>
                    <Typography fontWeight="bold" variant='body1' color={data.corporateFare ? "error" : "primary.main"}>ERRANCES { t("specialEthnicFares") }</Typography>
                    <Box sx={{position: "relative" }}>
                        {/* <img style={{ height: "60px", width: "60px", objectFit: "cover", position: "absolute", left: "20%" }} src={offerLogo} alt="offer-logo" /> */}
                        {/* <button className='premium-glow'>
                            {t("offer")}
                        </button> */}
                        <Lottie 
                            style={{
                                backgroundColor: "transparent",
                                margin: "0 auto",
                                height: 70,
                                width: 70,
                                marginTop: "-35px"
                            }}
                            loop={true}
                            play={true}
                            animationData={specialFaresAnimation}
                            rendererSettings= {{
                                preserveAspectRatio: "xMidYMid slice"
                            }}
                        />
                    </Box>
                </Box>
            </Stack>
            {data.trip === "twoway" && <Stack>
                <FormGroup sx={{ mx: { xs: "auto", md: 0 } }}>
                    <FormControlLabel sx={{ color: "primary.main" }}  control={<Checkbox sx={checkboxStyle} checked={data.isCombinable} onChange={() => dispatch(setSearchState({ type: "isCombinable", value: !data.isCombinable }))} />} label={<Typography>{t("chooseYourInboundAndOutboundFlightsSeparately")}</Typography>} />
                </FormGroup>
            </Stack>}
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                mt: 2,
                gap: 3,
            }}>
                <Stack direction={{xs: "column", md: "row"}} spacing={3}>
                    <Autocomplete
                        fullWidth
                        loading={isLoading}
                        onChange={(event, newVal) => {
                            if(newVal){
                                // setSearchData(prev => ({...prev, origin: newVal.IATA, originName: newVal.name}))
                                dispatch(setSearchState({ type: "origin", value: newVal}))
                            }
                        }}
                        value={data.origin}
                        size="medium"
                        options={airports}
                        defaultValue={{"name": "Charles de Gaulle Intl ", "city": "Paris", "country": "France", "IATA": "CDG" }}
                        getOptionLabel={(option) => option && `${capitalize(option.name)}(${option.IATA}), ${option.city}`}
                        // isOptionEqualToValue={(option, value) =>  console.log(option.name, " ---- ", value.name, option.name === value.name)}
                        isOptionEqualToValue={(option, value) => true}
                        // isOptionEqualToValue={(option, value) => option.IATA === value.IATA}
                        noOptionsText="No flights available"
                        blurOnSelect
                        renderInput={(params) => (
                            <TextField
                                // error={!searchData.origin}
                                // helperText={!searchData.origin && "Please choose your origin"}
                                {...params}
                                sx={inputStyle}
                                label={t("from")}
                                value={originKey.searchKey}
                                onChange={(e) => setOriginKey(prev => ({...prev, searchKey: e.target.value}))}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off',
                                }}
                            />
                        )}
                    />
                    
                    <Autocomplete
                        fullWidth
                        loading={isLoading}
                        onChange={(event, newVal) => {
                            if(newVal){
                                // setSearchData(prev => ({...prev, destination: newVal.IATA, destinationName: newVal.name}))
                                dispatch(setSearchState({ type: "destination", value: newVal}))
                            }
                        }}
                        value={data.destination}
                        size="medium"
                        options={airports}
                        getOptionLabel={(option) => option && `${capitalize(option.name)}(${option.IATA}), ${option.city}`}
                        // isOptionEqualToValue={(option, value) =>  console.log(option.name, " ---- ", value.name, option.name === value.name)}
                        isOptionEqualToValue={(option, value) => true}
                        noOptionsText="No flights available"
                        blurOnSelect
                        renderInput={(params) => (
                            <TextField
                                // error={!searchData.destination}
                                // helperText={!searchData.destination && "Please choose destination"}
                                // focused={searchData.origin}
                                sx={inputStyle}
                                autoComplete='off'
                                {...params}
                                value={destinationKey.searchKey}
                                onChange={(e) => setDestinationKey(prev => ({...prev, searchKey: e.target.value}))}
                                label={t("to")}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off',
                                }}
                            />
                        )}
                    />

                    <LocalizationProvider 
                        dateAdapter={AdapterDayjs}
                        adapterLocale={i18n?.language}
                    >
                        <MobileDatePicker
                            disablePast
                            label={t("departureDate")}
                            format='DD-MM-YYYY'
                            fixedWeekNumber={0}
                            value={data.departureDate ? dayjs(data.departureDate) : null}
                            onChange={(newValue) => {
                                if(newValue !== null){
                                    dispatch(setSearchState({ type: "departureDate", value: dayjs(newValue["$d"]).format("YYYY-MM-DD")}))
                                    dispatch(setMulticitySearchState({ type: "minDepartureDate", value: dayjs(newValue["$d"]).format("YYYY-MM-DD"), index: 0}))
                                }
                            }}
                            slots={{
                                textField: (params) => <TextField 
                                    size="medium" fullWidth {...params}
                                    sx={inputStyle} 
                                />
                            }}
                            
                        />
                    </LocalizationProvider>
                    {data.trip === "twoway" && 
                        <LocalizationProvider 
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n?.language}
                        >
                            <MobileDatePicker
                                disablePast
                                format='DD-MM-YYYY'
                                minDate={dayjs(data.departureDate)}
                                label={t("returnDate")}
                                value={data.returnDate ? dayjs(data.returnDate) : null}
                                onChange={(newValue) => {
                                    if(newValue !== null){
                                        dispatch(setSearchState({ type: "returnDate", value: dayjs(newValue["$d"]).format("YYYY-MM-DD")}))
                                    }
                                }}
                                slots={{
                                    textField: (params) => <TextField 
                                        size="medium" fullWidth {...params} 
                                        sx={inputStyle}
                                    />
                                }}
                            />
                        </LocalizationProvider>
                    }

                    <TextField
                        sx={inputStyle}
                        ref={anchorRef}
                        fullWidth
                        size='medium'
                        label={t("selectNoOfPassengers")}
                        onClick={handleToggle}
                        value={`${data.adultCount + data.childrenCount + data.infantsCount} ${t("passengers")}`}
                        // onChange={(e) => {
                        //     return e.target.value > 0 && e.target.value <= 9 ? setSearchData(prev => ({...prev, infants: e.target.value})) :  setSearchData(prev => ({...prev, infants: "0"}))
                        // }}
                    />
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                        sx={{
                            zIndex: 10
                        }}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                            >
                            <Paper sx={{p: 2}}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <Stack direction="row" py={1} gap={2} justifyContent="space-between" alignItems="center">
                                            <Typography>{t("adult")}</Typography>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <IconButton onClick={() => dispatch(decreasePassengerCount("adultCount"))}>
                                                <RemoveCircleOutline/>
                                                </IconButton>
                                                <Typography>{data.adultCount}</Typography>
                                                <IconButton onClick={() => dispatch(increasePassengerCount("adultCount"))}>
                                                    <AddCircleOutline></AddCircleOutline>
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" py={1} gap={2} justifyContent="space-between" alignItems="center">
                                            <Typography>{t("children")}</Typography>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <IconButton onClick={() => dispatch(decreasePassengerCount("childrenCount"))}>
                                                <RemoveCircleOutline/>
                                                </IconButton>
                                                <Typography>{data.childrenCount}</Typography>
                                                <IconButton onClick={() => dispatch(increasePassengerCount("childrenCount"))}>
                                                    <AddCircleOutline></AddCircleOutline>
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" py={1} gap={2} justifyContent="space-between" alignItems="center">
                                            <Typography>{t("infants")}</Typography>
                                            <Stack direction="row" spacing={1} alignItems='center'>
                                                <IconButton onClick={() => dispatch(decreasePassengerCount("infantsCount"))}>
                                                <RemoveCircleOutline/>
                                                </IconButton>
                                                <Typography>{data.infantsCount}</Typography>
                                                <IconButton onClick={() => dispatch(increasePassengerCount("infantsCount"))}>
                                                    <AddCircleOutline></AddCircleOutline>
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                            </Grow>
                        )}
                        </Popper>

                    <TextField
                        fullWidth
                        sx={inputStyle}
                        size='medium'
                        select
                        label={t("class")}
                        value={data.class}
                        onChange={(event) => dispatch(setSearchState({ type: "class", value: event.target.value}))}

                    >
                        <MenuItem value="ECONOMY">{t("economy")}</MenuItem>
                        <MenuItem value="BUSINESS">{t("business")}</MenuItem>
                        <MenuItem value="FIRSTCLASS">{t("firstClass")}</MenuItem>
                    </TextField>
                </Stack>
                {data.trip === "multi" && 
                    <>
                        {multicitySearchData.map((objs, i) => (
                            <MultiCityInputs
                                key={i} 
                                index={i} 
                            />
                        ))}

                        {multicitySearchData.length > 1 && 
                            <Box sx={{m: "0 auto"}} >
                                <Tooltip title="Remove itinerary" placement='top'>
                                    <IconButton sx={{color: 'primary.main'}} onClick={() => dispatch(removeMultiCityObject())}>
                                        <Delete/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        }

                        {multicitySearchData.length <= 4 && 
                            <BlueButtonDashed 
                                sx={{maxWidth: 200, m: "0 auto"}} 
                                variant='outlined'
                                onClick={() => dispatch(insertNewMulticityObject())}
                            >
                                + Add new city
                            </BlueButtonDashed>
                        }
                    </>
                }
                <BlueButton 
                    sx={{maxWidth: 200, m: "0 auto", border: "2px solid white"}} 
                    size='large'  
                    onClick={initiateSearch}
                >
                    <FiSend/>
                    {t("showFlights")}
                </BlueButton>
                <EthnicFaresModal
                    openEthnicFaresModal={openEthnicFaresModal}
                    setOpenEthnicFaresModal={setOpenEthnicFaresModal}
                    triggerSearch={triggerSearch}
                    // setSearchData={setSearchData}
                    setTriggerFlightSearchThroughEthnicFaresModal={setTriggerFlightSearchThroughEthnicFaresModal}
                />
            </Box>
        </Box>
    </Box>
    </>
  )
}

export default Search