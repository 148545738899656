import { Box, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import "./styles/testimonials.css";
import { TestimonialCard } from './fragments/TestimonialCard';
import { useTranslation } from 'react-i18next';

export const Testimonials = () => {

  const { t } = useTranslation();

  const testimonials = [
    {
      name: "Prathima smiley",
      date: "August 2022",
      rating: 4,
      review: `"I share my smooth journey with Errances Voyages. Initially it was tough reaching out to them through phone due to their busyness. But then they apologised and took care of everything A-Z right from ticketing till making the documents needed for my travel. They also have online site payment which made me pay easily from my house. Thumbs up for Errances!!!"`
    },
    {
      name: "Valar bala",
      date: "March 2022",
      rating: 4,
      review: `"Since 2 yrs we buy tickets from Errances. They do good service to clients, respectable and on time. Even if they are busy they call you back and satisfy our demands. Thank you Errances."`
    },
    {
      name: "SOD POD",
      date: "December 2021",
      rating: 5,
      review: `"Good response
      Covid19 time this agency helped me very well.
      They are give good solution and great cooperate.
      Thanks for your service."`
    },
    {
      name: "Darren PETTITT",
      date: "October 2021",
      rating: 4,
      review: `"Just visiting with friends who were booking flights to India.
      Seem very professional and they are recommended."`
    },
    {
      name: "Vijay Sankar",
      date: "July 2022",
      rating: 4,
      review: `"Accueil chaleureux et convivial. Toujours prêt à satisfaire les clients. Ils offrent le meilleur service sur le marché. Leurs prestations et services sont de haute qualité et le personnel est multilingue. Excellente agence que je vous recommande pour vos voyages."`
    },
    {
      name: "mathanolivier TILLACOVINDARADJANE",
      date: "August 2022",
      rating: 5,
      review: `"Accueil chaleureux et convivial. Toujours prêts à satisfaire la clientèle. Ils offrent les meilleurs services. Leurs prestations et leur service sont d'une grande qualité . Excellente agence et je la recommande sans hésitation 😊😊 et merci encore pour tout 😘."`
    },
    {
      name: "Udaya Devaradjou",
      date: "August 2022",
      rating: 5,
      review: `"Je me suis retrouvée dans une situation délicate et ils m’ont rassurée et aidée avec bienveillance et gentillesse.
      Je recommande mille fois cette agence pour leur professionnalisme et efficacité.
      Encore mille fois merci."`
    },
    {
      name: "Sandya Vairon",
      date: "April 2022",
      rating: 5,
      review: `"Jusqu à présent un sans fautes, personnel professionnel, répondent rapidement aux questions posées, disponible à tous moment. J étais au début septique et aujourd hui je recommande sans hésiter cette agence. J ai fait une demande de Visa permanent qui est en cours de traitement, une demande de visa touristique et ai acheté mon billet dans cette agence. Une très bonne prise en charge. Merci !"`
    },
    {
      name: "Monique MORAULT",
      date: "Jan 2023",
      rating: 5,
      review: `"Très bonne expérience.
      A l'écoute de nos besoins.
      Je recommande."`
    },
    {
      name: "sony bravia",
      date: "Mar 2022",
      rating: 5,
      review: `"Je connais cet agence depuis très longtemps et le personnel est très agréable avec un très bon service !
      J’apprécie énormément, toujours à notre besoin , avec beaucoup de professionnalisme, un travail sérieux.
      Merci !"`
    },
    {
      name: "Remi Vengadessin",
      date: "October 2021",
      rating: 4,
      review: `"Professionnalisme, Qualité service,  Réactivité.
      Service très satisfaisant et professionnel. Je vous le recommande très fortement.  Ils nous aider ils répondent à nos messages ou appels si nous voulons des informations  bon courage"`
    },
    {
      name: "ivan dass",
      date: "April 2021",
      rating: 5,
      review: `"Bon accueil et ils sont très efficaces. Personnel très  agréable. Toujours prêts à satisfaire la clientèle. Ils offrent les meilleurs services. Leurs prestations et leur service sont d'une grande qualité . Excellente agence que je vous recommande volontiers pour les voyages en Inde."`
    },
    {
      name: "mettrine martin",
      date: "July 2022",
      rating: 5,
      review: `"Meilleures qualités prix de toutes les agences que j'ai pu connaître au alentour de celui ci
      Et surtout des agents très compétant et avenant
      Merci beaucoup."`
    },
    {
      name: "Kevin",
      date: "July 2022",
      rating: 5,
      review: `"Bonne accueil des clients, le monsieur nous a bien accueillit. Une bonne communication des services et des prix proposés. 👍👍"`
    },
    {
      name: "Patrick Sabapathi",
      date: "August 2022",
      rating: 5,
      review: `"Excellent accueil , je recommande très vivement l'agence de voyage Errances pour la qualité de son écoute et sa réactivité"`
    },
    {
      name: "Marie C",
      date: "May 2021",
      rating: 5,
      review: `"Je suis absolument surprise par la simplicité, l'écoute, le professionnalisme de Mitra qui a su répondre à notre demande avec rapidité. Un grand merci ! Je recommande cette agence."`
    },
    {
      name: "Pyrosdeen Malim",
      date: "June 2021",
      rating: 5,
      review: `"Très bon service surtout Mme Mithra qui est toujours à notre écoute;
      Si je peux me permettre de dire que C’est la 1er agence (indien) qui a un service remarquable et très rapide, un service toujours accueillant
      Bonne continuation- :) Fairose"`
    },
    {
      name: "Shakthidevi Shakthidevi",
      date: "March 2022",
      rating: 5,
      review: `"Mme Mithra est toujour à notre écoute je suis très impressionné par cet ERRANCE VOYAGE ils demandent toujours quelles sont les questions et comment peuvent-ils nous aider ils répondent à nos messages ou appels si nous voulons des informations bon courage."`
    },
    {
      name: "JUSTUS RUBAN",
      date: "April 2021",
      rating: null,
      review: `"One of the Best and Trusted agencies in Paris. Deserve more than 5 stars. Professional / Follow up / Customer Satisfaction.
      I had my first flight got cancelled, immediately I got the call to inform and make an alternative booking. In this pandemic Errances doing the best to help us to meet our families.
      Highly recommend it. A big thanks to Mithra Madam."`
    },
    {
      name: "JAGANATHAN RAJA",
      date: "February 2021",
      rating: null,
      review: `"Flexible, Friendly, Efficient '
      A first rate travel company who epitomise the personal touch, are very keen to be flexible and have handled Covid-related changes and cancellations impeccably. Bravo! Highly recommended. Sincere thanks to Mme. MITHRA"`
    },
    {
      name: "VALLABAN BASCARANE",
      date: "April 2021",
      rating: null,
      review: `"It's an very good experience.. very professional and good follow ups.. Am very comfortable with their services... thanks and best wishes"`
    },
    
  ]
  return (
    <Box>
      <Stack textAlign="center" pt={5}>
          <Typography 
            sx={{fontWeight: "bold"}} 
            variant='body2'
            component={motion.h3} 
            initial={{
                opacity: 0,
                y: 50
            }}
            whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.1,
                  damping: 2
                }
            }}
          >
            {t("testimonials")}
          </Typography>
          <Typography 
            sx={{py: 2, fontWeight: "bold"}} 
            variant='h3' 
            color="primary.main"
            component={motion.h3} 
            initial={{
                opacity: 0,
                y: 50
            }}
            whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.1,
                  damping: 2
                }
            }}
          >
            {t("whatPeopleSayAboutUs")}
          </Typography>
      </Stack>
      <Stack className='testimonials-carousel'>
        <Splide options={{ 
          rewind: true, 
          type: 'loop',
          autoplay     : true,
          pauseOnHover : false,
          resetProgress: false,
          pagination: false,
          // drag: 'free',
          focus: 'center',
          gap: "1rem",
          perPage: 3,
          breakpoints: {
            600 : { 
              perPage: 1,

            },
          },
          autoScroll: {
            speed: 1,

          },
        }} 
        // extensions={{ AutoScroll }}
        
        >
          {testimonials.map((data, i) => (
            <SplideSlide style={{height: "auto"}} key={i}>
              <TestimonialCard name={data.name} date={data.date} review={data.review} rating={data.rating}/>
            </SplideSlide>
          ))}
        </Splide>
      </Stack>
    </Box>
  )
}
