import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import React from 'react'
import Lottie from 'react-lottie-player';
import { ethnicFaresAnimation } from '../../Assests/assets';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchState } from '../../Lib/Redux/SearchSlice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs:370, md:600},
    bgcolor: '#FFD600',
    border: '1px solid gray',
    borderRadius: 2,
    boxShadow: 10,
    p: 2,
};


function EthnicFaresModal({ openEthnicFaresModal, setOpenEthnicFaresModal, setTriggerFlightSearchThroughEthnicFaresModal }) {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { searchData: data } = useSelector(data => data.persistedReducer.searchKeys) 

  const initiateSearchFlight = (showEthnicFares) => {
    dispatch(setSearchState({ type: "corporateFare", value: showEthnicFares }))
    setTriggerFlightSearchThroughEthnicFaresModal(true);
    setOpenEthnicFaresModal(false);
  }

  return (
    <Modal
        open={openEthnicFaresModal}
        onClose={() => setOpenEthnicFaresModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Lottie 
                style={{
                    height: 400,
                    width: 400,
                    margin: "0 auto"
                }}
                loop={true}
                play={true}
                animationData={ethnicFaresAnimation}
                rendererSettings= {{
                    preserveAspectRatio: "xMidYMid slice"
                }}
            />
            <Typography textAlign="center" variant='h3' color="white">{t("doYouWannaExploreErrancesVoyagesEthnicOffers")}</Typography>
            <Stack justifyContent="center" direction="row" spacing={5} my={5}>
                <Button onClick={() => initiateSearchFlight(false)} variant='contained' color="warning">{t("showOnlyRegularFare")}</Button>
                <Button onClick={() => initiateSearchFlight(true)} variant='contained' color="primary">{t("yesShowEthnicFare")}</Button>
            </Stack>
        </Box>
    </Modal>
  )
}

export default EthnicFaresModal