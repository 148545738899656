import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Avatar, Box, Button, Card, Divider, Grid, List, ListItem, ListSubheader, Stack, Typography } from '@mui/material'
import { RiPlaneFill } from 'react-icons/ri'
import { AnchorText, BlueButton, GlobeLoaderWithTransparentBackdrop } from '../../Lib/MuiThemes/MuiComponents'
import TicketDetailsCard from './TicketDetailsFragments/TicketDetailsCard'
import { useLocation, useNavigate } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../../Lib/Firebase/firebase.config'
import { BASE_URL } from '../../Lib/Axios/AxiosConfig'
import { useSelector } from 'react-redux'
import CancelTicketModal from './fragments/CancelTicketModal'
import useSnackBar from '../../Lib/CustomHooks/useSnackBar'
import useLoader from '../../Lib/CustomHooks/useLoader'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import Lottie from 'react-lottie-player'
import { flightLoader } from '../../Assests/assets'

function TicketDetails() {

    const { state } = useLocation();
    const { t } = useTranslation();
    const { auth: userAuth } = useSelector(data => data.persistedReducer);
    const { showSnackBar } = useSnackBar();
    const ticketInfos = useMemo(() => state, [state]);
    // const [ cancelButtonLoader, startCancelButtonLoader, restCancelButtonLoader ] = useLoader();
    const [ fetchedTickedDetails, setFetchedTicketDetails ] = useState({
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {},
        error: null
    });

    useEffect(() => {

        const controller = axios.CancelToken.source();
        (async () => {
            setFetchedTicketDetails(prev => ({...prev, isLoading: true, isError: false, isSuccess: false, data: {}}));
            onAuthStateChanged(auth, async (user) => {
                if(user){
                    try{
                        const response = await axios({
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: `${BASE_URL}/getFlightOrder`,
                            cancelToken: controller.token,
                            headers: { 
                                'Content-Type': 'application/json',
                                idToken: user.accessToken
                            },
                            data : {
                                orderID: ticketInfos.orderId,
                                docId: ticketInfos.docId,
                                userId: ticketInfos.userId
                            },
                        })
            
                        if(response.status === 200){
                            setFetchedTicketDetails(prev => ({...prev, isLoading: false, isError: false, isSuccess: true, data: response.data}));
                        }
            
                    }catch(error){
                        if(!axios.isCancel(error)){
                            console.log(error.response.data.errors[0]);
                            setFetchedTicketDetails(prev => ({...prev, isLoading: false, isError: true, isSuccess: false, error: error?.response?.data?.errors[0]?.detail}));
                            // showSnackBar("error", t(error.message));
                            if(error?.response?.data?.errors[0]?.code === 1797){
                                showSnackBar("error", t(error?.response?.data?.errors[0]?.detail));
                            }else{
                                showSnackBar("error", t(error.message));
                            }
                        }
                    }
                }
            })
        })()

        return () => controller.cancel();
    }, [ ticketInfos.orderId ]);


    // const cancelTicket = () => {
    //     onAuthStateChanged(auth, async (user) => {
    //         if(user){
    //             try{
    //                 startCancelButtonLoader()
    //                 const response = await axios({
    //                     method: 'post',
    //                     maxBodyLength: Infinity,
    //                     url: 'cancelFlightOrder',
    //                     headers: { 
    //                         'Content-Type': 'application/json',
    //                         idToken: user.accessToken
    //                     },
    //                     data : {
    //                         orderID: ticketInfos.orderId,
    //                         userId: userAuth.userId
    //                     },
    //                     validateStatus: (status) => {
    //                         if(status >= 400){
    //                             restCancelButtonLoader()
    //                             showSnackBar("error", t("Error happened while cancelling the ticket"));
    //                         }
    //                         return status;
    //                     },
    //                 })
        
    //                 if(response.status === 200){
    //                     restCancelButtonLoader();
    //                     showSnackBar("success", t("Your ticket has been cancelled"));
    //                 }
        
    //             }catch(error){
    //                 console.log(error);
    //                 restCancelButtonLoader();
    //                 showSnackBar("error", t("Error happened while cancelling the ticket"));
    //             }
    //         }
    //     })
    // }

    let content;

    if(fetchedTickedDetails.isLoading){
        content = <GlobeLoaderWithTransparentBackdrop/>
    }

    if(fetchedTickedDetails.isError){
        content = <Typography variant='h6' fontWeight="bold" color="error" textAlign="center" mt={20}>{fetchedTickedDetails.error}</Typography>
    }

    if(fetchedTickedDetails.isSuccess){
        content = (
            <Box sx={{
                height: "auto",
                width: "auto",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "common.background",
                px: {
                    xs: 1,
                    md: 20
                }
            }}>
                <Stack sx={{width: "100%"}} spacing={2} py={3}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <Typography variant='h5' color="text.main">Booking Information</Typography>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            // justifyContent: "space-between",
                            gap: 2,
                            alignItems: "center"
                        }}>
                            <AnchorText fontWeight="bold" variant="h3">{fetchedTickedDetails.data.bookingData?.display?.totalFare} {fetchedTickedDetails.data.bookingData?.display?.currency}</AnchorText>
                            {fetchedTickedDetails.data?.bookingData?.status !== "pending" &&
                            <BlueButton onClick={() => window.open(`${BASE_URL}/fetchInvoice?orderId=${encodeURIComponent(ticketInfos.orderId)}`)} sx={{maxWidth: 150}}>Download Ticket</BlueButton>
                        }
                        </Box>
                        
                    </Box>
                </Stack>
                <Stack spacing={3} direction="column">
                    {fetchedTickedDetails.data.display && fetchedTickedDetails.data.display.map((disp, index) => (
                        <Card key={`cardkey-${index}`}>
                            {disp.data.map((data, ind) => (
                                <TicketDetailsCard key={ind} data={data} fetchedTickedDetails={fetchedTickedDetails.data}/>
                            ))}
                        </Card>
                    ))}
                </Stack>
                {/* <Card>
                    {fetchedTickedDetails.data.display && fetchedTickedDetails?.data?.display[0]?.data?.map((data, index) => (
                        <TicketDetailsCard key={index} data={data} fetchedTickedDetails={fetchedTickedDetails.data}/>
                    )) }
                </Card> */}
                <Stack sx={{width: "100%"}} spacing={2} py={3}>
                    <Typography variant='h5' color="text.main">Passenger Information</Typography>  
                </Stack>
                <Stack>
                    <Grid
                        container 
                        // border="1px solid black" 
                        rowSpacing={2} 
                        columnSpacing={{ xs: 1, sm: 2, md: 5 }}
                    >
                        {fetchedTickedDetails.data.display && fetchedTickedDetails.data.data.travelers.map((traveler, index) => (
                            <Grid key={index} item xs={6}>
                                <Card sx={{ p: 3 }}>
                                    <Grid 
                                        container 
                                        // border="1px solid black" 
                                        rowSpacing={2} 
                                        columnSpacing={{ xs: 1, sm: 2, md: 5 }}
                                    >
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <Typography variant='body2'>Name of the traveller</Typography>
                                                <Typography color="text.main" variant='h5'>{traveler.name.firstName} {traveler.name.lastName}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <Typography variant='body2'>Gender</Typography>
                                                <Typography color="text.main" variant='h5'>{traveler.gender ? traveler.gender : "-"}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <Typography variant='body2'>Email</Typography>
                                                <Typography color="text.main" variant='h5'>{traveler?.contact?.emailAddress ? traveler?.contact?.emailAddress : "None"}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <Typography variant='body2'>Contact number</Typography>
                                                <Typography color="text.main" variant='h5'>{traveler?.contact?.phones[0]?.countryCallingCode ? `+${traveler?.contact?.phones[0]?.countryCallingCode} ${traveler?.contact?.phones[0]?.number}` : "None"}</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
        
                <Stack spacing={2} py={3}>
                    <Typography variant='h5' color="text.main">Terms and Conditions</Typography>
                    <Box>
                        <Typography variant='h6' color="text.main">Payments</Typography>
                        <List sx={{ listStyleType: 'disc', pl: 2,
                            '& .MuiListItem-root': {
                                display: 'list-item',
                            }, 
                        }}>
                            <ListItem><Typography color="text.main">If you are purchasing your ticket using a debit or credit card via the Website, we will process these payments via the automated secure common payment gateway which will be subject to fraud screening purposes.</Typography></ListItem>
        
                            <ListItem><Typography color="text.main">If you do not supply the correct card billing address and/or cardholder information, your booking will not be confirmed and the overall cost may increase. We reserve the right to cancel your booking if payment is declined for any reason or if you have supplied incorrect card information. If we become aware of, or is notified of, any fraud or illegal activity associated with the payment for the booking, the booking will be cancelled and you will be liable for all costs and expenses arising from such cancellation, without prejudice to any action that may be taken against us.</Typography></ListItem>
        
                            <ListItem><Typography color="text.main">Errances Voyages may require the card holder to provide additional payment verification upon request by either submitting an online form or visiting the nearest Errances Voyages office, or at the airport at the time of check-in. Errances Voyages reserves the right to deny boarding or to collect a guarantee payment (in cash or from another credit card) if the card originally used for the purchase cannot be presented by the cardholder at check-in or when collecting the tickets, or in the case the original payment has been withheld or disputed by the card issuing bank. Credit card details are held in a secured environment and transferred through an internationally accepted system.</Typography> </ListItem>
                        </List>
                    </Box>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                        <Box>
                            <Typography variant='h6' color="text.main">Contact us</Typography>
                            <Box>
                                <Typography color="text.main">
                                    If you have any questions about our Website or our Terms of Use, please contact:
                                </Typography>
                                <Typography color="text.main">
                                    Errances Voyages,
                                </Typography>
                                <Typography color="text.main">
                                    55, Rue Louis Blanc, 75010-Paris, 
                                </Typography>
                                <Typography color="text.main">
                                    France
                                </Typography>
                                <Typography color="text.main">
                                    Further contact details can be found at paris@errancesvoyages.com  
                                </Typography>
                            </Box>
                        </Box>
                        {/* <Box>
                            <CancelTicketModal 
                                cancelTicket={cancelTicket}
                                cancelButtonLoader={cancelButtonLoader} 
                            />
                        </Box> */}
                    </Stack>
                </Stack>
        
            </Box>
        )
    }

  return content;
}

export default TicketDetails