import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'card.background',
    borderRadius: 5,
    boxShadow: 24,
    p: 3,
};

function SeeConditionsTravelInsuranceModal() {

  const [ open, setOpen ] = useState(false);
  const { t } = useTranslation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
        <Typography component="a" color="error" variant='h6' onClick={handleOpen} sx={{ textDecoration: "underline", cursor: "pointer" }} >See conditions</Typography>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5" fontWeight="bold" color="primary.main">
                        {t("ourConditions")}
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <Close/>
                    </IconButton>
                </Stack>
                <Stack py={1}>
                    <Typography variant='h6'>
                        {t("theDetailsRegardingTheInsuranceAndTermsAndConditionsAndPremiumWillBeCommunicatedByErrancesVoyages")} <Typography component="a" variant='h6' href='mailto: paris@errancesvoyages.com'> paris@errancesvoyages.com </Typography>
                        {t("orPhone")} - <Typography component="a" variant='h6' href='tel: 330142097000'>+33 (0) 1 42 09 70 00</Typography>.
                    </Typography>
                </Stack>
            </Box>
        </Modal>
    </div>
  )
}

export default SeeConditionsTravelInsuranceModal