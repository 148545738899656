import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { BlueButton, InputField, WhiteCard } from '../../Lib/MuiThemes/MuiComponents'
import { Autocomplete, Box, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FiSend } from 'react-icons/fi'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../../Lib/Firebase/firebase.config'
import { Axios } from '../../Lib/Axios/AxiosConfig'
import useSnackBar from '../../Lib/CustomHooks/useSnackBar'
import PhoneInput from 'react-phone-input-2';
import "../ContactUs/css/style.css";
import useLanguageConsumer from '../../Lib/CustomHooks/useLanguageConsumer'
import { securePaymentBackground } from '../../Assests/assets'


function PayOnlineForm() {
  const { t } = useTranslation();
  const [ loading, setLoading ] = useState(false);
  const { showSnackBar } = useSnackBar();
  const { country } = useLanguageConsumer();
  const [ postData, setPostData ] = useState({
    amount: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: ""
  });
  const [ validateData, setValidateData ] = useState({
    amount: null,
    firstname: null,
    lastname: null,
    email: null,
    phone: null
  })

  console.log(postData);
  const handleChanges = (e) => {
    let { name, value } = e.target;
    setValidateData(prev => ({...prev, [name]: null}))
    setPostData(prev => ({...prev, [name]: value}))
  }

  function isDataValidated(){
    let hasError = false;

    if(postData.amount === "" || postData.amount.length > 10 || !/[0-9]/.test(postData.amount)){
        hasError = true;
        setValidateData(prev => ({...prev, amount: t("Please enter a valid amount")}))
    }

    if(postData.firstname === "" || postData.firstname.length > 50){
        hasError = true;
        setValidateData(prev => ({...prev, firstname: t("Please enter a valid firstname")}))
    }

    if(postData.lastname === "" || postData.lastname.length > 50){
        hasError = true;
        setValidateData(prev => ({...prev, lastname: t("Please enter a valid lastname")}))
    }

    if(postData.email === "" || postData.email.length > 70 || !/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(postData.email)){
        hasError = true;
        setValidateData(prev => ({...prev, email: t("Please enter a valid email")}))
    }

    if(postData.phone === ""){
        hasError = true;
        setValidateData(prev => ({...prev, phone: t("Please enter a valid phone number")}))
    }

    return hasError;
  }

  async function initiatePayment(){
    if(!isDataValidated()){
        try{
            setLoading(true);
            const response = await Axios({
                method: "get",
                url: `initiatePayment?amount=${postData.amount}&currency=${country.currency}&firstName=${postData.firstname}&lastName=${postData.lastname}&number=${postData.phone}&email=${postData.email}`,
                maxBodyLength: Infinity,
                validateStatus: function (status) {
                    if(status >= 400){
                        showSnackBar("error", t("Error happened while iniating payment, Please try again later"));   
                        setLoading(false);
                        throw new Error("Error");
                    }
                    return status;
                }
            });

            if(response.status === 200){
                setLoading(false);
                window.location.assign(response.data.url);
            }
        }catch(error){
            console.log(error);
            showSnackBar("error", t("Error happened while iniating payment, Please try again later")); 
            setLoading(false);
        }
    }
  }

  return (
    <Box sx={{py: 5}}>
        <Stack
            sx={{
                height: "auto",
                borderRadius: 3,
                // background: `url('${securePaymentBackground}')`,
                // maskImage: "linear-gradient(to right, transparent, black 20%, black 80%)",
                // backgroundSize: "1000px",
                // backgroundPositionX: "600px",
                // backgroundRepeat: "no-repeat",
                backgroundColor: "#F8F8F8",
                boxShadow: "4px 4px 36px 0px #00008033",
                display: "flex",
                flexDirection: "row",
                overflow: "hidden"

            }}
        >
            <Stack width={{xs:"100%",md:"50%"}} py={5} px={{ xs: 2, md: 10 }}>
                <Stack spacing={{xs:0.7,md:1}} direction="column">
                    <Stack textAlign={{ xs: "center", md: "left"}} spacing={5}>
                        <Typography 
                            component={motion.h3} 
                            initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                                transition: {
                                delay: 0.1,
                                damping: 2
                                }
                            }}
                            sx={{ fontWeight: "bold"}} 
                            variant='h2' 
                            color="primary.main"
                            
                        >
                            {t("securePayment")}
                        </Typography>
                        <Typography 
                            component={motion.h3} 
                            initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                                transition: {
                                    delay: 0.1,
                                    damping: 2
                                }
                            }}
                            variant='h5' 
                            color="error"
                            sx={{ position: "relative", bottom: { xs: "25px", md: 0 }}}
                        >
                            {t("guaranteedSecurityAndPeaceOfMindWithEveryPayment")}
                        </Typography>
                    </Stack>
                    <Stack spacing={{xs:0.7,md:1}} width="100%">
                        <Typography fontWeight="bold" variant='body1' color="text.main">{t("amount")} {`( ${t("currencyIn")} ${country.currency} )`}<span style={{color: "red"}}>*</span></Typography>
                        <InputField
                            sx={{
                                boxShadow: "4px 4px 36px 0px #0000801A",
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: "2px solid #00000030",
                                    },
                                    '&:hover fieldset': {
                                        border: "2px solid #00000030",
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: "2px solid #00000030",
                                    },
                                }
                            }}
                            fullWidth
                            size="small"
                            placeholder={`${country.currencySymbol} 0.00`}
                            type='text'
                            name="amount"
                            value={postData.amount}
                            onChange={handleChanges}
                            error={validateData.amount}
                        />
                        <Typography 
                            height={20}
                            variant='subtitle1' 
                            color="error"
                        >
                            {validateData.amount ?? ""}
                        </Typography>
                    </Stack>
                    <Stack spacing={{xs: -2, md: 3}} direction={{xs: "column", md: "row"}} justifyContent="center">
                        <Stack spacing={1} width="100%">
                            <Typography fontWeight="bold" variant='body1' color="text.main">{t("firstName")}<span style={{color: "red"}}>*</span></Typography>

                            <InputField
                                sx={{
                                    boxShadow: "4px 4px 36px 0px #0000801A",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: "2px solid #00000030",
                                        },
                                        '&:hover fieldset': {
                                            border: "2px solid #00000030",
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: "2px solid #00000030",
                                        },
                                    }
                                }}
                                fullWidth
                                size="small"
                                placeholder={t("yourFirstName")}
                                type='text'
                                name='firstname'
                                value={postData.firstname}
                                onChange={handleChanges}
                                error={validateData.firstname}
                            />
                            <Typography height={20} variant='subtitle1' color="error">{validateData.firstname ?? ""}</Typography>
                        </Stack>
                        <Stack spacing={{xs:0.7,md:1}} width="100%">
                            <Typography fontWeight="bold" variant='body1' color="text.main">{t("lastName")}<span style={{color: "red"}}>*</span></Typography>

                            <InputField
                                sx={{
                                    boxShadow: "4px 4px 36px 0px #0000801A",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: "2px solid #00000030",
                                        },
                                        '&:hover fieldset': {
                                            border: "2px solid #00000030",
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: "2px solid #00000030",
                                        },
                                    }
                                }}
                                fullWidth
                                size="small"
                                placeholder={t("yourLastName")}
                                type='text'
                                name="lastname"
                                value={postData.lastname}
                                onChange={handleChanges}
                                error={validateData.lastname}
                            />
                            <Typography height={20} variant='subtitle1' color="error">{validateData.lastname ?? ""}</Typography>
                        </Stack>
                    </Stack>
                    <Stack spacing={{xs: -2, md: 3}} direction={{xs: "column", md: "row"}} justifyContent="center">
                        <Stack spacing={1} width="100%">
                            <Typography fontWeight="bold" variant='body1' color="text.main">{t("emailAddress")}<span style={{color: "red"}}>*</span></Typography>
                            <InputField
                                sx={{
                                    boxShadow: "4px 4px 36px 0px #0000801A",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: "2px solid #00000030",
                                        },
                                        '&:hover fieldset': {
                                            border: "2px solid #00000030",
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: "2px solid #00000030",
                                        },
                                    }
                                }}
                                fullWidth
                                size="small"
                                placeholder={t("yourEmailAddress")}
                                type='text'
                                name="email"
                                value={postData.email}
                                onChange={handleChanges}
                                error={validateData.email}
                            />
                            <Typography height={20} variant='subtitle1' color="error">{validateData.email ?? ""}</Typography>
                        </Stack>
                        <Stack spacing={1} width="100%">
                            <Typography fontWeight="bold" variant='body1' color="text.main">{t("phoneNumber")}<span style={{color: "red"}}>*</span></Typography>
                            <PhoneInput
                                className="secure-payment-phone-field"
                                specialLabel=''
                                country={'fr'}
                                inputStyle={{
                                    border: validateData.phone ? "2px solid red" : "2px solid #00000030",
                                    backgroundColor: "white",
                                    boxShadow: "4px 4px 36px 0px #0000801A",
                                    height: 38,
                                    fontFamily: "Montserrat",
                                    fontSize: 14,
                                    fontWeight: 500,
                                }}
                                // {...props}
                                fullWidth
                                placeholder={t("yourPhoneNumber")}
                                type='text'
                                name="phone"
                                value={postData.phone}
                                onChange={(num) => {
                                    setValidateData(prev => ({...prev, phone: null}))
                                    setPostData(prev => ({...prev, phone: num}))
                                }}
                            />
                            <Typography height={20} variant='subtitle1' color="error">{validateData.phone ?? ""}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={{xs: "column", md: "row"}} spacing={3}  >
                        <BlueButton  
                            size='large'  
                            disabled={loading} 
                            onClick={initiatePayment}
                        >
                            {t("payNow")}
                        </BlueButton>
                    </Stack>
                </Stack>
            </Stack>
            <Box display={{xs:"none",md:"block"}}
                sx={{
                    width: "50%",
                    height: "auto",
                    // border: "1px solid black",
                    maskImage: "linear-gradient(to right, transparent, black 20%, black 80%)",

                }}
            >
                <img 
                    style={{ 
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        
                    }} 
                    src={securePaymentBackground} 
                    alt="img" 
                   
                />
            </Box>
        </Stack>
   </Box>
  )
}

export default PayOnlineForm