import React from 'react';
import { ThemeProvider } from '@mui/material';
import { LightTheme } from './LightTheme';
// import { DarkTheme } from './DarkTheme';
// import { useSelector } from 'react-redux';

function CustomThemeProvider(props) {
  
  return (
    <ThemeProvider theme={LightTheme}>
      {props.children}
    </ThemeProvider>
  )
}

export default CustomThemeProvider; 