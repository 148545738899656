import { Divider, FormControl, Radio, RadioGroup, Typography } from '@mui/material'
import { StyledRadioControl, WhiteCard } from '../../../Lib/MuiThemes/MuiComponents'
import { useTranslation } from 'react-i18next';

function BookingOptions({ bookingType, setBookingType, payingAmount, serviceCharge, currency }) {


  const { t } = useTranslation();

  function handlePaymentMethod(event){
    setBookingType(event.target.value);
  }

  return (
    <WhiteCard>
        <Typography variant='h5' fontWeight="bold" color="primary">{t("Type of Booking")}</Typography>
        <FormControl fullWidth>
            <RadioGroup
                sx={{gap: 2}}
                value={bookingType}
                onChange={handlePaymentMethod}
            >
                <StyledRadioControl
                    value="selfbook" 
                    control={<Radio />} 
                    label={
                        <>
                        <Typography variant='h6' color="text.main">{t("bookMyself")}</Typography>
                        <Typography variant='subtitle1' color="text.main">{t("payTheTotalFareByYourself")}</Typography>
                        </>
                    } 
                />
                <Divider></Divider>
                <StyledRadioControl 
                    value="agencybook" 
                    control={<Radio />} 
                    label={
                        <>
                        <Typography variant='h6' color="text.main">{t("bookThroughAgency")}</Typography>
                        {/* <Typography variant='subtitle1' color="text.main">{t("pay")} {getCurrencySymbol(currency)} {Math.ceil(bookThroughAdminTotalPayableAmount)} {t("OtherChargesInCashOrCreditCardToErrancesVoyagesTeamDirectlyOnTheSameDayOfTheBookingOrElseYourBookingWillBeCancelledAutomatically")}</Typography> */}
                        <Typography variant='subtitle1' color="text.main">{t("anAgentWillGetBackToYouOnThisOption")}</Typography>
                        </>
                    }
                />
            </RadioGroup>
        </FormControl>
    </WhiteCard>
  )
}

export default BookingOptions