import React, {lazy, Suspense, useEffect} from 'react';
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import BookingDetails from '../Components/BookingDetails/BookingDetails';
import FlightSearch from "../Components/FightSearch/FlightSearch";
import FlightDetailPage from '../Components/FlightDetailPage/FlightDetailPage';
import FlightListingsLayout from '../Components/FlightListing/FlightListingsLayout';
import HomeLayout from '../Components/HomeLayout/HomeLayout';
// import Profile from '../Components/Profile/Profile';
// import AccountTab from '../Components/Profile/ProfileFragments/AccountTab';
// import MasterPassengerList from '../Components/Profile/ProfileFragments/MasterPassengerList';
// import PaymentMethods from '../Components/Profile/ProfileFragments/PaymentMethods';
// import TicketBookingsList from '../Components/Profile/ProfileFragments/TicketBookingsList';
// import { FullScreenLoader } from '../Lib/MuiThemes/MuiComponents';
import { FullScreenLoader } from '../Lib/MuiThemes/MuiComponents';
// import UserRoutes from './UserRoutes';
// import AdminRoutes from './AdminRoutes';
// import AdminLayout from '../Components/AdminComponents/AdminLayout/AdminLayout';
// import Dashboard from '../Components/AdminComponents/Dashboard/Dashboard';
import TicketDetails from '../Components/TicketDetails/TicketDetails';
// import TripDetails from '../Components/AdminComponents/TripDetails/TripDetails';
// import UserDetails from '../Components/AdminComponents/UserDetails/UserDetails';
// import AddServiceCharge from '../Components/Profile/ProfileFragments/AddServiceCharge';
// import RequestedBookingDetails from '../Components/AdminComponents/RequestedBookingDetails/RequestedBookingDetails';
import SeatSelection from '../Components/BookingDetails/SeatSelection/SeatSelection';
import VisaTracker from '../Components/VisaTracker/VisaTracker';
import PayOnline from '../Components/PayOnline/PayOnline';
import Formalities from '../Components/Formalities/Formalities';
import Services from '../Components/Services/Services';
import Packages from '../Components/Packages/Packages';
import ContactUs from '../Components/ContactUs/ContactUs';
import TourPackagesList from '../Components/Packages/TourPackagesList';
import TourDetails from '../Components/Packages/TourDetails';
import PageUnderMaintenance from '../Components/PageUnderMaintenance/PageUnderMaintenance';
import SeasonalFares from '../Components/SeasonFares/SeasonalFares';
import ArchivedPromotions from '../Components/ArchivedPromotion/ArchivedPromotions';
import Policy from '../Components/LoginPages/Policy';
import PageNotFound from '../Components/ReusableComponents/PageNotFound';
import CreateBlog from '../Components/AdminComponents/Blogs/create-blog/CreateBlog';
import ViewBlogs from '../Components/Blogs/ViewBlogs';
import Blog from '../Components/Blogs/Blog';
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs";
import Preview from '../Components/AdminComponents/Blogs/create-blog/Preview';
import { Box } from '@mui/material';
import ValidateBookingBooking from '../Components/BookingDetails/ValidateBookingMobile';



// login screen routes
const Login = lazy(() => import("../Components/LoginPages/Login"));
const Signup = lazy(() => import("../Components/LoginPages/Signup"));
const ForgotPassword = lazy(() => import("../Components/LoginPages/ForgotPassword"));
// const AddPayment = lazy(() => import("../Components/LoginPages/AddPayment"));
const VerifyCode = lazy(() => import("../Components/LoginPages/VerifyCode"));
const SetPassword = lazy(() => import("../Components/LoginPages/SetPassword"));

// user profile routes
const UserRoutes = lazy(() => import("./UserRoutes"));
const Profile = lazy(() => import("../Components/Profile/Profile"));
const AccountTab = lazy(() => import("../Components/Profile/ProfileFragments/AccountTab"));
const MasterPassengerList = lazy(() => import("../Components/Profile/ProfileFragments/MasterPassengerList"));
const PaymentMethods = lazy(() => import("../Components/Profile/ProfileFragments/PaymentMethods"));
const TicketBookingsList = lazy(() => import("../Components/Profile/ProfileFragments/TicketBookingsList"));
const LegalNotices = lazy(() => import("../Components/LegalNotice/LegalNotice"));
const TravelInsurance = lazy(() => import("../Components/TravelInsurance/TravelInsurance"));
const AccountDeletion = lazy(() => import("../Components/AccountDeletion/AccountDeletion"));
const ValidateBooking = lazy(() => import("../Components/BookingDetails/ValidateBooking"));

// admin component routes
const AdminRoutes = lazy(() => import("./AdminRoutes"));
const AdminLayout = lazy(() => import("../Components/AdminComponents/AdminLayout/AdminLayout"));
const Dashboard = lazy(() => import("../Components/AdminComponents/Dashboard/Dashboard"));
const TripDetails = lazy(() => import("../Components/AdminComponents/TripDetails/TripDetails"));
const BookingInfo = lazy(() => import("../Components/AdminComponents/TripDetails/BookingInfo"));
const UserDetails = lazy(() => import("../Components/AdminComponents/UserDetails/UserDetails"));
// const RequestedBookingDetails = lazy(() => import("../Components/AdminComponents/RequestedBookingDetails/RequestedBookingDetails"));
const ApproverTravelers = lazy(() => import("../Components/AdminComponents/ApproveTravelers/ApproveTravelers"));
const AddPackages = lazy(() => import("../Components/AdminComponents/ConfigurePackages.jsx/AddPackages"))
const TourTable = lazy(() => import("../Components/AdminComponents/ConfigureTours/TourTable"))
const AddTour = lazy(() => import ('../Components/AdminComponents/ConfigureTours/AddTour'))
const EditTour = lazy(() => import ('../Components/AdminComponents/ConfigureTours/EditTour'))
const Blogs = lazy(() => import ('../Components/AdminComponents/Blogs/ViewBlogs'))
const TransactionDetails = lazy(() => import ('../Components/AdminComponents/TransactionDetails/TransactionDetails'));
const SeasonalFaresTable = lazy(() => import('../Components/AdminComponents/ConfigureSeasonalFares/SeasonalFaresTable'));
const SeasonalFaresFlightTable = lazy(() => import('../Components/AdminComponents/ConfigureSeasonalFareFlights/ConfigureSeasonalFareFlights'));

//marketeer component routes
const MarketeerRoutes = lazy(() => import("./MarketeerRoutes"));
const MarketeerLayout = lazy(() => import("../Components/AdminComponents/MarketeerLayout/MarketeerLayout"));

// admin profile routes
const AdminProfile = lazy(() => import("../Components/Profile/Profile")); 
const AdminAccountTab = lazy(() => import("../Components/Profile/ProfileFragments/AccountTab"));
const AdminPaymentMethods = lazy(() => import("../Components/Profile/ProfileFragments/PaymentMethods"));
const AdminServiceCharge = lazy(() => import("../Components/Profile/ProfileFragments/AddServiceCharge"));


function AppRoutes() {

  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {

    if(location.pathname === "/"){
      navigate(`/${i18n.language}/home`);
    }

  }, [ location.pathname, navigate, i18n.language ]);

  useEffect(() => {

    if(i18n.language === "fr"){
      dayjs.locale(require("dayjs/locale/fr"))
    }

  }, [ i18n.language ])
  
  return (
    <>   
    <Routes>
      <Route path={`/`} element={<HomeLayout/>}>
        <Route index element={<FlightSearch/>}/>
        <Route path="home" element={<FlightSearch/>}/>
        <Route path="our-blogs" element={<ViewBlogs/>}/>
        <Route path="our-blogs/blog/:blogId" element={<Blog/>}/>
        <Route path="packages" element={<Packages/>}/>
        <Route path="packages/tour" element={<TourPackagesList/>}/>
        <Route path="packages/tour/itinerary" element={<TourDetails/>}/>
        <Route path="promotions" element={<SeasonalFares/>}/>
        <Route path="archived-promotions" element={<ArchivedPromotions/>}/>
        <Route path="promotions/seasonal-fares/:fareId" element={<Services/>}/>
        <Route path="visa-enquiry" element={<VisaTracker/>}/>
        <Route path="formalities" element={<Formalities/>}/>
        <Route path="contact" element={<ContactUs/>}/>
        {/* <Route path="/promotions" element={<Promotions/>}/> */}
        <Route path="secure-payment" element={<PayOnline/>}/>
        <Route path="legal-notices" element={<LegalNotices/>}/>
        <Route path="travel-insurance" element={<TravelInsurance/>}/>
        {/* <Route path="/flights" element={<PageUnderMaintenance/>}/> */}
        <Route path="flights" element={<FlightListingsLayout/>}/>
        <Route path="flightdetails" element={<FlightDetailPage/>} />
        <Route path="bookingdetails" element={<BookingDetails/>} />
        <Route path="seatmap/:id" element={<SeatSelection/>} />
        <Route path='ticket' element={<TicketDetails/>} />
        <Route path='payment-validation' element={<ValidateBookingBooking/>} />
        <Route element={<Suspense fallback={<FullScreenLoader/>}><UserRoutes/></Suspense>}>
          <Route path="validate-booking" element={<Suspense fallback={<FullScreenLoader/>}><ValidateBooking/></Suspense>}/>
          <Route 
            path={"booking-info"} 
            element={
              <Suspense 
                fallback={<FullScreenLoader/>}
              >
                <Box sx={{
                  px: "10rem",
                  py: "50px",
                }}>
                  <BookingInfo/>
                </Box>
              </Suspense>
            }
          ></Route>
          <Route path="profile" element={<Suspense fallback={null}><Profile/></Suspense>}>
            <Route index element={<Suspense fallback={null}><AccountTab/></Suspense>}></Route>
            <Route path="account" element={<Suspense fallback={null}><AccountTab/></Suspense>}></Route>
            <Route path="master-passenger-list" element={<Suspense fallback={<FullScreenLoader/>}><MasterPassengerList/></Suspense>}></Route>
            <Route path="ticket-bookings-history" element={<Suspense fallback={<FullScreenLoader/>}><TicketBookingsList/></Suspense>}></Route>
            <Route path="payment/add" element={<Suspense fallback={<FullScreenLoader/>}><PaymentMethods/></Suspense>}></Route>
          </Route>
          <Route path='delete-account' element={<AccountDeletion/>} />
        </Route>

        {/* admin routes */}

        <Route element={<Suspense fallback={<FullScreenLoader/>}><AdminRoutes/></Suspense>}>
          <Route path="admin" element={<Suspense fallback={null}><AdminLayout/></Suspense>}>
            <Route index element={<Suspense fallback={null}><Dashboard/></Suspense>}></Route>
            <Route path={"dashboard"} element={<Suspense fallback={<FullScreenLoader/>}><Dashboard/></Suspense>}></Route>
            <Route path={"trip-details"} element={<Suspense fallback={<FullScreenLoader/>}><TripDetails/></Suspense>}></Route>
            <Route path={"trip-details/booking-info"} element={<Suspense fallback={<FullScreenLoader/>}><BookingInfo/></Suspense>}></Route>
            <Route path={"user-details"} element={<Suspense fallback={<FullScreenLoader/>}><UserDetails/></Suspense>}></Route>
            {/* <Route path={"requested-booking"} element={<Suspense fallback={<FullScreenLoader/>}><RequestedBookingDetails/></Suspense>}></Route> */}
            <Route path={"approve-travelers"} element={<Suspense fallback={<FullScreenLoader/>}><ApproverTravelers/></Suspense>}></Route>
            <Route path={"fares"} element={<Suspense fallback={<FullScreenLoader/>}><SeasonalFaresTable/></Suspense>}></Route>
            <Route path={"config-fares"} element={<Suspense fallback={<FullScreenLoader/>}><SeasonalFaresFlightTable/></Suspense>}></Route>
            <Route path={"config-packages"} element={<Suspense fallback={<FullScreenLoader/>}><AddPackages/></Suspense>}></Route>
            <Route path={"config-tour"} element={<Suspense fallback={<FullScreenLoader/>}><TourTable/></Suspense>}></Route>
            <Route path={"config-tour/add"} element={<Suspense fallback={<FullScreenLoader/>}><AddTour/></Suspense>}></Route>
            <Route path={"config-tour/update"} element={<Suspense fallback={<FullScreenLoader/>}><EditTour/></Suspense>}></Route>
            <Route path={"blogs"} element={<Suspense fallback={<FullScreenLoader/>}><Blogs/></Suspense>}></Route>
            <Route path={"blogs/create/:blogId"} element={<Suspense fallback={<FullScreenLoader/>}><CreateBlog/></Suspense>}></Route>
            <Route path={"blogs/create/:blogId/preview"} element={<Suspense fallback={<FullScreenLoader/>}><Preview/></Suspense>}></Route>
            <Route path={"transactions"} element={<Suspense fallback={<FullScreenLoader/>}><TransactionDetails/></Suspense>}></Route>
          </Route>
          <Route path="profile" element={<Suspense fallback={null}><AdminProfile/></Suspense>}>
            <Route index element={<Suspense fallback={null}><AdminAccountTab/></Suspense>}></Route>
            <Route path="account" element={<Suspense fallback={null}><AdminAccountTab/></Suspense>}></Route>
            <Route path="payment/add" element={<Suspense fallback={<FullScreenLoader/>}><AdminPaymentMethods/></Suspense>}></Route>
            <Route path="service" element={<Suspense fallback={<FullScreenLoader/>}><AdminServiceCharge/></Suspense>}></Route>
          </Route>
          <Route path='delete-account' element={<AccountDeletion/>} />
        </Route>

        {/* marketeer route */}

        <Route element={<Suspense fallback={<FullScreenLoader/>}><MarketeerRoutes/></Suspense>}>
          <Route path="marketeer" element={<Suspense fallback={null}><MarketeerLayout/></Suspense>}>
            <Route index element={<Suspense fallback={null}><AddPackages/></Suspense>}></Route>
            <Route path={"config-packages"} element={<Suspense fallback={<FullScreenLoader/>}><AddPackages/></Suspense>}></Route>
            <Route path={"config-tour"} element={<Suspense fallback={<FullScreenLoader/>}><TourTable/></Suspense>}></Route>
            <Route path={"config-tour/add"} element={<Suspense fallback={<FullScreenLoader/>}><AddTour/></Suspense>}></Route>
            <Route path={"config-tour/update"} element={<Suspense fallback={<FullScreenLoader/>}><EditTour/></Suspense>}></Route>
            <Route path={"blogs"} element={<Suspense fallback={<FullScreenLoader/>}><Blogs/></Suspense>}></Route>
            <Route path={"blogs/create/:blogId"} element={<Suspense fallback={<FullScreenLoader/>}><CreateBlog/></Suspense>}></Route>
          </Route>
          <Route path="profile" element={<Suspense fallback={null}><Profile/></Suspense>}>
            <Route index element={<Suspense fallback={null}><AccountTab/></Suspense>}></Route>
            <Route path="account" element={<Suspense fallback={null}><AccountTab/></Suspense>}></Route>
            <Route path="master-passenger-list" element={<Suspense fallback={<FullScreenLoader/>}><MasterPassengerList/></Suspense>}></Route>
            <Route path="ticket-bookings-history" element={<Suspense fallback={<FullScreenLoader/>}><TicketBookingsList/></Suspense>}></Route>
            <Route path="payment/add" element={<Suspense fallback={<FullScreenLoader/>}><PaymentMethods/></Suspense>}></Route>
          </Route>
          <Route path='delete-account' element={<AccountDeletion/>} />
        </Route>

      </Route>
      <Route path="login" element={<Suspense fallback={<FullScreenLoader/>}><Login/></Suspense>}/>
      <Route path="signup" element={<Suspense fallback={<FullScreenLoader/>}><Signup/></Suspense>}/>
      <Route path="password/forgot" element={<Suspense fallback={<FullScreenLoader/>}><ForgotPassword/></Suspense>}/>
      <Route path="password/set" element={<Suspense fallback={<FullScreenLoader/>}><SetPassword/></Suspense>}/>
      <Route path="policy" element={<Suspense fallback={<FullScreenLoader/>}><Policy/></Suspense>}/>
      <Route path="verify" element={<Suspense fallback={<FullScreenLoader/>}><VerifyCode/></Suspense>}/>
    </Routes>
    </>
  )
}

export default AppRoutes