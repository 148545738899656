import React, { useEffect, useState } from 'react';
import { AppBar, Backdrop, Box, Card, Divider, Fab, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import FiltersSetting from './FlightListingFragments/FiltersSetting';
import FlightListings from './FlightListingFragments/FlightListings';
import { globeFlightLoader, noFlightsFound } from '../../Assests/assets';
import SearchFlightBox from '../ReusableComponents/SearchFlightBox';
import { Navigation } from '@mui/icons-material';
import axios from 'axios';
import useSnackBar from '../../Lib/CustomHooks/useSnackBar';
import { useSearchParams } from 'react-router-dom';
import { Axios } from '../../Lib/Axios/AxiosConfig';
import Lottie from 'react-lottie-player';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { capitalize } from '../../Lib/utils/helperFunctions';
import { useDispatch } from 'react-redux';
import { setDictionary } from '../../Lib/Redux/FlightPricePayloadSlice';
import { FullScreenLoader, WhiteCard } from '../../Lib/MuiThemes/MuiComponents';
import FlexibleDateSearch from './FlightListingFragments/FlexibleDateSearch';
import dayjs from 'dayjs';
import ConditionForSalesModal from '../ReusableComponents/ConditionForSalesModal';
import Search from '../ReusableComponents/Search';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import MobileFilter from './FlightListingFragments/MobileFilter';
import Filters from './FlightListingFragments/Filters';

function FlightListingsLayout() {

  const [ flightResult, setFlightResult ]  = useState({
    data: {},
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorMessage: ""
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [ flightCountsBasedOnStops, setFlightCountBasedOnStops ] = useState({});
  const [showValue, setShowValue] = useState(10);
  const [scrollValue, setScrollValue] = useState(0);
  const { showSnackBar } = useSnackBar();                   //Snackbar context hook
  const [ searchParams ] = useSearchParams();               // URL params state

  const [ combinableFlightTabFocus, setCombinableFlightTabFocus ] = useState("0")  // Tab focus state for combinable flights
  const [ openConditionForSalesModal, setOpenConditionForSalesModal ] = useState(false); // condition for sales modal

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }


  /* Show flight result data */
  useEffect(() => {
    const controller = axios.CancelToken.source();
    if(!parseInt(searchParams.get("isMultiCity"))){
      (async () => {
        try{
          setFlightResult(prev => ({...prev, isLoading: true, isError: false, isSuccess: false, data: {}}))
          let params = {
            tripType: searchParams.get("tripType"),
            isCombinable: parseInt(searchParams.get("isCombinable")),
            origin: searchParams.get("origin"),
            destination: searchParams.get("destination"),
            departureDate: searchParams.get("departureDate"),
            returnDate: searchParams.get("returnDate"),
            adults: parseInt(searchParams.get("adults")),
            children: parseInt(searchParams.get("children")),
            infants: parseInt(searchParams.get("infants")),
            travelClass: searchParams.get("travelClass"),
            currencyCode: searchParams.get("currencyCode"),
            corporateCode: searchParams.get("corporateCode"),
          }
          
          let url = `${params.tripType === "twoway" && params.isCombinable ? "fetchOnewayCombinableOffers" : params.tripType === "twoway" ? "fetchTwowayOffers" : "fetchOnewayOffers"}?origin=${params.origin}&destination=${params.destination}&departureDate=${params.departureDate}${params.tripType === "twoway" ? `&returnDate=${params.returnDate}` : ""}&adults=${params.adults}&children=${params.children}&infants=${params.infants}&travelClass=${params.travelClass}&currencyCode=${params.currencyCode}&corporateCode=${params.corporateCode}&system=web`;

          const response = await Axios({
            url,
            method: "get",
            cancelToken: controller.token,
            validateStatus: (status) => {
              return status < 600; // Reject only if the status code is greater than or equal to 500
            }
          });
          
          if(response.status >= 500){
            setFlightResult(prev => ({
              ...prev,
              isError: true,
              isLoading: false,
              isSuccess: false
            }))
            showSnackBar("error", t("No Flights found"));
          }
  
          if(response.status === 200){
            setFlightResult(prev => ({
              ...prev,
              data: response.data,
              isLoading: false,
              isSuccess: true
            }));
            dispatch(setDictionary(response.data.carriers))
            setOpenConditionForSalesModal(true)
            sessionStorage.setItem("refId", response.data.refId);
          }
  
        }catch(error){
          setFlightResult(prev => ({
            ...prev,
            isError: true,
            isLoading: false,
            isSuccess: false
          }))
          console.log(error);
        }
      })();

    }else{
      
      (async () => {
        try{
          setFlightResult(prev => ({...prev, isLoading: true, isError: false, isSuccess: false, data: {}}))
          let params = {
            origin: searchParams.get("origin").split(","),
            destination: searchParams.get("destination").split(","),
            departureDate: searchParams.get("departureDate").split(","),
            adults: parseInt(searchParams.get("adults")),
            children: parseInt(searchParams.get("children")),
            infants: parseInt(searchParams.get("infants")),
            travelClass: searchParams.get("travelClass"),
            currencyCode: searchParams.get("currencyCode"),
            corporateCode: searchParams.get("corporateCode"),
            system: "web"
          }
          const response = await Axios({
            url: "fetchMulticityOffers",
            method: "post",
            cancelToken: controller.token,
            data: params,
            validateStatus: (status) => {
              return status < 600; // Reject only if the status code is greater than or equal to 500
            }
          });
          // console.log(response);
          if(response.status >= 500){
            setFlightResult(prev => ({
              ...prev,
              isError: true,
              isLoading: false,
              isSuccess: false
            }))
            showSnackBar("error", t("No Flights found"));
          }
  
          if(response.status === 200){
            setFlightResult(prev => ({
              ...prev,
              data: response.data,
              isLoading: false,
              isSuccess: true
            }));
            dispatch(setDictionary(response.data.carriers))
            sessionStorage.setItem("refId", response.data.refId);
          }
  
        }catch(error){
          setFlightResult(prev => ({
            ...prev,
            isError: true,
            isLoading: false,
            isSuccess: false
          }))
          console.log(error);
        }
      })();
    }

    return () => controller.cancel();
  }, [searchParams, dispatch ])

  /* Scroll to top button functionality */

  function scrollToTop(){
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollValue(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[]);


  /* Render elements */
  let jsx;

  if(flightResult.isLoading){
    jsx = (
      <FullScreenLoader/>
    )
  }

  if(flightResult.isError){
    jsx = (
      <Stack m="0 auto">
        <Lottie 
            style={{
                height: 300,
                width: 500,
            }}
            loop={true}
            play={true}
            animationData={noFlightsFound}
            rendererSettings= {{
                preserveAspectRatio: "xMidYMid slice"
            }}
        />
        <Typography variant="h5" color="text.main" textAlign="center" mr={5}>No flights found</Typography>
      </Stack>
    )
  }

  if(flightResult.isSuccess && flightResult.data.hasOwnProperty("data")){

    // let FlightCount = flightResult?.data?.data?.reduce((acc, curr) => {
    //   if(curr.stops === 0){
    //     acc.nonstopFlightCount++
    //   }
  
    //   if(curr.stops === 1){
    //     acc.onestopFlightCount++
  
    //   }
  
    //   if(curr.stops === 2){
    //     acc.twostopFlightCount++
    //   }
    //   return acc;
    // }, {
    //   nonstopFlightCount: 0, 
    //   onestopFlightCount: 0, 
    //   twostopFlightCount: 0
    // });
    
    jsx = (
      <>
        <Box
          sx={{
            display: {
              xs: "none",
              md: "block"
            },
          }}
        >
          <FiltersSetting/>
        </Box>
        <Stack sx={{flexDirection: "column", width: {xs: "100%", md: "70%"}}}>
          <FlexibleDateSearch/>
          <FlightListings
            cardData={flightResult.data.data}
            showValue={showValue}
            setShowValue={setShowValue}
            originName={capitalize(searchParams.get("originName").split(",")[0])}
            destinationName={capitalize(searchParams.get("destinationName").split(",")[0])}
          />
        </Stack>
      </>
    )
  }

  if(flightResult.isSuccess && flightResult.data.hasOwnProperty("incoming") && flightResult.data.hasOwnProperty("outgoing")){

    jsx = (
      <>
        <Box
          sx={{
            display: {
              xs: "none",
              md: "block"
            },
          }}
        >
          <FiltersSetting/>
        </Box>
        <Stack sx={{flexDirection: "column", width: "70%"}}>
          <TabContext 
            value={combinableFlightTabFocus}
          >
            <AppBar 
              position="static"
              sx={{
                border: "2px solid white",
                backgroundColor: "#e8e1fa"
              }}
            >
              <Tabs
                value={combinableFlightTabFocus}
                onChange={(e, val) => setCombinableFlightTabFocus(val)}
                // indicatorColor="error.main"
                sx={{
                  color: "black",
                  fontWeight: "bold"
                }}
                variant="fullWidth"
                aria-label="full width tabs example"
                TabIndicatorProps={{
                  style: {
                    display: "none"
                  }
                }}
              >
                <Tab
                  sx={{
                    backgroundColor: combinableFlightTabFocus === "0" ? "primary.main" : "transparent",
                  }} 
                  label={
                    <Stack sx={{ color: combinableFlightTabFocus === "0" ? "white" : "primary.main" }}>
                      <Typography fontWeight="bold">{t("outbound")}</Typography>
                      <Typography>{capitalize(searchParams.get("originName"))} - {capitalize(searchParams.get("destinationName"))}</Typography>
                      <Typography>{dayjs(searchParams.get("departureDate")).format("DD-MM-YYYY")}</Typography>
                    </Stack>
                  }
                  {...a11yProps(0)}
                  value="0" 
                />
                
                <Tab
                  sx={{
                    backgroundColor: combinableFlightTabFocus === "1" ? "primary.main" : "transparent"
                  }} 
                  label={
                    <Stack sx={{ color: combinableFlightTabFocus === "1" ? "white" : "primary.main" }}>
                      <Typography fontWeight="bold">{t("inbound")}</Typography>
                      <Typography>{capitalize(searchParams.get("destinationName"))} - {capitalize(searchParams.get("originName"))}</Typography>
                      <Typography>{dayjs(searchParams.get("returnDate")).format("DD-MM-YYYY")}</Typography>
                    </Stack>
                  } 
                  {...a11yProps(1)} 
                  value="1"
                />
              </Tabs>
            </AppBar>
            {/* <Box>
              <TabList
                centered 
                onChange={(e, val) => setCombinableFlightTabFocus(val)}
                indicatorColor="info"
                // TabIndicatorProps={{
                //   height: "50px"
                // }}
                sx={{ 
                  width: "100%", 
                  backgroundColor: "card.background", 
                  boxShadow: "0px 4px 16px rgba(141, 211, 187, 0.15)",
                  borderRadius: "10px",
                }}
              >
                <Tab 
                  label={
                    <Stack>
                      <Typography color="text.main">{capitalize(searchParams.get("originName"))} to {capitalize(searchParams.get("destinationName"))}</Typography>
                      <Typography color="text.main">{dayjs(searchParams.get("departureDate")).format("DD-MM-YYYY")}</Typography>
                    </Stack>
                  } 
                  value="0"
                  sx={{minWidth: "50%", color: "text.main"}} 
                />
                <Tab 
                  label={
                    <Stack>
                      <Typography color="text.main">{capitalize(searchParams.get("destinationName"))} to {capitalize(searchParams.get("originName"))}</Typography>
                      <Typography color="text.main">{dayjs(searchParams.get("returnDate")).format("DD-MM-YYYY")}</Typography>
                    </Stack>
                  } 
                  value="1"
                  sx={{minWidth: "50%", color: "text.main"}} 
                />
              </TabList>
            </Box> */}
            <TabPanel value="0" sx={{px: 0}}>
              <FlightListings
                cardData={flightResult.data.outgoing}
                showValue={showValue}
                setShowValue={setShowValue}
                originName={capitalize(searchParams.get("originName"))}
                destinationName={capitalize(searchParams.get("destinationName"))}
                combinableFlightTabFocus={combinableFlightTabFocus}
              />
            </TabPanel>
            <TabPanel value="1"  sx={{px: 0}}>
              <FlightListings
                cardData={flightResult.data.incoming}
                showValue={showValue}
                setShowValue={setShowValue}
                originName={capitalize(searchParams.get("destinationName"))}
                destinationName={capitalize(searchParams.get("originName"))}
                combinableFlightTabFocus={combinableFlightTabFocus}
              />
            </TabPanel>
          </TabContext>
        </Stack>
      </>
    )
  }
  
  return (
    <>
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      gap: 3,
      backgroundColor: "common.background",
      px: {
        xs: 1,
        sm: 10
      },
      py: 3
    }}>
      <Box sx={{display: {xs: "none", md: "block"}}}>
        <Search px={0} top={0} />
        <Card elevation={0} p={1}>
          <Typography 
            component={motion.h6} 
            variant='h6' 
            fontWeight="bold" 
            color="primary.main" 
            letterSpacing={1}
            textAlign="center"
            // initial={{
            //   x: window.innerWidth
            // }}
            
            // animate={{
            //     x: -window.innerWidth,
            //     transition: {
            //         duration: 40,
            //         repeat: Infinity
            //     }
            // }}
          >
            {t("For Etihad Airways and Srilankan Airlines Flexi fares, Contact our Agency directly to 01.42.09.70.00.")}
          </Typography>
        </Card>
      </Box>
      <Box sx={{
          height: "auto",
          width: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 5,
          backgroundColor: "common.background",
      }}>
          {jsx}
      </Box>
    </Box>
    <ConditionForSalesModal
      openConditionForSalesModal={openConditionForSalesModal}
      setOpenConditionForSalesModal={setOpenConditionForSalesModal}
    />
    <MobileFilter/>
    {scrollValue > 400 && <Tooltip title="Go to top" placement="top">
      <Fab 
        sx={{ position: 'fixed', bottom: 16, right: 16 }} 
        color="primary" 
        size='medium'
        onClick={scrollToTop}
      >
        <Navigation />
      </Fab>
    </Tooltip>}
    </>
  )
}

export default FlightListingsLayout