import { Typography } from '@mui/material'
import { Box } from '@mui/material'
import React from 'react'
import { BlueButton } from '../../../Lib/MuiThemes/MuiComponents'
import { Modal } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Lottie from 'react-lottie-player'
import { successGifBookThroughAdmin } from '../../../Assests/assets'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 3,
    boxShadow: 24,
    p: 3,
    display: "grid",
    placeItems: "center",
    gap: 3
};

function BookingSuccessModalByBookThroughAdmin({ open, setOpen }) {

  const navigate = useNavigate();

  const returnToHomePage = () => {
    setOpen(false);
    navigate("/home")
  }

  return (
    <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Box>
                <Lottie 
                style={{
                    height: 200,
                    width: 300,
                    padding: 0
                    // margin: "0 auto",
                }}
                loop={false}
                play={true}
                animationData={successGifBookThroughAdmin}
                rendererSettings= {{
                    preserveAspectRatio: "xMidYMid slice"
                }}
                />
            </Box>
            <Typography textAlign="center" variant='h5' fontWeight="bold" color="text.main">
                Thank you for choosing Errances Voyages! We've recieved you booking request.
            </Typography>
            <BlueButton sx={{ maxWidth: 150 }} onClick={returnToHomePage}>Return to home</BlueButton>
        </Box>
    </Modal>
  )
}

export default BookingSuccessModalByBookThroughAdmin