import { Close, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, IconButton, Modal, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'card.background',
    borderRadius: 5,
    boxShadow: 24,
    p: 3,
};

function ViewFareRulesModal({ fareDetails }) {

  const [ fareRules ] = Object.values(fareDetails);
  const [ open, setOpen ] = useState(false);
  const [ expanded, setExpanded ] = useState(0);
  const { t } = useTranslation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  console.log();

  return (
    <div>
        {fareRules && <Typography component="a" onClick={handleOpen} variant='body1' sx={{ textDecoration: "underline", color: "blue" }}>{t("viewFareRules")}</Typography>}
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5" fontWeight="bold" color="primary.main">
                        FARE RULES
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <Close/>
                    </IconButton>
                </Stack>
                <Stack my={2} py={1} sx={{backgroundColor: "card.background", boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", borderRadius: 3, overflow: "hidden"}}>
                    {fareRules?.map((fare, index) => (
                        <Accordion 
                            key={index}
                            expanded={expanded === index} 
                            onChange={handleChange(index)}
                            elevation={0} 
                        >
                            <AccordionSummary
                                expandIcon={
                                    <IconButton sx={{ boxShadow: "0px 6px 16px rgba(8, 15, 52, 0.08)"}}>
                                        <ExpandMore />
                                    </IconButton>
                                }
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography variant='h6' fontWeight="bold">
                                    {fare.descriptionType}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack sx={{ maxHeight: 200, overflowY: "scroll" }}>
                                    <Typography variant='h6' textTransform="lowercase">
                                        {fare.text}
                                    </Typography>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    
                </Stack>
            </Box>
        </Modal>
    </div>
  )
}

export default ViewFareRulesModal