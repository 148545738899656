import { Box, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import HomeBanner from '../ReusableComponents/HomeBanner'
import ServicesCard from './ServicesCard'
import { motion } from 'framer-motion'
import { flightLoader, homeBanner } from '../../Assests/assets'
import { useTranslation } from 'react-i18next'
import useSnackBar from '../../Lib/CustomHooks/useSnackBar'
import axios from 'axios'
import { BASE_URL } from '../../Lib/Axios/AxiosConfig'
import { useLocation, useParams } from 'react-router-dom'
import Lottie from 'react-lottie-player'

function Services() {

  const { t } = useTranslation();
  const { state } = useLocation()
  const { fareId } = useParams();
  const { showSnackBar } = useSnackBar();
  const [ flightFares, setFlightFares ] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: {},
    error: null
  });

    useEffect(() => {
        const controller = axios.CancelToken.source();
        (async () => {

            try{
                setFlightFares(prev => ({ ...prev, isLoading: true, isError: false, isSuccess: false, data: [], error: null }))
                const response = await axios({
                    method: "get",
                    url: `${BASE_URL}/fetchSeasonalFares?id=${fareId}`,
                    cancelToken: controller.token,
                });
                
                if(response.status === 200){
                    return setFlightFares(prev => ({ ...prev, isLoading: false, isSuccess: true, data: response.data }))
                }

                return setFlightFares(prev => ({ ...prev, isLoading: false, isError: true, error: response.data }))
                
            }catch(error){
                if(error.code !== "ERR_CANCELED"){
                    console.log(error);
                    showSnackBar("error", t("Error happened while fetching seasonal fares"));
                    setFlightFares(prev => ({ ...prev, isLoading: false, isError: true, error: error.message }))
                }
            }
        })()
        
        return () => {
            controller.cancel();
        }

    },[]);

    let content;

    if(flightFares.isLoading){
        content = (
            <Stack
                sx={{
                   width: "100%",
                }}
            >
                <Lottie 
                    style={{
                        height: 200,
                        width: 400,
                        margin: "100px auto"
                    }}
                    loop={true}
                    play={true}
                    animationData={flightLoader}
                    rendererSettings= {{
                        preserveAspectRatio: "xMidYMid slice"
                    }}
                />
            </Stack>
        )
    }

    if(flightFares.isError){
        content = <Typography variant='h5' color="red" fontWeight="bold" textAlign="center">{flightFares.error}</Typography>
    }

    if(flightFares.isSuccess){
        content = flightFares.data.map((data) => (
            <Grid key={data.id} item xs={12} md={6} >
                <ServicesCard {...data}/>
            </Grid>
        ))
    }

  return (
    <Box
      sx={{ backgroundColor: "card.background", overflow: "hidden",}}
    >
     <HomeBanner bannerImage={homeBanner}/>
     <Box sx={{ backgroundColor: "card.background", px: {xs: 1, md: 15}, py: 7}}>
            <Stack textAlign="center">
                <Typography 
                    component={motion.h3} 
                    initial={{
                        opacity: 0,
                        y: 50
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: {
                        delay: 0.1,
                        damping: 2
                        }
                    }}
                    sx={{py: 2, fontWeight: "bold"}} 
                    variant='h3' 
                    color="primary.main"
                >
                    {state.fareTitle}
                </Typography>
                <Typography
                    component={motion.h3} 
                    initial={{
                        opacity: 0,
                        y: 50
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: {
                        delay: 0.1,
                        damping: 2
                        }
                    }}
                    sx={{fontWeight: "bold"}} 
                    variant='body2'
                >
                    Rates subject to change without notice (fuel increase, government taxes, etc.).For One Way Fares, contact us.
                </Typography>
            </Stack>
            <Stack py={5}>
                <Grid spacing={{xs: 2, md: 5}} container>
                    {content} 
                </Grid>
            </Stack>
     </Box>
   </Box>
  )
}

export default Services