import React from 'react'
import { WhiteCard } from '../../../Lib/MuiThemes/MuiComponents'
import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { conditionForSales, confidentialate } from '../../../Assests/assets';

function AgreeToTerms({ isTermsAndConditionAgreed, setTermsAndConditionAgreed }) {

  const { t } = useTranslation();

  return (
    <WhiteCard>
        <Stack>
            <FormGroup sx={{pl: 2}}>
                <FormControlLabel 
                    checked={isTermsAndConditionAgreed} 
                    onChange={() => setTermsAndConditionAgreed(!isTermsAndConditionAgreed)} 
                    control={<Checkbox />} 
                    label={<Typography variant='h6' color="text.main">{t("byCheckingThisBoxIDeclare")}: <Typography component="span" color="error">*</Typography></Typography>}
                />
            </FormGroup>
            <ul>
                <li>
                    <Typography variant='body1'>{t("haveReadThe")} <Typography sx={{ cursor: "pointer", textDecoration: "underline" }} variant='h6' component="a" onClick={() => window.open(conditionForSales)} color="error">{t("conditionForSales")}</Typography> {t("andAcceptThemWithoutReservation")}</Typography>
                </li>
                <li>
                    <Typography variant='body1'>{t("AcceptThatTheInformationEnteredWillBeUsedAsPartOfTheReservationAndTheCommercialRelationshipThatMayResultFromIt")}</Typography>
                </li>
            </ul>
            <Typography pl={5} variant='h6' color="text.main">{t("forMoreInformation")}: <Typography variant='h6' sx={{ cursor: "pointer", textDecoration: "underline" }} component="a" onClick={() => window.open(confidentialate)} color="error">{t("privacyPolicyAndCookies")}</Typography></Typography>
            <ul>
                <li>
                    <Typography variant='body1'>{t("haveCheckedThePoliceFormalitiesForTheVisitedAndTransitCountriesDependingOnTheNationalityOfTheTravelers")}</Typography>
                </li>
            </ul>
        </Stack>
    </WhiteCard>
  )
}

export default AgreeToTerms