import { Box, Grid, Modal, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import TopDestinationTourCard from './TopDestinationTourCard'
import ConnectWithUs from './ConnectWithUs'
import axios from 'axios'
import { Axios } from '../../Lib/Axios/AxiosConfig'
import { usePackageContextConsumer } from '../../Lib/Contexts/PackageListContext'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    boxShadow: 24,
};


function TopSellingDestinations() {

    const [ openConnectWithUsModal, setOpenConnectWithUsModal ] = useState(false);
    const [ topCity, setTopCity ] = useState([]);
    const [ packagesList ] = usePackageContextConsumer();

    useEffect(() => {
        const controller = axios.CancelToken.source();
        if(packagesList){
            (async () => {
                const [ topDestination ] = Object.keys(packagesList).filter(key => key.startsWith("Top") || key.startsWith("top"))
                try{
                  const response = await Axios({
                    method: "get",
                    url: `fetchPackages?packageName=${topDestination}`,
                    cancelToken: controller.token,
                    validateStatus: function (status) {
                      if(status >= 400){
                        // showSnackBar("error", `Error: ${status}, Unable to fetch packages, Please try again`);  
                        // setDataFetchError(true); 
                        throw new Error("Error");
                      }
                      return status;
                    }
                  });
                  
                  if(response.data && response.status === 200){
                    setTopCity(response.data);
                  }
                  
                }catch(error){
                //   setDataFetchError(true);
                //   showSnackBar("error", "Unable to fetch passenger details");
                  console.log(error);
                }
            })()
        }
          
    
        return () => {
          controller.cancel();
        }
    },[ packagesList ]);

  return (
    <>
    <Stack>
        <Stack textAlign="center" >
            <Typography 
                component={motion.h3} 
                initial={{
                    opacity: 0,
                    y: 50
                }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                    delay: 0.1,
                    damping: 2
                    }
                }}
                sx={{py: 2, fontWeight: "bold"}} 
                variant='h3' 
                color="primary.main"
            >
                Top Selling
            </Typography>
            <Typography
                component={motion.h3} 
                initial={{
                    opacity: 0,
                    y: 50
                }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                    delay: 0.1,
                    damping: 2
                    }
                }}
                sx={{fontWeight: "bold"}} 
                variant='body2'
            >
                Top Destinations
            </Typography>
        </Stack>
        <Box
            sx={{
                display: "flex",
                flexDirection: {xs: "column", md: "row"},
                alignItems: "center",
                justifyContent: "center",
                // border: "1px solid green",
                width: "auto",
                gap: 2,
                p: 5
            }} 
        >
            <Grid container rowSpacing={7} columnSpacing={10}>
                {topCity.map((tour, index) => (
                    <Grid key={index}  item xs={12} sm={6} md={4}>
                        <TopDestinationTourCard
                            tourName={tour.name} 
                            destinations={tour.destination}
                            duration={tour.duration}
                            packageName={"Top destination tour packages"}
                            packageId={"9"}
                            tourObj={tour}
                            setOpenConnectWithUsModal={setOpenConnectWithUsModal}
                            imageUrl={tour.images[0]}
                        />
                    </Grid>
                ))}
            </Grid>

        </Box>
    </Stack>
    <div>
        <Modal
        open={openConnectWithUsModal}
        onClose={() => setOpenConnectWithUsModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <ConnectWithUs openConnectWithUsModal={openConnectWithUsModal} setOpenConnectWithUsModal={setOpenConnectWithUsModal}/>
        </Box>
        </Modal>
    </div>
    </>
  )
}

export default TopSellingDestinations