import { Box, Grid, Stack, Typography } from '@mui/material'
import axios from 'axios';
import { flightLoader } from '../../../Assests/assets'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useSnackBar from '../../../Lib/CustomHooks/useSnackBar';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../../../Lib/Axios/AxiosConfig';
import Lottie from 'react-lottie-player';

function ListOfSeasonalFares() {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showSnackBar } = useSnackBar();
  const [ seasonalFares, setSeasonalFares ] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: {},
    error: null
  });

    useEffect(() => {
        const controller = axios.CancelToken.source();
        (async () => {

            try{
                setSeasonalFares(prev => ({ ...prev, isLoading: true, isError: false, isSuccess: false, data: [], error: null }))
                const response = await axios({
                    method: "get",
                    url: `${BASE_URL}/fetchSeasonalFareCategories`,
                    cancelToken: controller.token,
                });
                
                if(response.status === 200){
                    return setSeasonalFares(prev => ({ ...prev, isLoading: false, isSuccess: true, data: response.data }))
                }

                return setSeasonalFares(prev => ({ ...prev, isLoading: false, isError: true, error: response.data }))
                
            }catch(error){
                if(error.code !== "ERR_CANCELED"){
                    console.log(error);
                    showSnackBar("error", t("Error happened while fetching seasonal fares"));
                    setSeasonalFares(prev => ({ ...prev, isLoading: false, isError: true, error: error.message }))
                }
            }
        })()
        
        return () => {
            controller.cancel();
        }

    },[]);

    let content;

  if(seasonalFares.isLoading){
    content = (
        <Stack
            sx={{
              margin: "100px auto",
            }}
        >
            <Lottie 
                style={{
                    height: 200,
                    width: 400
                }}
                loop={true}
                play={true}
                animationData={flightLoader}
                rendererSettings= {{
                    preserveAspectRatio: "xMidYMid slice"
                }}
            />
        </Stack>
    )
  }

  if(seasonalFares.isError){
    content = <Typography variant='h5' color="red" fontWeight="bold" textAlign="center">{seasonalFares.error}</Typography>
  }

  if(seasonalFares.isSuccess){
    content = (
        seasonalFares.data.map((data) => (
            <Grid key={data.id} item xs={12} md={4}>
                <div
                    onClick={() => navigate(`seasonal-fares/${data.id}`, { state: { fareTitle: data.displayTitle } })}
                    className="card"
                    style={{
                        background: `url(${data.image})`,
                        backdropFilter: "brightness(40%)",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        cursor:  "pointer"
                        
                    }}
                >
                    <div className="card-details">
                        <Typography sx={{ fontWeight: "bold" }} variant='h4' color="text.white">{data.displayTitle.toUpperCase()}</Typography>
                    </div>
                </div>
            </Grid>
        ))
    )
  }

  return (
    <Box sx={{ flexGrow: 1, py: {xs: 3, md: 5} }}>
        <Grid container spacing={5}>
            {content}
        </Grid>
    </Box>
  )
}

export default ListOfSeasonalFares