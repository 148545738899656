import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import Quill from 'quill';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import { FlightLoaderWithTransparentBackdrop, WhiteCard } from '../../../../Lib/MuiThemes/MuiComponents';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { auth } from '../../../../Lib/Firebase/firebase.config';
import axios from 'axios';
import { BASE_URL } from '../../../../Lib/Axios/AxiosConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { useSelector } from 'react-redux';
import { BsFileEarmarkPlus } from 'react-icons/bs';
import useLoader from '../../../../Lib/CustomHooks/useLoader';
import { LoadingButton } from '@mui/lab';
import useSnackBar from '../../../../Lib/CustomHooks/useSnackBar';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom/dist';
import Compressor from "compressorjs";
import { nanoid } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import Preview from './Preview';


function CreateBlog() {

  const [ mode, setMode ] = useState("create-mode");
  const [ loading, setLoading ] = useState(false);
  const { blogId } = useParams();
  const { auth: userAuth } = useSelector(data => data.persistedReducer);
  const quillRef = useRef(null);
  const { account } = useSelector(data => data.persistedReducer); 
  const navigate = useNavigate();
  const [ isLoading, startLoading, restLoading ] = useLoader();
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();
  const [ data, setData ] = useState({
    image1: "",
    title: "Add Blog Title",
    description: "",
    tags: "",
    content: "",
    blogId: ""
  });
  const [ validate, setValidate ] = useState({
    image1: null,
    title: null,
    description: null,
    tags: null,
    content: null
  });

  useEffect(() => {
        const controller = axios.CancelToken.source();
        
        if(blogId !== "new"){
            (async () => {
                try{
                    // setBlogData(prev => ({ ...prev, isLoading: true, isError: false, isSuccess: false, data: [], error: null }))
                    const response = await axios({
                    method: "get",
                    url: `${BASE_URL}/fetchBlog?blogID=${blogId}`,
                    cancelToken: controller.token,
                    });
                    
                    if(response.status === 200){
                        setData({
                            image1: response.data.image1,
                            title: response.data.title,
                            description: response.data.description,
                            tags: response.data.tags,
                            content: response.data.content,
                            blogId: response.data.id
                        }) 
                    }
    
                    // return setBlogData(prev => ({ ...prev, isLoading: false, isError: true, error: response.data }))
                    
                }catch(error){
                    if(error.code !== "ERR_CANCELED"){
                        console.log(error);
                        // return setBlogData(prev => ({ ...prev, isLoading: false, isError: true, error: error.message }))
                    }
                }
            })()
        }
        

        return () => {
            controller.cancel();
        }

    },[ blogId ]);


  const fileCompressor = async (file) => {
    return new Compressor(file, {
        file: "File",
        quality: 0.5,
    })
  }

  const quillImageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file")
    input.setAttribute("accept", "image/*")
    input.click();
    input.onchange = async () => {
        setLoading(true);
        const file = input.files[0];
        const compressImage = await fileCompressor(file);
        console.log(compressImage);
        if(!compressImage.aborted){

            const formdata = new FormData();
            formdata.append("image", compressImage.file);
            formdata.append("index", "1");
            formdata.append("blogId", nanoid());

            const response = await axios({
                method: "post",
                url: `${BASE_URL}/uploadBlogImage`,
                maxBodyLength: Infinity,
                data: formdata
            });

            const imageUrl = response.data;

            const quill = quillRef.current.getEditor();
            const range = quill.getSelection();

            const value = `<div style="height: 600px; width: 400px;">
                <img src='${imageUrl}' alt='media'/>
            </div>`;

            quill.clipboard.dangerouslyPasteHTML(range.index, value)

            // quill.editor.insertEmbed(
            //   range.index,
            //   "image",
            //   imageUrl,
            //   Quill.sources.USER
            // );
            quill.setSelection(range.index + 1, Quill.sources.SILENT);
             
            setLoading(false);
        }
    }
   
  }, [])

  const modules = useMemo(() => ({
    toolbar: {
        container: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
        ],
        handlers: {
            'image': () => quillImageHandler()
        },
    }
  }), [ quillImageHandler ])

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  const handleChanges = (event) => {
    const { name, value } = event.target;
    
    setValidate(prev => ({ ...prev, [ name ]: null }))
    setData(prev => ({ ...prev, [ name ]: value }))
  }

  const handleContentChange = (val) => {
    setValidate(prev => ({ ...prev, content: null }))
    setData(prev => ({ ...prev, content: val }));
  }

  const handleValidation = () => {
    let isValidated = true;

    if(data.image1 === ""){
        isValidated = false;
        setValidate(prev => ({ ...prev, image1: t("Please add cover image") }))
    }

    if(data.content === ""){
        isValidated = false;
        setValidate(prev => ({ ...prev, content: t("Blog content is required") }))
    }

    if(data.tags === ""){
        isValidated = false;
        setValidate(prev => ({ ...prev, tags: t("Tags are required") }))
    }

    if(data.tags.split("#").length > 10){
        isValidated = false;
        setValidate(prev => ({ ...prev, tags: t("Tags must not exceed 10 keywords") }))
    }

    if(data.title === ""){
        isValidated = false;
        setValidate(prev => ({ ...prev, title: t("Blog title is required") }))
    }

    if(data.description === ""){
        isValidated = false;
        setValidate(prev => ({ ...prev, description: t("Description is required") }))
    }

    return isValidated
  }

  const publishBlog = () => {
    onAuthStateChanged(auth, async (user) => {
        if(user && handleValidation()){
        
          let endpoint = "createBlog";

          if(blogId && blogId !== "new" && blogId.length > 3){
            endpoint = "updateBlog"
          }

          try{
            startLoading();
            let formdata = new FormData();
            formdata.append('image1', data.image1);
            formdata.append('description', data.description.trim());
            formdata.append('title', data.title.trim());
            formdata.append('content', data.content.trim());
            formdata.append('author', account.name);
            formdata.append('tags', data.tags.trim());

            if(blogId && blogId !== "new" && blogId.length > 3){
                formdata.append('blogID', blogId);
            }

            const response = await axios({
              method: "post",
              url: `${BASE_URL}/${endpoint}`,
              maxBodyLength: Infinity,
              headers: {
                idToken: user.accessToken
              },
              data: formdata
            });

            console.log(response);

            if(response.status === 200){
                if(endpoint === "createBlog"){
                    showSnackBar("success", t("Blog created successfully"));
                }else{
                    showSnackBar("success", t("Blog updated successfully"));
                }
                navigate(`/${userAuth.role}/blogs`)
                return restLoading();
            }
            
            showSnackBar("error", `${response.data}`);
            return restLoading();
            
          }catch(error){
            if(error.code !== "ERR_CANCELED"){
              console.log(error);
              showSnackBar("error", `${error.message}`);
              return restLoading();
            }
          }
        }
      })
  }

  console.log(data)

  let content;

  if(mode === "create-mode"){
    content = (
        <WhiteCard>
            <Stack>
                <TextField
                    inputProps={{
                        style: {fontSize: 25, fontWeight: "bold", textAlign: "center"} 
                    }}
                    fullWidth
                    type="text"
                    variant='standard'
                    value={data.title}
                    onChange={handleChanges}
                    name="title"
                    InputProps={{
                        disableUnderline: !validate.title , // <== added this to disable border line
                    }}
                />
                {validate.title && <Typography variant='subtitle1' color="error" textAlign="center">{validate.title}</Typography>}
            </Stack>

            <Stack direction="row" py={3} spacing={3} alignItems="center">
                <Stack width="100%">
                    <TextField
                        inputProps={{
                            style: {fontSize: 15, color: "gray"} 
                        }}
                        fullWidth
                        placeholder='Add Description'
                        type="text"
                        variant='standard'
                        name="description"
                        value={data.description}
                        onChange={handleChanges}
                        // InputProps={{
                        //     disableUnderline: !validate.description, // <== added this to disable border line
                        // }}
                    />
                    {validate.description && <Typography variant='subtitle1' color="error">{validate.description}</Typography>}
                </Stack>

                <Stack width="100%">
                    <TextField
                        inputProps={{
                            style: {fontSize: 15, color: "gray"} 
                        }}
                        fullWidth
                        placeholder='Add tags'
                        type="text"
                        variant='standard'
                        name="tags"
                        value={data.tags}
                        onChange={handleChanges}
                        // InputProps={{
                        //     disableUnderline: !validate.tags , // <== added this to disable border line
                        // }}
                    />
                    {validate.tags && <Typography variant='subtitle1' color="error">{validate.tags}</Typography>}
                </Stack>
            </Stack>

            <Stack spacing={1} py={1}>
                <Typography fontWeight="bold" variant='body1' color="text.main">Add Cover Image</Typography>
                <Box
                    component="label"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        backgroundColor: "#F9F7FE",
                        border: "1px dashed gray",
                        borderRadius: 2,
                        cursor: "pointer",
                        minHeight: 200,
                        width: "100%",
                        "&:hover": { backgroundColor: "#F9F7FE" },
                        overflow: "hidden"
                    }}
                >
                    <TextField
                        sx={{display: "none"}}
                        name="image"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            setValidate(prev => ({...prev, image1: null}));
                            setData(prev => ({...prev, image1: e.target.files[0]}))
                        }}
                    />
                    <Box 
                        sx={{ 
                            height: "100%", 
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center" 
                        }}
                    >
                        {typeof data.image1 === "object" &&
                            <img 
                                style={{height: "100%", width: "100%", objectFit: "cover"}} 
                                src={URL.createObjectURL(data.image1)} 
                                alt="img" 
                            />
                            
                        } 
                        {typeof data.image1 === "string" && data.image1.length > 1 &&
                            <img 
                                style={{height: "100%", width: "100%", objectFit: "cover"}} 
                                src={data.image1} 
                                alt="img" 
                            />
                            
                        } 
                        {data.image1 === "" &&
                            <Box>
                                <BsFileEarmarkPlus/>
                                <Typography variant='body1' color="text.main">Drag and drop file or <span style={{fontWeight: "bold"}}>Browse through computer</span></Typography>
                            </Box> 
                        }
                    </Box>
                </Box>
                {validate.image1 && <Typography variant='subtitle1' color="error">{validate.image1}</Typography>}
            </Stack>

            <Box>
                <Typography fontWeight="bold" variant='body1' color="text.main" my={1}>Add Content</Typography>
                <ReactQuill value={data.content}
                    ref={quillRef}
                    placeholder="Add contents here..."
                    modules={modules}
                    formats={formats}
                    onChange={handleContentChange} 
                />
                {validate.content && <Typography variant='subtitle1' color="error">{validate.content}</Typography>}
            </Box>
        </WhiteCard>
    )
  }

  if(mode === "preview-mode"){
    content = <Preview data={data} setMode={setMode}/>
  }

  let loader;

  if(loading){
    loader = <FlightLoaderWithTransparentBackdrop/>
  }

  return (
    <Stack
      minHeight="100vh"
    >
        {loader}
        <Stack pb={2} direction="row" justifyContent="space-between">
            <Stack
                sx={{
                    width: { xs: "100%", md: "60%" },
                    mx: "auto"
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    pb={3}
                >
                    <Typography fontWeight="bold" variant='h4' color="primary.main">CREATE BLOG</Typography>
                    <Stack direction="row" spacing={2}>
                        {mode === "create-mode" ?
                            <Button onClick={() => setMode("preview-mode")} variant='outlined'>Preview</Button> :
                            <Button onClick={() => setMode("create-mode")} variant='outlined'>Back</Button>
                        }
                        <LoadingButton loading={isLoading} variant='contained' onClick={publishBlog}>Publish</LoadingButton>
                    </Stack>
                </Stack>
                {content}
            </Stack>
        </Stack>
    </Stack>
  )
}

export default CreateBlog