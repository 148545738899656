import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import TourCarousel from './TourCarousel'
import ConnectWithUs from './ConnectWithUs'
import { ImLocation2 } from "react-icons/im"
import { FaHotel } from 'react-icons/fa'
import { MdOutlineCameraEnhance } from 'react-icons/md'
import { GiHotMeal } from 'react-icons/gi'
import { AiFillCar } from 'react-icons/ai'
import { BsBasketFill, BsFillPeopleFill } from 'react-icons/bs'
import { GoClock } from 'react-icons/go'
import { ExpandMore } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

function TourDetails() {

  const { state: tourObj } = useLocation();
  const [ expandAccordian, setExpandAccordian ] = useState("panel0");
  const handleAccordian = (panel, newExpanded) => {
    setExpandAccordian(newExpanded ? panel : false);
  }
  // console.log(tourObj);
  const itineraryObject = tourObj?.itinerary?.map((iti, itiIndex) => ({itineraryName: iti, itineraryDescription: tourObj.itineraryDescription[itiIndex]}));

  let itineraryElements = itineraryObject.map((obj, i) => (
    <Stack key={i} spacing={3} sx={{boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", borderRadius: 3, overflow: "hidden"}}>
      <Accordion 
          expanded={expandAccordian === `panel${i}`} 
          elevation={0} 
          sx={{p: {xs: 0, md: 2}}}
          onChange={(e, newExpanded) => handleAccordian(`panel${i}`, newExpanded)}
      >
          <AccordionSummary
              expandIcon={
              <IconButton sx={{backgroundColor: "text.white", boxShadow: "0px 6px 16px rgba(8, 15, 52, 0.08)"}}>
                  <ExpandMore />
              </IconButton>}
          >
              <Stack spacing={2}>
                  <Typography variant='h6' fontWeight="bold">{obj.itineraryName}</Typography>
              </Stack>
          </AccordionSummary>
          <AccordionDetails sx={{wordBreak: "break-word"}}>
                <Stack>
                  <Typography variant='h6'>
                    {obj.itineraryDescription}
                  </Typography>
                </Stack>
          </AccordionDetails>
      </Accordion>
    </Stack>
  ))

  return (
    <Box 
      sx={{ 
        backgroundColor: "card.background", 
        px: {xs: 1, md: 15},
        display: "flex",
        flexDirection: "column",
        p: 2,
        gap: 3,
        position: "relative",
    
      }}
    >
      <Stack spacing={1}>
        <Stack>
          <Typography fontWeight="bold" fontSize={24} variant='h4' color="text.main">{tourObj.name}</Typography>
        </Stack>
        <Stack direction={{xs:"column",md:"row"}} alignItems="center" spacing={{xs:1,md:5}}>
          <Stack color="text.light" direction="row" alignItems="center" spacing={1}>
            <GoClock/>
            <Typography fontWeight="bold" variant='h6' >{tourObj.duration[0]} / {tourObj.duration[1]}</Typography>
          </Stack>
          <Stack color="text.light" direction="row" alignItems="center" spacing={1}>
            <ImLocation2/>
            <Typography fontWeight="bold" variant='h6' >{tourObj.destination.join(" - ")}</Typography>
        </Stack>
        </Stack>
      </Stack>
      <Box sx={{ 
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
         
      }}
      >
          <Stack sx={{ width:{xs:"100%",md:"68%"}}} pb={5} >
              {!!tourObj.images.length && <TourCarousel carouselImgArray={tourObj.images}/>}
              <Stack py={2}>
                <Stack 
                  color="primary.main" 
                  direction="row" 
                  justifyContent="space-between" 
                  alignItems="center" 
                  spacing={1}
                  px={1}
                  py={2}
            
                >
                  <Stack alignItems="center" color="text.light">
                      <FaHotel fontSize={{xs:16,md:25}}/>
                      <Typography variant='subtitle2'>Hotel</Typography>
                  </Stack>
                  <Stack alignItems="center" color="text.light">
                      <MdOutlineCameraEnhance fontSize={{xs:16,md:25}}/>
                      <Typography variant='subtitle2'>Sightseeing</Typography>
                  </Stack>
                  <Stack alignItems="center" color="text.light">
                      <GiHotMeal fontSize={{xs:16,md:25}}/>
                      <Typography variant='subtitle2'>Meals</Typography>
                  </Stack>
                  <Stack alignItems="center" color="text.light">
                      <AiFillCar fontSize={{xs:16,md:25}}/>
                      <Typography variant='subtitle2'>Transport</Typography>
                  </Stack>
                  <Stack alignItems="center" color="text.light">
                      <BsFillPeopleFill fontSize={{xs:16,md:25}}/>
                      <Typography variant='subtitle2'>8People</Typography>
                  </Stack>
                  <Stack alignItems="center" color="text.light">
                      <BsBasketFill fontSize={{xs:16,md:25}}/>
                      <Typography variant='subtitle2'>Daily tour</Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack spacing={1} py={1}>
                <Typography fontWeight="bold" fontSize={24} variant='h4' color="text.main">Description</Typography>
                <Typography variant='h6'>
                  {tourObj.tourDescription}
                </Typography>
              </Stack>
              <Stack spacing={2} py={1}>
                <Typography fontWeight="bold" fontSize={24} variant='h4' color="text.main">Itinerary</Typography>
                {itineraryElements}
              </Stack>
          </Stack>
          <Stack width="30%" display={{xs:"none",md:"flex"}}>
              <ConnectWithUs></ConnectWithUs>
          </Stack>
      </Box>
    </Box>
  )
}

export default TourDetails