import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { BiMessageAltError } from "react-icons/bi";
import { AiFillQuestionCircle } from "react-icons/ai";
import { LightTooltip } from '../../../Lib/MuiThemes/MuiComponents';
import { FormattedNumber } from 'react-intl';
import { useTranslation } from 'react-i18next';

function TravellerNotice({ bookingDetailsData }) {

  const { adultRefund, childRefund, infantRefund, currency } = bookingDetailsData;
  const { t } = useTranslation();

  return (
    <Stack 
        sx={{
            height: "auto",
            p: 2,
            backgroundColor: "veryLightBlue.main", 
            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", 
            borderRadius: 3, 
            overflow: "hidden"
        }}
        spacing={1}
    >
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="left">
            <BiMessageAltError style={{ fontSize: 25 }}/>
            <Typography variant='body1' color="text.main" fontWeight="bold">
                {t("makeSurePassengerNamesAreCorrectAnyChangesWillIncurCharges")}
            </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1} justifyContent="left">
            <LightTooltip 
                title={
                    <Box>
                        <Typography fontWeight="bold">Please do not indicate “ep”, “wife”. <br /> <br /> For married women, you can use the maiden name or married name if it is mentioned in the passport. </Typography>
                        <br />
                        <Typography fontWeight="bold">If you need a visa or travel authorization, it is important to provide the same information as that on the transport ticket.</Typography>
                    </Box>
                } 
                placement='top'>
                <Box>
                    <AiFillQuestionCircle style={{ fontSize: 20, paddingLeft: 4 }}/>
                </Box>
            </LightTooltip>
            <Typography>{t("pleaseIndicateTheFirstAndLastNameWithoutAccentAsTheyAppearOnTheIdentityDocumentsWithWhichPassengersWillTravel")}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1} justifyContent="left">
            <LightTooltip 
                title={
                    <Box>
                        <Typography fontWeight="bold">If you are French, you can check police and health formalities on:</Typography>
                        <br />
                        <Typography component="a" href='https://www.diplomatie.gouv.fr/fr/conseils-aux-voyageurs/'>https://www.diplomatie.gouv.fr/fr/conseils-aux-voyageurs/</Typography>
                        <br />
                        <Typography component="a" href='https://www.pasteur.fr/fr/centre-medical/preparer-son-voyage'>https://www.pasteur.fr/fr/centre-medical/preparer-son-voyage</Typography>
                        <br />
                        <br />
                        <Typography fontWeight="bold">For other nationalities, please contact the embassy of the country of travel destination, including transit countries if applicable.</Typography>
                    </Box>
                } 
                placement='top'>
                <Box>
                    <AiFillQuestionCircle style={{ fontSize: 20, paddingLeft: 4 }}/>
                </Box>
            </LightTooltip>
            <Typography>{t("weAdviseYouToCheckTheValidityOfIdentityDocumentsAndAdministrativeFormalitiesForTheTravelDestinationsAsWellAsTheTransitCountries")}</Typography>
        </Stack>
        {/* <Stack spacing={1} justifyContent="left">
            <Stack direction="row" alignItems="center" spacing={1}>
                <BiMessageAltError style={{ fontSize: 25 }}/>
                <Typography variant='body1' color="text.main" fontWeight="bold">Refundable taxes</Typography>
            </Stack>
            <ul>
                {adultRefund && <li>
                    <Typography>for adult : &nbsp; <FormattedNumber value={adultRefund} currency={currency} style='currency' /></Typography>
                </li>}
                {childRefund && <li>
                    <Typography>for child : &nbsp; <FormattedNumber value={childRefund} currency={currency} style='currency' /></Typography>
                </li>}
                {infantRefund && <li>
                    <Typography>for infant : &nbsp; <FormattedNumber value={infantRefund} currency={currency} style='currency' /></Typography>
                </li>}
            </ul>
        </Stack> */}
    </Stack>
  )
}

export default TravellerNotice