import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { aboutUsImage } from '../../Assests/assets'

function AboutUs() {

  const { t } = useTranslation();

  return (
    <>
    <Box  
        component={motion.div}
        initial={{
            opacity: 0,
            y: 100
        }}
        whileInView={{
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.2,
                damping: 2
            }
        }}
        viewport={{once: false}}
        sx={{
            mt: {xs: -10, md: -35},
           
        }}
    >
        <Stack textAlign="center">
            <Typography
                component={motion.h2} 
                initial={{
                    opacity: 0,
                    y: 50
                }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      delay: 0.1,
                      damping: 2
                    }
                }}
                sx={{fontWeight: "bold"}} 
                variant='body2'
            >
                {t("parisToIndiaCheapTickets")}
            </Typography>
            <Typography 
                component={motion.h1} 
                initial={{
                    opacity: 0,
                    y: 50
                }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                    delay: 0.1,
                    damping: 2
                    }
                }}
                sx={{py: 2, fontWeight: "bold"}} 
                variant='h3' 
                color="primary.main"
            >
                {t("parisTravelAgency")}
            </Typography>
        </Stack>

        <Stack
            sx={{
                display: "flex", 
                flexDirection: {xs: "column", lg: "row"}, 
                gap: {xs: 5, md: 7}, 
                px: {xs: 1, md: 15},
                // border: "1px solid black", 
                py: 5,
            }}
        >
            <Box 
                sx={{
                    height: {xs: 250, sm: 300}, 
                    width: {xs: "100%", md: "50%"}, 
                    overflow: "hidden",
                    borderRadius: 4,
                    boxShadow: "4px 4px 30px rgba(0, 0, 0, .2)"
                }}
            >
                <img style={{height: "100%", width: "100%", objectFit: "cover"}} src={aboutUsImage} alt="img" />
            </Box>
        
            <Stack width={{xs: "100%", md: "50%"}}>
                <Typography textAlign={{xs: "center", md: "left"}} sx={{fontWeight: "bold", pb: 2}} variant='h3' color="primary.main">{t("aboutUs")}</Typography>

                <Typography sx={{lineHeight: "30px"}} variant='h5' color="text.main" >
                    {t("ourTravelAgency")} <Typography sx={{fontWeight: "bold"}} component="span" variant='h5' color="primary.main">ERRANCES VOYAGES</Typography> {t("aimsToOfferYouFlights")}
                </Typography>
            </Stack>
        </Stack>
    </Box>
    </>
  )
}

export default AboutUs