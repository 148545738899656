import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CustomThemeProvider from './Lib/MuiThemes/ThemeProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import "./Lib/I18n/i18nConfig";
import 'dayjs/locale/fr';
import { HelmetProvider } from 'react-helmet-async';
import { FRONTEND_URL } from './Lib/Axios/AxiosConfig';
import updateLocale from "dayjs/plugin/updateLocale";
import dayjs from "dayjs";
import { getI18n } from 'react-i18next'; 


//Redux
import { Provider } from 'react-redux';
import {store} from "./Lib/Redux/Store";

const helmetContext = {}

const root = ReactDOM.createRoot(document.getElementById('root'));
const href = window.location.href

console.log(href);
console.log(FRONTEND_URL);

if (getI18n().language !== "fr" && getI18n().language !== "en") {
  getI18n().changeLanguage("fr")
}

if(href === FRONTEND_URL){
  window.location.href = `${FRONTEND_URL}${getI18n().language}/home`
}

dayjs.extend(updateLocale);

dayjs.updateLocale("fr", {
  weekStart: 0
});

root.render(
  <React.StrictMode>
    <Router basename={`/${getI18n().language}`}>
      <Provider store={store}>
          <CustomThemeProvider>
            <HelmetProvider context={helmetContext}>
              <App />
            </HelmetProvider>
          </CustomThemeProvider>
      </Provider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
