import { Avatar, Box, Chip, Divider, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { backgroundPatternImg, flightLoader } from '../../Assests/assets'
import { WhiteCard } from '../../Lib/MuiThemes/MuiComponents'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../../Lib/Axios/AxiosConfig'
import Lottie from 'react-lottie-player'
import dayjs from 'dayjs'
import relativeTime from "dayjs/plugin/relativeTime"

function Blog() {

  dayjs.extend(relativeTime)

  const { blogId } = useParams();
  const [ blogData, setBlogData ] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: {},
    error: null
  });

  useEffect(() => {
      const controller = axios.CancelToken.source();
        
      (async () => {
        try{
          setBlogData(prev => ({ ...prev, isLoading: true, isError: false, isSuccess: false, data: [], error: null }))
          const response = await axios({
            method: "get",
            url: `${BASE_URL}/fetchBlog?blogID=${blogId}`,
            cancelToken: controller.token,
          });
          
          if(response.status === 200){
            return setBlogData(prev => ({ ...prev, isLoading: false, isSuccess: true, data: response.data }))
          }

          return setBlogData(prev => ({ ...prev, isLoading: false, isError: true, error: response.data }))
          
        }catch(error){
          if(error.code !== "ERR_CANCELED"){
            console.log(error);
            return setBlogData(prev => ({ ...prev, isLoading: false, isError: true, error: error.message }))
          }
        }
      })()
        

      return () => {
        controller.cancel();
      }
  },[ blogId ]);

  let content;

  if(blogData.isLoading){
    content = (
        <Stack
            sx={{
              margin: "100px auto",
            }}
        >
            <Lottie 
                style={{
                    height: 200,
                    width: 400
                }}
                loop={true}
                play={true}
                animationData={flightLoader}
                rendererSettings= {{
                    preserveAspectRatio: "xMidYMid slice"
                }}
            />
        </Stack>
    )
  }

  if(blogData.isError){
    content = <Typography variant='h5' color="red" fontWeight="bold" textAlign="center">{blogData.error}</Typography>
  }

  if(blogData.isSuccess){

    const blogContentObj = { __html: blogData.data.content }

    const BlogContent = () =>{
      return <div dangerouslySetInnerHTML={blogContentObj} />
    }

    content = (
      <WhiteCard>
          <Stack py={1} spacing={1}>
            <Typography variant='h3' fontWeight="bold">{blogData.data.title}</Typography>
            <Typography variant='body2'>{blogData.data.description}</Typography>
          </Stack>

          <Divider/>

          <Stack pt={2} direction="row" alignItems="center" gap={2}>
            <Avatar sx={{ width: 56, height: 56 }} alt={blogData.data.author} src="" />
            <Stack>
              <Typography variant='h6'>{blogData.data.author}</Typography>
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography variant='body2'>{dayjs(blogData.data.updatedOn).fromNow()}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" mt={2} flexWrap="wrap" gap={1}>
            {blogData.data.tags.split("#").map((tag, index) => {
                if(tag.trim() !== ""){
                    return <Chip key={`tag${index}`} label={`#${tag.trim()}`} variant="outlined" />
                }
            })}
          </Stack>

          <Stack pt={2}>
            <img src={blogData.data.image1} alt="coverimage" />
          </Stack>

          <Stack className='blog-div' mt={1} overflow="hidden">
            <BlogContent/>
          </Stack>
      </WhiteCard>
    )
  }
  
  return (
    <Box
        sx={{ 
          background: `none, url('${backgroundPatternImg}')`,
          backgroundRepeat: "repeat",
          backgroundSize: "700px", 
          overflow: "hidden",
          minHeight: "100vh"
        }}
      >
        <Stack width={{ xs: "100%", md: "50%" }} mx="auto" py={3}>
          {content}
        </Stack>
    </Box>
  )
}

export default Blog