import React, { Fragment, useEffect, useState } from 'react'
import { ExpandMore } from '@mui/icons-material'
import {  Button, List, ListItemButton, Stack, Typography } from '@mui/material'
import { ReuseMenu } from '../../../Lib/MuiThemes/MuiComponents'
import useMenu from '../../../Lib/CustomHooks/useMenu';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Axios } from '../../../Lib/Axios/AxiosConfig';
import { BsDot } from 'react-icons/bs';
import { usePackageContextConsumer } from '../../../Lib/Contexts/PackageListContext';

function PackagesMenu() {

  const navigate = useNavigate();
//   const [ packageRoutes, setPackageRoutes ] = useState([]);
  const [ packagesList, setPackagesList ] = usePackageContextConsumer(); 
  const [ loading, setLoading ] = useState(false);
  const { menu, openMenu, closeMenu} = useMenu(); 
  const { t } = useTranslation();

//   console.log(packagesList);

  useEffect(() => {
    const controller = axios.CancelToken.source();
    (async () => {
        try{
            setLoading(true);
            const response = await Axios({
                url: "fetchPackagesList",
                method: "get",
                cancelToken: controller.token,
                validateStatus: function (status) {
                  if(status >= 400){
                    throw new Error("Error");
                  }
                  return status;
                }
            });
            if(response.status === 200){
              setPackagesList(response.data);  
              setLoading(false)
            }
        }catch(error){
          console.log(error); 
          setLoading(false); 
        }
    })()

    return () => controller.cancel();
  },[]);


  return (
    <>
        <Button 
            onClick={openMenu} 
            disableRipple
            sx={{
                color: "primary.main",
                "&:hover": {
                    color: "red"
                },
            }}>
            <Typography variant='body1' fontWeight="bold">
                {t("packages")}
            </Typography>
            <ExpandMore/>
        </Button>
        <ReuseMenu
            menu={menu}
            closeMenu={closeMenu}
        >
            <Stack>
                <List sx={{
                    height: 400,
                    width: 700,
                    maxWidth: 1000,
                    p: 1,
                    display: "flex",
                    gap: "0 10px",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    // overflow: "hidden",
                    textAlign: "left",
                    '&::-webkit-scrollbar': {
                        height: "7px",
                        // padding: "10px"
                    },
                    '&::-webkit-scrollbar-track': {
                        // boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        // webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        backgroundColor: "text.light",

                        
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'primary.main',
                        borderRadius: 2
                        // outline: '3px solid #FAFAFA',
                    }
                }}>
                    {Object.keys(packagesList).map((pack, i) => {
                        return(
                            <Fragment key={i}>
                                {!!packagesList[pack].length && 
                                    <>
                                        <Stack>
                                            <ListItemButton
                                                onClick={() => navigate(`packages/tour?package=${pack}`, { state: { packageName: pack } })} 
                                                sx={{
                                                    // px: 3, 
                                                    px: 1,
                                                    py: 0,
                                                    borderRadius: 2,
                                                    "&:hover": {
                                                        backgroundColor: "primary.main",
                                                        color: "text.white"
                                                    }
                                                }}
                                            >
                                                <Typography fontWeight="bold" variant='body1'>{pack}</Typography>
                                            </ListItemButton>
                                        </Stack>
                                        {!!packagesList[pack].length && packagesList[pack].map((options, index) => {
                                            return(
                                                <Stack key={`package${index}`}>
                                                    <ListItemButton
                                                        onClick={() => navigate(`packages/tour?package=${pack}&country=${options}`, { state: { packageName: pack, country: options } })}  
                                                        sx={{
                                                            // px: 3,
                                                            p: 0,
                                                            borderRadius: 2,
                                                            "&:hover": {
                                                                backgroundColor: "primary.main",
                                                                color: "text.white"
                                                            }
                                                        }}
                                                    >
                                                        <BsDot/>
                                                        <Typography ml={2} variant='body1'>{options}</Typography>
                                                    </ListItemButton>
                                                </Stack>
                                            )
                                        })}   
                                    </>
                                }                     
                            </Fragment>
                        )
                    })}
                    <Stack>
                        <ListItemButton 
                            onClick={() => navigate(`/packages`, { state: packagesList })} 

                            sx={{
                                p: 0,
                                borderRadius: 2,
                                "&:hover": {
                                    backgroundColor: "primary.main",
                                    color: "text.white",
                                }
                            }}
                        >
                            <Typography fontWeight="bold" variant='body1'>{t("viewAllPackages")}</Typography>
                        </ListItemButton>
                    </Stack>
                </List>
            </Stack>
        </ReuseMenu>
    </>
  )
}

export default PackagesMenu