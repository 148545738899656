import React from 'react'
import { GrayCard } from '../../Lib/MuiThemes/MuiComponents'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { MdOutlineAirplaneTicket } from 'react-icons/md'
import { FiBriefcase } from 'react-icons/fi'
import { BsCalendar3, BsFillPeopleFill } from 'react-icons/bs'

function ServicesCard(props) {
  return (
    <GrayCard elevation={0} sx={{border: "1px solid rgba(0, 0, 0, 0.4)", p: {xs: 1, md: 2}}}>
        <Stack direction="row" spacing={3}>
            <Box sx={{height: {xs: 75, md: 100}, width: {xs: 100, md: "auto"}, overflow: "hidden", borderRadius: 3}}>
                <img style={{height: "100%", width: "100%", objectFit: "cover"}} src={props.image} alt="img" />
            </Box>
            <Stack spacing={1}>
                <Typography variant='h5' color="text.main">{props.airlinesName}</Typography>
                <Grid rowGap={1} container>
                    <Grid item xs={6} >
                        <Stack 
                            direction="row"  
                            alignItems="center" 
                            spacing={2}
                        >
                            <Box sx={{fontSize: 20, color: "primary.main"}}>
                                <BsCalendar3/>
                            </Box>
                            <Typography color="text.main" variant='body1'>{props.departure}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} >
                        <Stack 
                            direction="row" 
                            alignItems="center"
                            spacing={2}
                        >
                            <Box sx={{fontSize: 20, color: "primary.main"}}>
                                <MdOutlineAirplaneTicket />
                            </Box>
                            <Typography color="text.main" variant='body1'>{props.validity} months</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} >
                        <Stack 
                            direction="row" 
                            alignItems="center"
                            spacing={2}
                        >
                            <Box sx={{fontSize: 20, color: "primary.main"}}>
                                <BsFillPeopleFill />
                            </Box>
                            <Typography color="text.main" variant='body1'>{props.priceForAdult} €</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} >
                        <Stack 
                            direction="row" 
                            alignItems="center"
                            spacing={2}
                        >
                            <Box sx={{fontSize: 20, color: "primary.main"}}>
                                <FiBriefcase/>
                            </Box>
                            <Typography color="text.main" variant='body1'>{props.baggage} Kg</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Stack>
    </GrayCard>
  )
}

export default ServicesCard