import { Chip, Divider, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import relativeTime from "dayjs/plugin/relativeTime"
import { WhiteCard } from '../../../../Lib/MuiThemes/MuiComponents'
import "../../../../App.css";

function Preview({ data, setMode }) {

    dayjs.extend(relativeTime)


    const blogContentObj = { __html: data.content }

    const BlogContent = () =>{
        return <div dangerouslySetInnerHTML={blogContentObj} />
    }
  
  return (
    <WhiteCard>
        <Stack py={1} spacing={1}>
            <Typography variant='h3' fontWeight="bold">{data.title}</Typography>
            <Typography variant='body2'>{data.description}</Typography>
        </Stack>

        <Divider/>

        <Stack py={2}>
            <Stack direction="row" flexWrap="wrap" gap={1}>
                {data.tags.split("#").map((tag, index) => {
                    if(tag.trim() !== ""){
                        return <Chip sx={{ width: "fit-content" }} key={`tag${index}`} label={`#${tag.trim()}`} variant="outlined" />
                    }
                })}
            </Stack>
        </Stack>

        <Stack pt={2}>
            {typeof data.image1 === "object" &&
                <img 
                    style={{height: "100%", width: "100%", objectFit: "cover"}} 
                    src={URL.createObjectURL(data.image1)} 
                    alt="coverimage" 
                />
                
            } 
            {typeof data.image1 === "string" && data.image1.length > 1 &&
                <img 
                    style={{height: "100%", width: "100%", objectFit: "cover"}} 
                    src={data.image1} 
                    alt="coverimage" 
                />
                
            } 
        </Stack>

        <Stack className='blog-div' mt={1} overflow="hidden">
            <BlogContent/>
        </Stack>
    </WhiteCard>
  )
}

export default Preview