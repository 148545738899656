import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { FullScreenLoader } from '../../Lib/MuiThemes/MuiComponents';
import BookingOptions from './BookingDetailsFragments/BookingOptions';
import TravellerDetailsCard from './BookingDetailsFragments/TravellerDetailsCard';
import SendBookingDetailsTo from './BookingDetailsFragments/SendBookingDetailsTo';
import FareSummary from './BookingDetailsFragments/FareSummary';
import axios from 'axios';
import { BASE_URL } from '../../Lib/Axios/AxiosConfig';
import BookingDetailsCard from './BookingDetailsFragments/BookingDetailsCard';
import useSnackBar from '../../Lib/CustomHooks/useSnackBar';
import { useLocation } from 'react-router-dom';
import AddBaggage from './BookingDetailsFragments/AddBaggage';
import TravelInsuranceOptions from './BookingDetailsFragments/TravelInsuranceOptions';
import TravellerNotice from './BookingDetailsFragments/TravellerNotice';
import AgreeToTerms from './BookingDetailsFragments/AgreeToTerms';
import { useTranslation } from 'react-i18next';
import { failedGifBookMyself, noFlightsFound } from '../../Assests/assets';
import Lottie from 'react-lottie-player';

function BookingDetails() {

    const { t } = useTranslation();
    const { state: flightPricePayload } = useLocation();
    const [ bookingType, setBookingType ] = useState("");
    const [ travelInsurance, setTravelInsurance ] = useState({ fee: 0, type: null });
    const [ isTermsAndConditionAgreed, setTermsAndConditionAgreed ] = useState(false);
    const { showSnackBar } = useSnackBar();
    const [ bookingDetailsData, setBookingDetailsData ] = useState({
        data: {},
        isLoading: false,
        isError: false,
        isSuccess: false,
        errorMessage: ""
    });


    useEffect(() => {
        const controller = axios.CancelToken.source();
        (async () => {
            try{
                setBookingDetailsData(prev => ({...prev, isLoading: true, isError: false, isSuccess: false, data: {}}))
                const response = await axios({
                    method: "post",
                    url: `${BASE_URL}/fetchFlightOffersPrice`,
                    cancelToken: controller.token,
                    data: { ...flightPricePayload, system: "web" },
                });
                
                if(response.status === 200){
                    setBookingDetailsData(prev => ({
                        ...prev,
                        data: response.data,
                        isLoading: false,
                        isSuccess: true
                    }));
                }

            }catch(error){
                if(!axios.isCancel(error)){
                    setBookingDetailsData(prev => ({
                        ...prev,
                        isError: true,
                        isLoading: false,
                        isSuccess: false,
                        errorMessage: error.response.data
                    }))
                    console.log(error);
                }
            }
        })();

        return () => controller.cancel();
    }, [ flightPricePayload ]);

    /* render elements */
    let jsx;

    if(bookingDetailsData.isLoading){
        jsx = <FullScreenLoader/>
    }

    if(bookingDetailsData.isError){
        jsx = (
            <Box sx={{
                minHeight: "100vh",
                width: "auto",
                display: "flex",
                flexDirection: {xs: "column", md: "row"},
                backgroundColor: "common.background",
                gap: 3,
                px: {
                    xs: 1,
                    md: 10
                },
                py: 5,
            }}>
                <Stack m="0 auto">
                    <Lottie 
                        style={{
                            height: 300,
                            width: 500,
                        }}
                        loop={true}
                        play={true}
                        animationData={noFlightsFound}
                        rendererSettings= {{
                            preserveAspectRatio: "xMidYMid slice"
                        }}
                    />
                    <Typography variant="h5" color="text.main" textAlign="center" mr={5}>{bookingDetailsData.errorMessage}</Typography>
                </Stack>
            </Box>
        )
    }

    if(bookingDetailsData.isSuccess){
        jsx = (
            <Box sx={{
                height: "auto",
                width: "auto",
                display: "flex",
                flexDirection: {xs: "column", md: "row"},
                backgroundColor: "common.background",
                gap: 3,
                px: {
                    xs: 1,
                    md: 10
                },
                py: 5,
            }}>
                <Box 
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        width: { xs: "100%", md: "70%" }
                    }}

                >
                    <Stack direction="column" spacing={2} >
                        {bookingDetailsData?.data?.display.map((displayDetails, i) => (
                            <BookingDetailsCard 
                                key={i}
                                index={i}
                                fareDetails={bookingDetailsData.data.detailedFares} 
                                bookingDetails={displayDetails}
                                from={bookingDetailsData.data.from}
                                fromCity={bookingDetailsData.data.fromCity}
                                fromCountry={bookingDetailsData.data.fromCountry}
                                to={bookingDetailsData.data.to}
                                toCity={bookingDetailsData.data.toCity}
                                toCountry={bookingDetailsData.data.toCountry}
                                totalPassengers={bookingDetailsData.data.totalPassengers}
                                includedBaggages={bookingDetailsData.data.included}
                            />
                        ))}
                    </Stack>
                    
                    <AddBaggage bookingDetailsData={bookingDetailsData.data}/>

                    <TravellerNotice
                        bookingDetailsData={bookingDetailsData.data}
                    />

                    {/* {bookingDetailsData.isSuccess && <SegmentSeatSelectionCard segmentDetails={bookingDetailsData.data} />} */}
                    
                    <TravellerDetailsCard 
                        bookingDetailsData={bookingDetailsData.data}
                    />

                    <BookingOptions 
                        bookingType={bookingType} 
                        setBookingType={setBookingType} 
                        payingAmount={bookingDetailsData.data.totalFare}
                        serviceCharge={bookingDetailsData.data.serviceCharge} 
                        currency={bookingDetailsData.data.currency} 
                    />
                    <TravelInsuranceOptions 
                        data={bookingDetailsData.data}
                        currency={bookingDetailsData.data.currency}
                        travelInsurance={travelInsurance}
                        setTravelInsurance={setTravelInsurance}
                    />

                    <SendBookingDetailsTo/>

                    <AgreeToTerms
                        isTermsAndConditionAgreed={isTermsAndConditionAgreed}
                        setTermsAndConditionAgreed={setTermsAndConditionAgreed}
                    />

                </Box>
                <Box
                    sx={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        gap: 3,
                        width: { xs: "100%", md: "30%" },
                    }}
                   
                >
                    <FareSummary 
                        bookingType={bookingType} 
                        fareSummaryDetails={bookingDetailsData.data}
                        travelInsurance={travelInsurance}
                        isTermsAndConditionAgreed={isTermsAndConditionAgreed}
                    />

                </Box>
            </Box>
            
        )
    }
  

    return jsx
}

export default BookingDetails