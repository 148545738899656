import React, { useEffect, useState } from 'react'
import { BlueButton, ReuseMenu } from '../../Lib/MuiThemes/MuiComponents'
import { Box, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Stack } from '@mui/material'
import useMenu from '../../Lib/CustomHooks/useMenu'
import { useTranslation } from 'react-i18next';
import useLanguageConsumer from '../../Lib/CustomHooks/useLanguageConsumer';
import { useLocation, useNavigate } from 'react-router-dom';
import { FRONTEND_URL } from '../../Lib/Axios/AxiosConfig';

function ChooseLanguage() {

  const location = useLocation();
  const navigate = useNavigate();
  const [ languageCountryCurrency, setLanguageCountryCurrency ] = useState({
    language: "",
    country: "",
    code: "",
    currency: "",
    currencySymbol: ""
  });
  const { menu, openMenu, closeMenu } = useMenu();
  const { language, setLanguage, country, setCountry } = useLanguageConsumer();
  const { t, i18n } = useTranslation();

//   console.log(location.pathname);
//   console.log(window.location.href);

  useEffect(() => {
    setLanguageCountryCurrency({
        language: i18n.language,
        country: country.country,
        code: country.code,
        currency: country.currency,
        currencySymbol: country.currencySymbol
    })
  }, [ language, country, i18n.language ]);

  const applySettings = () => {

    setLanguage(languageCountryCurrency.language);
    setCountry(prev => ({...prev,
        country: languageCountryCurrency.country,
        code: languageCountryCurrency.code,
        currency: languageCountryCurrency.currency,
        countrySymbol: languageCountryCurrency.currencySymbol,
    }));
    window.location.href = FRONTEND_URL;
    navigate(location.pathname)
  }

  const countryList = [
    {
        name: "France",
        code: "FR",
        currency: "EUR",
        currencySymbol: "€"
    },
    {
        name: "India",
        code: "IN",
        currency: "INR",
        currencySymbol: "₹"
    },
    {
        name: "Sri Lanka",
        code: "LK",
        currency: "LKR",
        currencySymbol: "Rs"
    },
    {
        name: "United Kingdom",
        code: "GB",
        currency: "GBP",
        currencySymbol: "£"
    },
    {
        name: "USA",
        code: "US",
        currency: "USD",
        currencySymbol: "$"
    },
    {
        name: "Singapore",
        code: "SG",
        currency: "SGD",
        currencySymbol: "$"
    },
    {
        name: "Canada",
        code: "CA",
        currency: "CAD",
        currencySymbol: "$"
    },
    {
        name: "Brazil",
        code: "BR",
        currency: "BRL",
        currencySymbol: "R$"
    },
    {
        name: "Japan",
        code: "JP",
        currency: "JPY",
        currencySymbol: "¥"
    },
    {
        name: "Australia",
        code: "AU",
        currency: "AUD",
        currencySymbol: "$"
    },
    {
        name: "UAE",
        code: "AE",
        currency: "AED",
        currencySymbol: "د.إ"
    },
    {
        name: "Andorra",
        code: "AD",
        currency: "EUR",
        currencySymbol: "€"

    }, {
        name: "Albania",
        code: "AL",
        currency: "ALL",
        currencySymbol: "L"
    }, {
        name: "Austria",
        code: "AT",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Åland Islands",
        code: "AX",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Belgium",
        code: "BE",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Bulgaria",
        code: "BG",
        currency: "BGN",
        currencySymbol: "лв"
    }, {
        name: "Belarus",
        code: "BY",
        currency: "BYN",
        currencySymbol: "Br"
    }, {
        name: "Switzerland",
        code: "CH",
        currency: "CHF",
        currencySymbol: "Fr"
    }, {
        name: "Cyprus",
        code: "CY",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Czech Republic",
        code: "CZ",
        currency: "CZK",
        currencySymbol: "Kč"
    }, {
        name: "Germany",
        code: "DE",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Denmark",
        code: "DK",
        currency: "DKK",
        currencySymbol: "kr"
    }, {
        name: "Estonia",
        code: "EE",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Spain",
        code: "ES",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Finland",
        code: "FI",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Faroe Islands",
        code: "FO",
        currency: "DKK",
        currencySymbol: "kr"
    }, {
        name: "Guernsey",
        code: "GG",
        currency: "GBP",
        currencySymbol: "£"
    }, {
        name: "Greece",
        code: "GR",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Croatia",
        code: "HR",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Hungary",
        code: "HU",
        currency: "HUF",
        currencySymbol: "Ft"
    }, {
        name: "Ireland",
        code: "IE",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Isle of Man",
        code: "IM",
        currency: "GBP",
        currencySymbol: "£"
    }, {
        name: "Iceland",
        code: "IC",
        currency: "ISK",
        currencySymbol: "kr"
    }, {
        name: "Italy",
        code: "IT",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Jersey",
        code: "JE",
        currency: "GBP",
        currencySymbol: "£"
    }, {
        name: "Liechtenstein",
        code: "LI",
        currency: "CHF",
        currencySymbol: "Fr"
    }, {
        name: "Lithuania",
        code: "LT",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Luxembourg",
        code: "LU",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Latvia",
        code: "LV",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Monaco",
        code: "MC",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Moldova",
        code: "MD",
        currency: "MDL",
        currencySymbol: "L"
    }, {
        name: "Macedonia",
        code: "MK",
        currency: "MKD",
        currencySymbol: "ден"
    }, {
        name: "Malta",
        code: "MT",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Netherlands",
        code: "NL",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Norway",
        code: "NO",
        currency: "NOK",
        currencySymbol: "kr"
    }, {
        name: "Poland",
        code: "PL",
        currency: "PLN",
        currencySymbol: "zł"
    }, {
        name: "Portugal",
        code: "PT",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Romania",
        code: "RO",
        currency: "RON",
        currencySymbol: "lei"
    }, {
        name: "Russia",
        code: "RU",
        currency: "RUB",
        currencySymbol: "₽"
    }, {
        name: "Sweden",
        code: "SE",
        currency: "SEK",
        currencySymbol: "kr"
    }, {
        name: "Slovenia",
        code: "SI",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Svalbard and Jan Mayen",
        code: "SJ",
        currency: "NOK",
        currencySymbol: "kr"
    }, {
        name: "Slovakia",
        code: "SK",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "San Marino",
        code: "SM",
        currency: "EUR",
        currencySymbol: "€"
    }, {
        name: "Ukraine",
        code: "UA",
        currency: "UAH",
        currencySymbol: "₴"
    }, {
        name: "Holy See",
        code: "VA",
        currency: "EUR",
        currencySymbol: "€"
    }
  ]

  return (
    <>
    
    <Box>
        <IconButton 
            sx={{
                overflow: "hidden",
            }} 
            onClick={openMenu}
        >
            <img
                loading="lazy"
                width={32}
                height={25}
                style={{objectFit: "cover"}}
                src={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png`}
                alt="flag"
            />
        </IconButton>
    </Box>

    <ReuseMenu 
        menu={menu} 
        closeMenu={closeMenu}
    >
        <Box 
            sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                p: 2,
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <Stack 
                sx={{
                    p: 2,
                    maxHeight: 200,
                    overflowY: "scroll",
                    '&::-webkit-scrollbar': {
                        width: '3px',
                    },
                    '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'primary.main',
                        // outline: '3px solid #FAFAFA',
                    }
                }}
            >
                <FormControl>
                    <FormLabel sx={{py: 1}} id="demo-controlled-radio-buttons-group">{t("selectCountry")}</FormLabel>
                    <RadioGroup
                        value={languageCountryCurrency.country}
                        onChange={(e) => setLanguageCountryCurrency(prev => ({...prev, country: e.target.value}))}
                    >
                        {countryList?.map((country, i) => (
                            <FormControlLabel 
                                key={i} 
                                onChange={() => setLanguageCountryCurrency(prev => ({...prev, currency: country.currency, currencySymbol: country.currencySymbol, code: country.code}))} 
                                value={country.name}
                                control={<Radio />} 
                                label={country.name}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </Stack>
            <Stack sx={{p: 2}}>
                <FormControl>
                    <FormLabel sx={{py: 1}} id="demo-controlled-radio-buttons-group">{t("currency")}</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={languageCountryCurrency.currency}
                    >
                        <FormControlLabel defaultChecked value={languageCountryCurrency.currency} control={<Radio />} label={languageCountryCurrency.currency} />
                    </RadioGroup>
                </FormControl>
            </Stack>
            <Stack sx={{p: 2}}>
                <FormControl>
                    <FormLabel sx={{py: 1}} id="demo-controlled-radio-buttons-group">{t("selectLanguage")}</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={languageCountryCurrency.language}
                        onChange={(e) => setLanguageCountryCurrency(prev => ({...prev, language: e.target.value}))}
                    >
                        <FormControlLabel value="fr" control={<Radio />} label="Français" />
                        <FormControlLabel value="en" control={<Radio />} label="English" />
                        {/* <FormControlLabel value="es" control={<Radio />} label="español" /> */}
                        {/* <FormControlLabel value="ta" control={<Radio />} label="தமிழ்" /> */}
                    </RadioGroup>
                </FormControl>
            </Stack>
        </Box>
        <Stack alignItems="flex-end" width="100%">
            <BlueButton 
                sx={{ maxWidth: 100, mr: 3 }}
                onClick={applySettings}
            >
                {t("apply")}
            </BlueButton>
        </Stack>
    </ReuseMenu>
    </>
  )
}

export default ChooseLanguage