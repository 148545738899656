import { Stack, Typography } from '@mui/material'
import React from 'react'
import BaggageModal from '../Modals/BaggageModal'
import { useTranslation } from 'react-i18next'

function AddBaggage({ bookingDetailsData }) {

  const { t } = useTranslation();
  
  return (
    <Stack 
        sx={{
            px: 2,
            py: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "card.background", 
            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", 
            borderRadius: 3, 
            overflow: "hidden"
        }}
    >
        <Typography variant='body1' color="text.main" fontWeight="bold">
            {t("gotExcessBaggagePleaseContactAdminOrBookBaggagesFromOfficialAirlinesWebsite")}
        </Typography>
        {/* <BaggageModal bookingDetailsData={bookingDetailsData}/> */}
    </Stack>
  )
}

export default AddBaggage