import { Box, Divider, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BASE_URL } from '../../../Lib/Axios/AxiosConfig';
import { AnchorText, BlueButton, GlobeLoaderWithTransparentBackdrop, WhiteCard } from '../../../Lib/MuiThemes/MuiComponents';
import LoginToContinuePopup from '../Modals/LoginToContinuePopup';
import useSnackBar from '../../../Lib/CustomHooks/useSnackBar';
import { BookingObjContext } from '../../../Lib/Contexts/BookingObjContext';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../Lib/Firebase/firebase.config';
import BookingSuccessModalByBookThroughAdmin from '../Modals/BookingSuccessModalByBookThroughAdmin';
import { age, getCurrencySymbol, travellerType } from '../../../Lib/utils/helperFunctions';
import { getI18n, useTranslation } from 'react-i18next';
import { HiArrowNarrowRight } from 'react-icons/hi';
import useLoader from '../../../Lib/CustomHooks/useLoader';

function FareSummary({ bookingType, travelInsurance, fareSummaryDetails, isTermsAndConditionAgreed }) {
 

  const { totalPassengers, bookingObj: flightPriceBookingObj } = fareSummaryDetails;
  const refId = sessionStorage.getItem("refId");
  const { state: paymentPayload } = useLocation();
  const { auth: userAuth, account } = useSelector(data => data.persistedReducer);
  const [ openLoginPopup, setOpenLoginPopup ] = useState(false);
  const [ isLoading, startLoading, restLoading ] = useLoader();
  const { bookingObj } = useContext(BookingObjContext);
  const { showSnackBar } = useSnackBar();
  const [ openBookingSuccessModalByBookThroughAdmin, setOpenBookingSuccessModalByBookThroughAdmin ] = useState(false);
  const userId = userAuth.userId
  const [ grandTotal, setGrandTotal ] = useState(0);
  const { t } = useTranslation();

  
  useEffect(() => {
    let total = Math.ceil(parseFloat(fareSummaryDetails.totalFare));

    if(travelInsurance.type !== null){
        total += travelInsurance.fee * totalPassengers;
    }

    if(fareSummaryDetails.serviceCharge){
        total += Math.ceil(parseFloat(fareSummaryDetails.serviceCharge))
    }

    setGrandTotal(total);

  }, [ fareSummaryDetails.insurance.fee, fareSummaryDetails.totalFare, travelInsurance.fee, fareSummaryDetails.serviceCharge ]);

  function blockWindow(event) {
    const confirmationMessage = "Changes you've made are not saved, are your sure you want to reload?";

    (event || window.event).returnValue = confirmationMessage; //Gecko + IE

    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  }

//   useEffect(() => {
//     if(bookingObj?.travelers?.length > 0){
//         window.addEventListener("beforeunload", blockWindow);
//     }
//   }, [ bookingObj?.travelers?.length ])
  
  function triggerBooking(){
    if(userAuth && userAuth.auth){
        if(bookingType !== ""){            
            switch(bookingType){
                case "selfbook":
                    return initiateSelfBooking();
                case "agencybook":
                    return initiateBookingThroughAgency();
                default:
                    return showSnackBar("error", t("Unhandled booking type"));
            } 
        }else{
            return showSnackBar("error", t("Please select your booking type"));
        }
    }else{
        setOpenLoginPopup(true);
    }
  }

  function validateBookingDetails(){

    let isValidated = true;

    const expectedTravellers = {
        adult: flightPriceBookingObj?.flightOffers[0].travelerPricings.filter(traveler => traveler.travelerType === "ADULT").length,
        children: flightPriceBookingObj?.flightOffers[0].travelerPricings.filter(traveler => traveler.travelerType === "CHILD").length,
        infants: flightPriceBookingObj?.flightOffers[0].travelerPricings.filter(traveler => traveler.travelerType === "HELD_INFANT").length,
    }

    const addedTravellers = {
        adult: bookingObj.travelers.filter(traveler => travellerType(age(traveler.dob)) === "Adult").length,
        children: bookingObj.travelers.filter(traveler => travellerType(age(traveler.dob)) === "Children").length,
        infants: bookingObj.travelers.filter(traveler => travellerType(age(traveler.dob)) === "Infants").length,
    }

    if(bookingObj.travelers.length === 0){
        isValidated = false;
        return showSnackBar("error", `${t("Traveller details is empty, Please add")} ${flightPriceBookingObj?.flightOffers[0].travelerPricings.length} ${t("traveller(s)")}`);
    }

    if(bookingObj.travelers.length < flightPriceBookingObj?.flightOffers[0].travelerPricings.length){
        isValidated = false;
        return showSnackBar("error", `${t("Traveller list is not valid, please add")} ${flightPriceBookingObj?.flightOffers[0].travelerPricings.length - bookingObj.travelers.length} ${t("more traveller(s)")}`);
    }

    if(bookingObj.travelers.length > flightPriceBookingObj?.flightOffers[0].travelerPricings.length){
        isValidated = false;
        return showSnackBar("error", `${t("Traveller list is exceeded, please add only")} ${flightPriceBookingObj?.flightOffers[0].travelerPricings.length} ${t("traveller(s)")}`);
    }

    // if(!bookingObj.travelers.some(traveler => age(traveler.dob) > 11 && age(traveler.dob) < 59)){
    //     isValidated = false;
    //     return showSnackBar("error", t("Not enough adult traveler to accompany, atleast one of the adult traveller must be less than 60 years old"));
    // }

    if(expectedTravellers.adult !== addedTravellers.adult){
        isValidated = false;
        return showSnackBar("error", t("Adult traveller details are not matching"));
    }

    if(expectedTravellers.children !== addedTravellers.children){
        isValidated = false;
        return showSnackBar("error", t("Child traveller details are not matching"));
    }

    if(expectedTravellers.infants !== addedTravellers.infants){
        isValidated = false;
        return showSnackBar("error", t("Infant traveller details are not matching"));
    }

    if(!isTermsAndConditionAgreed){
        isValidated = false;
        return showSnackBar("error", t("Please accept terms and conditions"));
    }

    return isValidated;

  }

    async function initiateSelfBooking(){


        if(validateBookingDetails()){
            try{
                startLoading();
                let data = {
                    userId,
                    data: [...flightPriceBookingObj.flightOffers],
                    travelers: [...bookingObj.travelers],
                    from: paymentPayload.searchKeys.origin,
                    date: paymentPayload.searchKeys.departureDate,
                    type: paymentPayload.searchKeys.trip,
                    flightNumber: fareSummaryDetails.display[0].data[0].flightNumber,
                    to: paymentPayload.searchKeys.destination,
                    airlines: fareSummaryDetails.display[0].data[0].airlines,
                    airlinesLogo: fareSummaryDetails.display[0].data[0].airlinesLogo,
                    duration: fareSummaryDetails.display[0].data[0].duration,
                    insurance: travelInsurance.fee * totalPassengers ?? 0,
                    insuranceType: travelInsurance.type,
                    serviceCharge: fareSummaryDetails.serviceCharge ?? 0,
                    totalPassengers: totalPassengers,
                    system: "web",
                    language: getI18n().language,
                    refId
                }

                if(paymentPayload.searchKeys.trip === "OWC"){
                    data.returnAirlines = fareSummaryDetails.display.at(-1).data[0].airlines
                }

                const response = await axios({
                    method: "post",
                    url: `${BASE_URL}/initiateFlightOrder`,
                    data,
                })
                // console.log(response);
                if(response.status === 200){
                    let { paymentURL } = response.data;
                    window.open(paymentURL, "_self");
                    restLoading();
                }else{
                    return showSnackBar("error", t(response?.data))
                }

            }catch(error){
                console.log(error);
                showSnackBar("error", t(error?.response?.data));
                restLoading() 
            }
        }
    }

    function initiateBookingThroughAgency(){
        
        if(validateBookingDetails()){
            onAuthStateChanged(auth, async (user) => {
                if(user){
                    try{
                        startLoading();

                        let data = {
                            userId,
                            data: [...flightPriceBookingObj.flightOffers],
                            travelers: [...bookingObj.travelers],
                            from: paymentPayload.searchKeys.origin,
                            date: paymentPayload.searchKeys.departureDate,
                            type: paymentPayload.searchKeys.trip,
                            flightNumber: fareSummaryDetails.display[0].data[0].flightNumber,
                            to: paymentPayload.searchKeys.destination,
                            bookedBy: account.name,
                            fromCity: fareSummaryDetails.display[0].data[0].originCity,
                            toCity: fareSummaryDetails.display[0].data[0].destinationCity,
                            airlines: fareSummaryDetails.display[0].data[0].airlines,
                            airlinesLogo: fareSummaryDetails.display[0].data[0].airlinesLogo,
                            duration: fareSummaryDetails.display[0].data[0].duration,
                            insurance: travelInsurance.fee * totalPassengers ?? 0,
                            insuranceType: travelInsurance.type,
                            serviceCharge: fareSummaryDetails.serviceCharge ?? 0,
                            system: "web",
                            refId,
                        }

                        if(paymentPayload.searchKeys.trip === "OWC"){
                            data.returnAirlines = fareSummaryDetails.display.at(-1).data[0].airlines
                        }

                        const response = await axios({
                            method: "post",
                            url: `${BASE_URL}/createFlightOrder`,
                            headers: {
                                idToken: user.accessToken
                            },
                            data: data
                        });
                        
                        console.log(JSON.stringify(response))
                        if(response.status === 200){
                            restLoading();
                            setOpenBookingSuccessModalByBookThroughAdmin(true)
                        }else{
                            restLoading();
                            return showSnackBar("error", t(response?.data));
                        }
    
                    }catch(error){
                        console.error(error);
                        showSnackBar("error", t(error?.response?.data));
                        restLoading();
                    }
                }
            })
        }
    }

    if(isLoading){
        return (
            <GlobeLoaderWithTransparentBackdrop/>
        )
    }

  return (
    <>
    <Box sx={{
        position: "sticky",
        top: 150,        
    }}>
        <WhiteCard>
            <Stack spacing={3}>
                <Typography variant='h4'>{t("fareSummary")}</Typography>
                <Box sx={{
                    display: "flex", 
                    flexDirection: "column", 
                    gap: 1
                }}>
                    {/* <Box sx={{
                        height: "70px", 
                        width: "90px", 
                        borderRadius: 3, 
                        overflow: "hidden"
                    }}>
                        <img src={fareSummaryDetails.display[0].data[0].airlinesLogo} alt="img" height="100%" width="100%" style={{objectFit: "cover"}} />
                    </Box> */}
                    {fareSummaryDetails?.display?.map((item, index) => (
                        <Box 
                            key={`disp${index}`} 
                            sx={{
                                display: "flex", 
                                flexDirection: "row",
                                // justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Typography sx={{ width: "100%" }} variant='h6'>{item.data[0].originCity}</Typography>
                            <Box sx={{ width: "100%" }}>
                                <HiArrowNarrowRight/>
                            </Box>
                            <Typography sx={{ width: "100%" }} variant='h6'>{item.data.at(-1).destinationCity}</Typography>
                        </Box>
                    ))}
                </Box>
                <Divider orientation='horizontal'></Divider> 
                <Box>
                    <Typography color="text.main">{t("yourBookingIsProtectedByErrancesVoyages")} <Typography color="text.main" variant='h6' component="span">Errances Voyages</Typography></Typography>
                </Box>
                <Divider orientation='horizontal'></Divider> 
                <Stack spacing={3}>
                    <Typography variant='h6' color="text.main">{t("priceDetails")}</Typography>

                    <Stack spacing={1} direction="row" justifyContent="space-between">
                        <Typography variant='body1' color="text.main">{t("ticketCost")} <span style={{fontSize: 11}}><br/>({t("includingTax")})</span></Typography>
                        <AnchorText variant='h6'>
                            {getCurrencySymbol(fareSummaryDetails.currency)}{Math.ceil(parseFloat(fareSummaryDetails.totalFare) + fareSummaryDetails.serviceCharge)}
                            {/* <FormattedNumber value={parseFloat(price.total) + fareSummaryDetails.serviceCharge} currency={price.currency} style="currency"/> */}
                        </AnchorText>
                    </Stack>

                    {/* <Stack direction="row" justifyContent="space-between">
                        <Typography variant='body1' color="text.main">EV Service Charge</Typography>
                        <AnchorText variant='h6'>
                            <FormattedNumber value={fareSummaryDetails.serviceCharge} currency={price.currency} style="currency"/>
                        </AnchorText>
                    </Stack> */}

                    {travelInsurance.type !== null && <Stack direction="row" justifyContent="space-between">
                        <Typography variant='body1' color="text.main">{t("travelInsurance")} <span style={{fontSize: 11}}>({t("serviceCharge")})</span> x {totalPassengers}</Typography>
                        <AnchorText variant='h6'>
                            {getCurrencySymbol(fareSummaryDetails.currency)}{travelInsurance.fee * totalPassengers}
                            {/* <FormattedNumber value={travelInsurance.fee * totalPassengers} currency={fareSummaryDetails.currency} style="currency"/> */}
                        </AnchorText>
                    </Stack>}
                    
                    {/* {price.additionalServices && price.additionalServices.map((service, index) => (
                    <Stack key={index} direction="row" justifyContent="space-between">
                        <Typography variant='body1' color="text.main">{service.type}</Typography>
                        <AnchorText variant='h6'>
                        <FormattedNumber value={service.amount} currency={price.currency} style="currency"/>
                        </AnchorText>
                    </Stack>
                    ))} */}
                </Stack>
                <Divider orientation='horizontal'></Divider> 
                <Stack spacing={1} direction="row" justifyContent="space-between">
                    <Typography variant='body1' color="text.main">{t("grandTotal")}</Typography>
                    <AnchorText variant='h6'>
                        {getCurrencySymbol(fareSummaryDetails.currency)}{Math.ceil(grandTotal)}
                        {/* <FormattedNumber value={grandTotal} currency={price.currency} style="currency"/> */}
                    </AnchorText>
                </Stack>
            </Stack>
            <BlueButton loading={isLoading} onClick={triggerBooking} sx={{my: 1}} fullWidth>{t("continueBooking")}</BlueButton>
        </WhiteCard>
        <LoginToContinuePopup open={openLoginPopup} setOpen={setOpenLoginPopup}></LoginToContinuePopup>
        {/* <PaymentValidationModal open={openPaymentValidationModal.open} paymentStatus={openPaymentValidationModal.paymentStatus}/> */}
    </Box>
    <BookingSuccessModalByBookThroughAdmin
        open={openBookingSuccessModalByBookThroughAdmin}
        setOpen={setOpenBookingSuccessModalByBookThroughAdmin}
    />
    </>
  )
}

export default FareSummary