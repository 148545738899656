import { Box, IconButton, Typography } from "@mui/material"
import DoneOutlineTwoToneIcon from '@mui/icons-material/DoneOutlineTwoTone';

function ValidateBookingBooking() {

  return (
    <Box sx={{
        minHeight: "100vh",
        backgroundColor: "common.background"
    }}>
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            py: 3
        }}>
            <IconButton sx={{
                height: 120,
                width: 120,
                mt: 2,
                backgroundColor: "#00c853",
                color: "white",
                "&:hover": {
                    backgroundColor: "#00c853"
                },
            }}>
                <DoneOutlineTwoToneIcon sx={{ fontSize: 50 }} />
            </IconButton>
        </Box>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
        }}>
            <Typography variant="h3">Your payment is received</Typography>

            <Box sx={{ mt: 5 }}>
                <Typography textAlign="justify" variant='h6' color="text.main">
                    Please navigate back to App
                </Typography>
            </Box>
        </Box>
    </Box>
  )
}

export default ValidateBookingBooking