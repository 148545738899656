import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { ThemeReducer } from "./ThemeSlice";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { authReducer } from "./AuthSlice";
import { accountReducer } from "./AccountSlice";
import { flightPricePayloadReducer } from "./FlightPricePayloadSlice";
import { flightResultFilterReducer } from "./FilterSlice";
import { searchKeysReducer } from "./SearchSlice";
import { filterArrayReducer } from "./Filter2Slice";

const persistConfig = {
    key: "root",
    version: 1,
    storage
}

const rootReducer = combineReducers({
    theme: ThemeReducer,
    auth: authReducer,
    account: accountReducer,
    searchKeys: searchKeysReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: {
        persistedReducer,
        flightPricePayload: flightPricePayloadReducer,
        flightResultFilter: flightResultFilterReducer,
        filterArray: filterArrayReducer
    },
    middleware: [thunk]
})

export const persistor = persistStore(store);