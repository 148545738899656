import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { StyledToggleButton, StyledToggleButtonGroup } from '../../../Lib/MuiThemes/MuiComponents'
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

function FlexibleDateSearch() {

    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    
    const getDate = searchParams.get("departureDate");
    
    const previousDate = [1, 2, 3].map(cutOff => dayjs(new Date(getDate).setDate(new Date(getDate).getDate() - cutOff)).format("YYYY-MM-DD")).reverse();
    const futureDate = [1, 2, 3].map(cutOff => dayjs(new Date(getDate).setDate(new Date(getDate).getDate() + cutOff)).format("YYYY-MM-DD"))
    
    const flexibleDates = [...previousDate, getDate, ...futureDate];
    
    const [ date, setDate ] = useState(getDate);


  useEffect(() => {
    searchParams.set("departureDate", date);
    navigate(`/flights?${searchParams}`)
  }, [date, navigate])

  return (
    searchParams.get("tripType") === "oneway" && <Stack
        sx={{
            width: "100%",
        }}
    >
        <StyledToggleButtonGroup
            sx={{ 
                gap: 1,  
            }}
            fullWidth
            color="primary"
            value={date}
            exclusive
            onChange={(_event, newValue) => setDate(newValue)}
            >
            {flexibleDates.map(d => (
                <StyledToggleButton
                    disabled={new Date(d).getTime() <= new Date().setDate(new Date().getDate() - 1)}
                    sx={{
                        bgcolor: "card.background",
                        boxShadow: 1,
                        outlineColor: "transparent", 
                    }}
                    key={d}
                    value={d}
                >
                    <Box
                        sx={{
                            p: 1
                        }}
                    >
                        <Typography variant='body1' fontWeight="bold">{dayjs(d).format("ddd")}</Typography>
                        <Box>
                            <Typography variant='h5' fontWeight="bold">{dayjs(d).format("DD")}</Typography> 
                        </Box> 
                    </Box> 
                </StyledToggleButton>
            ))}
        </StyledToggleButtonGroup>

    </Stack>
  )
}

export default FlexibleDateSearch