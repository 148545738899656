import React, {useEffect, useState} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Slider, Stack, styled, Switch, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { BsCloudMoonFill, BsFillCloudSunFill, BsFillSunFill, BsFillSunriseFill } from "react-icons/bs"

//Accordian
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledToggleButton, StyledToggleButtonGroup, WhiteCard } from '../../../Lib/MuiThemes/MuiComponents';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setAirlines, setArrivalTime, setCorporateFares, setDepartureTime, setDuration, setPrice, setStops } from '../../../Lib/Redux/FilterSlice';
import dayjs from 'dayjs';


function FiltersSetting() {
  
  const carriers = useSelector(data => data.flightPricePayload.dictionary.carriers); 
  const [expanded, setExpanded] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterStops, setFilterStops] = useState([]);
  const [filterPrice, setFilterPrice] = useState(false);
  const [filterDuration, setFilterDuration] = useState({ departure: "", return : "" });
  const [filterAirlines, setFilterAirlines] = useState([]);
  const [filterDepartureTime, setFilterDepartureTime] = useState("");
  const [filterArrivalTime, setFilterArrivalTime] = useState("");
  const [ filterCorporateFares, setFilterCorporateFares ] = useState(false);
  const dispatch = useDispatch();
  const { flightResultFilter } = useSelector(data => data);

  const durationRange = [
    {
      value: 1,
      label: '1h',
    },
    {
      value: 24,
      label: '24h',
    },
  ];


  const handleChange = (panel) => {
    if(expanded.includes(panel)){
        setExpanded(prevState => prevState.filter(data => data !== panel));
    }else{
        setExpanded(prevState => [...prevState, panel]);
    }
  };

  const handlePrice = (event) => {
    if(event.target.value === "true"){
        setFilterPrice(true);
    }else{
        setFilterPrice(false);
    }
  };

  function handleStops(event){
    const {checked, value} = event.target;
    if(checked){
        setFilterStops(prevState => [...prevState, value]);
    }else{
        setFilterStops(prev => prev.filter(stop => stop !== value));
    }

  }

  function handleAirlines(event){
    const {checked, value} = event.target;
    if(checked){
        setFilterAirlines(prevState => [...prevState, value]);
    }else{
        setFilterAirlines(prev => prev.filter(airline => airline !== value));
    }

  }

  useEffect(() => {
    if(filterStops || filterPrice || filterDuration || filterAirlines || filterDepartureTime || filterArrivalTime){
        dispatch(setStops(filterStops))
        dispatch(setPrice(filterPrice))
        dispatch(setDuration(filterDuration))
        dispatch(setDepartureTime(filterDepartureTime))
        dispatch(setAirlines(filterAirlines))
        dispatch(setCorporateFares(filterCorporateFares))
        dispatch(setArrivalTime(filterArrivalTime))
    }

  }, [filterStops, filterPrice, filterDuration, filterAirlines, filterDepartureTime, filterCorporateFares, filterArrivalTime, dispatch])


  return (
    <Box sx={{
        height: "auto",
        width: "100%"
        // flex: 1,
    }}>
        <WhiteCard>
            <Typography variant='h5' color="text.main" sx={{fontWeight: "bold", pl: 2}}>Filters</Typography>
            <Box 
                sx={{
                    pl: 2, 
                    py: 2, 
                    display: "flex", 
                    gap: 1, 
                    flexWrap: "wrap"
                }}
            >
                {!!flightResultFilter.stops && flightResultFilter.stops.map((stop, i) => (
                    <Chip 
                        key={i} 
                        size='small' 
                        label={parseInt(stop) === 1 ? "1 stop" : parseInt(stop) === 2 ? "2 stops" : "Non stop"} 
                        color='primary' 
                        onDelete={() => setFilterStops(prev => !!prev && prev.filter(value => value !== stop))} 
                    />
                ))}
                {!!flightResultFilter.price && <Chip  
                    size='small' 
                    label="Highest to Lowest" 
                    color='primary' 
                    onDelete={() => setFilterPrice(false)} 
                />}
                {!!flightResultFilter.duration.departure && 
                    <Chip  
                        size='small' 
                        label={flightResultFilter.duration.departure === 1 ? "dep. duration 1 hr" : `dep. duration ${flightResultFilter.duration.departure} hrs`}
                        color='primary' 
                        onDelete={() => setFilterDuration(prev => ({ ...prev, departure: "" }))} 
                    />
                }
                {!!flightResultFilter.duration.return && 
                    <Chip  
                        size='small' 
                        label={flightResultFilter.duration.return === 1 ? "ret. duration 1 hr" : `ret. duration ${flightResultFilter.duration.return} hrs`}
                        color='primary' 
                        onDelete={() => setFilterDuration(prev => ({ ...prev, return: "" }))} 
                    />
                }
                {!!flightResultFilter.departureTime && 
                    <Chip  
                        size='small' 
                        label={`${dayjs(`12-25-1995 ${flightResultFilter.departureTime.split(",")[0]}:00`).format("HH:mm")} to ${dayjs(`12-25-1995 ${parseInt(flightResultFilter.departureTime.split(",")[1]) - 1}:59`).format("HH:mm")}`}
                        color='primary' 
                        onDelete={() => setFilterDepartureTime("")} 
                    />
                }
                {!!flightResultFilter.arrivalTime && 
                    <Chip  
                        size='small' 
                        label={`${dayjs(`12-25-1995 ${flightResultFilter.arrivalTime.split(",")[0]}:00`).format("HH:mm")} to ${dayjs(`12-25-1995 ${parseInt(flightResultFilter.arrivalTime.split(",")[1]) - 1}:59`).format("HH:mm")}`}
                        color='primary' 
                        onDelete={() => setFilterArrivalTime("")} 
                    />
                }
                {!!flightResultFilter.airlines && flightResultFilter.airlines.map((airline, i) => (
                    <Chip 
                        key={i} 
                        size='small' 
                        label={airline} 
                        color='primary' 
                        onDelete={() => setFilterAirlines(prev => !!prev && prev.filter(value => value !== airline))} 
                    />
                ))}
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                pb: 1,
                ml: 2,
            }}>
                <Typography>{t("showOnlyEthnicFares")}</Typography>
                <Switch
                    checked={filterCorporateFares}
                    onChange={() => setFilterCorporateFares(!filterCorporateFares)}
                />
            </Box>
            <Accordion 
                elevation={0} 
                sx={{bgcolor: "card.background", borderTop: "1px solid #bdbdbd"}}
                expanded={expanded.includes("panel2")}
                onChange={() => handleChange("panel2")}
                >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: "text.main"}}/>}
                    >
                <Typography variant='body1' color="text.main">{t("sort")}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{px: 5}}>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={false}
                            name="radio-buttons-group"
                            value={filterPrice}
                            onChange={handlePrice}

                        >
                            <FormControlLabel value={false} control={<Radio />} label={t("Lowest to Highest")} />
                            <FormControlLabel value={true} control={<Radio />} label={t("Highest to Lowest")} />
                        </RadioGroup>
                    </FormControl>
                </AccordionDetails>
            </Accordion>
            <Accordion 
                elevation={0} 
                sx={{bgcolor: "card.background", borderTop: "1px solid #bdbdbd"}}
                expanded={expanded.includes("panel1")}
                onChange={() => handleChange("panel1")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: "text.main"}}/>}
                >
                    <Typography variant='body1' sx={{color: "text.main"}}>{t("flightStops")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormGroup>
                        <FormControlLabel 
                            control={<Checkbox />} 
                            label={
                                <Typography variant='body1' color="text.main">
                                    {t("nonStop")} 
                                    {/* {`(${flightCountsBasedOnStops.nonstopFlightCount})`} */}
                                </Typography>
                            }
                            value="0"
                            checked={!!filterStops && filterStops.includes("0")}
                            onChange={handleStops} 
                        />
                        <FormControlLabel 
                            control={<Checkbox />} 
                            label={
                                <Typography variant='body1' color="text.main">
                                    {t("oneStop")} 
                                    {/* {`(${flightCountsBasedOnStops.onestopFlightCount})`} */}
                                </Typography>
                            }
                            value="1"
                            checked={!!filterStops && filterStops.includes("1")}
                            onChange={handleStops} 
                        />
                        <FormControlLabel 
                            control={<Checkbox />} 
                            label={
                                <Typography variant='body1' color="text.main">
                                    {t("twoStops")} 
                                    {/* {`(${flightCountsBasedOnStops.twostopFlightCount})`} */}
                                </Typography>
                            }
                            value="2"
                            checked={!!filterStops && filterStops.includes("2")}
                            onChange={handleStops} 
                        />
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
            <Accordion 
                elevation={0} 
                sx={{bgcolor: "card.background", borderTop: "1px solid #bdbdbd"}}
                expanded={expanded.includes("panel3")}
                onChange={() => handleChange("panel3")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: "text.main"}}/>}
                >
                    <Typography variant='body1' color="text.main">{t("duration")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack>
                        <Typography pb={5} textAlign="center">{t("Departure duration")}</Typography>
                        <Slider
                            size="small"
                            onChange={(e, newVal) => setFilterDuration(prev => ({ ...prev, departure: newVal }))}
                            value={Number(filterDuration.departure)}
                            // getAriaValueText={(val) => `$${val}`}
                            aria-label="small"
                            step={1}
                            valueLabelDisplay="on"
                            marks={durationRange}
                            min={1}
                            max={24}
                        />
                    </Stack>
                    {
                        searchParams.get("tripType") === "twoway" && searchParams.get("isCombinable") === "0" &&
                        <Stack>
                            <Typography pb={5} textAlign="center">{t("Return duration")}</Typography>
                            <Slider
                                size="small"
                                onChange={(e, newVal) => setFilterDuration(prev => ({ ...prev, return: newVal }))}
                                value={Number(filterDuration.return)}
                                // getAriaValueText={(val) => `$${val}`}
                                aria-label="small"
                                step={1}
                                valueLabelDisplay="on"
                                marks={durationRange}
                                min={1}
                                max={24}
                            />
                        </Stack>
                    }
                </AccordionDetails>
            </Accordion>
            <Accordion 
                elevation={0} 
                sx={{bgcolor: "card.background", borderTop: "1px solid #bdbdbd"}}
                expanded={expanded.includes("panel4")}
                onChange={() => handleChange("panel4")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: "text.main"}} />}
                >
                <Typography variant='body1' color="text.main">{t("departureTime")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledToggleButtonGroup
                        sx={{ gap: 1 }}
                        fullWidth
                        color="primary"
                        value={filterDepartureTime}
                        exclusive
                        onChange={(event, newValue) => setFilterDepartureTime(newValue)}
                        >
                        <StyledToggleButton value={"0,6"}>        {/*6 am converted into seconds 6 x 60 x 60 */}
                            <Box>
                                <BsFillSunriseFill/>
                            </Box>  
                            <Typography variant='subtitle1'>00:00 - 05:59</Typography>
                        </StyledToggleButton>
                        <StyledToggleButton value="6,12">
                            <Box>
                                <BsFillSunFill/>
                            </Box>
                            <Typography variant='subtitle1'>06:00 - 11:59</Typography>
                        </StyledToggleButton>
                        <StyledToggleButton value="12,18">
                            <Box>
                                <BsFillCloudSunFill/>
                            </Box>
                            <Typography variant='subtitle1'>12:00 - 17:59</Typography> 
                        </StyledToggleButton>
                        <StyledToggleButton value="18,24">
                            <Box>
                                <BsCloudMoonFill/>
                            </Box>
                            <Typography variant='subtitle1'>18:00 - 23:59</Typography>
                        </StyledToggleButton>
                    </StyledToggleButtonGroup>
                </AccordionDetails>
            </Accordion>
            <Accordion 
                elevation={0} 
                sx={{bgcolor: "card.background", borderTop: "1px solid #bdbdbd"}}
                expanded={expanded.includes("panel5")}
                onChange={() => handleChange("panel5")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: "text.main"}} />}
                >
                <Typography variant='body1' color="text.main">{t("arrivalTime")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledToggleButtonGroup
                        sx={{ gap: 1 }}
                        fullWidth
                        color="primary"
                        value={filterArrivalTime}
                        exclusive
                        onChange={(event, newValue) => setFilterArrivalTime(newValue)}
                        >
                        <StyledToggleButton value={"0,6"}>        {/*6 am converted into seconds 6 x 60 x 60 */}
                            <Box>
                                <BsFillSunriseFill/>
                            </Box>  
                            <Typography variant='subtitle1'>00:00 - 05:59</Typography>
                        </StyledToggleButton>
                        <StyledToggleButton value="6,12">
                            <Box>
                                <BsFillSunFill/>
                            </Box>
                            <Typography variant='subtitle1'>06:00 - 11:59</Typography>
                        </StyledToggleButton>
                        <StyledToggleButton value="12,18">
                            <Box>
                                <BsFillCloudSunFill/>
                            </Box>
                            <Typography variant='subtitle1'>12:00 - 17:59</Typography> 
                        </StyledToggleButton>
                        <StyledToggleButton value="18,24">
                            <Box>
                                <BsCloudMoonFill/>
                            </Box>
                            <Typography variant='subtitle1'>18:00 - 23:59</Typography>
                        </StyledToggleButton>
                    </StyledToggleButtonGroup>
                </AccordionDetails>
            </Accordion>
            
            <Accordion 
                elevation={0} 
                sx={{bgcolor: "card.background", borderTop: "1px solid #bdbdbd"}}
                expanded={expanded.includes("panel6")}
                onChange={() => handleChange("panel6")}
                >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{color: "text.main"}}/>}
                >
                <Typography variant='body1' sx={{color: "text.main"}}>{t("airlines")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Stack maxHeight={300} sx={{
                    overflowY: "scroll",
                    '&::-webkit-scrollbar': {
                        width: '3px',
                    },
                    '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'primary.main',
                        // outline: '3px solid #FAFAFA',
                    }
                }}>
                    <FormGroup>
                        {carriers && Object.values(carriers).sort().map((carrier, index) => (

                            <FormControlLabel
                                key={index}
                                value={carrier}
                                checked={filterAirlines.includes(carrier)}
                                onChange={handleAirlines} 
                                control={<Checkbox />} 
                                label={<Typography variant='body1' color="text.main">{carrier}</Typography>} 
                            />

                        ))}
                    </FormGroup>
                </Stack>
                </AccordionDetails>
            </Accordion>
        </WhiteCard>
    </Box>
  )
}

export default FiltersSetting