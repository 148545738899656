import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { emiratesFlight } from '../../../Assests/assets';
import { Axios, BASE_URL } from '../../../Lib/Axios/AxiosConfig';
import { BookingObjContextProvider } from '../../../Lib/Contexts/BookingObjContext';
import { LoaderConsumer } from '../../../Lib/Contexts/LoaderContext';
import useBookingObj from '../../../Lib/CustomHooks/useBookingObj';
import useSnackBar from '../../../Lib/CustomHooks/useSnackBar';
import { AnchorText, BlueButton, WhiteCard } from '../../../Lib/MuiThemes/MuiComponents';
import FareSummary from '../BookingDetailsFragments/FareSummary';
// import seatMap from "../seatMap.json";
import Deck from './SeatSelectionFragments/Deck';


function SeatSelection() {

    const navigate = useNavigate();
    // const { state } = useLocation();
    // console.log(state);
    const params = useParams();
    const seatMapArray = JSON.parse(sessionStorage.getItem("seatmap_response"));
    const [ seatMap ] = seatMapArray.filter(seg => seg.segmentId === params.id);
    // console.log(seatMapArray);
    const segmentId = seatMap.segmentId;
    const [seatObj, setSeatObj] = useState(JSON.parse(sessionStorage.getItem("seatObj")));
    console.log(seatObj);
    const [isLoading, startLoading, restLoading] = LoaderConsumer();
    const [seatArray, setSeatArray] = useState([]);
    const [ areSeatsMapped, setAreSeatsMapped ] = useState(false);
    // console.log(seatArray);
    
    useEffect(() => {
        // sessionStorage.setItem("seatObj", JSON.stringify(seatObj));
        // console.log(seatObj);
        if(seatArray.length > 0){
            setSeatObj(prevState => ({...prevState, data: {...prevState.data, travelerPricings: prevState.data.travelerPricings.map((travellerElement, travelerIndex) => {
                return {...travellerElement, fareDetailsBySegment: travellerElement.fareDetailsBySegment.map(segmentDetail => {
                    if(segmentDetail.segmentId === segmentId && seatArray[travelerIndex]){
                        return {
                            ...segmentDetail, 
                            additionalServices: {
                                chargeableSeatNumber: seatArray[travelerIndex]
                            }
                        }
                    }else{
                        return {...segmentDetail}
                    }
                })}
            })}}));
            console.log(seatObj);
            sessionStorage.setItem("seatObj", JSON.stringify(seatObj));
        }

    },[seatArray])
    

    function handleSeatSelection(event){
        const travelerSeatLimit = seatObj.data.travelerPricings.length;
        console.log(event.target.value);
        // if(seatArray.length < travelerSeatLimit){
        //     if(seatArray.includes(event.target.value)){
        //         setSeatArray(prev => prev.filter(seat => seat !== event.target.value));
        //     }else{
        //         setSeatArray(prev => [...prev, event.target.value]);
        //     }
        // }

        if(seatArray.includes(event.target.value)){
            console.log(seatArray.includes(event.target.value));
            setSeatArray(prev => prev.filter(seat => seat !== event.target.value));
        }else{
            if(seatArray.length < travelerSeatLimit){
                setSeatArray(prev => [...prev, event.target.value])
            }else{
                setSeatArray(prev => {
                    prev.shift();
                    return [...prev, event.target.value];
                })
            }
        }

            // setAreSeatsMapped(true)
            // setSeatObj(prevState => ({...prevState, travelerPricings: prevState.travelerPricings.map((travellerElement, travelerIndex) => {
            //     return {...travellerElement, fareDetailsBySegment: travellerElement.fareDetailsBySegment.map(segmentDetail => {
            //         if(segmentDetail.segmentId === segmentId){
            //             return {
            //                 ...segmentDetail, 
            //                 additionalServices: {
            //                     chargeableSeatNumber: seatArray[travelerIndex]
            //                 }
            //             }
            //         }else{
            //             return {...segmentDetail}
            //         }
            //     })}
            // })})); 

    }


    async function submitSeatSelection(){

        if(seatArray.length === seatObj.data.travelerPricings.length){
            
            const verifyId = localStorage.getItem("verifyId");
            
            try{
                // startLoading();
                const flightPriceResponse = await Axios({
                    method: "post",
                    url: `flightPrice`,
                    data: seatObj,
                });
                // console.log(flightPriceResponse);

                const seatMapResponse = await Axios({
                    method: "post",
                    url: `seatBooking`,
                    data: JSON.stringify({data: [seatObj.data]}),
                    headers: {
                        idToken: verifyId,
                        "Content-Type": "text/plain"
                    },
                })

                if(flightPriceResponse.status === 200 && seatMapResponse.status === 200){
                    sessionStorage.setItem("flight_price", JSON.stringify(flightPriceResponse.data));
                    sessionStorage.setItem("seatmap_response", JSON.stringify(seatMapResponse.data));
                    sessionStorage.setItem("seatObj", JSON.stringify(seatObj))
                    // restLoading();
                    navigate("/bookingdetails");
                }

            }catch(error){
                console.error(error)
                // restLoading();
                // console.log(error);
                // showSnackBar("error", "unable process Information for this flight, Please try again later");
            }

        }
        

        //     sessionStorage.setItem("seatObj", JSON.stringify(seatObj));

        //     // try{
        //     //     startLoading();
        //     //     const controller = axios.CancelToken.source();
        //     //     const response = await Axios({
        //     //         method: "post",
        //     //         url: `flightPrice`,
        //     //         data: bookingObj,
        //     //         cancelToken: controller.token
        //     //     });

        //     //     if(response.status === 200){
        //     //         sessionStorage.setItem("seatObj", JSON.stringify(response.data));
        //     //         navigate("/bookingdetails")
        //     //         restLoading();
        //     //     }
        
        //     // }catch(error){
        //     //     console.error(error);
        //     //     restLoading();

        //     // }
        // }
        
    }

  return (
        <Box sx={{
            height: "auto",
            width: "auto",
            display: "flex",
            flexDirection: {xs: "column", md: "row"},
            backgroundColor: "common.background",
            gap: 3,
            px: {
                xs: 1,
                md: 10
            },
            py: 5   
        }}>
            <Box 
                display="flex"
                flexDirection="column"
                gap={2}
                flex={3} 
            >
                <WhiteCard>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" py={1}>
                        <Typography variant='h4'>Seat selection</Typography>
                        <BlueButton onClick={submitSeatSelection}>Continue booking</BlueButton>
                    </Stack>
                    <Box sx={{
                        display: "flex", 
                        justifyContent: "space-evenly",
                        alignItems: "flex-start",    
                        flexDirection: "row",
                        gap: 5,
                        backgroundColor: "common.background", 
                        p: 3, 
                        maxHeight:"100vh",
                        overflowY: "scroll",
                        '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'card.background',
                            outline: '3px solid #FAFAFA'
                        }
                    }}>
                        <>
                        {/* {isLoading && <Skeleton variant="rounded" width={600} height={200} />} */}
                        {!isLoading && seatMap && <Box>
                            <WhiteCard>
                                <Stack spacing={2} direction="column" justifyContent="space-between">
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Box sx={{
                                            height: "40px",
                                            width: "40px",
                                            borderRadius: 3,
                                            boxShadow: "0px 2px 10px rgba(155, 157, 172, 0.3)",
                                            border: "1px solid black",
                                            color: "black",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}></Box>
                                        <Typography variant='body1'>Free</Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Box sx={{
                                            height: "40px",
                                            width: "40px",
                                            borderRadius: 3,
                                            boxShadow: "0px 2px 10px rgba(155, 157, 172, 0.3)",
                                            color: "black",
                                            backgroundColor: "primary.main",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}></Box>
                                        <Typography>Selected</Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Box sx={{
                                            height: "40px",
                                            width: "40px",
                                            borderRadius: 3,
                                            boxShadow: "0px 2px 10px rgba(155, 157, 172, 0.3)",
                                            color: "black",
                                            backgroundColor: "veryLightBlue.main",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}></Box>
                                        <Typography>Not available</Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Box sx={{
                                            height: "40px",
                                            width: "40px",
                                            borderRadius: 3,
                                            boxShadow: "0px 2px 10px rgba(155, 157, 172, 0.3)",
                                            color: "black",
                                            backgroundColor: "error.light",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}></Box>
                                        <Typography>Facilities</Typography>
                                    </Stack>
                                </Stack>
                            </WhiteCard>
                        </Box>}
                        
                        {isLoading && <Skeleton variant="rounded" width={600} height={1500} />}

                        {!isLoading && seatMap && <Box>
                            {seatMap.decks && seatMap.decks.map((deck, i) => (
                                <Deck deck={deck} key={i} handleSeatSelection={handleSeatSelection} areSeatsMapped={areSeatsMapped} seatArray={seatArray}/>
                            ))}
                        </Box>}
                        </> 
                        {/* <Typography variant='h4' color="text.main">Seat selection not available for this flight</Typography> */}
                    </Box>
                </WhiteCard>
            </Box>
            {/* <Box
                flex={2} 
                display="flex"
                flexDirection="column"
                gap={3}
            >
                <FareSummary></FareSummary>
            </Box> */}
        </Box>
      )
}

export default SeatSelection