import { Box, Typography } from '@mui/material'
import { motion, useInView } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'

function OurResultsCountIncrement(props) {
  
  const [ count, setCount ] = useState(0);
  const ref = useRef(null);
  const isInView = useInView(ref);
  
    useEffect(() => {
        if(isInView){
            if(count < props.count){
                const interval = setInterval(() => {
                    setCount(counter => counter + 1);
                }, 50);
                
                return () => {
                    clearInterval(interval);
                };
            }
        }else{
            setCount(0)
        }
    }, [count, props.count, isInView]);

  return (
    <Box
        ref={ref} 
        component={motion.div}
        variants={props.variants}
        sx={{
            textAlign: "center", 
            width: {xs: "35%", md: "auto"}, 
            height: "150px"
        }}
        
    >
        <Typography variant='h3' sx={{fontWeight: "bold", pb: 3}}>{count}<Typography sx={{fontWeight: "bold", pb: 3}} variant='h3' component="span" color="primary.main">+</Typography></Typography>
        <Typography variant='body2' sx={{fontWeight: "bold"}}>{props.title}</Typography>
    </Box>
  )
}

export default OurResultsCountIncrement