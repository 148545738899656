import React from 'react'
import { ExpandMore } from '@mui/icons-material'
import { Box, Button, List, ListItem, ListItemButton, Typography } from '@mui/material'
import { ReuseMenu } from '../../../Lib/MuiThemes/MuiComponents'
import useMenu from '../../../Lib/CustomHooks/useMenu';
import { useTranslation } from 'react-i18next';
import { CiSignpostDuo1, CiViewTimeline } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';

function MarketeerMenu() {

    const navigate = useNavigate();
    const { menu, openMenu, closeMenu} = useMenu(); 
    const { t } = useTranslation();
    const adminRoutes = [
        {
          route: "/marketeer/config-packages",
          title: "Add packages",
          icon: <CiSignpostDuo1/>
        },
        {
          route: "/marketeer/blogs",
          title: "Blogs",
          icon: <CiViewTimeline/>
        }
      ]
    


  return (
    <>
        <Button 
            disableRipple 
            onClick={openMenu} 
            sx={{
                color: "primary.main",
                "&:hover": {
                    color: "red"
                },
            }}
        >
            <Typography variant='body1' fontWeight="bold">
                {t("C Panel")}
            </Typography>
            <ExpandMore/>
        </Button>
        <ReuseMenu
            menu={menu}
            closeMenu={closeMenu}
        >
            <List sx={{ p: 1 }}>
                {adminRoutes.map((text, index) => (
                    <ListItem
                        key={index}
                        onClick={() => navigate(`${text.route}`)} 
                        disablePadding
                    >
                        <ListItemButton 
                        sx={{
                            py: 1,
                            px: 3, 
                            mt: 2,
                            borderRadius: 2,
                            "&:hover": {
                                backgroundColor: "primary.main",
                                color: "text.white"
                            }
                        }}>
                            <Box sx={{fontSize: 25}}>
                                {text.icon}
                            </Box>
                            <Typography sx={{ml: 5}} variant='h6'>{t(text.title)}</Typography>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </ReuseMenu>
    </>
  )
}

export default MarketeerMenu