import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import Lottie from 'react-lottie-player'
import { pageNotFound } from '../../Assests/assets'
import { Link } from 'react-router-dom'

function PageNotFound() {
  return (
    <Stack
        sx={{
            margin: "0 auto",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}
    >
        <Stack>
            <Lottie 
                loop={true}
                play={true}
                animationData={pageNotFound}
                rendererSettings= {{
                    preserveAspectRatio: "xMidYMid slice"
                }}
            />
        </Stack>
        <Stack sx={{
            position: "absolute",
            top: "60%",
            textAlign: "center"
        }}>
            <Typography variant='h5' color="#4977F9" fontWeight="bold" py={1}>Page not found</Typography>
            <Link to="/home">
                <Button sx={{ color: "#4977F9" }}>
                    Go back to Home page
                </Button>
            </Link>
        </Stack>
    </Stack>
  )
}

export default PageNotFound