import { IconButton, MenuItem, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { BlueButton, InputField } from '../../Lib/MuiThemes/MuiComponents'
import { FiPhone } from "react-icons/fi"
import { MdOutlineMail } from "react-icons/md"
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import useSnackBar from '../../Lib/CustomHooks/useSnackBar'
import { Axios } from '../../Lib/Axios/AxiosConfig'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import PhoneInput from 'react-phone-input-2'
import * as locales from '@mui/x-date-pickers/locales';

function ConnectWithUs({ openConnectWithUsModal, setOpenConnectWithUsModal }) {

    const { t, i18n } = useTranslation();
    const [ loading, setLoading ] = useState(false);
    const { showSnackBar } = useSnackBar();
    const [ enquiryFormData, setEnquiryFormData ] = useState({
      name: "",
      number: "",
      email: "",
      city: "",
      destination: "",
      date: null,
      people: "none",
      packageFamily: "none"
    });
    console.log(enquiryFormData);
    const [ validateData, setValidateData ] = useState({
        name: null,
        number: null,
        email: null,
        city: null,
        destination: null,
        date: null,
        people: null,
        packageFamily: null
    });
  
    const handleChanges = (e) => {
      let { name, value } = e.target;
      setValidateData(prev => ({...prev, [name]: null}))
      setEnquiryFormData(prev => ({...prev, [name]: value}))
    }
  
    function isDataValidated(){
      let hasError = false;
  
      if(enquiryFormData.name === "" || enquiryFormData.name.length > 70){
          hasError = true;
          setValidateData(prev => ({...prev, name: t("Please enter a valid name")}))
      }
  
      if(enquiryFormData.email === "" || enquiryFormData.email.length > 70 || !/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(enquiryFormData.email)){
          hasError = true;
          setValidateData(prev => ({...prev, email: t("Please enter a valid email address")}))
      }
  
      if(enquiryFormData.number === ""){
          hasError = true;
          setValidateData(prev => ({...prev, number: t("Please enter a valid phone number")}))
      }

      if(enquiryFormData.city === "" || enquiryFormData.city.length > 50){
        hasError = true;
        setValidateData(prev => ({...prev, city: t("Please enter a valid city of residence")}))
      }

      if(enquiryFormData.destination === "" || enquiryFormData.destination.length > 50){
        hasError = true;
        setValidateData(prev => ({...prev, destination: t("Please enter destination")}))
      }

      if(!enquiryFormData.date){
        hasError = true;
        setValidateData(prev => ({...prev, date: t("Please enter date of travel")}))
      }

      if(enquiryFormData.people === "" || enquiryFormData.people === "none"){
        hasError = true;
        setValidateData(prev => ({...prev, people: t("Please enter a number of passengers")}))
      }

      if(enquiryFormData.packageFamily === "" || enquiryFormData.packageFamily === "none"){
        hasError = true;
        setValidateData(prev => ({...prev, packageFamily: t("Please enter tour package")}))
      }
  
      return hasError;
    }
  
    async function submitEnquiryForm(){
      if(!isDataValidated()){
          try{
              setLoading(true);
              const response = await Axios({
                  method: "post",
                  url: `enquiry`,
                  data: enquiryFormData,
                  maxBodyLength: Infinity,
                  validateStatus: function (status) {
                      if(status >= 400){
                          showSnackBar("error", `Error: ${status}, ${t("Error happened while submitting form, Please try again later")}`);   
                          setLoading(false);
                          throw new Error("Error");
                      }
                      return status;
                  }
              });
  
              if(response.status === 200){
                  showSnackBar("success", t("We've received your enquiry, We will get in touch within 24 hours")); 
                  setEnquiryFormData(prev => ({
                    ...prev,
                    name: "",
                    number: "",
                    email: "",
                    city: "",
                    destination: "",
                    date: "",
                    people: "",
                    packageFamily: ""
                  }))
                  setLoading(false);
              }
          }catch(error){
              console.log(error);
              showSnackBar("error", t("Error happened while submitting form, Please try again later")); 
              setLoading(false);
          }
      }
    }

  return (
    <form>
        <Stack 
            p={3}
            spacing={2} 
            borderRadius={3} 
            sx={{
                backgroundColor: "#F8F6FD",
             
            }}
            position="sticky"
            top={10}
          
        >
            
            {!openConnectWithUsModal && 
            <>
                <Typography 
                    variant='h4' 
                    color="text.main" 
                    fontWeight="bold"
                    textAlign="center"
                >
                    {t("stayConnected")}
                </Typography>
                <Stack spacing={1} alignItems="flex-start">
                    <Typography variant='h5' fontWeight="bold">FRANCE</Typography>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <FiPhone/>
                        <Typography component="a" href='tel:+330142097000' variant='body1' color="primary.main" sx={{ cursor: "pointer" }}>+33 (0) 1 42 09 70 00</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <MdOutlineMail/>
                        <Typography component="a" href="mailto:paris@errancesvoyages.com" variant='body1' color="primary.main" sx={{ cursor: "pointer" }}>paris@errancesvoyages.com</Typography>
                    </Stack>
                </Stack>
                <Stack spacing={1} alignItems="flex-start">
                    <Typography variant='h5' fontWeight="bold">INDIA</Typography>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <FiPhone/>
                        <Typography component="a" href="tel:+04132228600" variant='body1' color="primary.main" sx={{ cursor: "pointer" }}>0413 - 222 86 00</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <MdOutlineMail/>
                        <Typography component="a" href="mailto:pondy@errancesvoyages.com" variant='body1' color="primary.main" sx={{ cursor: "pointer" }}>pondy@errancesvoyages.com</Typography>
                    </Stack>
                </Stack>
            </>}
            <Stack direction="row" alignItems="center" justifyContent={openConnectWithUsModal ? "space-between" : "center"}>
                <Typography 
                    variant='h4' 
                    color="text.main" 
                    fontWeight="bold"
                    textAlign="center"
                >
                    {t("enquiryForm")}
                </Typography>
                {openConnectWithUsModal && <IconButton onClick={() => setOpenConnectWithUsModal(false)}>
                    <Close/>
                </IconButton>}
            </Stack>
            <Stack spacing={1} width="100%">
                <InputField
                    // required
                    size='small'
                    fullWidth
                    placeholder="Name"
                    type='text'
                    name="name"
                    value={enquiryFormData.name}
                    onChange={handleChanges}
                    error={validateData.name}
                    // error={isFormDataError["devis-form-firstname"]}
                    // helperText={isFormDataError["devis-form-firstname"] && "Firstname is required"}
                />
                {validateData.name && <Typography variant='subtitle1' color="error">{validateData.name}</Typography>}
            </Stack>
            <Stack spacing={1} width="100%">
                <InputField
                    // required
                    fullWidth
                    size='small'
                    placeholder="Email"
                    type='text'
                    name="email"
                    value={enquiryFormData.email}
                    onChange={handleChanges}
                    error={validateData.email}
                    // error={isFormDataError["devis-form-firstname"]}
                    // helperText={isFormDataError["devis-form-firstname"] && "Firstname is required"}
                />
                {validateData.email && <Typography variant='subtitle1' color="error">{validateData.email}</Typography>}

            </Stack>
            <Stack spacing={1} width="100%">
                <PhoneInput
                    specialLabel=''
                    country={'fr'}
                    inputStyle={{
                        borderColor: validateData.number && "red",
                        backgroundColor: "#FAFAFA",
                        height: 38
                    }}
                    // {...props}
                    fullWidth
                    placeholder="Phone number"
                    type='text'
                    name="number"
                    value={enquiryFormData.number}
                    onChange={(num) => {
                        setValidateData(prev => ({...prev, number: null}))
                        setEnquiryFormData(prev => ({...prev, number: num}))
                    }}
                />
                {validateData.number && <Typography variant='subtitle1' color="error">{validateData.number}</Typography>}

            </Stack>
            <Stack spacing={1} width="100%"> 
                <InputField
                    // required
                    size='small'
                    fullWidth
                    placeholder={t("cityOfResidence")}
                    type='text'
                    name="city"
                    value={enquiryFormData.city}
                    onChange={handleChanges}
                    error={validateData.city}
                    // error={isFormDataError["devis-form-firstname"]}
                    // helperText={isFormDataError["devis-form-firstname"] && "Firstname is required"}
                />
                {validateData.city && <Typography variant='subtitle1' color="error">{validateData.city}</Typography>}

            </Stack>
            <Stack spacing={1} width="100%">  
                <InputField
                    // required
                    size='small'
                    fullWidth
                    placeholder={t("travelDestination")}
                    type='text'
                    name="destination"
                    value={enquiryFormData.destination}
                    onChange={handleChanges}
                    error={validateData.destination}
                    // error={isFormDataError["devis-form-firstname"]}
                    // helperText={isFormDataError["devis-form-firstname"] && "Firstname is required"}
                />
                {validateData.destination && <Typography variant='subtitle1' color="error">{validateData.destination}</Typography>}

            </Stack>
            <Stack spacing={1} width="100%"> 
                <LocalizationProvider 
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n?.language}
                >
                    <MobileDatePicker
                        disablePast
                        format='DD-MM-YYYY'
                        value={enquiryFormData.date ? dayjs(enquiryFormData.date) : null}
                        onChange={(newValue) => {
                            if(newValue !== null){
                                setValidateData(prev => ({...prev, date: null}))
                                setEnquiryFormData(prev => ({...prev, date: dayjs(newValue).format("YYYY-MM-DD")}))
                            }
                        }}
                        slots={{
                            textField: (params) => <InputField 
                                fullWidth
                                name="date"
                                size="small" 
                                placeholder={t("travelDate")}
                                {...params}
                                inputProps={
                                    { 
                                      ...params.inputProps, 
                                        placeholder: t("travelDate")


                                    }
                                }
                                error={validateData.date} 
                            />
                        }}
                        
                    />
                </LocalizationProvider>
                {validateData.date && <Typography variant='subtitle1' color="error">{validateData.date}</Typography>}

            </Stack>
            <Stack spacing={1} width="100%">
                <InputField
                    select
                    size='small'
                    fullWidth
                    placeholder="No.of people"
                    name="people"
                    value={enquiryFormData.people}
                    onChange={handleChanges}
                    error={validateData.people}
                >
                    <MenuItem value="none" disabled>{t("selectNoOfPassengers")}</MenuItem>
                    {Array.from({ length: 9 }, (_, i) => i + 1).map((day) => (
                        <MenuItem key={day} value={day}>{day}</MenuItem>
                    ))}
                </InputField>
                {validateData.people && <Typography variant='subtitle1' color="error">{validateData.people}</Typography>}

            </Stack>
            <Stack spacing={1} width="100%">
                <InputField
                    // required
                    select
                    size='small'
                    fullWidth
                    placeholder="Vacation type"
                    type='text'
                    name="packageFamily"
                    value={enquiryFormData.packageFamily}
                    onChange={handleChanges}
                    error={validateData.packageFamily}
                    // erro
                    // helperText={isFormDataError["devis-form-firstname"] && "Firstname is required"}
                >
                    <MenuItem value="none" disabled>{t("selectPackageType")}</MenuItem>
                    <MenuItem value="honeymoon">Honeymoon trip</MenuItem>
                    <MenuItem value="family">Family trip</MenuItem>
                    <MenuItem value="friends">Friends trip</MenuItem>
                    <MenuItem value="corporate">Corporate trip</MenuItem>
                    <MenuItem value="devotional">Devotional trip</MenuItem>
                </InputField>
                {validateData.packageFamily && <Typography variant='subtitle1' color="error">{validateData.packageFamily}</Typography>}

            </Stack>
            <BlueButton loading={loading} onClick={submitEnquiryForm}>Submit</BlueButton>
        </Stack>
    </form>
  )
}

export default ConnectWithUs