import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dictionary: {},
    data: []
};

export const flightPricePayloadSlice = createSlice({
    name: "flightPricePayload",
    initialState,
    reducers: {
        setDictionary: (state, action) => {
            return {...state, dictionary: action.payload}
        },
        removeDictionary: (state) => {
            return {...state, dictionary: {}}
        },
        setFlightPricePayload: (state, action) => {
            let { index, value } = action.payload;
            state.data[index] = value;
            return state;
        },
        removeFlightPricePayload: (state, action) => {
            // return state.data.map((payload, index) => action.payload !== index ? payload : null);
            state.data[action.payload] = null;
            return state;
        } 
    }
});

export const { setDictionary, removeDictionary, setFlightPricePayload, removeFlightPricePayload } = flightPricePayloadSlice.actions;
export const flightPricePayloadReducer = flightPricePayloadSlice.reducer;