import { Splide, SplideSlide } from '@splidejs/react-splide';
import React, { useEffect, useRef } from 'react';
import '@splidejs/react-splide/css';
import "./css/tourSplide.css";

export default function TourCarousel({carouselImgArray}){
    
  const mainRef = useRef();
  const thumbsRef = useRef();

  const mainOptions = {
    type      : 'loop',
    perPage   : 1,
    perMove   : 1,
    gap       : '1rem',
    pagination: false,
    arrows: false
  };

  const thumbsOptions = {
    type        : 'slide',
    rewind      : true,
    gap         : '1rem',
    pagination  : false,
    fixedWidth  : "25%",
    fixedHeight : 70,
    cover       : true,
    focus       : 'center',
    isNavigation: true,
    arrows: false
  };

//   componentDidMount() {
//     if ( this.mainRef.current && this.thumbsRef.current && this.thumbsRef.current.splide ) {
//       this.mainRef.current.sync( this.thumbsRef.current.splide );
//     }
//   }

  useEffect(() => {
    if ( mainRef.current && thumbsRef.current && thumbsRef.current.splide ) {
        mainRef.current.sync( thumbsRef.current.splide );
    }
  }, [])

  /**
   * Render slides.
   *
   * @return Slide nodes.
   */
  function renderSlides() {
    return carouselImgArray.map(( slide, i )  => (
      <SplideSlide key={ slide.src }>
        <img src={ slide } alt={`img${i}`}/>
      </SplideSlide>
    ) );
  }

    

    return (
      <div className="wrapper" >

        <Splide
          options={ mainOptions }
          ref={ mainRef }
          aria-labelledby="thumbnail-slider-example"
        >
          { renderSlides() }
        </Splide>

        <Splide
          options={ thumbsOptions }
          ref={ thumbsRef }
          aria-label="The carousel with thumbnails. Selecting a thumbnail will change the main carousel"
        >
          { renderSlides() }
        </Splide>
      </div>
    );
}

// function TourCarousel(){

//     // const splide = new Splide("#main-slider", {
//     //     width: 600,
//     //     height: 300,
//     //     pagination: false,
//     //     cover: true
//     // });

//     // console.log(splide);

//     const thumbnails = document.querySelectorAll(".thumbnail");
//     let current;

//     // for (var i = 0; i < thumbnails.length; i++) {
//     //     initThumbnail(thumbnails[i], i);
//     // }

//     function initThumbnail(index) {
//         console.log(index);

//         let thumbnail = document.querySelectorAll(".splide__slide")[index];
//         console.log(thumbnail);
//         // if (thumbnail) {
//         //     if (current) {
//         //         current.classList.remove("is-active");
//         //     }

//         //     thumbnail.classList.add("is-active");
//         //     current = thumbnail;
//         // }
//         // thumbnail.addEventListener("click", function () {
//         //     // console.log(thumbnails[index]);
//         //     console.log(splide.go(index));
//         // });
//     }

//     // splide.render("mounted move", function () {
//     //     var thumbnail = thumbnails[splide.index];

//     //     if (thumbnail) {
//     //         if (current) {
//     //             current.classList.remove("is-active");
//     //         }

//     //         thumbnail.classList.add("is-active");
//     //         current = thumbnail;
//     //     }
//     // });

//     // splide.mount();

//     return(
//     <>
//     <section id="main-slider" className="splide" aria-label="My Awesome Gallery">
//       <div className="splide__track">
//         <ul className="splide__list">
//           <li className="splide__slide is-active">
//             <img
//               src="https://images.pexels.com/photos/17591272/pexels-photo-17591272/free-photo-of-light-landscape-man-people.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
//               alt="img"
//             />
//           </li>
//           <li className="splide__slide">
//             <img
//               src="https://images.pexels.com/photos/13383722/pexels-photo-13383722.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
//               alt="img"
//             />
//           </li>
//           <li className="splide__slide">
//             <img
//               src="https://images.pexels.com/photos/13383722/pexels-photo-13383722.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
//               alt="img"
//             />
//           </li>
//           <li className="splide__slide">
//             <img
//               src="https://images.pexels.com/photos/13383722/pexels-photo-13383722.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
//               alt="img"
//             />
//           </li>
//         </ul>
//       </div>
//     </section>

//     <ul id="thumbnails" className="thumbnails">
//       <li onClick={() => initThumbnail(0)} className="thumbnail">
//         <img src="https://source.unsplash.com/random/1000x1000?sig=1" alt="" />
//       </li>
//       <li onClick={() => initThumbnail(1)} className="thumbnail">
//         <img src="https://source.unsplash.com/random/1000x1000?sig=2" alt="" />
//       </li>
//       <li onClick={() => initThumbnail(2)} className="thumbnail">
//         <img src="https://source.unsplash.com/random/1000x1000?sig=3" alt="" />
//       </li>
//       <li onClick={() => initThumbnail(3)} className="thumbnail">
//         <img src="https://source.unsplash.com/random/1000x1000?sig=4" alt="" />
//       </li>
//     </ul>
//     </>
//     )
// }

// function TourCarousel({carouselImgArray}) {

//   return (
//         <Splide
//             style={{ minWidth: "100%", padding: 0 }} 
//             options={{
//                 rewind: true,
//             }}
//         >
//             {carouselImgArray.map((tour, i) => (
//                 <SplideSlide key={i} style={{width: "100%"}}>
//                     <Box sx={{width: "100%", height: "500px", overflow: "hidden"}}>
//                         <img
//                             width="100%"
//                             height="100%"
//                             style={{ objectFit: "cover" }}
//                             src={tour} alt="img"
//                         />
//                     </Box>
//                 </SplideSlide>
//             ))}
//         </Splide>
//   )
// }

// export default TourCarousel