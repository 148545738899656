import { createSlice } from "@reduxjs/toolkit";

const initialState = [
    {
        name: "flexiFares",
        value: false
    },
    {
        name: "sort",
        value: false
    },
    {
        name: "stops",
        value: []
    },
];

export const filterSlice = createSlice({
    name: "filterArray",
    initialState,
    reducers: {
        insert: (state, action) => {
            const { name, value } = action.payload;
            return state.map(item => {
                if(item.name === name){
                    return { name, value }
                }

                return item
            })
        },
        removeFilterByName: (state, action) => {
            return state.map(item => {
                if(item.name === action.payload){
                    return { name: action.payload, value: null }
                }

                return item
            })
        },
    }
});

export const { insert, removeFilterByName } = filterSlice.actions;
export const filterArrayReducer = filterSlice.reducer;