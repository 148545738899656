import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { BlackButtonOutlined, BlueButton, FlightSearchLoader } from "../../../Lib/MuiThemes/MuiComponents";
import FlightListCard from "./FlightListCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoaderConsumer } from "../../../Lib/Contexts/LoaderContext";
import { t } from "i18next";
import { noFlightsFound } from "../../../Assests/assets";
import Lottie from "react-lottie-player";
import { useSelector } from "react-redux";

function FlightListings({cardData, showValue, setShowValue, originName, destinationName, combinableFlightTabFocus}){
    
    const { flightPricePayload, flightResultFilter } = useSelector(data => data);
    const [ cardList, setCardList ] = useState("");
    const [ searchParams ] = useSearchParams();
    const [ isLoading ] = LoaderConsumer();
    const navigate = useNavigate();

    //dummy state
    const [ mount, setMount ] = useState(false);

    /* Show more button function */
    function showMoreFlights(){
        setShowValue(prev => prev + 10);
    }

    /* Show less button function */
    function showLessFlights(){
        setShowValue(10)
    }

    // const filteredList = useCallback((list) => {

    //     let arr = list;

    //     if(flightResultFilter.corporateFares){
    //         arr = list.filter(data => data.fareType !== "PUBLISHED");
    //     }

    //     if(flightResultFilter.stops.length !== 0 || !!flightResultFilter.duration.departure || !!flightResultFilter.duration.return || flightResultFilter.airlines.length !== 0 || !!flightResultFilter.departureTime || !!flightResultFilter.arrivalTime){
            
            
    //         return arr.filter(data => {
                
    //             let value = false;

    //             if((searchParams.get("tripType") === "oneway" || searchParams.get("isCombinable") === "1") && Array.isArray(flightResultFilter.stops) && flightResultFilter.stops.includes(data.stops.toString())){
    //                 value = true;
    //             }

    //             if(searchParams.get("tripType") === "twoway" && searchParams.get("isCombinable") === "0" && Array.isArray(flightResultFilter.stops) && flightResultFilter.stops.includes(data.stops.toString()) && flightResultFilter.stops.includes(data.returnStops.toString())){
    //                 value = true;
    //             }

    //             if(searchParams.get("tripType") === "multi" && Array.isArray(flightResultFilter.stops) && data.stops.every(ele => flightResultFilter.stops.includes(ele.toString()))){
    //                 value = true;
    //             }
                
    //             if((searchParams.get("tripType") === "oneway" || searchParams.get("isCombinable") === "1") && Number(data?.duration && data?.duration.split("h")[0]) < Number(flightResultFilter.duration.departure)){
    //                 value = true;
    //             }

    //             if(searchParams.get("tripType") === "twoway" && searchParams.get("isCombinable") === "0" && !!flightResultFilter.duration.return && (!!!flightResultFilter.duration.departure) && Number(data?.returnDuration && data?.returnDuration.split("h")[0]) < Number(flightResultFilter.duration.return)){
    //                 value = true;
    //             }

    //             if(searchParams.get("tripType") === "twoway" && searchParams.get("isCombinable") === "0" && !!flightResultFilter.duration.departure && (!!!flightResultFilter.duration.return) && Number(data?.duration && data?.duration.split("h")[0]) < Number(flightResultFilter.duration.departure)){
    //                 value = true;
    //             }

    //             if(searchParams.get("tripType") === "twoway" && searchParams.get("isCombinable") === "0"){

    //                 if(!!flightResultFilter.duration.return && !!flightResultFilter.duration.departure && Number(data?.returnDuration && data?.returnDuration.split("h")[0]) < Number(flightResultFilter.duration.return) && Number(data?.duration && data?.duration.split("h")[0]) < Number(flightResultFilter.duration.departure)){
    //                     value = true
    //                 }

    //             }

    //             if(searchParams.get("tripType") === "multi" && data.duration.every(val => Number(val && val.split("h")[0]) < Number(flightResultFilter.duration.departure))){
    //                 value = true;
    //             }

    //             if((searchParams.get("tripType") === "oneway" || searchParams.get("isCombinable") === "1") && Array.isArray(flightResultFilter.airlines) && flightResultFilter.airlines.includes(data.airlinesName)){
    //                 value = true;
    //             }

    //             if(searchParams.get("tripType") === "twoway" && searchParams.get("isCombinable") === "0" && Array.isArray(flightResultFilter.airlines) && flightResultFilter.airlines.includes(data.airlinesName) && flightResultFilter.airlines.includes(data.returnAirlinesName)){
    //                 value = true;
    //             }

    //             if(searchParams.get("tripType") === "multi" && Array.isArray(flightResultFilter.airlines) && data.airlinesName.every(ele => flightResultFilter.airlines.includes(ele))){
    //                 value = true;
    //             }

    //             if((searchParams.get("tripType") === "oneway" || searchParams.get("isCombinable") === "1") && flightResultFilter.departureTime && Number(data.originTime.slice(0, 2)) > Number(flightResultFilter.departureTime.split(",")[0]) && Number(data.originTime.slice(0, 2)) <= Number(flightResultFilter.departureTime.split(",")[1])){
    //                 value = true;
    //             }

    //             if((searchParams.get("tripType") === "oneway" || searchParams.get("isCombinable") === "1") && flightResultFilter.arrivalTime && Number(data.destinationTime.slice(0, 2)) > Number(flightResultFilter.arrivalTime.split(",")[0]) && Number(data.destinationTime.slice(0, 2)) <= Number(flightResultFilter.arrivalTime.split(",")[1])){
    //                 value = true;
    //             }

    //             if(
    //                 searchParams.get("tripType") === "twoway" 
    //                 && 
    //                 searchParams.get("isCombinable") === "0"
    //                 && 
    //                 flightResultFilter.departureTime 
    //                 && 
    //                 ((Number(data.originTime.slice(0, 2)) > Number(flightResultFilter.departureTime.split(",")[0]) && Number(data.originTime.slice(0, 2)) <= Number(flightResultFilter.departureTime.split(",")[1]))
    //                 ||
    //                 (Number(data.returnOriginTime.slice(0, 2)) > Number(flightResultFilter.departureTime.split(",")[0]) && Number(data.returnOriginTime.slice(0, 2)) <= Number(flightResultFilter.departureTime.split(",")[1])))
    //             ){
    //                 value = true;
    //             }

    //             if(
    //                 searchParams.get("tripType") === "twoway" 
    //                 && 
    //                 searchParams.get("isCombinable") === "0"
    //                 && 
    //                 flightResultFilter.arrivalTime 
    //                 && 
    //                 ((Number(data.destinationTime.slice(0, 2)) > Number(flightResultFilter.arrivalTime.split(",")[0]) && Number(data.destinationTime.slice(0, 2)) <= Number(flightResultFilter.arrivalTime.split(",")[1]))
    //                 ||
    //                 (Number(data.returnDestinationTime.slice(0, 2)) > Number(flightResultFilter.arrivalTime.split(",")[0]) && Number(data.returnDestinationTime.slice(0, 2)) <= Number(flightResultFilter.arrivalTime.split(",")[1])))
    //             ){
    //                 value = true;
    //             }

    //             if(searchParams.get("tripType") === "multi" && flightResultFilter.departureTime && data.originTime.some(time => Number(time.slice(0, 2)) > Number(flightResultFilter.departureTime.split(",")[0]) && Number(time.slice(0, 2)) <= Number(flightResultFilter.departureTime.split(",")[1]))){
    //                 value = true;
    //             }

    //             if(searchParams.get("tripType") === "multi" && flightResultFilter.arrivalTime && data.destinationTime.some(time => Number(time.slice(0, 2)) > Number(flightResultFilter.arrivalTime.split(",")[0]) && Number(time.slice(0, 2)) <= Number(flightResultFilter.arrivalTime.split(",")[1]))){
    //                 value = true;
    //             }

    //             return value;
    //         })

    //     }
        
    //     return arr;
        

    // }, [ flightResultFilter ])

    // useEffect(() => {

    //     if(flightResultFilter.price){
            
    //         // descending
    //         let filteredCardData = filteredList(cardData);
    //         setCardList(filteredCardData.sort((a, b) => parseFloat(b.totalPrice) - parseFloat(a.totalPrice)));
    //         setMount(false);

    //     }else{
    //         // ascending
    //         let filteredCardData = filteredList(cardData);
    //         setCardList(filteredCardData.sort((a, b) => parseFloat(a.totalPrice) - parseFloat(b.totalPrice))); 
    //         setMount(true);
    //     }

        

    // }, [ cardData, flightResultFilter, filteredList, showValue ]);


    const applyFilter = useCallback((data) => {

        let filterData = data.sort((a, b) => parseFloat(a.totalPrice) - parseFloat(b.totalPrice))
        
        if(flightResultFilter.corporateFares){
            filterData = filterData.filter(data => data.fareType !== "PUBLISHED");
        }

        if(flightResultFilter.price){
            filterData = filterData.sort((a, b) => parseFloat(b.totalPrice) - parseFloat(a.totalPrice));
        }

        // one way and combinable flights filter

        if((searchParams.get("tripType") === "oneway" || searchParams.get("isCombinable") === "1")){

            if(flightResultFilter.stops.length > 0){
                filterData = filterData.filter(data => flightResultFilter.stops.includes(data.stops.toString()))
            }

            if(flightResultFilter.duration.departure){
                filterData = filterData.filter(data => Number(data?.duration.split("h")[0]) < Number(flightResultFilter.duration.departure))
                
            }

            if(flightResultFilter.departureTime){
                filterData = filterData.filter(data => Number(data.originTime.slice(0, 2)) > Number(flightResultFilter.departureTime.split(",")[0]) && Number(data.originTime.slice(0, 2)) <= Number(flightResultFilter.departureTime.split(",")[1]))
            }

            if(flightResultFilter.arrivalTime){
                filterData = filterData.filter(data => Number(data.destinationTime.slice(0, 2)) > Number(flightResultFilter.arrivalTime.split(",")[0]) && Number(data.destinationTime.slice(0, 2)) <= Number(flightResultFilter.arrivalTime.split(",")[1]))
            }

            if(flightResultFilter.airlines.length > 0){
                filterData = filterData.filter(data => flightResultFilter.airlines.includes(data.airlinesName))
            }
        }

        // two way flights filters

        if((searchParams.get("tripType") === "twoway" && searchParams.get("isCombinable") === "0")){

            if(flightResultFilter.stops.length > 0){
                filterData = filterData.filter(data => flightResultFilter.stops.includes(data.stops.toString()) || flightResultFilter.stops.includes(data.returnStops.toString()))
            }

            if(flightResultFilter.duration.departure){
                filterData = filterData.filter(data => Number(data?.duration && data?.duration.split("h")[0]) < Number(flightResultFilter.duration.departure))
            }

            if(flightResultFilter.duration.return){
                filterData = filterData.filter(data => Number(data?.returnDuration.split("h")[0]) < Number(flightResultFilter.duration.return))
            }

            if(flightResultFilter.departureTime){
                filterData = filterData.filter(data => 
                    ((Number(data.originTime.slice(0, 2)) > Number(flightResultFilter.departureTime.split(",")[0]) && Number(data.originTime.slice(0, 2)) <= Number(flightResultFilter.departureTime.split(",")[1]))
                    &&
                    (Number(data.returnOriginTime.slice(0, 2)) > Number(flightResultFilter.departureTime.split(",")[0]) && Number(data.returnOriginTime.slice(0, 2)) <= Number(flightResultFilter.departureTime.split(",")[1])))
                )
            }

            if(flightResultFilter.arrivalTime){
                filterData = filterData.filter(data => 
                    ((Number(data.destinationTime.slice(0, 2)) > Number(flightResultFilter.arrivalTime.split(",")[0]) && Number(data.destinationTime.slice(0, 2)) <= Number(flightResultFilter.arrivalTime.split(",")[1]))
                    &&
                    (Number(data.returnDestinationTime.slice(0, 2)) > Number(flightResultFilter.arrivalTime.split(",")[0]) && Number(data.returnDestinationTime.slice(0, 2)) <= Number(flightResultFilter.arrivalTime.split(",")[1])))    
                )
            }

            if(flightResultFilter.airlines.length > 0){
                filterData = filterData.filter(data => flightResultFilter.airlines.includes(data.airlinesName) && flightResultFilter.airlines.includes(data.returnAirlinesName))
            }

            
        }

        // multi city flight filters

        if((searchParams.get("tripType") === "multi" && searchParams.get("isCombinable") === "0")){

            if(flightResultFilter.stops.length > 0){
                filterData = filterData.filter(data => data.stops.every(ele => flightResultFilter.stops.includes(ele.toString())))
            }

            if(flightResultFilter.duration.departure){
                filterData = filterData.filter(data => data.duration.every(val => Number(val && val.split("h")[0]) < Number(flightResultFilter.duration.departure)))
            }

            if(flightResultFilter.departureTime){
                filterData = filterData.filter(data => data.originTime.some(time => Number(time.slice(0, 2)) > Number(flightResultFilter.departureTime.split(",")[0]) && Number(time.slice(0, 2)) <= Number(flightResultFilter.departureTime.split(",")[1])))
            }

            if(flightResultFilter.arrivalTime){
                filterData = filterData.filter(data => data.destinationTime.some(time => Number(time.slice(0, 2)) > Number(flightResultFilter.arrivalTime.split(",")[0]) && Number(time.slice(0, 2)) <= Number(flightResultFilter.arrivalTime.split(",")[1])))
            }

            if(flightResultFilter.airlines.length > 0){
                filterData = filterData.filter(data => data.airlinesName.every(ele => flightResultFilter.airlines.includes(ele)))
            }
        }

        return filterData;
    }, [ 
        flightResultFilter.corporateFares, 
        flightResultFilter.price, 
        flightResultFilter.stops, 
        flightResultFilter.duration.departure,
        flightResultFilter.departureTime,
        flightResultFilter.arrivalTime,
        flightResultFilter.airlines,
        flightResultFilter.duration.return,
        searchParams  
    ])


    useEffect(() => {

        let filteredCardData = applyFilter(cardData);
        setCardList(filteredCardData);
        setMount(prev => !prev);
        // console.log(cardList);
    }, [ cardData, showValue, applyFilter ]);
    
    /* Combinable flight price function */
    
    function triggerCombinableFlightPrice(){

        navigate("/bookingdetails", { state: { 
            dictionary: flightPricePayload.dictionary, 
            data: [...flightPricePayload.data],
            searchKeys: {
                origin: searchParams.get("origin"),
                destination: searchParams.get("destination"),
                departureDate: searchParams.get("departureDate"),
                trip: "OWC"
            },
            flightNumber: cardData.flightNumber,
            refId: sessionStorage.getItem("refId")
        }});
    }
        
    return(
        <Box sx={{
            minHeight: "100vh",
            width: "auto",
        }}>
            {cardList?.length > 0 && (
                <Stack direction="row" justifyContent="space-between" alignItems="center" >
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        p: 1
                    }}>
                        <Typography variant="h4" color="text.main">{t("flightsFrom")} {originName} - {destinationName}</Typography>
                        <Typography variant="body1" color="text.main">{t("showingResults")} {cardList.slice(0, showValue).length} {t("outof")} {cardList.length}</Typography>
                    </Box>
                    {!!parseInt(searchParams.get("isCombinable")) && 
                        <BlueButton 
                            onClick={triggerCombinableFlightPrice} 
                            sx={{maxWidth: 150}}
                            disabled={flightPricePayload.data.length !== 2 || flightPricePayload.data.some(payload => payload === null)}
                        >
                            Book now
                        </BlueButton>
                    }
                </Stack>
            )}
            <Box sx={{
                display: "flex", 
                flexDirection: "column",
                // border: "1px solid black",  
                gap: "25px",
                py: 2
            }}>
                {!isLoading && Array.isArray(cardList) && cardList.length === 0 &&
                    <>
                    <Lottie 
                        style={{
                            height: 300,
                            width: 500,
                            margin: "0 auto"
                        }}
                        loop={true}
                        play={true}
                        animationData={noFlightsFound}
                        rendererSettings= {{
                            preserveAspectRatio: "xMidYMid slice"
                        }}
                    />
                    <Typography variant="h5" color="text.main" textAlign="center" mr={5}>No flights found</Typography>
                    </>
                }
                
                {cardList.length > 0 && cardList.slice(0, showValue).map((card, index) => (
                    <FlightListCard 
                        key={index} 
                        cardData={card}
                        combinableFlightTabFocus={combinableFlightTabFocus}
                    ></FlightListCard>
                ))}

                {!isLoading && cardList.length > 0 && showValue < cardList.length && <BlackButtonOutlined onClick={showMoreFlights}>{t("showMoreResult")}</BlackButtonOutlined>} 
                {!isLoading && cardList.length > 9 && showValue >= cardList.length && <BlackButtonOutlined onClick={showLessFlights}>{t("showLessResult")}</BlackButtonOutlined>} 
            </Box>
        </Box>
    )
}

export default FlightListings