import React, { createContext, useEffect, useState } from "react";

const BookingObjContext = createContext();

function BookingObjContextProvider(props){

    // const seatObj = JSON.parse(sessionStorage.getItem("seatObj")).data[0];
    const [bookingObj, setBookingObj] = useState({});
    
    return(
        <BookingObjContext.Provider value={{bookingObj, setBookingObj}}>
           {props.children}
        </BookingObjContext.Provider>
    )
}


export {BookingObjContextProvider, BookingObjContext}