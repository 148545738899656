// functional and config imports
import React, { useCallback, useEffect, useState } from 'react';
import { capitalize } from '../../Lib/utils/helperFunctions';
import useLoader from '../../Lib/CustomHooks/useLoader';
import { motion } from "framer-motion";

// ui imports
import { Autocomplete, Box, Checkbox, ClickAwayListener, FormControlLabel, FormGroup, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Stack, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { BlueButton, BlueButtonDashed } from '../../Lib/MuiThemes/MuiComponents';
import {FiSend} from "react-icons/fi";
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSnackBar from '../../Lib/CustomHooks/useSnackBar';
import { AddCircleOutline, Delete, RemoveCircleOutline } from '@mui/icons-material';
import useCounter from '../../Lib/CustomHooks/useCounter';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import MultiCityInputs from './MultiCityInputs';
import useLanguageConsumer from '../../Lib/CustomHooks/useLanguageConsumer';
import airports from "../../Lib/utils/airport.json";
import "../FightSearch/styles/search_box.css";
import { specialFaresAnimation } from '../../Assests/assets';
import Lottie from 'react-lottie-player';
import EthnicFaresModal from './EthnicFaresModal';
import { nanoid } from '@reduxjs/toolkit';

import * as locales from '@mui/x-date-pickers/locales';

const checkboxStyle = {
    color: "primary.main",
    "&:hover": {
        backgroundColor: "transparent",
    },
    "&.Mui-checked": {
        color: "primary.main",
    },
    "&.Mui-disabled": {
        backgroundColor: "veryLightBlue.main"
    }
};

const toggleStyle = {
    color: "primary.main",
    "&:hover": {
        backgroundColor: "transparent",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: "primary.main"
    },
    "&.Mui-disabled": {
        backgroundColor: "veryLightBlue.main"
    }
}

const inputStyle = {
    input: {
        color: 'black',
    },
    '& label': {
        color: "black",
    },
    '& label.Mui-focused': {
        color: "black",
    },
    '& .MuiFormLabel-root.Mui-disabled': {
        color: "black"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'black',
        },
        '&:hover fieldset': {
          borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'black',
        },
    },
}

function SearchFlightBox(props) {

  /************************** States and Variables ******************************/  
  
  const navigate = useNavigate()
  const [originKey, setOriginKey] = useState({searchKey: "", options: []})
  const [destinationKey, setDestinationKey] = useState({searchKey: "", options: []})
  const [adultCount, increaseAdultCount, decreaseAdultCount] = useCounter(1);
  const [childrenCount, increaseChildrenCount, decreaseChildrenCount] = useCounter(0);
  const [infantCount, increaseInfantCount, decreaseInfantCount] = useCounter(0);
  const [isLoading, startLoading, restLoading] = useLoader();
  const { t, i18n } = useTranslation();
  const { showSnackBar } = useSnackBar();
  const { country } = useLanguageConsumer();

  const [searchData, setSearchData] = useState({
    origin: "CDG",
    originName: "Charles de Gaulle Intl ",
    destination: "",
    destinationName: "",
    trip: "twoway",
    isCombinable: false,
    isMultiCity: false,
    departureDate: null,
    returnDate: null,
    class: "ECONOMY",
    corporateFare: false
  });

  const [ multiCityData, setMultiCityData ] = useState([
    {
        origin: "",
        originName: "",
        destination: "",
        destinationName: "",
        departureDate: null,
        minDepartureDate: null
    }
  ]);
  
  const [ searchParams ] = useSearchParams();
  const [ openEthnicFaresModal, setOpenEthnicFaresModal ] = useState(false);
  const [ triggerFlightSearchThroughEthnicFaresModal, setTriggerFlightSearchThroughEthnicFaresModal ] = useState(false);
  
  

  // popper
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  /**************API call : Flight suggestion for origin and destination****************/

//   useEffect(() => {   
//     if(suggestRef.current && originKey.searchKey !== ""){
//         (async () => {
//             try{
//                 startLoading();
//                 // const verifyId = await auth.currentUser.getIdToken();
//                 const fetch = await Axios({
//                     url: `airports?keyword=${originKey.searchKey}`,
//                     method: "get",
//                     // cancelToken: controller.token
//                 });

//                 if(fetch.status === 200){
//                     setOriginKey(prev => ({...prev, options: fetch.data}))
//                 }
//                 // console.log(fetch);
//             }catch(err){
//                 console.error(err)
//             }finally{
//                 restLoading();
//             }
//         })()
//         suggestRef.current = false;
        
//     }

//     return () => {
//         suggestRef.current = true;
//     }
//   },[originKey.searchKey]);

//   useEffect(() => {
//     if(suggestRef.current && destinationKey.searchKey !== ""){
//         (async () => {
//             try{
//                 startLoading();
//                 // const verifyId = await auth.currentUser.getIdToken();
//                 const fetch = await Axios({
//                     url: `/airports?keyword=${destinationKey.searchKey}`,
//                     method: "get",
//                     // cancelToken: controller.token
//                 });
//                 if(fetch.status === 200){
//                     setDestinationKey(prev => ({...prev, options: fetch.data}))
//                 }
//             }catch(err){
//                 console.error(err)
//             }finally{
//                 restLoading();
//             }
//         })()
//         suggestRef.current = false;
//     }

//     return () => {
//         suggestRef.current = true;
//     }
//   },[destinationKey.searchKey]);

/***************************API call : Search Flight ******************************/

  const triggerSearch = useCallback(async () => {
    if(searchData.origin === ""){
        // console.error("please fill the required field");
        return showSnackBar("error", "Please enter your place of origin in From field")
    }

    if(searchData.destination === ""){
        return showSnackBar("error", "Please enter your place of destination in To field")
    }

    if(!searchData.departureDate){
        return showSnackBar("error", "Please enter departure date")
    }

    if(searchData.trip === "twoway" && !searchData.returnDate){
        return showSnackBar("error", "Please enter return date")
    }

    if(searchData.isMultiCity){
        let multiCityReducedData = multiCityData.reduce((acc, curr) => {
            if(!!curr.origin){
                acc.origin = [...acc.origin, curr.origin]
            }

            if(!!curr.originName){
                acc.originName = [...acc.originName, curr.originName]
            }

            if(!!curr.destination){
                acc.destination = [...acc.destination, curr.destination]
            }

            if(!!curr.destinationName){
                acc.destinationName = [...acc.destinationName, curr.destinationName]
            }

            if(!!curr.departureDate){
                acc.departureDate = [...acc.departureDate, curr.departureDate]
            }
            return acc;
        }, {
            origin: [searchData.origin],
            originName: [searchData.originName],
            destination: [searchData.destination],
            destinationName: [searchData.destinationName],
            departureDate: [searchData.departureDate]
        });

        // console.log(multiCityReducedData);
        searchParams.set("tripType", searchData.trip);
        searchParams.set("isCombinable", Number(searchData.isCombinable));
        searchParams.set("isMultiCity", Number(searchData.isMultiCity));
        searchParams.set("origin", multiCityReducedData.origin);
        searchParams.set("originName", multiCityReducedData.originName);
        searchParams.set("destination", multiCityReducedData.destination);
        searchParams.set("destinationName", multiCityReducedData.destinationName);
        searchParams.set("departureDate", multiCityReducedData.departureDate);
        searchParams.set("returnDate", searchData.returnDate);
        searchParams.set("adults", adultCount);
        searchParams.set("children", childrenCount);
        searchParams.set("infants", infantCount);
        searchParams.set("travelClass", searchData.class);
        searchParams.set("currencyCode", country.currency);
        searchParams.set("corporateCode", searchData.corporateFare);
        searchParams.set("searchId", nanoid());
    }else{
        searchParams.set("tripType", searchData.trip);
        searchParams.set("isCombinable", Number(searchData.isCombinable));
        searchParams.set("isMultiCity", Number(searchData.isMultiCity));
        searchParams.set("origin", searchData.origin);
        searchParams.set("originName", searchData.originName);
        searchParams.set("destination", searchData.destination);
        searchParams.set("destinationName", searchData.destinationName);
        searchParams.set("departureDate", searchData.departureDate);
        searchParams.set("returnDate", searchData.returnDate);
        searchParams.set("adults", adultCount);
        searchParams.set("children", childrenCount);
        searchParams.set("infants", infantCount);
        searchParams.set("travelClass", searchData.class);
        searchParams.set("currencyCode", country.currency);
        searchParams.set("corporateCode", searchData.corporateFare);
        searchParams.set("searchId", nanoid());
    }
    
    return navigate(`/flights?${searchParams}`)
  }, [ adultCount, childrenCount, country.currency, infantCount, multiCityData, navigate, searchData.class, searchData.corporateFare, searchData.departureDate, searchData.destination, searchData.destinationName, searchData.isCombinable, searchData.isMultiCity, searchData.origin, searchData.originName, searchData.returnDate, searchData.trip, searchParams ])

  const initiateSearch = () => {
    if(searchData.corporateFare){
        triggerSearch()
    }else{
        setOpenEthnicFaresModal(true);
    }
  }

  useEffect(() => {
    if(triggerFlightSearchThroughEthnicFaresModal){
        triggerSearch();
        setTriggerFlightSearchThroughEthnicFaresModal(false)
    }
  }, [ triggerFlightSearchThroughEthnicFaresModal ])

  return (
    <>
    <Box sx={{
        height: "auto",
        width: "auto",
        // backgroundColor: "card.background",
        px: {
            xs: 1,
            sm: props.px
        },
        // py: 5
        py: 1,
        position: "relative", 
        top: props.top, 
        zIndex: 20,
    }}
        component={motion.div}
        initial={{
            opacity: 0
        }}
        animate={{
            opacity: 1,
            transition: {
                delay: 1
            }
        }}
    >
        <Box sx={{
            backgroundColor: "veryLightBlue.main",
            p: 2,
            borderRadius: 2,
        }}>
            <Stack spacing={{xs: 3, md: 0}} direction={{xs: "column", md: "row"}} justifyContent="space-between" alignItems="center">
                <ToggleButtonGroup
                    size='small'
                    value={searchData.trip}
                    exclusive
                    onChange={(event, value) => {
                        value !== null && setSearchData(prev => ({...prev, trip: value, isCombinable: false}));
                        value === "multi" ? setSearchData(prev => ({...prev, isMultiCity: true})) : setSearchData(prev => ({...prev, isMultiCity: false}));
                    }}
                >
                    <ToggleButton sx={toggleStyle} value="twoway">{t("roundTrip")}</ToggleButton>
                    <ToggleButton sx={toggleStyle} value="oneway">{t("oneWay")}</ToggleButton>
                    <ToggleButton sx={toggleStyle} value="multi">{t("multicity")}</ToggleButton>
                </ToggleButtonGroup>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <label className="corporate-fare-switch">
                        <input 
                            type="checkbox"
                            name="corporateFares"
                            checked={searchData.corporateFare}
                            onChange={() => setSearchData(prev => ({...prev, corporateFare: !prev.corporateFare}))}
                        />
                        <span className="corporate-fare-slider"></span>
                    </label>
                    <Typography fontWeight="bold" variant='body1' color={searchData.corporateFare ? "error" : "primary.main"}>ERRANCES { t("specialEthnicFares") }</Typography>
                    <Box sx={{position: "relative" }}>
                        {/* <img style={{ height: "60px", width: "60px", objectFit: "cover", position: "absolute", left: "20%" }} src={offerLogo} alt="offer-logo" /> */}
                        {/* <button className='premium-glow'>
                            {t("offer")}
                        </button> */}
                        <Lottie 
                            style={{
                                backgroundColor: "transparent",
                                margin: "0 auto",
                                height: 70,
                                width: 70,
                                marginTop: "-35px"
                            }}
                            loop={true}
                            play={true}
                            animationData={specialFaresAnimation}
                            rendererSettings= {{
                                preserveAspectRatio: "xMidYMid slice"
                            }}
                        />
                    </Box>
                </Box>
            </Stack>
            {searchData.trip === "twoway" && <Stack>
                <FormGroup sx={{ mx: { xs: "auto", md: 0 } }}>
                    <FormControlLabel sx={{ color: "primary.main" }}  control={<Checkbox sx={checkboxStyle} checked={searchData.isCombinable} onChange={() => setSearchData(prev => ({...prev, isCombinable: !searchData.isCombinable}))} />} label={<Typography>{t("chooseYourInboundAndOutboundFlightsSeparately")}</Typography>} />
                </FormGroup>
            </Stack>}
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                mt: 2,
                gap: 3,
            }}>
                <Stack direction={{xs: "column", md: "row"}} spacing={3}>
                    <Autocomplete
                        fullWidth
                        loading={isLoading}
                        onChange={(event, newVal) => newVal && setSearchData(prev => ({...prev, origin: newVal.IATA, originName: newVal.name}))}
                        size="medium"
                        options={airports}
                        defaultValue={{"name": "Charles de Gaulle Intl ", "city": "Paris", "country": "France", "IATA": "CDG" }}
                        getOptionLabel={(option) => `${capitalize(option.name)}(${option.IATA}), ${option.city}`}
                        // isOptionEqualToValue={(option, value) =>  console.log(option.name, " ---- ", value.name, option.name === value.name)}
                        isOptionEqualToValue={(option, value) => true}
                        // isOptionEqualToValue={(option, value) => option.IATA === value.IATA}
                        noOptionsText="No flights available"
                        blurOnSelect
                        renderInput={(params) => (
                            <TextField
                                // error={!searchData.origin}
                                // helperText={!searchData.origin && "Please choose your origin"}
                                {...params}
                                sx={inputStyle}
                                label={t("from")}
                                value={originKey.searchKey}
                                onChange={(e) => setOriginKey(prev => ({...prev, searchKey: e.target.value}))}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off',
                                }}
                            />
                        )}
                    />
                    
                    <Autocomplete
                        fullWidth
                        loading={isLoading}
                        onChange={(event, newVal) => newVal && setSearchData(prev => ({...prev, destination: newVal.IATA, destinationName: newVal.name}))}
                        size="medium"
                        options={airports}
                        getOptionLabel={(option) => `${capitalize(option.name)}(${option.IATA}), ${option.city}`}
                        // isOptionEqualToValue={(option, value) =>  console.log(option.name, " ---- ", value.name, option.name === value.name)}
                        isOptionEqualToValue={(option, value) => true}
                        noOptionsText="No flights available"
                        blurOnSelect
                        renderInput={(params) => (
                            <TextField
                                // error={!searchData.destination}
                                // helperText={!searchData.destination && "Please choose destination"}
                                // focused={searchData.origin}
                                sx={inputStyle}
                                autoComplete='off'
                                {...params}
                                value={destinationKey.searchKey}
                                onChange={(e) => setDestinationKey(prev => ({...prev, searchKey: e.target.value}))}
                                label={t("to")}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off',
                                }}
                            />
                        )}
                    />

                    <LocalizationProvider 
                        adapterLocale={i18n?.language}
                        dateAdapter={AdapterDayjs}
                    >
                        <MobileDatePicker
                            disablePast
                            label={t("departureDate")}
                            format='DD-MM-YYYY'
                            value={searchData.departureDate ? dayjs(searchData.departureDate) : null}
                            onChange={(newValue) => {
                                if(newValue !== null){
                                    setSearchData(prev => ({...prev, departureDate: dayjs(newValue["$d"]).format("YYYY-MM-DD")}));
                                    setMultiCityData(prev => {
                                        prev[0].minDepartureDate = dayjs(newValue["$d"]).format("YYYY-MM-DD")
                                        return prev;
                                    })
                                }
                            }}
                            slots={{
                                textField: (params) => <TextField 
                                    size="medium" fullWidth {...params}
                                    sx={inputStyle} 
                                />
                            }}
                            
                        />
                    </LocalizationProvider>
                    {searchData.trip === "twoway" && 
                        <LocalizationProvider 
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n?.language}
                        >
                            <MobileDatePicker
                                disablePast
                                format='DD-MM-YYYY'
                                minDate={dayjs(searchData.departureDate)}
                                label={t("returnDate")}
                                value={searchData.returnDate ? dayjs(searchData.returnDate) : null}
                                onChange={(newValue) => newValue !== null && setSearchData(prev => ({...prev, returnDate: dayjs(newValue["$d"]).format("YYYY-MM-DD")}))}
                                slots={{
                                    textField: (params) => <TextField 
                                        size="medium" fullWidth {...params} 
                                        sx={inputStyle}
                                    />
                                }}
                            />
                        </LocalizationProvider>
                    }

                    <TextField
                        sx={inputStyle}
                        ref={anchorRef}
                        fullWidth
                        size='medium'
                        label={t("selectNoOfPassengers")}
                        onClick={handleToggle}
                        value={`${adultCount + childrenCount + infantCount} ${t("passengers")}`}
                        // onChange={(e) => {
                        //     return e.target.value > 0 && e.target.value <= 9 ? setSearchData(prev => ({...prev, infants: e.target.value})) :  setSearchData(prev => ({...prev, infants: "0"}))
                        // }}
                    />
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                        >
                        {({ TransitionProps, placement }) => (
                            <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                            >
                            <Paper sx={{p: 2}}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <Stack direction="row" py={1} gap={2} justifyContent="space-between" alignItems="center">
                                            <Typography>{t("adult")}</Typography>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <IconButton onClick={decreaseAdultCount}>
                                                <RemoveCircleOutline/>
                                                </IconButton>
                                                <Typography>{adultCount}</Typography>
                                                <IconButton onClick={increaseAdultCount}>
                                                    <AddCircleOutline></AddCircleOutline>
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" py={1} gap={2} justifyContent="space-between" alignItems="center">
                                            <Typography>{t("children")}</Typography>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <IconButton onClick={decreaseChildrenCount}>
                                                <RemoveCircleOutline/>
                                                </IconButton>
                                                <Typography>{childrenCount}</Typography>
                                                <IconButton onClick={increaseChildrenCount}>
                                                    <AddCircleOutline></AddCircleOutline>
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" py={1} gap={2} justifyContent="space-between" alignItems="center">
                                            <Typography>{t("infants")}</Typography>
                                            <Stack direction="row" spacing={1} alignItems='center'>
                                                <IconButton onClick={decreaseInfantCount}>
                                                <RemoveCircleOutline/>
                                                </IconButton>
                                                <Typography>{infantCount}</Typography>
                                                <IconButton onClick={increaseInfantCount}>
                                                    <AddCircleOutline></AddCircleOutline>
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                            </Grow>
                        )}
                        </Popper>

                    <TextField
                        fullWidth
                        sx={inputStyle}
                        size='medium'
                        select
                        label={t("class")}
                        value={searchData.class}
                        onChange={(event) => setSearchData(prev => ({...prev, class: event.target.value}))}

                    >
                        <MenuItem value="ECONOMY">{t("economy")}</MenuItem>
                        <MenuItem value="BUSINESS">{t("business")}</MenuItem>
                        <MenuItem value="FIRSTCLASS">{t("firstClass")}</MenuItem>
                    </TextField>
                </Stack>
                {searchData.trip === "multi" && 
                    <>
                        {multiCityData.map((objs, i) => (
                            <MultiCityInputs
                                key={i} 
                                index={i} 
                                objs={objs} 
                                setMultiCityData={setMultiCityData}
                            />
                        ))}

                        {multiCityData.length > 1 && 
                            <Box sx={{m: "0 auto"}} >
                                <Tooltip title="Remove itinerary" placement='top'>
                                    <IconButton sx={{color: 'primary.main'}} onClick={() => setMultiCityData(prev => {
                                        prev.pop()
                                        return [...prev]
                                    })}>
                                        <Delete/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        }

                        {multiCityData.length <= 4 && 
                            <BlueButtonDashed 
                                sx={{maxWidth: 200, m: "0 auto"}} 
                                variant='outlined'
                                onClick={() => setMultiCityData(prev => [...prev, {
                                    origin: "",
                                    originName: "",
                                    destination: "",
                                    destinationName: "",
                                    departureDate: null,
                                    minDepartureDate: prev[prev.length - 1].departureDate
                                }])}
                            >
                                + Add new city
                            </BlueButtonDashed>
                        }
                    </>
                }
                <BlueButton 
                    sx={{maxWidth: 200, m: "0 auto", border: "2px solid white"}} 
                    size='large'  
                    onClick={initiateSearch}
                >
                    <FiSend/>
                    {t("showFlights")}
                </BlueButton>
                <EthnicFaresModal
                    openEthnicFaresModal={openEthnicFaresModal}
                    setOpenEthnicFaresModal={setOpenEthnicFaresModal}
                    triggerSearch={triggerSearch}
                    searchData={searchData}
                    setSearchData={setSearchData}
                    setTriggerFlightSearchThroughEthnicFaresModal={setTriggerFlightSearchThroughEthnicFaresModal}
                />
            </Box>
        </Box>
    </Box>
    </>
  )
}

export default SearchFlightBox