import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { ExpandMore } from '@mui/icons-material';
import { MdFlightLand, MdFlightTakeoff } from 'react-icons/md';
import { getAirports } from '../../../Lib/utils/helperFunctions';
import ViewFareRulesModal from '../Modals/ViewFareRulesModal';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';


function BookingDetailsCard({index, fareDetails, bookingDetails, includedBaggages, from, fromCity, fromCountry, to, toCity, toCountry, totalPassengers}) {

    const [ expanded, setExpanded ] = useState([ 0 ]);
    const { t } = useTranslation();

    const handleChange = (panel) => {
        if(expanded.includes(panel)){
            setExpanded(prevState => prevState.filter(data => data !== panel));
        }else{
            setExpanded(prevState => [...prevState, panel]);
        }
    };

  return (
        <Stack sx={{backgroundColor: "card.background", boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.08)", borderRadius: 3, overflow: "hidden"}}>
            <Accordion 
                expanded={expanded.includes(index)} 
                elevation={0} 
                sx={{px: {xs: 0, md: 2}}}
                // onChange={() => handleChange(index)}
            >
                <AccordionSummary
                    expandIcon={
                        <IconButton onClick={() => handleChange(index)} sx={{ boxShadow: "0px 6px 16px rgba(8, 15, 52, 0.08)"}}>
                            <ExpandMore />
                        </IconButton>
                    }
                >
                    <Stack spacing={2} >
                        <Stack direction="row" alignItems="center" spacing={{xs: 2, sm: 5}}>
                            <Box>
                                <Typography color="text.main" variant='h5'>{bookingDetails.data[0].originCity}</Typography>
                            </Box>
                            <HiArrowNarrowRight/>
                            <Box>
                                <Typography color="text.main" variant='h5'>{bookingDetails.data[bookingDetails.data.length - 1].destinationCity}</Typography>
                            </Box>
                            {fareDetails && 
                                <Box>
                                    <ViewFareRulesModal fareDetails={fareDetails}  />
                                </Box>
                            }
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" alignItems="center"  >
                            <Stack direction="row" spacing={{xs:0.5,md:2}} alignItems="center" flexWrap="wrap">
                                <Box sx={{
                                    backgroundColor: "veryLightBlue.main",
                                    borderRadius: 2,
                                    p: "5px"
                                }}>
                                    <Typography  variant='subtitle1'>{dayjs(bookingDetails.data[0].departureDate).format("dddd, MMMM DD, YYYY")}</Typography>
                                </Box>
                                <Box>
                                    <Typography  variant='subtitle2'>{parseInt(bookingDetails.info.stops) === 1 ? t("oneStop") : parseInt(bookingDetails.info.stops) === 2 ? t("twoStops") : t("nonStop")}</Typography>
                                </Box>
                                <Box >
                                    <Typography   variant='subtitle2' >{t("travelDuration")}: {bookingDetails.info.totalDuration}</Typography>
                                </Box>
                                <Box>
                                    <Typography  variant='subtitle2'>{t("noOfPassenger")}: {totalPassengers}</Typography>
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{wordBreak: "break-word"}}>
                    {/* <Stack direction="row" justifyContent="space-between" alignItems="center" >
                        <Stack direction={{xs: "row", sm: "row"}} spacing={{xs:1.5,md:3}}  alignItems="center">
                            <Box 
                                sx={{
                                    height: "70px", 
                                    width: "70px", 
                                    borderRadius: "50%", 
                                    overflow: "hidden"
                                }}
                            >
                                <img src={bookingDetails.data[0].airlinesLogo} alt="img" height="100%" width="100%" style={{objectFit: "cover"}} />
                            </Box>
                            <Box 
                                sx={{
                                    display:"flex", 
                                    flexDirection:"column", 
                                    gap:1
                                }}
                            >

                                <Typography variant='h5' color="text.main">{bookingDetails.data[0].airlines}</Typography> 
                                <Typography variant='subtitle2'  >{bookingDetails.data[0].flightNumber}</Typography>
                            </Box>
                        </Stack>
                    </Stack> */}
                    <Stack direction="row" justifyContent="space-between">
                        <Stack width="100%">
                            {bookingDetails.data.map((data, index) => (
                                <Stack key={`BookingDetailsCard-${index}`}>
                                    <Stack direction={{xs: "row", sm: "row"}} spacing={{ xs:1.5, md:3 }} py={2} alignItems="center">
                                        <Box 
                                            sx={{
                                                height: "50px", 
                                                width: "50px", 
                                                borderRadius: "50%", 
                                                overflow: "hidden"
                                            }}
                                        >
                                            <img src={data.airlinesLogo} alt="img" height="100%" width="100%" style={{objectFit: "cover"}} />
                                        </Box>
                                        <Box 
                                            sx={{
                                                display:"flex", 
                                                flexDirection:"column", 
                                                gap:1
                                            }}
                                        >
            
                                            <Typography variant='h5' color="text.main">{data.airlines}</Typography> 
                                            <Typography variant='subtitle2'  >{data.flightNumber}</Typography>
                                        </Box>
                                    </Stack>
                                    <Stack direction={{ xs: "column", md: "row" }} alignItems={{ xs: "center", md: "flex-start" }}>
                                        <Timeline position="right" sx={{padding: 0, margin: 0}}>
                                            <TimelineItem>
                                                <TimelineOppositeContent sx={{maxWidth: "100px", paddingLeft: 0, paddingRight: '10px'}}>
                                                    <Stack direction="row" alignItems="center" spacing={1}>
                                                        <Typography variant='body1' fontWeight="bold">{t("departAt")}</Typography> 
                                                        <MdFlightTakeoff style={{fontSize: 20}}/>
                                                    </Stack>
                                                    <Typography variant='h6'>{dayjs(data.departureDate).format("DD/MM/YYYY")}</Typography>
                                                    <Typography variant='h6'>{dayjs(`${data.departureDate} ${data.originTime}`).format("HH:mm")}</Typography>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot color='primary' />
                                                    <TimelineConnector sx={{height: 120}} />
                                                </TimelineSeparator>
                                                <TimelineContent> 
                                                    <Stack direction="row" justifyContent="space-between">
                                                        <Stack>
                                                            <Typography variant='h6'>{getAirports(data.origin).name}({data.origin}), {data.originCity}</Typography>  
                                                            <Typography variant='h6'>{t("terminal")}: {data.originTerminal ? data.originTerminal : "not available"}</Typography>
                                                            <Typography variant='h6'>{t("travelDuration")}: {data.duration}</Typography> 
                                                        </Stack>
                                                    </Stack>
                                                </TimelineContent>
                                            </TimelineItem>
                                            <TimelineItem>
                                                <TimelineOppositeContent sx={{maxWidth: "100px", paddingLeft: 0, paddingRight: '10px'}}>
                                                    <Stack direction="row" alignItems="center" spacing={1}>
                                                        <Typography variant='body1' fontWeight="bold">{t("arriveAt")}</Typography> 
                                                        <MdFlightLand style={{fontSize: 20}}/>
                                                    </Stack>
                                                    <Typography variant='h6'>{dayjs(data.destinationDate).format("DD/MM/YYYY")}</Typography>
                                                    <Typography variant='h6'>{dayjs(`${data.destinationDate} ${data.destinationTime}`).format("HH:mm")}</Typography>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot color="primary" />
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Typography variant='h6'>{getAirports(data.destination).name}({data.destination}), {data.destinationCity}</Typography>  
                                                    <Typography variant='h6'>{t("terminal")}: {data.destinationTerminal ? data.destinationTerminal : "not available"}</Typography>  
                                                </TimelineContent>
                                            </TimelineItem>
                                        </Timeline>
                                        <Stack direction="column" spacing={2}>
                                            <Stack direction="row" spacing={5}>
                                                <Typography variant='h6' fontWeight="bold">{t("baggageIncluded")}</Typography>
                                                <Typography variant='h6' fontWeight="bold">{t("quantity")}</Typography>
                                            </Stack>
                                            {data.adultBaggage && <Stack direction="row" spacing={15}>
                                                <Typography variant='body1' width="100%">{t("adult")}</Typography>
                                                {data.adultBaggage && data.adultBaggage.quantity && <Typography width="50%" variant='body1'>{data.adultBaggage.quantity}</Typography>}
                                                {data.adultBaggage && data.adultBaggage.weight && <Typography width="50%" variant='body1'>{data.adultBaggage.weight} {data.adultBaggage.weightUnit}</Typography>}
                                            </Stack>}
                                            {data.childBaggage && <Stack direction="row" spacing={15}>
                                                <Typography variant='body1' width="100%">{t("children")}</Typography>
                                                {data.childBaggage && data.childBaggage.quantity && <Typography variant='body1' width="50%">{data.childBaggage.quantity}</Typography>}
                                                {data.childBaggage && data.childBaggage.weight && <Typography width="50%" textAlign="right" variant='body1'>{data.childBaggage.weight} {data.childBaggage.weightUnit}</Typography>}
                                            </Stack>}
                                            {data.infantBaggage && <Stack direction="row" spacing={15}>
                                                <Typography variant='body1' width="100%">{t("infants")}</Typography>
                                                {data.infantBaggage && data.infantBaggage.quantity && <Typography width="50%" variant='body1'>{data.infantBaggage.quantity}</Typography>}
                                                {data.infantBaggage && data.infantBaggage.weight && <Typography width="50%" textAlign="right" variant='body1'>{data.infantBaggage.weight} {data.infantBaggage.weightUnit}</Typography>}
                                                
                                            </Stack>}
                                        </Stack>
                                    </Stack>

                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Stack>
  )
}

export default BookingDetailsCard