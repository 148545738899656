import React from 'react';
import { Box, Button, Card, Checkbox, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { AnchorText, BlueBox, BlueButton, NonStopConnector, OneStopConnector, TwoStopConnector, WhiteCard } from "../../../Lib/MuiThemes/MuiComponents";
import { FormattedNumber } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { t } from 'i18next';
import { MdFlightLand, MdFlightTakeoff } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { removeFlightPricePayload, setFlightPricePayload } from '../../../Lib/Redux/FlightPricePayloadSlice';
import { getAirports, getCurrencySymbol } from '../../../Lib/utils/helperFunctions';

// css for premium-glow button
import "../../FightSearch/styles/search_box.css"
import dayjs from 'dayjs';
import Lottie from 'react-lottie-player';
import { confetti, offerLogo } from '../../../Assests/assets';

const style = {
    width: "100px",
    borderRadius: 2,
    boxShadow: "0px 2px 10px rgba(155, 157, 172, 0.3)",
    color: "black",
    backgroundColor: "white",
    "&:hover": {
        backgroundColor: "transparent",
    },
    "&.Mui-checked": {
        backgroundColor: "primary.main",
        color: "white"
    },
    "&.Mui-disabled": {
        backgroundColor: "veryLightBlue.main"
    }
}

function FlightListCard({cardData, combinableFlightTabFocus}) {

//   const [openFlightStopsModal, setOpenFlightStopsModal] = useState(false);
  const [ searchParams ] = useSearchParams();
  const dispatch = useDispatch();
  const flightPricePayload = useSelector(data => data.flightPricePayload);
  const navigate = useNavigate();
  const cardDataArray = Array.isArray(cardData.origin) && cardData.origin.map((data, index) => {
    return {
        airlines: cardData.airlines[index],
        airlinesName: cardData.airlinesName[index],
        destination: cardData.destination[index],
        destinationTime: cardData.destinationTime[index],
        duration: cardData.duration[index],
        flightNumber: cardData.flightNumber[index],
        origin: data,
        originDate: cardData.originDate[index],
        originTime: cardData.originTime[index],
        stops: cardData.stops[index],
        logo: cardData.logo[index]
    }
  })


  async function triggerFlightPrice(){
    navigate("/bookingdetails", { state: { 
        dictionary: flightPricePayload.dictionary, 
        data: [cardData.seatObj[0]],
        searchKeys: {
            origin: searchParams.get("origin"),
            destination: searchParams.get("destination"),
            departureDate: searchParams.get("departureDate"),
            trip: searchParams.get("tripType")
        },
        flightNumber: cardData.flightNumber,
        refId: sessionStorage.getItem("refId")
    }});
  }

  return (
    <Card sx={{ p: 1, boxShadow: "0px 4px 16px rgba(141, 211, 187, 0.15)", display: "flex", alignItems: "center", justifyContent: "center" }}>
        
        <Stack width="100%">
            {Array.isArray(cardData.origin) ? cardDataArray?.map((details, i) => {
                return(
                    <FlightDetails
                        key={i}
                        tripName={`${t("trip")} ${i + 1}`}
                        tripType={searchParams.get("tripType")}
                        departureDate={details.originDate}
                        airlinesName={details.airlinesName}
                        flightNumber={details.flightNumber} 
                        originTime={details.originTime}
                        origin={details.origin}
                        destination={details.destination} 
                        duration={details.duration}
                        stops={details.stops}
                        destinationTime={details.destinationTime}
                        logo={details.logo}
                    />
                )
            }) : 
                <Stack direction={{ xs: "column", md: 'row' }} justifyContent="space-between">
                    <Stack>
                        <FlightDetails
                            tripName={t("departure")}
                            departureDate={cardData.originDate}
                            airlinesName={cardData.airlinesName}
                            flightNumber={cardData.flightNumber} 
                            originTime={cardData.originTime}
                            logo={cardData.logo}
                            origin={cardData.origin}
                            destination={cardData.destination} 
                            duration={cardData.duration}
                            stops={cardData.stops}
                            destinationTime={cardData.destinationTime}
                        />
                        {
                            (!!cardData.returnAirlines && !!cardData.returnAirlinesName && !!cardData.returnDestination && !!cardData.returnDestinationTime
                                && !!cardData.returnDuration && !!cardData.returnFlightNumber
                                && !!cardData.returnOrigin && !!cardData.returnOriginTime)
                            && 
                            <FlightDetails
                                tripName={t("return")}
                                departureDate={cardData.returnOriginDate}
                                airlinesName={cardData.returnAirlinesName}
                                flightNumber={cardData.returnFlightNumber} 
                                originTime={cardData.returnOriginTime}
                                logo={cardData.returnLogo}
                                origin={cardData.returnOrigin}
                                destination={cardData.returnDestination} 
                                duration={cardData.returnDuration}
                                stops={cardData.returnStops}
                                destinationTime={cardData.returnDestinationTime}
                            />
                        }
                    </Stack>
                    <Stack alignItems="center" spacing={1} direction="column" width={{xs:"100%",md:"20%"}} >
                        {cardData.fareType !== "PUBLISHED" && 
                            <Box sx={{position: "relative" }}>
                                <img style={{ height: "60px", width: "60px", objectFit: "cover", position: "absolute", left: "20%" }} src={offerLogo} alt="offer-logo" />
                                <Lottie 
                                    style={{
                                        height: 50,
                                        width: 100,
                                        backgroundColor: "transparent",
                                        margin: "0 auto",
                                    }}
                                    loop={true}
                                    play={true}
                                    animationData={confetti}
                                    rendererSettings= {{
                                        preserveAspectRatio: "xMidYMid slice"
                                    }}
                                />
                            </Box>
                        }
                        
                        <Typography variant="subtitle1" color="text.main">{t("ticketFare")}</Typography>
                        <AnchorText variant="h4">{getCurrencySymbol(cardData.currency)}{Math.ceil(cardData.totalPrice)}</AnchorText>
                        <Typography variant="subtitle1" color="text.main">({t("inclusiveOfAllTaxes")})</Typography>
                        {!!parseInt(searchParams.get("isCombinable")) ? 
                            <Checkbox
                                sx={style}
                                icon={<Typography>Select</Typography>} 
                                checkedIcon={<Typography>Selected</Typography>}
                                value={cardData.seatObj[0]}
                                checked={flightPricePayload.data.includes(cardData.seatObj[0])}
                                onChange={(e) => {
                                    if(flightPricePayload.data.indexOf(cardData.seatObj[0]) === -1){
                                        dispatch(setFlightPricePayload({index: parseInt(combinableFlightTabFocus), value: cardData.seatObj[0]}))
                                    }else{
                                        dispatch(removeFlightPricePayload(parseInt(combinableFlightTabFocus)))

                                    }
                                }}
                            />
                        : <BlueButton fullWidth onClick={triggerFlightPrice}>{t("bookNow")}</BlueButton>}
                    </Stack>
                </Stack>
            }
        </Stack>
        <Stack display={searchParams.get("tripType") === "multi" ? "flex" : "none"} width="100%" direction="column" alignItems="center" >
            {cardData.fareType !== "PUBLISHED" && 
                <Box sx={{position: "relative" }}>
                    <img style={{ height: "60px", width: "60px", objectFit: "cover", position: "absolute", left: "20%" }} src={offerLogo} alt="offer-logo" />
                    <Lottie 
                        style={{
                            height: 50,
                            width: 100,
                            backgroundColor: "transparent",
                            margin: "0 auto",
                        }}
                        loop={true}
                        play={true}
                        animationData={confetti}
                        rendererSettings= {{
                            preserveAspectRatio: "xMidYMid slice"
                        }}
                    />
                </Box>
            }
            <Stack spacing={1} alignItems="center" py={1}>
                <Typography variant="subtitle1" color="text.main">{t("ticketFare")}</Typography>
                <AnchorText variant="h4">{getCurrencySymbol(cardData.currency)}{Math.ceil(cardData.totalPrice)}</AnchorText>
                <Typography variant="subtitle1" color="text.main">({t("inclusiveOfAllTaxes")})</Typography>
                <Button sx={{ width: "fit-content" }} variant='contained' onClick={triggerFlightPrice}>{t("bookNow")}</Button>
            </Stack>
        </Stack>
    </Card>
  )
}

export default FlightListCard


function FlightDetails({airlinesName, flightNumber, originTime, origin, logo, destination, duration, stops, destinationTime, tripName, departureDate, tripType}){

    const [ searchParams ] = useSearchParams();

    return(
    <Stack spacing={2} width={"100%"} >
        <Stack spacing={1} alignItems="flex-start" >
            {!parseInt(searchParams.get("isCombinable")) && <Typography variant='body1' color="text.secondary">{tripName}: </Typography>}
            <Typography variant='body1' color="text.secondary">{ getAirports(origin).name }({origin}) to { getAirports(destination).name }({destination}) | { dayjs(`${departureDate} ${originTime}`).format("MMM DD, YYYY, HH:mm") }</Typography>
        </Stack>
        <Stack spacing={1} direction={{xs:"column",md:'row'}} >
            <Stack direction="row" spacing={2} alignItems="center" width={250}>
                <BlueBox sx={{borderRadius: {xs: "50%",md: 2}, p: 0.5}}>
                    <Box sx={{
                        height: "50px", 
                        maxWidth: "50px", 
                        borderRadius: {xs: "50%",md: 2}, 
                        overflow: "hidden",
                    }}>
                        <img src={logo} alt="img" height="100%" width="100%" style={{objectFit: "cover"}} />
                    </Box>
                </BlueBox>
                <Stack spacing={1}>
                    <Typography variant='h4' color="text.main">{airlinesName}</Typography>
                    <Typography variant='body2'>{flightNumber}</Typography>
                </Stack>
           </Stack>
           <Stack direction="row" spacing={3} justifyContent="space-between" >
                <Stack alignItems="center" spacing={1}  width={{xs:"75px",md:"100px"}}>
                        <MdFlightTakeoff style={{fontSize: 20, color: "gray"}}/>
                        <Typography color="text.main" variant='h4'  sx={{textAlign:"center"}}>{dayjs(`${departureDate} ${originTime}`).format("HH:mm")}</Typography>
                        <Typography variant='body2' sx={{textAlign:"center"}}>{getAirports(origin).name}</Typography>
                </Stack>
                <Stack alignItems="center" spacing={1} sx={{width:{xs:"100px",md:"200px"}}} >
                        <Typography variant="h6" color="primary.main">{duration}</Typography>
                        {/* <Divider sx={{width: 150, backgroundColor: "#1DAD3C", p: "2px", borderRadius: 10}}></Divider> */}
                        {stops === 0 && <NonStopConnector/>}
                        {stops === 1 && <OneStopConnector/>}
                        {stops === 2 && <TwoStopConnector/>}
                        <Typography variant="subtitle2">{stops === 2 ? t("twoStops") : stops === 1 ? t("oneStop") : t("nonStop")}</Typography>
                </Stack>
                <Stack alignItems="center" spacing={1} width={{xs:"75px",md:"100px"}}>
                        <MdFlightLand style={{fontSize: 20, color: "gray"}}/>
                        <Typography color="text.main" variant='h4'sx={{textAlign:"center"}}>{dayjs(`${departureDate} ${destinationTime}`).format("HH:mm")}</Typography>
                        <Typography variant='body2' sx={{textAlign:"center"}} >{getAirports(destination).name}</Typography>
                </Stack>
           </Stack>
        </Stack>
    </Stack>
    )
}

